// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ApproveCard_ApproveCardLocation__NHIoJ {\n  height: 20px;\n  width: 20px;\n  background: rgb(249, 249, 249);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: rgb(57, 191, 143);\n}", "",{"version":3,"sources":["webpack://./src/components/common/card/approve-card/ApproveCard.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,wBAAA;AACF","sourcesContent":[".ApproveCardLocation {\n  height: 20px;\n  width: 20px;\n  background: rgb(249, 249, 249);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: rgb(57, 191, 143);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"ApproveCardLocation": "ApproveCard_ApproveCardLocation__NHIoJ"
};
export default ___CSS_LOADER_EXPORT___;
