import { authApi } from "../interceptor/auth.interceptor";

class CrumbService {

  static async getCrumbs(queryParams) {
    return await authApi
      .get(`crumbs/all?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getDeletedCrumb(queryParams) {
    return await authApi
      .get(`crumbs/status?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getCrumbDetails(queryParams) {
    return await authApi
      .get(`crumbs/audience?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getCardInfo(queryParams) {
    return await authApi
      .get(`audience/info?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async addCrumb(queryParams, formData) {
    return await authApi
      .post(`crumbs/save?${queryParams}`, formData)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async deleteCrumb(id, queryParams) {
    return await authApi
      .put(`crumbs/change-availability/${id}?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async activateCrumb(id, queryParams) {
    return await authApi
      .put(`crumbs/change-availability/${id}?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getBrands(queryParams) {
    return await authApi
      .get(`crumbs/brand-type?${queryParams}`)
      .then((response) => {
        return response?.data;
      })
  }

    static async updateCrumb(queryParams) {
        return await authApi
            .put(`crumbs/update?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

}

export default CrumbService;
