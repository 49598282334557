import { createSlice } from '@reduxjs/toolkit';

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState: {
    refreshTokenSchedule: null,
    userInteractionSchedule: null
  },
  reducers: {
    setRefreshTokenSchedule: (state, action) => {
      state.refreshTokenSchedule = action.payload;
    },
    setUserInteractionSchedule: (state, action) => {
      state.userInteractionSchedule = action.payload;
    }
  },
});

export const { setRefreshTokenSchedule, setUserInteractionSchedule } = scheduleSlice.actions;

export default scheduleSlice.reducer;
