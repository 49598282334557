import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useForm } from "react-hook-form";
import Image from 'mui-image';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import Compress from 'compress.js';
import { TickCircle } from 'iconsax-react';
import { CloseCircle } from 'iconsax-react';

import { setCreateCrumbModalState, setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import { errorMessage } from '../../../../config/config';
import CrumbService from '../../../../services/api/crumb.service';

import galleryAdd from '../../../../assets/images/icons/svg/gallery-add.svg';
import galleryAddBlack from '../../../../assets/images/icons/svg/gallery-add-black.svg';
import { useWindowWidth } from '../../../../utils/browser';

function CreateCrumbModal() {

  const browserWidth = useWindowWidth();
  const navigate = useNavigate();

  const { createCrumbModalState } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);

  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNameAvailable, setIsNameAvailable] = useState(null);
  // const [crumbs, setCrumbs] = useState('');
  const [crumbNames, setCrumbName] = useState('');

  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setCreateCrumbModalState(false));
      reset();
      setImageFile(null);
      setImageFileName(null);
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  // const handleFileChange = (event) => {
  //   const file = event.target.files[0];

  //   if (file && file.type.startsWith('image/')) {
  //     if (file.size > 2 * 1024 * 1024) {
  //       Resizer.imageFileResizer(
  //         file,
  //         'JPEG', // output format
  //         80, // quality
  //         0, // rotation
  //         (resizedImage) => {
  //           const resizedFileName = file.name;
  //           const resizedFile = new File([resizedImage], resizedFileName, { type: file.type });
  //           setImageFile(resizedFile);
  //           setImageFileName(resizedFileName);
  //         },
  //         'blob', // output type
  //         (error)=>{
  //           console.log(error)
  //         }
  //       );
  //     } else {
  //       // Use the original image if it's already under 2 MB
  //       setImageFile(file);
  //       setImageFileName(file.name);
  //     }
  //   } else {
  //     // Handle non-image files
  //     dispatch(setMessage({
  //       type: "warning",
  //       title: "please upload image file",
  //     }));
  //     dispatch(setMessageSnackbarStateState(true));
  //   }
  // };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {

      if (file.size > 2 * 1024 * 1024) {
        const compress = new Compress();
        compress.compress([file], {
          size: 2, // Set the desired max size in MB
          quality: 1, // Set the desired quality (0 to 1)
          maxWidth: 800 // Set the desired max width in pixels
        }).then((resizedImages) => {
          const resizedFileName = file.name;
          const resizedFile = Compress.convertBase64ToFile(resizedImages[0].data, resizedFileName);
          console.log(resizedFileName);
          setImageFile(resizedFile);
          setImageFileName(resizedFileName);
        }).catch((error) => {
          console.error(error);
          dispatch(setMessage({
            type: "error",
            title: "An error occurred while resizing the image",
            description: error.message
          }));
          dispatch(setMessageSnackbarStateState(true));
        });
      } else {
        // Use the original image if it's already under 2 MB
        setImageFile(file);
        setImageFileName(file.name);
      }
    } else if (!file && imageFile) {
      setImageFile(imageFile);
      setImageFileName(imageFile.name);
    } else {
      // Handle non-image files
      dispatch(setMessage({
        type: "warning",
        title: "Please Upload jpeg or png Formats",
      }));
      dispatch(setMessageSnackbarStateState(true));
    }
  };

  const handleChange = (event) => {
    const name = event.target.value.toLowerCase();
    const crumbList = JSON.parse(localStorage.getItem('crumbList'));
    const crumbName = crumbList.map(crumb => crumb.name.toLowerCase());
    setCrumbName(crumbName);

    if (crumbNames.includes(name)) {
      // console.log('Audience name already taken');
      setIsNameAvailable(false);
      dispatch(setMessage({
        type: "error",
        title: "Crumb Name Is Already Taken",
      }));
      dispatch(setMessageSnackbarStateState(true));
    } else {
      // console.log('Audience name available');
      setIsNameAvailable(true);
      dispatch(setMessage({
        type: "success",
        title: "Crumb Name Is Available",
      }));
      dispatch(setMessageSnackbarStateState(true));
    }

  }
  const onSubmit = (values) => {
    const nameRegex = /^[a-zA-Z0-9\s-]+$/;
    if (!nameRegex.test(values.name)) {
      if (/\s/.test(values.name)) {
        dispatch(setMessage({
          type: "error",
          title: "Name must not contain spaces",
        }));
      } else {
        dispatch(setMessage({
          type: "error",
          title: "Name must contain only letters and hyphens",
        }));
      }
      dispatch(setMessageSnackbarStateState(true));
      return;
    }
    if (!imageFile && !imageFileName) {
      dispatch(setMessage({
        type: "error",
        title: "Kindly upload an image"
      }));
      dispatch(setMessageSnackbarStateState(true));
      return;
    }
    const queryParams = [
      `name=${values.name.trim()}`,
      `description=${values.description}`,
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
    ];
    const formData = new FormData();
    formData.append('file', imageFile);
    setIsSubmitting(true);
    CrumbService.addCrumb(queryParams.join('&'), formData).then(
      (data) => {
        // console.log("data", data);
        setIsSubmitting(false);
        if (data?.results) {
          dispatch(setMessage({
            type: "success",
            title: data?.message ? data.message : "Crumb added successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));
          reset();
          setImageFileName(null);
          // const crumbsDummy = [data.results].concat([...crumbs]);
          // dispatch(setCrumbs(crumbsDummy));
          handleClose();
          navigate('/');
        } else {
          dispatch(setMessage({
            type: "error",
            title: data?.message ? data.message : errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
          setIsSubmitting(false);
        }
        return Promise.resolve();
      },
      (error) => {
        setIsSubmitting(false);
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: error.response.data.message,
        }));
        dispatch(setMessageSnackbarStateState(true));
        setIsSubmitting(false);
        return Promise.reject();
      }
    );
  };

  return (<>
    <Dialog
      open={createCrumbModalState}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Create A New Crumb</DialogTitle>
      <Divider />
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <TextField
            autoFocus
            InputProps={{
              endAdornment: isNameAvailable === true ? (
                <TickCircle size="22" color="green" variant='Bold' />
              ) : isNameAvailable === false ? (
                <CloseCircle size="22" color="red" variant='Bold' />
              ) : null,
            }}
            placeholder="Crumb Name ( e.g. Coffee Lovers - NYC )"
            InputLabelProps={{
              style: { color: "#6F7288" }
            }}
            focused
            id="crumbName"
            type="text"
            fullWidth
            variant="standard"
            sx={{ my: 2 }}
            error={!!errors['name']}
            helperText={errors['name'] ? errors['name'].message : ''}
            {...register("name", {
              required: "Name is required"
            })}
            onChange={handleChange}
          />
          <TextField
            placeholder="Description"
            InputLabelProps={{
              style: { color: "#6F7288" }
            }}
            id="standard-multiline-static"
            multiline
            rows={3}
            variant="standard"
            fullWidth
            sx={{ my: 2 }}
            error={!!errors['description']}
            helperText={errors['description'] ? errors['description'].message : ''}
            {...register("description")}
          />

          <Box className="UploadProfilePhoto" sx={{ position: 'relative', overflow: 'hidden', mb: 2 }}>
            <Box sx={{ py: 2, height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mr: 2, width: 30, height: 30 }}>
                <Image src={currentTheme === 'light' ? galleryAddBlack : galleryAdd} alt="" />
              </Box>
              <Box>{imageFileName ? imageFileName : "Upload an image"}</Box>
            </Box>
            <input
              style={{ zIndex: 2, display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
              type="file"
              {...register("image")}
              onChange={handleFileChange}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Create
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default CreateCrumbModal;