import { authApi } from "../interceptor/auth.interceptor";

class AudienceService {
  static async getAudienceDetails(queryParams) {
    return await authApi
      .get(`audience/v2/stats?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async addAudience(queryParams, formData) {
    return await authApi
      .post(`audience/save?${queryParams}`, formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async cloneAudience(queryParams, formData) {
    return await authApi
      .post(`audience/clone?${queryParams}`, formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async refreshAudience(queryParams) {
    return await authApi
      .post(`audience/refresh?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async deleteAudience(id, queryParams) {
    return await authApi
      .put(`audience/change-availability/${id}?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async deactivateAudience(id, queryParams) {
    return await authApi
      .put(`audience/change-availability/${id}?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async activateAudience(id, queryParams) {
    return await authApi
      .put(`audience/change-availability/${id}?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async likeAudience(id, queryParams) {
    return await authApi
      .put(`audience/like/${id}?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getAudience(queryParams) {
    return await authApi.get(`audience/all?${queryParams}`).then((response) => {
      return response?.data;
    });
  }

  static async getCampaignAudience(queryParams) {
    return await authApi.get(`audience/campaign?${queryParams}`).then((response) => {
      return response?.data;
    });
  }


  static async gobalSearch(queryParams) {
    return await authApi.get(`global/search?${queryParams}`).then((response) => {
      return response?.data;
    });
  }

  static async mergeAudience(queryParams) {
    return await authApi
      .get(`audience/v2/merge?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async downloadAudience(queryParams) {
    return await authApi
      // .get(`audience/poi/download?${queryParams}`, { responseType: "arraybuffer" })
      .get(`audience/poi/download?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async activateCampaign(queryParams) {
    return await authApi
      .post(`audience/activate-campaign?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }


  static async v2activateCampaign(queryParams) {
    return await authApi
        .post(`audience/v2/activate-campaign?${queryParams}`)
        .then((response) => {
          return response?.data;
        });
  }


  static async assignAudience(queryParams) {
    return await authApi
      .post(`user/assign-card?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async globalSearch(queryParams) {
    return await authApi
      .get(`global/search?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async audienceActivity(queryParams) {
    return await authApi
      .get(`audience/activate_history?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async renameAudience(queryParams, formData) {
    return await authApi
      .put(`audience/update?${queryParams}`, formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async compareAudience(queryParams) {
    return await authApi
      .post(`audience/compare?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async compareDetails(queryParams) {
    return await authApi
      .get(`audience/compare-details?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async activateCompare(queryParams) {
    return await authApi
      .post(`audience/activate-compare?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getApprovalAudience(queryParams) {
    return await authApi.get(`audience/client?${queryParams}`).then((response) => {
      return response?.data;
    });
  }

  static async getAudienceImages(queryParams) {
    return await authApi.get(`audience/all-audience-image?${queryParams}`).then((response) => {
      return response?.data;
    });
  }

  static async approveAudience(queryParams, formData) {
    return await authApi
        .put(`audience/approve?${queryParams}`, formData)
        .then((response) => {
          return response?.data;
        });
  }
}


export default AudienceService;
