import React, { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Grid } from "@mui/material";
import Image from "mui-image";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

import exportIcon from '../../../../assets/images/icons/svg/export.svg';
import exportColorIcon from '../../../../assets/images/icons/svg/send-square.svg';
import note from '../../../../assets/images/icons/svg/note.svg';
import noteBlack from '../../../../assets/images/icons/svg/note-black.svg';

import styles from './AudiencePageDetails.module.scss';
import { CardTick, Flash, Trash } from "iconsax-react";
import AudienceStatisticsDoughnutCollapse from "../../collapse/audience-statistics-doughnut-collapse/AudienceStatisticsDoughnutCollapse";
import AudienceAgeStatisticsDoughnutCollapse from "../../collapse/audience-age-statistics-doughnut-collapse/AudienceAgeStatisticsDoughnutCollapse";
import AudienceStatisticsColumnBarCollapse from "../../collapse/audience-statistics-column-bar-collapse/AudienceStatisticsColumnBarCollapse";
import { useWindowWidth } from "../../../../utils/browser";
import {useSelector} from "react-redux";
import {ReactComponent as IncLightIcon} from "../../../../assets/images/icons/svg/allpings_inc_light.svg";
import {ReactComponent as IncIcon} from "../../../../assets/images/icons/svg/allpings_inc.svg";
import {ReactComponent as DecLightIcon} from "../../../../assets/images/icons/svg/allpings_dec_light.svg";
import {ReactComponent as DecIcon} from "../../../../assets/images/icons/svg/allpings_dec.svg";
import {ReactComponent as StableLightIcon} from "../../../../assets/images/icons/svg/allpings_stable_light.svg";
import {ReactComponent as StableIcon} from "../../../../assets/images/icons/svg/allpings_stable.svg";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";


const LightTooltip = styled(({ className, currentTheme, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, currentTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: currentTheme === 'light' ? '#010412' : theme.palette.common.white,
    color: currentTheme === 'light' ? '#fff' : '#3C4DE7',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.tooltip} .MuiTooltip-arrow`]: {
    color: currentTheme === 'light' ? '#010412' : theme.palette.common.white,
  },
}));

const AudiencePageDetailsContent = ({ audienceDetails, isPdfGenerate, crumbStatInfo, handleAudienceDeleteConfirmModal, activateCampaignHandler, generatePDF, cloneCard, approveCard, selectedMenu, brandAffinity, brandCategories, cardCategories, expanded, handleExpand, ageRange, interest, gender, topContentDtoList, topDevices, os, deviceType, carrier, connectionType, languages, apps, deleteCardPermission, cloneCardPermission, activateCampaignCardPermission, exportCampaignCardPermission, currentTheme }) => {

  const browserWidth = useWindowWidth();
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth.auth);
  const [stateAudienceDetails, setStateAudienceDetails] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    setStateAudienceDetails(audienceDetails);
    // console.log("apps", apps);
  }, [apps]);

  const [loading, setLoading] = useState(false);

  const generatePDFloading = () => {
    setLoading(true); // Set loading to true when the button is clicked
    generatePDF();
    // Simulate generating PDF for a few seconds (replace this with your actual logic)
    setTimeout(() => {
      setLoading(false); // Set loading back to false after the loading time is complete
    }, 3000); // Change the delay time as needed (3 seconds in this example)
  };

  const IncreaseIcon = currentTheme === 'light' ? IncLightIcon : IncIcon;

  const DecreaseIcon = currentTheme === 'light' ? DecLightIcon : DecIcon;

  const StabledIcon = currentTheme === 'light' ? StableLightIcon : StableIcon;


  const getIcon = () => {

    if (audienceDetails.audienceCountStatus === true) {

      return <IncreaseIcon variant="Bold" sx={{ fontSize: 32 }} />;

    } else if (audienceDetails.audienceCountStatus === false) {

      return <DecreaseIcon variant="Bold" sx={{ fontSize: 32 }} />;

    } else {

      return <StabledIcon variant="Bold" sx={{ fontSize: 32 }} />;
    }
  };

  const getHoverContent = () => {
    const formatPercentage = (percentage) => {
      let sign = audienceDetails.audienceCountStatus ? '+' : '-';
      if (percentage.length === 1) {
        return `${sign}${percentage}.0%`; // Add a space for single-digit percentages
      }
      return `${sign}${percentage}%`;
    };

    const spanStyle = {
      width: '32px', // Ensure the span is a circle with a fixed width
      height: '32px', // Same as width to make it a circle
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%', // Make the span a circle
      fontSize: '12px', // Adjust text size as needed
    };

    const textStyle = {
      lineHeight: '1', // Ensure text is vertically centered
    };

    if (audienceDetails.audienceCountStatus === true) {
      return (
          <Box
              style={{
                ...spanStyle,
                color: '#39BF8F',
              }}
          >
            <span style={textStyle}>{formatPercentage(audienceDetails.audienceCountPercentage)}</span>
          </Box>
      );
    } else if (audienceDetails.audienceCountStatus === false) {
      return (
          <span
              style={{
                ...spanStyle,
                color: '#FF004F',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
          >
        <span style={textStyle}>{formatPercentage(audienceDetails.audienceCountPercentage)}</span>
      </span>
      );
    } else {
      return (
          <div style={{ paddingTop: '10px' }}>
            <HorizontalRuleIcon style={{ color: '#FFB11A' }} />
          </div>
      );
    }
  };


  return (<>
    {audienceDetails &&
      <Box className={`${styles.CrumbDetailsContent ?? ''}`} sx={{ fontSize: 20, lineHeight: '24px', fontWeight: 700 }}>
        <Grid
          container
          spacing={3.5}
          sx={{ mb: 6, alignItems: 'center' }}
          direction={browserWidth > 610 ? "row" : "column"}
        >
          <Grid item xs={4}>
            <Box className={`${styles.CrumbImage ?? ''}`} sx={{ borderRadius: '12px', overflow: 'hidden' }}>
              <Image src={audienceDetails.imagePath} alt="" width="auto" height="auto" />
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box className={`${styles.CrumbContent ?? ''}`} sx={{ display: 'flex', flexDirection: 'column' }}>

              <Box className={`${styles.CrumbTitleDelete ?? ''}`} sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                <Box className={`${styles.CrumbTitle ?? ''}`} sx={{ display: 'flex', fontSize: 32, fontWeight: '700', lineHeight: '38px' }}>{audienceDetails.name}</Box>
                {!isPdfGenerate && deleteCardPermission &&
                  <Box className={`${styles.CrumbDeleteAction ?? ''}`} sx={{ display: 'flex' }}>
                    <Button className={`${styles.CrumbDeleteActionButton ?? ''}`} sx={{ p: 1, width: 32, height: 32, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} onClick={() => handleAudienceDeleteConfirmModal()}>
                      <Trash variant="Bold" />
                    </Button>
                  </Box>
                }
              </Box>

              <Box className={`${styles.CrumbStatInfo ?? ''}`} sx={{ display: 'flex' }}>
                <Grid container spacing={3}>
                  {crumbStatInfo?.length > 0 && crumbStatInfo.map((stat, index) => {
                    const StatIcon = stat.icon;
                    return (
                      <Grid item xs={6} key={index}>
                        <Box className={`${styles.CrumbStatInfoBox ?? ''}`} sx={{ display: 'flex', mb: 2 }}>
                          <Box className={`${styles.CrumbStatInfoIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 20, height: 20, mr: 1.5, borderRadius: '50%', color: stat.iconColor }}>
                            <StatIcon size="12" />
                          </Box>
                          <Box className={`${styles.CrumbStatInfoTextNumber ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                            <Box className={`${styles.CrumbStatInfoText ?? ''}`} sx={{ display: 'flex', fontSize: 12, lineHeight: '15px', fontWeight: '400', mb: 1 }}>{stat.text}</Box>
                            <Box className={`${styles.CrumbStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '500', fontSize: 20, lineHeight: '24px' }}>{stat.number}</Box>
                          </Box>
                        </Box>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>

              {!isPdfGenerate &&
                <Box className={`${styles.CrumbAction ?? ''}`} sx={{ display: 'flex' }}>
                  {activateCampaignCardPermission &&
                    <Button className={`${styles.CrumbActionButton ?? ''}`} sx={{ p: 1.1, mr: 2, height: 40, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' }}
                            onClick={() => {
                              if (user.isExternalUser || user.isInternalUser) {
                                navigate('/campaigns', { state: { stateAudienceDetails } });
                              } else {
                                activateCampaignHandler();
                              }
                            }}
                    >
                      <Box className={`${styles.CrumbActionButtonIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', width: 20, height: 20, mr: 1.2, p: 0.5 }}>
                        <Flash variant="Bold" size="16" />
                      </Box>
                      <Box className={`${styles.CrumbActionButtonText ?? ''}`} sx={{ mr: 1, textTransform: 'capitalize', fontSize: 12, fontWeight: '400', lineHeight: '15px' }}>Activate Audience</Box>
                    </Button>
                  }

                  {exportCampaignCardPermission &&
                    <LightTooltip title={"Export PDF"} arrow placement="bottom" className={`${styles.CrumbActionButtonTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>
                      <Button onClick={() => generatePDFloading()} className={`${styles.CrumbActionButton ?? ''}`} sx={{ p: 1, width: 40, height: 40, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' }}>
                        {loading ? (<CircularProgress color="inherit" size={20} />
                        ) : (
                          <img src={currentTheme === 'light' ? exportColorIcon : exportIcon} alt="export" />)
                        }
                      </Button>
                    </LightTooltip>
                  }

                  {cloneCardPermission &&
                    <LightTooltip title={"Clone Card"} arrow placement="bottom" className={`${styles.CrumbActionButtonTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>
                      <Button onClick={() => cloneCard(audienceDetails)} className={`${styles.CrumbActionButton ?? ''}`} sx={{ p: 1, ml: 2, width: 40, height: 40, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' }}>
                        <img src={currentTheme === 'light' ? noteBlack : note} alt="note" />
                      </Button>
                    </LightTooltip>
                  }

                  {audienceDetails.isApprove &&
                    // {console.log(audienceDetails.isApprove)}
                    <LightTooltip title={"Approve Card"} arrow placement="bottom" className={`${styles.CrumbActionButtonTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>
                      <Button onClick={() => approveCard(audienceDetails)} className={`${styles.CrumbActionButton ?? ''}`} sx={{ p: 1, ml: 2, width: 40, height: 40, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' }}>
                        <CardTick size="32" color="#39BF8F" />
                      </Button>
                    </LightTooltip>
                  }

                  {/*<LightTooltip title={"Audience Indicator"} arrow placement="bottom" className={`${styles.CrumbActionButtonTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>*/}
                  {/*  <Button   sx={{ p: 1, ml: 2, width: 40, height: 40, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' }}>*/}
                  {/*    {isHovered ? getHoverContent() : getIcon()}*/}
                  {/*  </Button>*/}
                  {/*</LightTooltip>*/}

                  <LightTooltip title={"Audience Indicator"} arrow placement="bottom" className={`${styles.CrumbActionButtonTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
                    <Button  className={`${styles.CrumbActionButton ?? ''}`} sx={{ ml: 2, width: 40, height: 40, minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px' }}>
                      {isHovered ? getHoverContent() : getIcon()}
                    </Button>
                  </LightTooltip>
                </Box>
              }
            </Box>
          </Grid>

        </Grid>
        <Box className={`${styles.CrumbDetailsStatistics ?? ''}`}>

          {selectedMenu === "All" || selectedMenu === "Geographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Related Brands"
              stat={cardCategories}
              expanded={expanded}
              property={'cardCategories'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />

          ) : null}

          {selectedMenu === "All" || selectedMenu === "Geographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Brand Affinity"
              stat={brandAffinity}
              // titles="Brand Categories"
              // stats={brandCategories}
              expanded={expanded}
              property={'brandAffinity'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />

          ) : null}

          {selectedMenu === "All" || selectedMenu === "Geographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Brand Categories"
              stat={brandCategories}
              expanded={expanded}
              property={'brandCategories'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />

          ) : null}

          {selectedMenu === "All" || selectedMenu === "Psychographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Category"
              stat={interest}
              expanded={expanded}
              property={'interest'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Psychographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Interest"
              stat={topContentDtoList}
              expanded={expanded}
              property={'topContentDtoList'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Psychographic" ? (
            <AudienceStatisticsColumnBarCollapse
              title="Apps on Devices"
              stat={apps}
              expanded={expanded}
              property={'apps'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Demographic" ? (
            <AudienceAgeStatisticsDoughnutCollapse
              title="Age Range"
              stat={ageRange}
              expanded={expanded}
              property={'ageRange'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Demographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Gender"
              stat={gender}
              expanded={expanded}
              property={'gender'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Technographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Top Devices"
              stat={topDevices}
              expanded={expanded}
              property={'topDevices'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Technographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Device Type"
              stat={deviceType}
              expanded={expanded}
              property={'deviceType'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Technographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Operating System"
              stat={os}
              expanded={expanded}
              property={'os'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}


          {selectedMenu === "All" || selectedMenu === "Technographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Carrier"
              stat={carrier}
              expanded={expanded}
              property={'carrier'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Technographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Connection Type"
              stat={connectionType}
              expanded={expanded}
              property={'connectionType'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
            />
          ) : null}

          {selectedMenu === "All" || selectedMenu === "Technographic" ? (
            <AudienceStatisticsDoughnutCollapse
              title="Languages"
              stat={languages}
              expanded={expanded}
              property={'languages'}
              handleExpand={handleExpand}
              isPdfGenerate={isPdfGenerate}
              bottomLine={false}
            />
          ) : null}

        </Box>
      </Box>
    }
  </>);
};

export default AudiencePageDetailsContent;