import { Box } from "@mui/material";
import Image from "mui-image";
// import DoughnutWithText from "../../chart/DoughnutWithText";

const GeneralStatisticsDoughnut = ({ stat }) => {

  return (<>
    {stat &&
      <Box className="StatInfoArea" sx={{ /* mb: 3, pb: 3, borderBottom: '1px solid #6F7288', */ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <Box className="StatInfo" sx={{ mr: 2, display: 'flex', alignItems: 'center', width: 'calc(100% - 65px)' }}>
          <Box className="StatIcon" sx={{ p: 0.8, mr: 2, width: 32, height: 32, backgroundColor: stat.iconBackgroundColor, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Image src={stat.icon} alt="" width={22} height={22} />
          </Box>
          <Box className="StatText" sx={{ width: 'calc(100% - 70px)' }}>
            <Box className="StatTitle" sx={{ mb: 1, fontSize: 12, lineHeight: '14px', color: '#6F7288' }}>{stat.title}</Box>
            <Box className="StatValue ff-tomorrow" sx={{ fontSize: 14, lineHeight: '17px', fontWeight: 500, whiteSpace: 'nowrap' }}>{stat.value}</Box>
          </Box>
        </Box>
        {/* <Box className="StatChart" sx={{ display: 'flex', width: 65, height: 60 }}>
          <DoughnutWithText
            title={stat.chart.title}
            data={stat.chart.data}
          />
        </Box> */}
      </Box>
    }
  </>);
};

export default GeneralStatisticsDoughnut;