import React, { useRef, useState, useEffect } from "react";
import { mapboxApiToken } from "../../../config/config";
import {Box, CircularProgress, Icon} from "@mui/material";
import { debounce } from "../../../utils/utils";
import {connect, useDispatch, useSelector} from "react-redux";
import { Map, Popup, Source, Layer } from 'react-map-gl';
import useSupercluster from "use-supercluster";
import ClusterdPoints from "./ClusterdPoints";
import PolygonOverlay from "./PolygonOverlay";



const ClusterdMap = ({ selectedCountry, mapPoints, mapLoading, currentTheme, pointerState, polygonData }) => {

  const { enablePolygon } = useSelector((state) => state.common);
  const mapContainerRef = useRef("map-container");
  const mapRef = useRef(null);
  const [popupInfo, setPopupInfo] = useState(null);
  const [form, setForm] = React.useState({});
  // const points = mapPoints ? mapPoints.slice(0, 3000) : [];
  const pointsBlue = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "BLUE") : [];
  const pointsOrange = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "ORANGE") : [];
  const pointsGreen = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "GREEN") : [];
  const pointsPink = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "PINK") : [];
  const pointsSkyBlue = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "SKYBLUE") : [];
  const pointsRed = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "RED") : [];
  const pointsYellow = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "YELLOW") : [];
  const pointsPurple = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "PURPLE") : [];
  const pointsCyan = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "CYAN") : [];
  const pointsBrown = mapPoints ? mapPoints.filter(mp => mp.properties.colorName === "BROWN") : [];

 // const [enablePolygons, setEnablePolygons] = useState('');

  const dispatch = useDispatch();

  const [viewState, setViewState] = useState({
    longitude: -100,
    latitude: 40,
    zoom: 3.5
  });

  const bounds = mapRef?.current
    ? mapRef.current
      .getMap()
      .getBounds()
      .toArray()
      .flat()
    : null;

  const { clusters: clustersBlue, supercluster: superclusterBlue } = useSupercluster({
    points: pointsBlue,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersOrange, supercluster: superclusterOrange } = useSupercluster({
    points: pointsOrange,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersGreen, supercluster: superclusterGreen } = useSupercluster({
    points: pointsGreen,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersPink, supercluster: superclusterPink } = useSupercluster({
    points: pointsPink,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersSkyBlue, supercluster: superclusterSkyBlue } = useSupercluster({
    points: pointsSkyBlue,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersRed, supercluster: superclusterRed } = useSupercluster({
    points: pointsRed,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersYellow, supercluster: superclusterYellow } = useSupercluster({
    points: pointsYellow,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersPurple, supercluster: superclusterPurple } = useSupercluster({
    points: pointsPurple,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersCyan, supercluster: superclusterCyan } = useSupercluster({
    points: pointsCyan,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  const { clusters: clustersBrown, supercluster: superclusterBrown } = useSupercluster({
    points: pointsBrown,
    bounds,
    zoom: viewState.zoom,
    options: { radius: 75, maxZoom: 20 }
  });

  useEffect(() => {
    let mounted = true;
    let resizer;
    if (mounted) {
      if (selectedCountry) {
        setViewState(vs => ({
          ...viewState,
          latitude: selectedCountry.center[1],
          longitude: selectedCountry.center[0],
          zoom: selectedCountry.zoom
        }));
      }

      resizer = new ResizeObserver(debounce(() => mapRef?.current?.resize(), 1));
      resizer.observe(mapContainerRef.current);
    }

    // clean up on unmount
    return () => {
      // resizer?.disconnect();
      mounted = false;
    }
  }, [selectedCountry]);

  const onMove = (event) => {
    setViewState(event.viewState);
  };

  const geojsonData = {
    type: 'FeatureCollection',
    features: [
      { type: "Feature", geometry: { type: "Polygon", coordinates: [[[-71.831196, 41.371431], [-71.831178, 41.371551], [-71.831371, 41.371568], [-71.83139, 41.371448], [-71.831196, 41.371431]]] } },
    ]
  };

  const layerStyle = {
    id: 'point',
    type: 'circle',
    paint: {
      'circle-radius': 50,
      'circle-color': '#007cbf',
      'circle-stroke-width': 1,
      'circle-stroke-color': '#ffffff'
    }
  };

  return (
    <Box className="map-container" ref={mapContainerRef}>
      {mapLoading &&
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', position: 'absolute', left: 0, top: 0, background: '#00000059', zIndex: 1 }}>
          <CircularProgress />
        </Box>
      }
      <Box className="map-area" sx={{ height: '100%' }}>
        {selectedCountry &&
          <Map
            {...viewState}
            mapStyle={`mapbox://styles/mapbox/${currentTheme === 'light' ? 'light-v10' : 'dark-v10'}`}
            mapboxAccessToken={`${mapboxApiToken}`}
            ref={mapRef}
            onMove={debounce((e) => onMove(e), 10)}
          >

            {!enablePolygon && popupInfo &&
                <Source id="my-data" type="geojson" data={{
                  type: 'FeatureCollection',
                  features: [
                    { type: 'Feature', geometry: { type: 'Point', coordinates: [Number(popupInfo.longitude), Number(popupInfo.latitude)] } }
                  ]
                }}>
                  <Layer
                      {...{
                        id: 'point',
                        type: 'circle',
                        paint: {
                          'circle-radius': 50,
                          'circle-opacity': .5,
                          // 'circle-color': `${popupInfo.info.colorValue}`,
                          'circle-color': `#394159`,
                          'circle-stroke-width': 2,
                          'circle-stroke-opacity': .7,
                          // 'circle-stroke-color': `${popupInfo.info.colorValue}`
                          'circle-stroke-color': `#FFF`
                        }
                      }}
                  />
                </Source>
            }

            { enablePolygon && <PolygonOverlay geojsonData={polygonData} /> }

            <ClusterdPoints
              clusters={clustersOrange}
              supercluster={superclusterOrange}
              points={pointsOrange}
              viewState={viewState}
              color={"#FC6B21"}
              setViewState={setViewState}
              setPopupInfo={setPopupInfo}
              mapPoints={mapPoints}
              pointerState={pointerState}
            />

            <ClusterdPoints
              clusters={clustersPink}
              supercluster={superclusterPink}
              points={pointsPink}
              viewState={viewState}
              color={"#FF0099"}
              setViewState={setViewState}
              setPopupInfo={setPopupInfo}
              mapPoints={mapPoints}
              pointerState={pointerState}
            />

            <ClusterdPoints
              clusters={clustersSkyBlue}
              supercluster={superclusterSkyBlue}
              points={pointsSkyBlue}
              viewState={viewState}
              color={"#1FB9FC"}
              setViewState={setViewState}
              setPopupInfo={setPopupInfo}
              mapPoints={mapPoints}
              pointerState={pointerState}
            />


          <ClusterdPoints
              clusters={clustersGreen}
              supercluster={superclusterGreen}
              points={pointsGreen}
              viewState={viewState}
              color={"#39BF8F"}
              setViewState={setViewState}
              setPopupInfo={setPopupInfo}
              mapPoints={mapPoints}
              pointerState={pointerState}
            />

          <ClusterdPoints
              clusters={clustersBlue}
              supercluster={superclusterBlue}
              points={pointsBlue}
              viewState={viewState}
              color={"#3955BF"}
              setViewState={setViewState}
              setPopupInfo={setPopupInfo}
              mapPoints={mapPoints}
              pointerState={pointerState}
            />


            <ClusterdPoints
                clusters={clustersRed}
                supercluster={superclusterRed}
                points={pointsRed}
                viewState={viewState}
                color={"#FF0000"}
                setViewState={setViewState}
                setPopupInfo={setPopupInfo}
                mapPoints={mapPoints}
                pointerState={pointerState}
            />

            <ClusterdPoints
                clusters={clustersYellow}
                supercluster={superclusterYellow}
                points={pointsYellow}
                viewState={viewState}
                color={"#FFFF00"}
                setViewState={setViewState}
                setPopupInfo={setPopupInfo}
                mapPoints={mapPoints}
                pointerState={pointerState}
            />

            <ClusterdPoints
                clusters={clustersPurple}
                supercluster={superclusterPurple}
                points={pointsPurple}
                viewState={viewState}
                color={"#800080"}
                setViewState={setViewState}
                setPopupInfo={setPopupInfo}
                mapPoints={mapPoints}
                pointerState={pointerState}
            />

            <ClusterdPoints
                clusters={clustersCyan}
                supercluster={superclusterCyan}
                points={pointsCyan}
                viewState={viewState}
                color={"#00FFFF"}
                setViewState={setViewState}
                setPopupInfo={setPopupInfo}
                mapPoints={mapPoints}
                pointerState={pointerState}
            />

            <ClusterdPoints
                clusters={clustersBrown}
                supercluster={superclusterBrown}
                points={pointsBrown}
                viewState={viewState}
                color={"#A52A2A"}
                setViewState={setViewState}
                setPopupInfo={setPopupInfo}
                mapPoints={mapPoints}
                pointerState={pointerState}
            />

            {popupInfo?.info && (
              <Popup
                anchor="bottom"
                offset={15}
                longitude={Number(popupInfo.longitude)}
                latitude={Number(popupInfo.latitude)}
                closeButton={false}
                onClose={() => setPopupInfo(null)}
              >
                <Box sx={{ color: '#000' }}>
                  {popupInfo.info.title &&
                    <Box sx={{ fontSize: 12 }}>
                      <Box sx={{ fontWeight: '700' }}>{popupInfo.info.title}</Box>
                    </Box>
                  }
                  {popupInfo.info.address &&
                    <Box sx={{ mt: .6, fontSize: 12 }}>
                      <Box sx={{ fontWeight: '700' }}>{popupInfo.info.address}</Box>
                    </Box>
                  }
                  {/* {popupInfo.info.estimatedCount ?
                    <Box sx={{ mt: .6 }}>Estimated Percentage: <strong>{popupInfo.info.estimatedCount.toLocaleString()}%</strong></Box>
                    :
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: .6 }}>
                      <CircularProgress size={10} />
                    </Box>
                  } */}
                </Box>
              </Popup>
            )}
          </Map>
        }
      </Box>


    </Box>


  );
};

const mapStateToProps = (state) => ({
  selectedCountry: state.auth.selectedCountry,
  mapPoints: state.map.mapPoints,
  mapLoading: state.map.mapLoading,
  currentTheme: state.theme.currentTheme,
  polygonData: state.map.polygonData,
});

export default connect(mapStateToProps, null)(ClusterdMap);
