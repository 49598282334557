import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import LoginBackgroundVideo from '../../../assets/videos/login-backgroung-video.mp4';
import ClusterdMap from '../../common/map/ClusterdMap';

import "./AuthLayout.scss";

const AuthLayout = ({ refreshToken }) => {

  const navigate = useNavigate();
  const { countryBackground } = useSelector((state) => state.common);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshToken) {
        navigate('/');
      }
    }
    return () => {

    }
  }, [refreshToken]);

  return (<>
    <div className={`auth-layout`}>
      {!refreshToken && !countryBackground &&
        <video autoPlay loop muted playbackrate={0.5} >
          <source src={LoginBackgroundVideo} type="video/mp4" />
        </video>
      }
      <Outlet />
    </div>
  </>)
}

const mapStateToProps = (state) => ({
  refreshToken: state.auth.auth.refreshToken
});

export default connect(mapStateToProps, null)(AuthLayout);