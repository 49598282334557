import './scss/style.scss';

import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from './redux/store';
import MainLayout from './components/layouts/main-layout/MainLayout';
import ScrollToTop from './components/common/scroll/ScrollToTop';

const loading = (
  <div className="text-center">Loading...</div>
)

const App = () => {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <ScrollToTop>
            <React.Suspense fallback={loading}>
              <MainLayout />
            </React.Suspense>
          </ScrollToTop>
        </BrowserRouter>
      </CookiesProvider>
      <canvas hidden className='canvas'></canvas>
    </Provider>
  )
}

export default App;
