import { createSlice } from '@reduxjs/toolkit';

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    mapPoints: [],
    polygonData: {},
    mapLoading: false
  },
  reducers: {
    setMapPoints: (state, action) => {
      state.mapPoints = action.payload;
    },
    setMapLoading: (state, action) => {
      state.mapLoading = action.payload;
    },
    setPolygonData: (state, action) => {
      state.polygonData = action.payload;
    }
  },
});

export const { setMapPoints, setMapLoading, setPolygonData } = mapSlice.actions;

export default mapSlice.reducer;
