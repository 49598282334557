import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, CircularProgress, Switch,  Select, MenuItem ,  Checkbox, ListItemText } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GridColumnMenu, GridToolbarQuickFilter, gridClasses } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

import { setMessageSnackbarStateState, setUserManagementModalState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import AuthService from '../../../../services/api/auth.service';
import BasicConfirmModal from "../basic-confirm-modal/BasicConfirmModal";
import UserService from "../../../../services/api/user.service";

// const roles = ['Market', 'Finance', 'Development'];
// const randomRole = () => {
//   return randomArrayItem(roles);
// };

function QuickSearchToolbar() {

  const { currentTheme } = useSelector((state) => state.theme);

  return (
    <Box
      sx={{
          display: 'flex',
          justifyContent: 'flex-end', // Align to the right corner
        p: 0.5,
        pb: 0,
        '& .MuiInputBase-input': {
          color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 'none', // Example: Remove border radius
        },
      }}
    >
      <GridToolbarQuickFilter sx={{ border: "none", borderBottom: 0, padding: 0 }} autoComplete='off' />
    </Box>
  );
}

function CustomColumnMenu(props) {
  return (
    <GridColumnMenu
      {...props}
      slots={{
        // Hide `columnMenuFilterItem`
        columnMenuFilterItem: null,
      }}
    />
  );
}

const useFakeMutation = () => {
  return React.useCallback(
    (user) =>
      new Promise((resolve, reject) => {
        setTimeout(() => {
          if (user.name?.trim() === '') {
            reject(new Error("Error while saving user: name can't be empty."));
          } else {
            resolve({ ...user, name: user.name?.toUpperCase() });
          }
        }, 200);
      }),
    [],
  );
};

function UserManagementModal() {

  //const { userManagementModalState } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const currentThemeColor = currentTheme === 'light' ? '#010412' : '#FFF';
  const dispatch = useDispatch();
  const [loadingState, setLoadingState] = useState({});

  const [tableData, setTableData] = useState([]);

    const [activateBasicConfirmModalState, setActivateBasicConfirmModalState] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [currentAction, setCurrentAction] = useState(null);


    const demoUsersCall = async () => {
    try {
        const queryParams = [
          `countryId=${selectedCountry.id}`,
        ];
        const data = await AuthService.demoUserCall(queryParams.join('&'));

        if (data?.results?.length) {
          // const convertedData = data.results.map((item) => ({
          //   ...item,
          //   dateOfExpiring: new Date(item.dateOfExpiring),
          //   dateOfApproval: new Date(item.dateOfApproval)
          // }));
          setTableData(data.results);
        } else {
          setTableData([]);
        }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
   // if (userManagementModalState) {
      demoUsersCall();
   // }
  }, []);

  const mutateRow = useFakeMutation();

  const processRowUpdate = React.useCallback(
    async (newRow) => {

        if(newRow.cardLimit == null || newRow.cardLimit < 0) {

            dispatch(setMessage({
                type: "error",
                title: 'Card Count must be greater than 0',
            }));
            dispatch(setMessageSnackbarStateState(true));
            return newRow;
        }

      try {
        // Update data in the backend
        const queryParams = [`userId=${newRow.id}`, `cardLimit=${newRow.cardLimit}`];
        const backendResponse = await AuthService.updateDemoUser(queryParams.join('&'));

        // Update the user in the local state
        const updatedUser = await mutateRow(newRow);

        // Display a success message
        dispatch(setMessage({
            type: 'success',
            title: backendResponse.message,
          })
        );
        dispatch(setMessageSnackbarStateState(true));

        await demoUsersCall();
        return updatedUser;
      } catch (error) {
        // Handle errors consistently
        console.log('Error:', error);
        // Display an error message if needed
        dispatch(
          setMessage({
            type: 'error',
            title: error.message,
          })
        );
        dispatch(setMessageSnackbarStateState(true));
        throw error; // Rethrow the error for any higher-level error handling
      }
    },
    [dispatch, setMessage, setMessageSnackbarStateState, mutateRow]
  );

  //
  // const handleChange = (params) => {
  //   // setLoading(true);
  //   const { id } = params.row;
  //   setLoadingState((prevState) => ({
  //     ...prevState,
  //     [id]: true,
  //   }));
  //
  //   if(params.row.cardLimit == null || params.row.cardLimit < 0) {
  //
  //       dispatch(setMessage({
  //           type: "error",
  //           title: 'Card Count must be greater than 0',
  //       }));
  //       dispatch(setMessageSnackbarStateState(true));
  //       setLoadingState((prevState) => ({
  //           ...prevState,
  //           [id]: false,
  //       }));
  //       return;
  //   }
  //
  //   const newIsApprove = !params.row.isApprove; // Toggle the isApprove status
  //   const queryParams = [
  //     `userId=${params.row.id}`,
  //     `isApprove=${newIsApprove}`,
  //     `cardCount=${params.row.cardLimit}`
  //   ]
  //   AuthService.approveUser(queryParams.join('&')).then(
  //     (data) => {
  //       // setLoading(false);
  //       setLoadingState((prevState) => ({
  //         ...prevState,
  //         [id]: false,
  //       }));
  //       dispatch(setMessage({
  //         type: "success",
  //         title: data.message,
  //       }));
  //       dispatch(setMessageSnackbarStateState(true));
  //
  //       const updatedTableData = tableData.map((row) => {
  //         if (row.email === params.row.email) {
  //           return { ...row, isApprove: newIsApprove };
  //         }
  //         return row;
  //       });
  //       setTableData(updatedTableData);
  //       demoUsersCall();
  //     })
  //
  //     .catch((error) => {
  //       // setLoading(false);
  //       setLoadingState((prevState) => ({
  //         ...prevState,
  //         [id]: false,
  //       }));
  //       dispatch(setMessage({
  //         type: "error",
  //         title: error.response.data.message,
  //       }));
  //       dispatch(setMessageSnackbarStateState(true));
  //     });
  // };


    const handleModalConfirm = () => {


        if (!currentAction) return;

        const { actionType, params } = currentAction;
        const { id } = params.row;

        setActivateBasicConfirmModalState(false);

        // Common logic for loading state
        setLoadingState((prevState) => ({
            ...prevState,
            [id]: true,
        }));

        // Dynamic API logic based on the action type
        let queryParams = [];

        if (actionType === "convertUserToClient") {
            queryParams = [`userId=${id}`, `isExternal=true`];
        } else if (actionType === "approveOrReject") {
            const newIsApprove = !params.row.isApprove; // Toggle approval status
            queryParams = [`userId=${id}`, `isApprove=${newIsApprove}`,  `cardCount=${params.row.cardLimit}`];
        }

        AuthService.approveUser(queryParams.join('&'))
            .then((data) => {
                setLoadingState((prevState) => ({
                    ...prevState,
                    [id]: false,
                }));
                dispatch(setMessage({
                    type: "success",
                    title: data.message,
                }));
                dispatch(setMessageSnackbarStateState(true));

                // Update table data dynamically
                const updatedTableData = tableData.map((row) => {
                    if (row.id === id) {
                        if (actionType === "convertUserToClient") {
                            return { ...row, isExternalUser: true };
                        } else if (actionType === "approveOrReject") {
                            return { ...row, isApprove: !params.row.isApprove };
                        }
                    }
                    return row;
                });
                setTableData(updatedTableData);
                demoUsersCall();
            })
            .catch((error) => {
                setLoadingState((prevState) => ({
                    ...prevState,
                    [id]: false,
                }));
                dispatch(setMessage({
                    type: "error",
                    title: error.response?.data?.message || 'An error occurred',
                }));
                dispatch(setMessageSnackbarStateState(true));
            });
    };

    const handleActionTrigger = (actionType, params) => {
        let message = "";

        if (actionType === "convertUserToClient") {
            message = "Are you sure you want to convert this user to a client?";
        } else if (actionType === "approveOrReject") {

            if(params.row.cardLimit == null || params.row.cardLimit < 0) {

                dispatch(setMessage({
                    type: "error",
                    title: 'Card Count must be greater than 0',
                }));
                dispatch(setMessageSnackbarStateState(true));
                return params;
            }

            message = "Are you sure you want to update the approval status?";
        }

        setModalMessage(message);
        setCurrentAction({ actionType, params }); // Store the action type and params
        setActivateBasicConfirmModalState(true);
    };


  //   const handleDateChange = (event, params) => {
  //
  //   const dateString = event["$d"];
  //   const date = new Date(dateString);
  //   const formattedDate = formatDateForQueryParam(date);
  //
  //   const queryParams = [
  //     `userId=${params.id}`,
  //     `endDate=${formattedDate}`
  //   ]
  //   AuthService.updateDemoUser(queryParams.join('&')).then(
  //     (data) => {
  //       dispatch(setMessage({
  //         type: "success",
  //         title: data.message,
  //       }));
  //       dispatch(setMessageSnackbarStateState(true));
  //     })
  //     .catch((error) => {
  //       console.log(error)
  //       dispatch(setMessage({
  //         type: "error",
  //         title: error.response.data.message,
  //       }));
  //       dispatch(setMessageSnackbarStateState(true));
  //     });
  // };

    const handleCountryChange = (userId, newCountryIds) => {

        sendSelectedCountriesToAPI(userId, newCountryIds);
    };


    const sendSelectedCountriesToAPI = async (userId, countryIds) => {
        // Build query parameters
        const queryParams = new URLSearchParams({
            userId: userId,
            countryIds: countryIds.join(','), // Join array into a comma-separated string
        });

        try {
            // Make the API call with query parameters
            const data = await UserService.updateCountries(queryParams.toString());

            // Dispatch success message
            dispatch(setMessage({
                type: "success",
                title: data.message,
            }));
            dispatch(setMessageSnackbarStateState(true));

            // Refresh the data
            demoUsersCall();
        } catch (error) {
            // Handle error state
            setLoadingState((prevState) => ({
                ...prevState,
                [userId]: false,
            }));
            dispatch(setMessage({
                type: "error",
                title: error.response?.data?.message || 'An error occurred',
            }));
            dispatch(setMessageSnackbarStateState(true));
        }
    };



    // const formatDateForQueryParam = (date) => {
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-based
  //   const day = String(date.getDate()).padStart(2, '0');
  //   const hours = String(date.getHours()).padStart(2, '0');
  //   const minutes = String(date.getMinutes()).padStart(2, '0');
  //   const seconds = String(date.getSeconds()).padStart(2, '0');
  //
  //   return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  // };

  const columns = [
    {
      field: 'name',
      headerName: 'Name',
      width: 140,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'companyName',
      headerName: 'Company Name',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      // editable: true,
    },
    {
      field: 'email',
      headerName: 'Email',
      width: 190,
      align: 'center',
      headerAlign: 'center',
      hideable: false,
      // editable: true,
      type: 'mail',
    },
    {
          field: 'linkedInUrl',
          headerName: 'LinkedIn URL',
          width: 200,
          align: 'center',
          headerAlign: 'center',
      },
    {
      field: 'phoneNumber',
      headerName: 'Phone Number',
      width: 170,
      align: 'center',
      headerAlign: 'center',
      // editable: true,
      type: 'tel',
    },
    {
      field: 'cardLimit',
      headerName: 'Card Count',
      type: 'number',
      width: 90,
      align: 'center',
      headerAlign: 'center',
      editable: true
    },
    {
          field: 'existingLimit',
          headerName: 'Used Limit',
          type: 'number',
          width: 90,
          align: 'center',
          headerAlign: 'center',
          editable: false
    },
    {
      field: "Activate Trial",
      width: 120,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => {
        const isApprove = params.row.isApprove;
        const id = params.row.id;
        const isLoading = loadingState[id] || false;
        //const isExpired = params.row.planType !== 'Trial' && params.row.isActivePlan;
        return (
          isLoading ?
            <CircularProgress size={20} sx={{ marginLeft: '20px' }} />
            :
            (
              <Switch
                color='success'
                checked={isApprove}
                onChange={() => handleActionTrigger("approveOrReject", params)}
                //disabled={isExpired}
              />)
        );
      }
    },
      {
          field: "Convert User to Client",
          width: 150,
          align: 'center',
          headerAlign: 'center',
          renderCell: (params) => {
              const isExternal = params.row.isExternalUser ;
              const id = params.row.id;
              const isLoading = loadingState[id] || false;
              const isActive = params.row.isActivePlan;
              return (
                  isLoading ?
                      <CircularProgress size={20} sx={{ marginLeft: '20px' }} />
                      :
                      (
                          <Switch
                              color='success'
                              checked={isExternal}
                              onChange={() => handleActionTrigger("convertUserToClient", params)}
                              disabled={isActive}
                          />)
              );
          }
      },
      {
          field: "countryIds",
          headerName: "Market",
          width: 250,
          renderCell: (params) => {
              const { id, countryIds } = params.row;

              // Define theme-specific colors
              const themeColors = {
                  light: {
                      background: "#e4e4e4",
                      text: "#212121",
                      border: "#BDBDBD",
                      hover: "#E0E0E0",
                      checked: "#1976D2", // Standard Material-UI primary color
                      checkedText: "#39BF8F",  // Greenish color when checked
                      uncheckedText: "#000",  // Black color when unchecked
                  },
                  dark: {
                      background: "#1E1E2F",
                      text: "#c7c7c7",
                      border: "#5C5C5C",
                      hover: "#33334A",
                      checked: "#64B5F6", // Lighter shade of blue for dark mode
                      checkedText: "#101110",  // Greenish color when checked
                      uncheckedText: "#FFF",  // White color when unchecked
                  },
              };

              const currentColors = currentTheme === "dark" ? themeColors.dark : themeColors.light;

              return (
                  <Select
                      multiple
                      value={countryIds}
                      onChange={(e) => handleCountryChange(id, e.target.value)}
                      renderValue={(selected) =>
                          countries
                              .filter((country) => selected.includes(country.id))
                              .map((country) => country.name)
                              .join(", ")
                      }
                      fullWidth
                      sx={{
                          backgroundColor: currentColors.background, // Apply theme background color
                          color: currentColors.text, // Apply theme text color
                          "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: currentColors.border, // Border color matching theme
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                              borderColor: currentColors.checked, // Highlight border on hover
                          },
                      }}
                  >
                      { countries.map((country) => {
                          const isChecked = countryIds.includes(country.id);
                          return (
                          <MenuItem
                              key={country.id}
                              value={country.id}
                              sx={{
                                  backgroundColor: currentColors.background,
                                  color: currentColors.text,
                                  "&:hover": {
                                      backgroundColor: currentColors.hover, // Adjust hover effect
                                  },
                              }}
                          >
                              <Checkbox
                                  checked={countryIds.includes(country.id)}
                                  sx={{
                                      color: currentColors.border, // Checkbox border matches theme
                                      "&.Mui-checked": {
                                          color: currentColors.checked, // Checked color matches theme
                                      },
                                  }}
                              />
                              <ListItemText
                                  primary={country.name}
                                  sx={{
                                      color: isChecked
                                          ? currentColors.checkedText  // Text color when checked
                                          : currentColors.uncheckedText,  // Text color when unchecked
                                  }}
                              />
                          </MenuItem>
                          );
                      })}
                  </Select>
              );
          },
      },
      // {
      //     field: 'planMode',
      //     headerName: 'Status',
      //     width: 200,
      //     align: 'center',
      //     headerAlign: 'center',
      // },
      {
        field: 'dateOfExpiring',
        headerName:  'Date of expiry',
        width: 200,
        align: 'center',
        headerAlign: 'center'
      }
  ];

  const updatedColumns = columns.map(column => ({
    ...column,
    sortable: false,
  }));

    const countries = [
        { id: 2, name: "UAE" },
        { id: 3, name: "QA" },
        { id: 4, name: "KSA" },
        { id: 8, name: "LB" },
        { id: 9, name: "DE" },
        { id: 10, name: "ES" },
    ];


    const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, color: currentTheme === 'light' ? '#000' : '#FFF' }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const initialState = {
    pagination: {
      paginationModel: {
        pageSize: 10,
      },
    },
    columns: {
      columnVisibilityModel: {
        phoneNumber: false,
        createdCards: false,
        cardLimit: true
      }
    },
  };

  return (<>
    {/*<Dialog*/}
    {/*  open={userManagementModalState}*/}
    {/*  fullScreen*/}
    {/*  onClose={handleClose}*/}
    {/*  onBackdropClick={handleBackdropClick}*/}
    {/*  PaperProps={{ sx: { width: '90%', height: '90%', borderRadius: "0px", boxShadow: currentTheme === "light" ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(255, 255, 255, 0.5)' } }}*/}
    {/*  sx={{*/}
    {/*    backdropFilter: "blur(25px)",*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Demo Accounts Management</DialogTitle>*/}
      <Box sx={{ height: '100%', width: '100%', padding: '5px' }}>
        {/*<CloseIcon onClick={handleClose} sx={{*/}
        {/*  cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,*/}
        {/*}} />*/}
        <DataGrid
          sx={{
            [`& .${gridClasses.row}`]: {
              borderLeft: '2.5px solid #3C4DE7',
              borderRadius: '0 10px 10px 0',
              bgcolor: currentTheme === 'light' ? grey[200] : grey[900],
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
              color: grey[600],
              '&:hover': {
                color: '#3C4DE7',
              },
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
              border: 'none',
            },
            '& .MuiDataGrid-footerContainer ': {
              border: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              color: currentThemeColor,
            },
            '& .MuiButtonBase-root ': {
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-selectedRowCount': {
              color: currentThemeColor
            },
            '& .MuiDataGrid-virtualScroller': {
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
              width: '0.4em',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
              background: currentTheme === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
              borderRadius: "10px",
              boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
              backgroundColor: currentTheme === 'light' ? '#f5f5f5' : '#494848',
            },
            '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
              background: currentTheme === 'light' ? '#494848' : '#f5f5f5',
            },
            border: 'none',
          }}
          initialState={initialState}
          getRowSpacing={getRowSpacing}
          pageSizeOptions={[10]}
          // disableColumnMenu
          disableSorting
          disableSelectionOnClick
          rows={tableData}
          columns={updatedColumns}
          editMode="cell"
          processRowUpdate={processRowUpdate}
          slots={{
            toolbar: QuickSearchToolbar,
            noRowsOverlay: CustomNoRowsOverlay,
            columnMenu: CustomColumnMenu
          }}
        />
      </Box>

      {/* Confirm Modal */}
      <BasicConfirmModal
          message={modalMessage}
          activateBasicConfirmModalState={activateBasicConfirmModalState}
          setActivateBasicConfirmModalState={setActivateBasicConfirmModalState}
          handleConfirm={handleModalConfirm}
      />
  </>);
}

export default UserManagementModal;