import React,  { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import {useSelector} from "react-redux";
import UserManagementModal from "../../components/common/modal/user-management-modal/UserManagementModal";
import AudienceActivityModal from "../../components/common/modal/audience-activity/AudienceActivityModal";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import Tab from "@mui/material/Tab";
import TabPanel from "@mui/lab/TabPanel";
import CompareCardStatusModal from "../../components/common/modal/compare-card-status-modal/CompareCardStatusModal";
import './ActivityLog.scss';
import {getPermission} from "../../utils/cryptograpgy";


const ActivityLog = () => {
    const [compareStatusPermission, setCompareStatusPermission] = useState(false);
    const [userManagementPermission, setUserManagementPermission] = useState(false);
    const [audienceActivityPermission, setAudienceActivityPermission] = useState(false);
    const [value, setValue] = React.useState('1');

    useEffect(() => {

        setAudienceActivityPermission(getPermission('audience_history'));
        setUserManagementPermission(getPermission('user_management'));
        setCompareStatusPermission(getPermission('compare_status'));


        if (getPermission('user_management')  ) {
            setValue('1');
        } else if (getPermission('compare_status')) {
            setValue('2');
        } else if (getPermission('audience_history')) {
            setValue('3');
        }

    }, []);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const {currentTheme} = useSelector((state) => state.theme);

    return (
        <Box className="FullArea" sx={{position: 'absolute', overflowX: 'hidden', height: '100vh', top: 0}}>
            {/*<MenuSideBar/>*/}
            <Box style={{paddingTop: '50px', marginLeft: '25px'}}>
                <Typography variant="h4" style={{
                    fontFamily: 'Manrope, Arial, sans-serif',
                    fontSize: '26px',
                    fontWeight: 700,
                    lineHeight: '32.78px',
                    textAlign: 'left',
                    color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
                }}>
                    Activity Logs
                </Typography>
            </Box>
            <Box>
                <TabContext value={value}>
                        <TabList onChange={handleChange} aria-label="lab API tabs example">
                            { userManagementPermission && <Tab
                                label="Demo Accounts Management"
                                value="1"
                                className="tabLabel"
                            /> }
                            { compareStatusPermission && <Tab
                                label="Cross Match Report"
                                value="2"
                                className="tabLabel"
                            /> }
                            { audienceActivityPermission && <Tab
                                label="Audience Activation History"
                                value="3"
                                className="tabLabel"
                            /> }
                        </TabList>

                    <TabPanel value="1">
                        {userManagementPermission && <UserManagementModal />}
                    </TabPanel>
                    <TabPanel value="2">
                        {compareStatusPermission && <CompareCardStatusModal />}
                    </TabPanel>
                    <TabPanel value="3">
                        {audienceActivityPermission && <AudienceActivityModal />}
                    </TabPanel>
                </TabContext>

            </Box>
        </Box>
    );
}

export default ActivityLog;