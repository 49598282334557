import { Box } from '@mui/system';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { thickDonutOptions } from '../../../config/highcharts.config';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '../../../utils/browser';

const LargeDoughnutWithText = ({ title = '', data, isPdfGenerate }) => {

  const { currentTheme } = useSelector((state) => state.theme);
  const browserWidth = useWindowWidth();

  const getDoughnutChartSize = () => {
    if (browserWidth >= 1400) {
      return 140;
    } else if (browserWidth > 1300 && browserWidth < 1400) {
      return 120;
    } else if (browserWidth > 1200 && browserWidth < 1300) {
      return 110;
    }
    return 140;
  }

  const getDoughnutChartCenter = () => {
    if (browserWidth >= 1400) {
      return [50, 50];
    } else if (browserWidth > 1300 && browserWidth < 1400) {
      return [40, 40];
    } else if (browserWidth > 1200 && browserWidth < 1300) {
      return [35, 35];
    }
    return [50, 50];
  }

  const options = {
    ...thickDonutOptions,
    plotOptions: {
      ...thickDonutOptions.plotOptions,
      series: {
        ...thickDonutOptions.plotOptions.series,
        borderColor: isPdfGenerate ? '#010412' : (currentTheme === 'light' ? '#fff' : '#010412')
      }
    },
    series: [{
      type: 'pie',
      center: getDoughnutChartCenter(),
      size: getDoughnutChartSize(),
      innerSize: '70%',
      showInLegend: false,
      dataLabels: {
        enabled: true,
        padding: 0,
        distance: -1 * getDoughnutChartSize() / 2,
        format: title,
        allowOverlap: false,
        showInLegend: false,
        verticalAlign: 'middle',
        useHTML: true,
        y: -9,
        style: {
          fontSize: '14px',
          width: (getDoughnutChartSize() / 2 + 20) + 'px',
          textAlign: 'center',
          textOutline: false,
          textShadow: false,
          // color: currentTheme === 'light' ? '#010412' : "#fff",
          color: isPdfGenerate && '#fff'
        }
      },
      data: data,
    }]
  };

  return (<>
    {data?.length > 0 &&
      <Box className="LargeDoughnutChartWithText" sx={{ position: 'relative', display: 'flex', width: '100%', height: '100%' }}>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </Box>
    }
  </>)
}

export default LargeDoughnutWithText;
