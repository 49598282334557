export function getChartColorByIndex(index) {
  try {
    const colors = ['#3C4DE7', '#1B7FF4', '#39BF8F', '#F1F1F1'];
    return colors[index];
  } catch {
    return '#3C4DE7';
  }

}

export function getDougnutChartColorByIndex(index) {
  try {
    const colors = ['#2553FF', '#3B69FF', '#527FFF', '#6995FF', '#80ABFF', '#97C2FF', '#AED8FF', '#C5EEFF', '#D2E8FF', '#E1F1FF'];
    return colors[index];
  } catch {
    return '#2553FF';
  }
}
