import React from "react";

const PlanExpiredModal = ({ open, onClose }) => {
    if (!open) return null;

    const handleLogout = () => {
        window.location.href = "/";
    };

    return (
        <div style={styles.modalOverlay}>
            <div style={styles.modalContent}>
                <h2>Plan Expired</h2>
                <p>Your plan has expired. Please renew your subscription.</p>
                <button onClick={handleLogout} style={styles.button}>OK</button>
            </div>
        </div>
    );
};

const styles = {
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        textAlign: "center",
    },
    button: {
        marginTop: "10px",
        padding: "8px 16px",
        border: "none",
        backgroundColor: "#007BFF",
        color: "#fff",
        borderRadius: "4px",
        cursor: "pointer",
    },
};

export default PlanExpiredModal;
