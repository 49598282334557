import React, { useState } from "react";
import { Box, Modal, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { useSelector } from "react-redux";

import AuthService from "../../../../services/api/auth.service";
import mail from "../../../../assets/images/icons/svg/sms.svg";
import Image from "mui-image";
import logoBlack from "../../../../assets/images/logo-black.svg";
import logo from "../../../../assets/images/logo.svg";

const ForgotPasswordModal = ({ forgotPasswordModalOpen, handleForgotPasswordModalClose }) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { currentTheme } = useSelector((state) => state.theme);

  const { register, reset, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handleClick = () => {
    setIsSuccess(false);
    reset();
    handleForgotPasswordModalClose();
  }

  const onSubmit = (values) => {
    // console.log("values", values);
    const formData = values.email

    setIsSubmitting(true);
    AuthService.forgetPassword(formData).then(
      (data) => {
        if (data) {
          setIsSubmitting(false);
          setIsSuccess(true);
          //window.location.reload();
          // handleForgotPasswordModalClose();
          // console.log('email', data);
        }
        return Promise.resolve();
      },
      (error) => {
        setIsSubmitting(false);
        console.log(error);
        return Promise.reject();
      }
    );
  };

  return (
    <Modal
      open={forgotPasswordModalOpen}
      onClose={handleForgotPasswordModalClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        backdropFilter: "blur(15px)",
      }}
    >

      {isSuccess ?
        <>
          <Box
            className="form-container-forgotpasswordmodal"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              p: 4,
              maxWidth: 400,
              width: "100%",
              textAlign: "center",
              backgroundColor: "white",
              borderRadius: 4,
            }}
          >
            <Box className="login-title" sx={{ mb: 4 }}>
              <Box className="login-logo">
                <img
                  src={currentTheme === "light" ? logoBlack : logo}
                  alt="logo"
                  style={{ height: 100 }}
                />
              </Box>
              <Box
                sx={{
                  textAlign: "center",
                  fontSize: [20, 24],
                  lineHeight: "30px",
                  mt: 1,
                }}
                className="logo-title"
              >
                Curate, Visualize, Activate
              </Box>
              <MarkEmailReadIcon sx={{ color: "#3C4DE7", width: "100px", height: "100px", mt: 2 }} />
            </Box>

            <Typography variant="body1" sx={{ mb: 2, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
              Password reset instructions sent to your email. Please check inbox and follow the link to reset your password.
            </Typography>

            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" sx={{ mb: 2, mt: 20, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                © Allpings
              </Typography>
              <Typography variant="body2" sx={{ color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                Reach us at{" "}
                <a
                  href="mailto:support@allpings.com"
                  style={{ color: "inherit", textDecoration: "underline" }}
                >
                  support@allpings.com
                </a>
              </Typography>
              <a
                href="/"
                onClick={handleClick}
              >
                Go back
              </a>
            </Box>
          </Box>
        </>
        :
        <>
          <Box
            className="form-container-forgotpasswordmodal"
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box className="login-title" sx={{ textAlign: 'center', mb: 6, mt: 2, fontSize: 32, lineHeight: '40px', fontWeight: 700 }}>Forgot Password</Box>
            <Box
              component='form'
              noValidate
              autoComplete='off'
              onSubmit={handleSubmit(onSubmit)}
              type='reset'
            >
              <Box>
                <Box className="form-label" sx={{ fontSize: 18, fontWeight: 400, mb: 3 }}>Enter your email address</Box>
                <TextField
                  autoFocus
                  required
                  fullWidth
                  error={!!errors['email']}
                  helperText={errors['email'] ? errors['email'].message : ''}
                  {...register('email', {
                    required: "Email is required"
                  })}
                  sx={{ mb: 4 }}
                  type="email"
                  placeholder="Your email"
                  variant="standard"
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image src={mail} alt="mail" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <LoadingButton
                  variant='contained'
                  type='submit'
                  loading={isSubmitting}
                  className="form-button"
                  sx={{ py: '0.8rem', mt: 3.5, textTransform: 'capitalize', fontSize: 20, fontWeight: 700, borderRadius: 1.5, width: '90%' }}
                >
                  Forgot Password
                </LoadingButton>
              </Box>

            </Box>

          </Box>
        </>
      }
    </Modal>
  );
};

export default ForgotPasswordModal;
