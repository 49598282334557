import MenuSideBar from "../../components/common/sidebar/menu-sidebar/MenuSideBar";
import {
    Box, OutlinedInput, IconButton, Grid, Typography
} from '@mui/material';
import HomepageDetails from "../../components/common/details/home-page-details/HomepageDetails";
import CampaignDetails from "../../components/common/details/campaign-details/CampaignDetails";
import styles from './ConnectorDetails.scss';
import React, { useEffect, useState } from "react";
import {SearchStatus} from "iconsax-react";
import ConnectorCard from "../../components/common/card/connector-card/ConnectorCard";
import {useSelector} from "react-redux";
import { useLocation } from 'react-router-dom';
import connectorService from "../../services/api/connector.service";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';


const ConnectorDetails = ( ) => {

    const { state } = useLocation();
    const { user } = useSelector((state) => state.auth.auth);
    const { stateAudienceDetails } = state ?? {};
    const [connectorData, setConnectorData] = useState([]);
    const [filteredConnectorData, setFilteredConnectorData] = useState([]);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    const [ connectorCount, setConnectorCount] = useState(0);

    useEffect(() => {
        let mounted = true;
        if (mounted) {

            const queryParams = [
                `userId=${user != null ? user.id : userFromLocalStorage.id}`,
            ];

            connectorService.getAllConnectorsByUser(queryParams).then((data) => {
                if (data) {
                    setConnectorData(data.results);
                    setFilteredConnectorData(data.results);
                }
            });


        }

        return () => {
            mounted = false;

        };
    }, []);

    const handleSearchChange = (event) => {

        const searchText = event.target.value.toLowerCase();

        if (connectorData?.length && searchText.length >= 3) {
            const filteredAudList = connectorData.filter((aud) =>
                aud.name.toLowerCase().includes(searchText)
            );
            setFilteredConnectorData(filteredAudList);
        } else {

            setFilteredConnectorData(connectorData);
        }
    }

    function handleDataFromChild(data) {

        setConnectorData(data);
        setFilteredConnectorData(data);
        const trueCount = data.filter(connector => connector.isOn).length;
        setConnectorCount(trueCount);
    }

    const { currentTheme } = useSelector((state) => state.theme);


    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (<>
        <Box className="campaignArea" >
        <Box className="MapArea" sx={{position: 'absolute', overflowX: 'hidden', height: '100vh', top: 0}}>

            <Box sx={{ display: 'flex', justifyContent: "space-between", padding: "3% 10%"}}>
                <div>
                    <Typography
                        variant="body1"
                        className="connectorText"
                        style={{
                            fontFamily: 'Manrope, Arial, sans-serif',
                            fontSize: '26px',
                            fontWeight: "550",
                            lineHeight: '43.71px',
                            textAlign: 'left',
                            color: currentTheme === 'dark' ? '#ffffff' : 'black', // Font color white if dark theme
                            marginLeft: "-50px"
                        }}
                    >
                        Select a connector to activate your campaign
                    </Typography>
                </div>
                <div style={{width : "35%"}}>
                    {/*<TextField placeholder="disableCloseOnSelect" variant="standard" */}
                    {/*/>*/}
                    <OutlinedInput
                        sx={{ borderRadius: "50px", background: "white", border: "solid 0 white",height: "45px", width: "100%" }}
                        placeholder="Search Connectors"
                        startAdornment={
                            <IconButton edge="start" aria-label="search">
                                <SearchStatus />
                            </IconButton>
                        }
                        onChange={handleSearchChange}
                    />
                </div>
            </Box>
            <Grid container className="gridContainer"  direction={isSmallScreen ? 'column' : 'row'}

                  sx={{ overflowX: 'hidden', marginLeft:"10px", top: 0,paddingLeft:'40px', gap: "20px",width: "calc(100% - 5px)"}}>

                {filteredConnectorData && filteredConnectorData.map((connector, index) => (
                        <Grid key={index} >
                            <ConnectorCard
                                connectorData={connector}
                                onChange={handleDataFromChild}
                                filteredConnectorData={connectorData}
                            />
                        </Grid>
                    ))}

            </Grid>
            <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
                <CampaignDetails
                    audienceDetails={stateAudienceDetails}
                    connectorCount={connectorCount}
                    connectorData={connectorData}
                />
            </Box>
        </Box>
        </Box>
    </>);
}

export default ConnectorDetails;