import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { DialogContentText, ImageList, ImageListItem } from '@mui/material';
import { Box } from '@mui/system';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {setMessage} from "../../../../redux/slices/message";
import {setMessageSnackbarStateState} from "../../../../redux/slices/common";
import {useDispatch, useSelector} from "react-redux";
import AudienceService from "../../../../services/api/audience.service";
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';

function ImageSelectionModal({ imageSelectionModalState, setImageSelectionModalState, setImageSelectionData, sendDataToParent }) {

    const dispatch = useDispatch();
    const [selectedImage, setSelectedImage] = useState(null);
    const [allImageData, setAllImageData] = useState([]);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.auth.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const [page, setPage] = useState(0); // Initial page number
    const [size, setSize] = useState(6); // Page size
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        if (imageSelectionModalState) {
            const queryParams = [
                `userId=${user.id}`,
                `countryId=${selectedCountry.id}`,
                `page=${page}`,
                `size=${size}`,
            ];
            AudienceService.getAudienceImages(queryParams.join('&')).then(
                (data) => {
                    if (data?.results.content?.length) {
                        setAllImageData(data.results.content);
                        setTotalPages(data.results.totalPages);
                    } else {
                        setAllImageData([]);
                    }
                    return Promise.resolve();
                },
                (error) => {
                    console.log(error);
                    return Promise.reject();
                }
            );
        }
    }, [imageSelectionModalState, page, size]); // Adding page and size to the dependency array

    const handleCloseButton = () => {
        setImageSelectionModalState(false);
        sendDataToParent("");
    };

    const handleConfirmButton = () => {
        // Handle the selected image here
        // Example: console.log(selectedImage);
        if(selectedImage == null) {

            dispatch(setMessage({
                type: "error",
                title: "Please select a image to save",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return;
        } else {

            setImageSelectionData(selectedImage);
        }
        setImageSelectionModalState(false);
    };

    const handleImageClick = (img) => {
        setSelectedImage(img);
    };


    const handlePageChange = (event, value) => {
        setPage(value - 1); // MUI Pagination is 1-based, so subtract 1
    };

    return (
        <Dialog
            open={imageSelectionModalState}
            onClose={handleCloseButton}
            PaperProps={{ sx: { width: "50%", borderRadius: "0px" } }}
            sx={{
                backdropFilter: "blur(2px)",
            }}
        >
            <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
                {"Image Selector"}
            </DialogTitle>
            <DialogContent sx={{ p: 4 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <ImageList sx={{ width: 500, height: 450 }} cols={3} rowHeight={164}>
                        {allImageData.map((item, index) => (
                            <ImageListItem key={index} onClick={() => handleImageClick(item)} sx={{ cursor: 'pointer', position: 'relative' }}>
                                <img
                                    srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                                    src={`${item}?w=164&h=164&fit=crop&auto=format`}
                                    loading="lazy"
                                    style={{
                                        filter: selectedImage === item ? 'brightness(0.7)' : 'none'
                                    }}
                                />
                                {selectedImage === item && (
                                    <CheckCircleIcon
                                        sx={{
                                            color: 'white',
                                            position: 'absolute',
                                            top: 5,
                                            right: 5,
                                            fontSize: '2rem',
                                        }}
                                    />
                                )}
                            </ImageListItem>
                        ))}
                    </ImageList>
                </Box>
                <Stack
                    spacing={2}
                    sx={{
                        mt: 2,
                        alignItems: 'center',
                        color: currentTheme === 'dark' ? '#ffffff' : '#202020',
                        '& .MuiPaginationItem-root': {
                            color: currentTheme === 'dark' ? '#ffffff' : '#202020',
                        },
                        '& .Mui-selected': {
                            backgroundColor: currentTheme === 'dark' ? '#555555' : '#cccccc',
                            color: currentTheme === 'dark' ? '#ffffff' : '#202020',
                        },
                    }}
                >
                    <Pagination
                        count={totalPages}
                        page={page + 1}
                        onChange={handlePageChange}
                    />
                </Stack>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ padding: "0px" }}>
                <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                    <Button variant='flat' onClick={handleCloseButton} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
                    <Button variant='contained' onClick={handleConfirmButton} sx={{ textTransform: "none", borderRadius: "0px" }}>Save</Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    );
}

export default ImageSelectionModal;
