import React from 'react';
import { Box, ThemeProvider } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
// import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import HomepageDetails from '../../components/common/details/home-page-details/HomepageDetails';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DataGrid } from '@mui/x-data-grid'

// import ClusterdMap from '../../components/common/map/ClusterdMap';
// import AudienceSlider from '../../components/common/slider/audience-slider/AudienceSlider';
import AudienceService from '../../services/api/audience.service';
import { currentTheme } from '../../config/highcharts.config';
// import { setMessage } from '../../redux/slices/message';
// import { setMessageSnackbarStateState } from '../../redux/slices/common';
// import { errorMessage } from '../../config/config';

const AudienceHistoryPage = () => {

  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { audienceHistoryChange } = useSelector((state) => state.audience);
  const [audienceList, setAudienceList] = useState(null);
  const dispatch = useDispatch();

  const columns = [
    { field: 'activationPlatformName', headerName: 'ActivationPlatformName', width: 250 },
    { field: 'dateOfActivation', headerName: 'DateOfActivation', width: 250 },
    { field: 'cardName', headerName: 'CardName', width: 200 },
    { field: 'category', headerName: 'Category', width: 200 },
    { field: 'activatedByName', headerName: 'ActivatedBy', width: 150 }
  ]

  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ]
        AudienceService.audienceActivity(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              console.log(data);
              setTableData(data.results);
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        )
      }
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry, dispatch]);


  return (<>
    <Box className='HomepageArea' sx={{ display: 'flex' }}>
      {/*<MenuSideBar />*/}
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        {/* <ClusterdMap /> */}
        {/* <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '65%', zIndex: 3 }}>
          <SearchAutoComplete />
        </Box> */}
        <ThemeProvider theme={currentTheme}>
          <DataGrid
            rows={tableData}
            columns={columns}
            pageSize={12}
          />
        </ThemeProvider>
      </Box>
      <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
        <HomepageDetails />
      </Box>
    </Box>
  </>);
}

export default AudienceHistoryPage;