// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CrumbSlider_CrumbSliderWrapper__86gWW .CrumbSlider_CrumbSlider__XVD1d {\n  padding: 60px 35px 0 35px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/slider/crumb-slider/CrumbSlider.module.scss"],"names":[],"mappings":"AAGC;EACG,yBAAA;AAFJ","sourcesContent":["@import \"../../../../scss/variables\";\n\n.CrumbSliderWrapper {\n\t.CrumbSlider {\n    padding: 60px 35px 0 35px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CrumbSliderWrapper": "CrumbSlider_CrumbSliderWrapper__86gWW",
	"CrumbSlider": "CrumbSlider_CrumbSlider__XVD1d"
};
export default ___CSS_LOADER_EXPORT___;
