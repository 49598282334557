import React, { useEffect, useState } from "react";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import Image from "mui-image";
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';
import { Heart, Location, More, Profile2User, InfoCircle } from "iconsax-react";
import { useSelector } from 'react-redux';
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import styles from './AudienceCard.module.scss';

import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import storeIcon from '../../../../assets/images/store.png';
import AudienceService from "../../../../services/api/audience.service";
import CrumbService from "../../../../services/api/crumb.service";
import { setAudienceHistoryChange, setAudienceIdsList } from "../../../../redux/slices/audience";
import { getRandomInt } from "../../../../utils/math";
import { getPermission } from "../../../../utils/cryptograpgy";

import RefreshAudienceConfirmModal from "../../modal/refresh-confirm-modal/RefreshAudienceConfirmModal";
import ActivateAudienceConfirmModal from "../../modal/activate-audience-confirm-modal/ActivateAudienceConfirmModal";
import DeleteAudienceConfirmModal from "../../modal/delete-audience-confirm-modal/DeleteAudienceConfirmModal";
import DeleteAudienceSuccessModal from "../../modal/delete-audience-success-modal/DeleteAudienceSuccessModal";
import AssignAudienceConfirmModal from "../../modal/card-assign-confirm-modal/CardAssignConfirmModal";
import RenameAudienceModal from "../../modal/rename-audiencename-modal/RenameAudienceModal";
import { ReactComponent as IncIcon } from "../../../../assets/images/icons/svg/allpings_inc.svg";
import { ReactComponent as DecIcon } from "../../../../assets/images/icons/svg/allpings_dec.svg";
import { ReactComponent as DecLightIcon } from "../../../../assets/images/icons/svg/allpings_dec_light.svg";
import { ReactComponent as IncLightIcon } from "../../../../assets/images/icons/svg/allpings_inc_light.svg";
import { ReactComponent as StableIcon } from "../../../../assets/images/icons/svg/allpings_stable.svg";
import { ReactComponent as StableLightIcon } from "../../../../assets/images/icons/svg/allpings_stable_light.svg";
import HorizontalRuleIcon from '@mui/icons-material/HorizontalRule';

// import ApproveAudienceConfirmModal from "../../modal/approve-audience-confirm-modal/ApproveAudienceConfirmModal";

function AudienceCard({ audience, crumbDetails, index, handleMouseEnter, handleMouseLeave, handleDeactivateAudience, handleAudienceRefresh, handleAudienceAssign, handleAudienceRename, handleActivateAudience, handleAudienceDelete, updateAudiences, audienceList, setAudienceList, comefrom }) {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const menuOpen = Boolean(menuAnchorEl);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const params = useParams();
  const [isLiked, setIsLiked] = useState(audience.isLike ? true : false);
  const [activateAudienceConfirmModalState, setActivateAudienceConfirmModalState] = useState(false);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const { audienceHistoryChange } = useSelector((state) => state.audience);
  const [isHovered, setIsHovered] = useState(false);

  const favouriteCardPermission = getPermission('like_card');
  const deactivateCardPermission = getPermission('deactivate_card');
  const deleteCardPermission = getPermission('delete_card');
  const refreshCardPermission = getPermission('refresh_card');
  const assignCardPermission = getPermission('assign_card');
  const renameCardPermission = getPermission('rename_card');
  //const changeFilterPermission = getPermission('change_filter');
  const approveCardPermission = getPermission('approve_card');
  const extraInfo = getPermission('extra_info');
  const downloadPOIPermission = getPermission('download_poi');
  const cardMenuPersmission = getPermission('card_menu');

  const { currentTheme } = useSelector((state) => state.theme);

  const [refreshAudienceModalState, setRefreshAudienceModalState] = useState(false);
  const [deleteAudienceConfirmModalState, setDeleteAudienceConfirmModalState] = useState(false);
  const [deleteAudienceSuccessModalState, setDeleteAudienceSuccessModalState] = useState(false);
  const [assignAudienceModalState, setAssignAudienceModalState] = useState(false);
  const [renameAudienceModalState, setRenameAudienceModalState] = useState(false);
  // const [approveAudienceModalState, setApproveAudienceModalState] = useState(false);

  useEffect(() => {
    if (comefrom === 'favourite-page') {
      setIsLiked(audience.isLike ? true : false);
    }
  }, [audienceHistoryChange, audience, comefrom]);

  const handleMenuOpen = (event) => {
    event.preventDefault();
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleAudienceClick = (audience) => {
    if (audience.isUploaded || audience.action === "refreshing") {
      if (audience.isActive === "Deactivated") {
        setActivateAudienceConfirmModalState(true);
      } else {
        const selectedAudience = {
          crumbDetails: {
            "id": crumbDetails?.id,
            "name": crumbDetails?.name,
            "imagePath": crumbDetails?.imagePath,
          },
          audienceDetails: {
            "id": audience.id,
            "name": audience.name,
            "imagePath": audience.imagePath,
          }
        }
        localStorage.setItem("selectedAudience", JSON.stringify(selectedAudience));
        if (location?.pathname.includes("/favourite-cards")) {
          localStorage.setItem("back-button-url", "/favourite-cards");
        } else {
          localStorage.setItem("back-button-url", "/crumb");
        }
        navigate('/audience/' + audience.id);
      }
    }
  }

  const handleLikeToggle = (audience) => {
    if (user && selectedCountry && favouriteCardPermission) {
      const queryParams = [
        `userId=${user.id}`,
        `status=${!isLiked}`
      ];
      AudienceService.likeAudience(audience.id, queryParams.join('&')).then(
        (data) => {
          if (data) {
            setIsLiked(data.results);
          }
          dispatch(setAudienceHistoryChange(getRandomInt(100)));
        });
    }
  };

  const downloadCard = (audience) => {
    if (user && selectedCountry) {
      const queryParams = [
        `audienceId=${audience.id}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
      ]
      AudienceService.downloadAudience(queryParams.join('&')).then(
        (data) => {
          const url = URL.createObjectURL(new Blob([data], { type: "text/csv" }));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${audience.name}`);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }).catch((error) => {
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
        });
    }
  }

  const handleAudienceDeleteConfirmModal = () => {
    if (deleteCardPermission) {
      setDeleteAudienceConfirmModalState(true);
    }
  }

  const handleAudienceRefreshClick = () => {
    if (refreshCardPermission) {
      setRefreshAudienceModalState(true);
    }
  }

  const handleAudienceRenameClick = () => {
    if (renameCardPermission) {
      setRenameAudienceModalState(true);
    }
  }

  const changeFilter = () => {
    // dispatch(setCloneAudienceModalState(true));
    const audiencesObject = {
      audienceList: [audience.id],
      poiList: [],
      // action: 'clone'
    };
    console.log(audience)
    const audienceArray = [audience];
    localStorage.setItem("selectedSearchKeywords", JSON.stringify(audienceArray));
    dispatch(setAudienceIdsList(audiencesObject));
    navigate('/search');

  }

  // const handleAudienceApproveClick = () => {
  //   if (approveCardPermission) {
  //     setApproveAudienceModalState(true);
  //   }
  // }

  const handleAudienceAssignClick = () => {
    if (assignCardPermission) {
      setAssignAudienceModalState(true);
    }
  }

  const [info, setInfo] = useState();

  const Info = () => {
    const queryParams = [
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
      `id=${audience.id}`,
    ]
    CrumbService.getCardInfo(queryParams.join('&')).then(
      (data) => {
        setInfo(
          <>
            <Box style={{ fontSize: "12px", fontWeight: "bold", width: "190px" }}>
              Created On &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.createdOn.substring(0, 10).padEnd(12)}<br />
              Refreshed On &nbsp;:&nbsp;&nbsp;{data.results.modifiedOn.substring(0, 10).padEnd(10)}<br />
              {extraInfo &&
                <>
                  Visit Period &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.visitPeriod.substring(0, 10).padEnd(10)}<br />
                  Geo Radius &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.geoRadius.substring(0, 10).padEnd(10)}<br />
                  Week Parting &nbsp;&nbsp;:&nbsp;&nbsp;{data.results.weekParting.substring(0, 10).padEnd(10)}<br />
                  Day Parting &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:&nbsp;&nbsp;{data.results.dayParting.substring(0, 15).padEnd(10)}<br />
                </>
              }
            </Box>
          </>
        );
      },
      (error) => {
        console.log(error);
      }
    );

  };

  const IncreaseIcon = currentTheme === 'light' ? IncLightIcon : IncIcon;

  const DecreaseIcon = currentTheme === 'light' ? DecLightIcon : DecIcon;

  const StabledIcon = currentTheme === 'light' ? StableLightIcon : StableIcon;


  const getIcon = () => {

    if (audience.audienceCountStatus === true) {

      return <IncreaseIcon variant="Bold" sx={{ fontSize: 32 }} />;

    } else if (audience.audienceCountStatus === false) {

      return <DecreaseIcon variant="Bold" sx={{ fontSize: 32 }} />;

    } else {

      return <StabledIcon variant="Bold" sx={{ fontSize: 32 }} />;
    }
  };

  const getHoverContent = () => {
    const formatPercentage = (percentage) => {
      if (percentage.length === 1) {
        return `${percentage}.0%`; // Add a space for single-digit percentages
      }
      return percentage + '%';
    };

    const spanStyle = {
      width: '32px', // Ensure the span is a circle with a fixed width
      height: '32px', // Same as width to make it a circle
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%', // Make the span a circle
      fontSize: '12px', // Adjust text size as needed
    };

    const textStyle = {
      lineHeight: '1', // Ensure text is vertically centered
    };

    if (audience.audienceCountStatus === true) {
      return (
          <Box
              style={{
                ...spanStyle,
                backgroundColor: 'rgb(136,134,134)',
                color: '#39BF8F',
              }}
          >
        <span style={textStyle}> {formatPercentage(audience.audienceCountPercentage)} </span>
      </Box>
      );
    } else if (audience.audienceCountStatus === false) {
      return (
          <span
              style={{
                ...spanStyle,
                backgroundColor: 'rgb(136,134,134)',
                color: '#FF004F',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
          >
        <span style={textStyle}>{formatPercentage(audience.audienceCountPercentage)}</span>
      </span>
      );
    } else {
      return (
          <div style={{ paddingTop: '10px' }}>
            <HorizontalRuleIcon style={{ color: '#FFB11A' }} />
          </div>
      );
    }
  };

  return (<>
    {audience &&
      <Box className={`${styles.AudienceCard ?? ''} ${audience.hover ? styles.Hover ?? '' : ''} ${!audience.isUploaded ? audience.action === "refreshing" ? '' : styles.NotUploaded ?? '' : ''} ${audience.isActive === "Deactivated" && audience.isUploaded ? styles.Deactivated ?? '' : ''}`} sx={{ position: 'relative', transition: '0.5s' }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave()}>
        <Box className={`${styles.CrumbContent ?? ''}`} sx={{ position: 'relative', zIndex: 2 }}>

          <Box className={`${styles.CrumbImage ?? ''}`} sx={{ position: 'relative', overflow: 'hidden', borderRadius: '12px', cursor: 'pointer' }} onClick={() => handleAudienceClick(audience)}>
            {/* <Image src={audience.imagePath} alt={audience.name} width="auto" height="auto" /> */}
            <Image src={audience.imagePath} alt={audience.name} />
          </Box>

          {audience.isActive === "Deactivated" && audience.isUploaded &&
            <Box className={`${styles.DeactivatedText ?? ''}`} sx={{ position: 'absolute', top: 15, left: 0, zIndex: 10, fontSize: 14, cursor: 'pointer' }} onClick={() => handleAudienceClick(audience)}>
              <div className="ribbon">Deactivated</div>
            </Box>
          }

          {!audience.isUploaded &&
            <Box className={`${styles.NotUploadedText ?? ''}`} sx={{ position: 'absolute', top: 15, left: 0, zIndex: 10, fontSize: 14 }}>
              <div className="ribbon" style={{ backgroundColor: 'red' }}>No Stats Available</div>
            </Box>
          }

          {audience.isUploaded === null &&
            <Box className={`${styles.NotUploadedText ?? ''}`} sx={{ position: 'absolute', top: 15, left: 0, zIndex: 10, fontSize: 14 }}>
              <div className="ribbon" style={{ backgroundColor: 'orange' }}> {audience.action === 'refreshing' ? 'Refreshing Audiences' : 'Building Audiences'} </div>
            </Box>
          }

          {audience.isUploaded &&
            <Box className={`${styles.CrumbCategoryDetails ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'absolute', zIndex: 4, width: '100%', top: 16, left: 0 }}>
              <Box className={`${styles.CrumbCategoryName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', height: 32, borderRadius: '100px', background: audience.categoryNameBackground, fontSize: 14, fontWeight: '500', lineHeight: '17px', pl: 2, pt: 2 }}>
                {crumbDetails ?
                  <Chip title={crumbDetails.name} label={crumbDetails.name.length > 18 ? `${crumbDetails.name.substring(0, 18)}...` : crumbDetails.name} component="h1" variant="contained" color="success" />
                  :
                  <Chip label={audience.crumbName ? audience.crumbName.length > 18 ? `${audience.crumbName.substring(0, 18)}...` : audience.crumbName : ''} component="h1" variant="contained" color="success" />
                }
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>

                <Button
                    disableRipple
                    sx={{
                      mr: 0.9,
                      width: 32,
                      height: 32,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '50%',
                      backgroundColor: 'rgb(136,134,134)',
                      color: 'white',
                      minWidth: 'initial',
                      position: 'relative', // Allows absolute positioning of the hover content
                      '&:hover': {
                        backgroundColor: 'rgb(136,134,134)',
                      },
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                >
                  <Tooltip title={"Audience Indicator"} arrow onMouseEnter={() => Info()}>
                  {isHovered ? getHoverContent() : getIcon()}
                  </Tooltip>
                </Button>
                <Button className={`${styles.CrumbFavoriteButton ?? ''}`} disableRipple sx={{ mr: favouriteCardPermission ? '' : 2, width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} >
                  <Tooltip title={info} arrow onMouseEnter={() => Info()}>
                    <InfoCircle size="32" variant="Bold" />
                  </Tooltip>
                </Button>
                {favouriteCardPermission &&
                  <Button className={`${styles.CrumbFavoriteButton ?? ''}`} disableRipple sx={{ ml: 0.9, width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} onClick={() => handleLikeToggle(audience)} >
                    <Heart variant="Bold" color={isLiked ? "red" : "white"} />
                  </Button>
                }
              </Box>
            </Box>
          }

          <Box className={`${styles.CrumbDetails ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', zIndex: 6, width: 'calc(100% - 24px)', bottom: -16, left: '12px', borderRadius: '12px', px: 3, py: 2, overflow: 'hidden' }}>
            <Box className={`${styles.CrumbNameLogo ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mb: 2 }} onClick={() => handleAudienceClick(audience)}>
              <Box className={`${styles.CrumbLogo ?? ''}`} sx={{ overflow: 'hidden', borderRadius: '50%', width: 36, height: 36, background: '#eee', p: 1 }}>
                <Image src={storeIcon} alt={audience.name} style={{ height: 28 }} />
              </Box>
              < Box title={audience.name} className={`${styles.CrumbName ?? ''}`} sx={{ ml: 2, fontSize: 20, fontWeight: '700', lineHeight: '24px' }}>
                {audience.name.length > 13 ? `${audience.name.substring(0, 12)}..` : audience.name}
              </Box>
            </Box>

            {audience.isUploaded &&
              <Box className={`${styles.CrumbVisitor ?? ''}`} sx={{ display: 'flex', alignItems: 'center' }} onClick={() => handleAudienceClick(audience)}>
                <Box className={`${styles.CrumbVisitorIcon ?? ''}`} sx={{ display: 'flex', mr: 2, fontSize: 12 }}>
                  <Profile2User size="16" />
                </Box>
                <Box className={`${styles.CrumbVisitorCount ?? ''}`} sx={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '15px' }}>{audience.totalAudienceCount ? (audience.totalAudienceCount).toLocaleString() : "0"}</Box>
              </Box>
            }

            {audience.isUploaded &&
              <Box className={`${styles.CrumbLocation ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mt: 2 }} onClick={() => handleAudienceClick(audience)}>
                <Box className={`${styles.CrumbLocationIcon ?? ''}`} sx={{ display: 'flex', mr: 2 }}>
                  <Location size="16" />
                </Box>
                <Box className={`${styles.CrumbLocationName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', fontSize: 12, lineHeight: '15px' }}>{audience.totalLocationPin ? (audience.totalLocationPin).toLocaleString() : "0"}</Box>
              </Box>
            }

            {cardMenuPersmission && audience.isUploaded &&
              <Button className={`${styles.CrumbMenuButton ?? ''}`} disableRipple sx={{ p: 0, width: 40, height: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', position: 'absolute', zIndex: 4, bottom: 10, right: 15, }} onClick={handleMenuOpen}>
                <More />
              </Button>
            }
          </Box>
        </Box>
      </Box >
    }

    <Menu
      anchorEl={menuAnchorEl}
      className={`${styles.audienceMenu ?? ''}`}
      open={menuOpen}
      onClose={handleMenuClose}
      onClick={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: 'visible',
          filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

          '&.MuiMenu-paper': {
            borderRadius: '8px',
            backgroundColor: '#3C4DE7',

            '*': {
              color: '#fff',
              fontSize: '14px',
              fontWeight: '500',
              '&:active, &:focus': {
                color: '#fff',
                backgroundColor: 'transparent',
              }
            },
          },
          '&:before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            bottom: '-4px',
            left: '50%',
            width: 10,
            height: 10,
            bgcolor: '#3C4DE7',
            transform: 'translateX(-50%) rotate(45deg)',
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >
      {assignCardPermission &&
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button onClick={() => handleAudienceAssignClick()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
            Assign
          </Button>
        </MenuItem>
      }
      {deactivateCardPermission &&
        <MenuItem sx={{ background: 'tranparent' }}>
          {audience.isActive === "Deactivated" ?
            <Button onClick={() => handleActivateAudience(audience)} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>Activate</Button>
            :
            <Button onClick={() => handleDeactivateAudience(audience)} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>Deactivate</Button>
          }
        </MenuItem>
      }

      {deleteCardPermission &&
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button onClick={() => handleAudienceDeleteConfirmModal()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
            Delete
          </Button>
        </MenuItem>
      }
      {downloadPOIPermission &&
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button onClick={() => {
            if (audience.isUploaded) {
              downloadCard(audience);
            } else {
              dispatch(setMessage({
                type: "warning",
                title: "Your Card is not ready to download",
              }));
              dispatch(setMessageSnackbarStateState(true));
            }
          }}
            disableRipple sx={{
              width: '100%',
              px: 0,
              pt: 0,
              textTransform: 'capitalize',
              textAlign: 'left',
              background: 'tranparent',
              justifyContent: 'flex-start'
            }}>
            Download POI
          </Button>
        </MenuItem>
      }



      {refreshCardPermission &&
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button onClick={() => handleAudienceRefreshClick()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
            Refresh
          </Button>
        </MenuItem>
      }
      {renameCardPermission && !location?.pathname.includes("/favourite-cards") &&
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button onClick={() => handleAudienceRenameClick()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
            Edit
          </Button>
        </MenuItem>
      }
      {/*{changeFilterPermission &&*/}
      {/*  <MenuItem sx={{ background: 'tranparent' }}>*/}
      {/*    <Button onClick={() => changeFilter()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>*/}
      {/*      Change Filter*/}
      {/*    </Button>*/}
      {/*  </MenuItem>*/}
      {/*}*/}
      {/* {approveCardPermission && audience.isDemoCard && audience.isApprove === null && (
        <MenuItem sx={{ background: 'tranparent' }}>
          <Button onClick={() => handleAudienceApproveClick()} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
            Approve
          </Button>
        </MenuItem>
      )} */}
    </Menu>


    {
      deactivateCardPermission &&
      <ActivateAudienceConfirmModal
        audience={audience}
        activateAudienceConfirmModalState={activateAudienceConfirmModalState}
        setActivateAudienceConfirmModalState={setActivateAudienceConfirmModalState}
        handleActivateAudience={handleActivateAudience}
      />
    }

    {
      refreshCardPermission &&
      <RefreshAudienceConfirmModal
        audienceId={audience.id}
        audience={audience}
        refreshAudienceModalState={refreshAudienceModalState}
        setRefreshAudienceModalState={setRefreshAudienceModalState}
        handleAudienceRefresh={handleAudienceRefresh}
      />
    }

    {
      deleteCardPermission &&
      <DeleteAudienceConfirmModal
        audienceId={audience.id}
        deleteAudienceConfirmModalState={deleteAudienceConfirmModalState}
        setDeleteAudienceConfirmModalState={setDeleteAudienceConfirmModalState}
        setDeleteAudienceSuccessModalState={setDeleteAudienceSuccessModalState}
        handleAudienceDelete={handleAudienceDelete}
        audienceList={audienceList}
        setAudienceList={setAudienceList}
      />
    }

    {
      deleteCardPermission &&
      <DeleteAudienceSuccessModal
        audienceId={audience.id}
        deleteAudienceSuccessModalState={deleteAudienceSuccessModalState}
        setDeleteAudienceSuccessModalState={setDeleteAudienceSuccessModalState}
        updateAudiences={updateAudiences}
      />
    }

    {
      assignCardPermission &&
      <AssignAudienceConfirmModal
        audienceId={audience.id}
        audience={audience}
        assignAudienceModalState={assignAudienceModalState}
        setAssignAudienceModalState={setAssignAudienceModalState}
        handleAudienceAssign={handleAudienceAssign}
      />
    }

    {
      renameCardPermission &&
      <RenameAudienceModal
        audienceId={audience.id}
        audience={audience}
        renameAudienceModalState={renameAudienceModalState}
        setRenameAudienceModalState={setRenameAudienceModalState}
        handleAudienceRename={handleAudienceRename}
      />
    }
    {/* {
      approveCardPermission &&
      <ApproveAudienceConfirmModal
        audienceId={audience.id}
        approveAudienceModalState={approveAudienceModalState}
        setApproveAudienceModalState={setApproveAudienceModalState}
      />
    } */}

  </>);
}

export default AudienceCard;