import React, { useEffect } from "react";
import { Box } from "@mui/material";
import styles from './AudienceStatisticsColumnBar.module.scss';
import ColumnBarWithImage from "../../chart/ColumnBarWithImage";

const AudienceStatisticsColumnBar = ({ stat, bottomLine = true }) => {

  useEffect(() => {
    console.log(stat);
  }, [stat]);

  return (<>
    {stat &&
      <Box className={`${styles.CrumbDetailsStatArea ?? ''}`} sx={{ mb: bottomLine ? 3 : 0, pb: bottomLine ? 3 : 0, borderBottom: bottomLine ? '1px solid #6F7288' : 'none', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box className="StatInfoArea">
          <Box className="StatChart" sx={{ display: 'flex', width: '100%' }}>
            <ColumnBarWithImage
              data={stat.data}
              categories={stat.label}
            />
          </Box>
        </Box>
      </Box>
    }
  </>);
};

export default AudienceStatisticsColumnBar;