import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  return (
    <div className="error-page-area">
      <div className="error-page-content">
        <h1>404</h1>
        <p>Page Not Found</p>
        <Link to="/" className="home-link">Back to Home</Link>
      </div>
    </div>
  )
}
export default Error404;