// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CampaignCard_CampaignCardLocation__282Y7 {\n  height: 22px;\n  width: 21px;\n  background: rgb(249, 249, 249);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: rgb(57, 191, 143);\n}\n\n.CampaignCard_btnCloseBorder__-i8Rm {\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #fff;\n  margin: 0 5px;\n}\n\n.CampaignCard_btnAddBorder__CTLOc {\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #3955BF;\n  margin: 0 5px;\n}", "",{"version":3,"sources":["webpack://./src/components/common/card/campaign-card/CampaignCard.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;EACA,WAAA;EACA,8BAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,wBAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,aAAA;AADF;;AAIA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,mBAAA;EACA,aAAA;AADF","sourcesContent":["@import \"../../../../scss/variables\";\n\n.CampaignCardLocation {\n  height: 22px;\n  width: 21px;\n  background: rgb(249, 249, 249);\n  border-radius: 50%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  color: rgb(57, 191, 143);\n}\n\n.btnCloseBorder {\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #fff;\n  margin: 0 5px\n}\n\n.btnAddBorder {\n  height: 40px;\n  width: 40px;\n  border-radius: 50%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: #3955BF;\n  margin: 0 5px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CampaignCardLocation": "CampaignCard_CampaignCardLocation__282Y7",
	"btnCloseBorder": "CampaignCard_btnCloseBorder__-i8Rm",
	"btnAddBorder": "CampaignCard_btnAddBorder__CTLOc"
};
export default ___CSS_LOADER_EXPORT___;
