import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Black from '../../../../assets/images/RefreshIConBlack.png';
import White from '../../../../assets/images/RefreshIconWhite.png';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import { useSelector } from 'react-redux';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';

function RefreshAudienceConfirmModal({ audience, refreshAudienceModalState, setRefreshAudienceModalState, handleAudienceRefresh }) {

  const browserWidth = useWindowWidth();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handleClose = () => {
    setRefreshAudienceModalState(false);
  };

  const handleConfirm = () => {
    handleAudienceRefresh(audience);
    setRefreshAudienceModalState(false);
  };

  const { currentTheme } = useSelector((state) => state.theme);

  return (<>
    <Dialog
      open={refreshAudienceModalState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 100, height: 100 }}>
            <Image src={currentTheme === 'light' ? White : Black} alt='logo' />
          </Box>
        </Box>
        <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
          {"Refresh"}
        </DialogTitle>
        <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
          Are you sure you want to refresh the card?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "0px" }}>
        <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
          <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
          <Button className="form-button" variant='contained' onClick={() => handleConfirm()} sx={{ textTransform: "none", borderRadius: "0px" }}>Refresh</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  </>);
}

export default RefreshAudienceConfirmModal;