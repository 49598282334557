import React, { useEffect } from "react";
import { Box, Button } from "@mui/material";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import AudienceStatisticsColumnBar from "../../statistics/audience-statistics-column-bar/AudienceStatisticsColumnBar";

const AudienceStatisticsColumnBarCollapse = ({ title, stat, expanded, property, handleExpand, bottomLine = true, isPdfGenerate }) => {

  useEffect(() => {    
    
  }, [stat]);
  
  return (<>
    {stat &&
      <Box sx={{ mb: 0 }}>
        <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1.2, px: 0, cursor: 'pointer', background: 'none', border: 'none', width: '100%', textTransform: 'capitalize' }} variant="raised" disableRipple onClick={() => handleExpand(property)} className="StatCollapseButton">
          <Box sx={{ fontSize: 18, fontWeight: '600' ,color: isPdfGenerate && "#fff"}}>{title}</Box>
          {!isPdfGenerate &&
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 2 }}>
              {expanded[property] ?
                <ArrowUp2 size="18" />
                :
                <ArrowDown2 size="18" />
              }
            </Box>
          }
        </Button>
        {/* <AudienceStatisticsColumnBar stat={stat} bottomLine={bottomLine} /> */}
        {(expanded[property] || isPdfGenerate) && (
          <AudienceStatisticsColumnBar stat={stat} bottomLine={bottomLine} />
        )}
      </Box>
    }
  </>);
};

export default AudienceStatisticsColumnBarCollapse;