import { Box, Button } from "@mui/material";
import { ArrowDown2, ArrowUp2 } from "iconsax-react";
import AudienceStatisticsDoughnut from "../../statistics/audience-statistics-doughnut/AudienceStatisticsDoughnut";
// import AudienceCategoryStatistics from "../../statistics/audience-category-statistics-bar-chart/AudienceCategoryStatistics";

const AudienceStatisticsDoughnutCollapse = ({ title, stat, expanded, property, handleExpand, bottomLine = true, isPdfGenerate }) => {
  return (<>
    {stat &&
      <Box sx={{ mb: 0 }}>
        <Button sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', py: 1.2, px: 0, cursor: 'pointer', background: 'none', border: 'none', width: '100%', textTransform: 'capitalize' }} variant="raised" disableRipple onClick={() => handleExpand(property)} className="StatCollapseButton">
          <Box sx={{ fontSize: 18, fontWeight: '600', color: isPdfGenerate && "#fff" }}>{title}</Box>
          {!isPdfGenerate &&
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', ml: 2 }}>
              {expanded[property] ?
                <ArrowUp2 size="18" />
                :
                <ArrowDown2 size="18" />
              }
            </Box>
          }
        </Button>
        {(expanded[property] || isPdfGenerate) && (
          <>
            <AudienceStatisticsDoughnut
              stat={stat}
              // bottomLine={title === 'Brand Affinity' ? false : true}
              bottomLine={bottomLine}
              isPdfGenerate={isPdfGenerate}
            />
            {/* {stats &&
              <>
                <Box sx={{ fontSize: 18, fontWeight: '600', color: isPdfGenerate && "#fff" }}>{titles}</Box>
                <AudienceCategoryStatistics stat={stats} bottomLine={bottomLine} />
              </>
            } */}
          </>
        )}
      </Box>
    }
  </>);
};

export default AudienceStatisticsDoughnutCollapse;