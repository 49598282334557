import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import {useDispatch, useSelector} from 'react-redux';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';
import checkCircle from "../../../../assets/images/check-circle.png";
import darkCheckCircle from "../../../../assets/images/check-circle-black.png";

import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import AudienceService from "../../../../services/api/audience.service";
import {setMessage} from "../../../../redux/slices/message";
import {setMessageSnackbarStateState} from "../../../../redux/slices/common";
import {useNavigate} from "react-router-dom";
import {setCampaignAudiences} from "../../../../redux/slices/audience";

function NewActivateConfirmModal({ audience, newActivateConfirmModalState, setNewActivateConfirmModalState, connectorData, connectorCount }) {

    const browserWidth = useWindowWidth();
    const dispatch = useDispatch();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { campaignAudiences, totalSelectedAudience } = useSelector((state) => state.audience);
    const navigate = useNavigate();

    const handleClose = () => {
        setNewActivateConfirmModalState(false);
    };

    const handleConfirm = () => {
        //handleAudienceRefresh(audience);

        const filteredConnectors = connectorData.filter((con) => con.isOn);

        const connectorIds = filteredConnectors.map(con => con.id).join(',');

        const filteredAudience = campaignAudiences.filter((aud) => aud.isAdd);
        let audienceIds = filteredAudience.map(aud => aud.id);

        if (audience && audience.audienceId) {

            audienceIds.push(audience.audienceId);
        }

        audienceIds = audienceIds.join(',');

        let count = totalSelectedAudience * (connectorCount === 0 ?  1 : connectorCount);

        const queryParams = [

            `partnerIdList=${connectorIds}`,
            `userId=${user.id}`,
            `audienceIds=${audienceIds}`,
            `countryId=${selectedCountry.id}`,
            `audienceCount=${count}`
        ];


        AudienceService.v2activateCampaign(queryParams.join('&'))
            .then(
                (data) => {
                    setNewActivateConfirmModalState(false);
                    dispatch(setMessage({
                        type: "success",
                        title: "Activated Successfully",
                    }));
                    dispatch(setMessageSnackbarStateState(true));
                    navigate('/home');
                    dispatch(setCampaignAudiences([]));
                },
                (error) => {
                    console.log(error);
                }
            );


    };

    const { currentTheme } = useSelector((state) => state.theme);

    return (<>
        <Dialog
            open={newActivateConfirmModalState}
            onClose={handleClose}
            PaperProps={{ sx: { width: "550px", borderRadius: "0px" } }}
            sx={{
                backdropFilter: "blur(2px)",
            }}
        >
            <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: 100, height: 100 }}>
                        <Image src={currentTheme === 'dark' ? checkCircle: darkCheckCircle} alt='logo' />
                    </Box>
                </Box>
                {/*<DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>*/}
                {/*    {"Refresh"}*/}
                {/*</DialogTitle>*/}
                <DialogContentText style={{ paddingTop : '5px' }} id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
                    Are you sure you want to Activate Campaign ?
                </DialogContentText>
                {/*<Grid container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 2, md: 3}} style={{ marginLeft: '5px' }}>*/}
                {/*    <Grid style={{ paddingTop: '8px' }} item xs={6}>*/}
                {/*        <Box style={{ fontSize: '12px' }}> Available card Credits </Box>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6}*/}
                {/*          style={{padding: '8px', color: currentTheme === 'dark' ? '#ffffff' : '#202020'}}>*/}
                {/*        <Box style={{ fontSize: '12px' }}> Available Audience Credits </Box>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Box style={{ color: '#39BF8F' }} > 40 Cards</Box>*/}
                {/*    </Grid>*/}
                {/*    <Grid item xs={6}>*/}
                {/*        <Box style={{ color: '#39BF8F' }}> 10000 </Box>*/}
                {/*    </Grid>*/}
                {/*</Grid>*/}
            </DialogContent>
            <Divider />
            <DialogActions sx={{ padding: "0px" }}>
                <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                    <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>No</Button>
                    <Button className="form-button" variant='contained' onClick={() => handleConfirm()} sx={{ textTransform: "none", borderRadius: "0px" }}>Yes</Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    </>);
}

export default NewActivateConfirmModal;