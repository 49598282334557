import { authApi } from "../interceptor/auth.interceptor";
import SiteService from "./site.service";


class StripeService {

    static async getCheckout(queryCalcParams) {
        console.log("queryCalcParams", queryCalcParams);
        return await authApi
            .post(`stripe/create-checkout-session?${queryCalcParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async addAudience(queryParams, formData) {
        return await authApi
            .post(`audience/save?${queryParams}`, formData)
            .then((response) => {
                return response?.data;
            });
    }

    static async getAmount(queryParams) {
        return await authApi
            .get(`tier/calculateTotalAmount?${queryParams}`)
            .then((response) => {
                return response?.data;
            })
    }

    static async getSessionReturn(queryParams) {
        return await authApi
            .get(`stripe/session-status?${queryParams}`)
            .then((response) => {
                return response?.data;
            })
    }

    static async getUserPlanDetails(queryParams) {
        return await authApi
            .get(`tier/user-details?${queryParams}`)
            .then((response) => {
                return response?.data;
            })
    }

}

export default StripeService;