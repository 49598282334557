import React, { useState, useEffect } from "react";
import {Box, Button, List, ListItem, CircularProgress, Checkbox, InputLabel, Divider} from "@mui/material";
import Image from "mui-image";
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch, connect} from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useWindowWidth } from "../../../../utils/browser";
import {setMessageSnackbarStateState, setTopUpDetailsState} from "../../../../redux/slices/common";
import styles from './TopupDetails.module.scss';
import {loadedLoggedIn, setUser} from "../../../../redux/slices/auth";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import mail from "../../../../assets/images/icons/svg/sms.svg";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {setCampaignAudiences} from "../../../../redux/slices/audience";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {styled} from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import StripeService from "../../../../services/api/stripe.service";
import {setMessage} from "../../../../redux/slices/message";


const TopUpDetails = ({ isCollapsed, handleCollapsed }) => {

    const browserWidth = useWindowWidth();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { topUpDetailsState } = useSelector((state) => state.common);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.auth.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const [ amountDetails, setAmountDetails] = useState([]);
    const [ cardCost, setCardCost] = useState(0);
    const [ audienceCost, setAudienceCost] = useState(0);
    const [ cardCount, setCardCount] = useState(0);
    const [ audienceCount, setAudienceCount] = useState(0);
    const [isCardChecked, setIsCardChecked] = useState(false);
    const [isAudienceChecked, setIsAudienceChecked] = useState(false);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));

    const [amount, setAmount] = useState(0);

    useEffect(() => {
        let mounted = true;
        if (mounted && params) {

            const userId = user?.id || userFromLocalStorage?.id;

            const queryCalcParams = [
                `userId=${userId}`,
                `countryId=${selectedCountry.id}`,
                `connectorsCount=${1}`
            ];

                queryCalcParams.push(`audienceCount=${1}`);
            StripeService.getAmount(queryCalcParams.join('&')).then((data) => {

                    if (data?.results) {
                        setAmountDetails(data.results);
                    }
                    return Promise.resolve();
                }, (error) => {
                    return Promise.reject();
                }
            )
        }

        return () => {
            mounted = false;
            localStorage.removeItem('applyFilterParams');
        }
    }, [selectedCountry, params, dispatch]);


    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };

    const handleOpen = () => {
        dispatch(setTopUpDetailsState(true));
    };

    const handleClose = () => {
        dispatch(setTopUpDetailsState(false));
        handleCollapsed();
    };

    const handleSubmit = () => {

        if(!isAudienceChecked &&  !isCardChecked) {
            dispatch(setMessage({
                type: "error",
                title: "Select any one of the desired top up types",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return;
        }

        if(isAudienceChecked &&  audienceCount < 10000) {
            dispatch(setMessage({
                type: "error",
                title: "Minimum of 10000 audiences is required",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return;
        }

        if(amount < 1) {
            dispatch(setMessage({
                type: "error",
                title: "The minimum amount should be $1",
            }));
            dispatch(setMessageSnackbarStateState(true));
            return;
        }

        navigate('/check-out', { state: { cardCount, audienceCount, amount } });
    }

    const handleChange = e => {
        setAmount(e.target.value);
    }

    const handleSearchChange = (event) => {

        const searchText = event.target.value.toLowerCase();

        setAmount(searchText);
    }

    const handleCardCheckboxChange = (event) => {
        setIsCardChecked(event.target.checked);
        if(!event.target.checked){
            setAmount(audienceCost);
            setCardCost(0);
            setCardCount(0);
        }
    };

    const handleAudienceCheckboxChange = (event) => {
        setIsAudienceChecked(event.target.checked);
        if(!event.target.checked){
            setAmount(cardCost);
            setAudienceCost(0)
            setAudienceCount(0);
        }
    };

    const AlwaysOutlinedTextField = styled(TextField)(({ theme }) => ({
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: currentTheme === 'dark' ? '#1565c0' : '#202020', // Default MUI border color
            },
            '&:hover fieldset': {
                borderColor: theme.palette.primary.main,
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.primary.main,
            },
        },
    }));

    const handleAudienceCountChange = async (event) => {
        const count = Number(event.target.value); // Ensure count is a number

        if (!isNaN(count)) {
            setAudienceCount(count);
            const audienceCost = (amountDetails.costPerAudience * count).toFixed(4);
            setAudienceCost(audienceCost);

            // Recalculate the total amount

            const cardCosts = amountDetails.costPerCard * cardCount;
            let amount = cardCosts + parseFloat(audienceCost);
            setAmount(amount.toFixed(2));
        }
    };

    const handleCardCountChange = async (event) => {
        const count = Number(event.target.value); // Ensure count is a number

        if (!isNaN(count)) {
            setCardCount(count);
            const cardCost = amountDetails.costPerCard * count;
            setCardCost(cardCost);

            const audienceCosts = amountDetails.costPerAudience * audienceCount;
            let amount = cardCost + parseFloat(audienceCosts);
            setAmount(amount.toFixed(2));
        }
    };


    return (<>
            <SwipeableDrawer
                anchor={"right"}
                open={topUpDetailsState}
                onClose={handleClose}
                onOpen={handleOpen}
                PaperProps={{
                    sx: {
                        width: "30%",
                        borderLeft: "1px solid #212330",
                    }
                }}
                sx={{
                    backdropFilter: "blur(2px)",
                }}
            >

                <Box
                    style={{
                        paddingTop: '50px',
                        paddingLeft: '50px',
                        paddingRight: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        justifyContent: 'space-between' // This pushes the button to the end
                    }}
                >

            <Grid container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 2, md: 3}}>
                <Grid item style={{paddingTop: '6px'}} xs={12}>
                    <Typography
                                style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020', fontSize: '32px'}}
                                variant="contained">
                        Top Up your account
                    </Typography>
                </Grid>
                <Grid item style={{paddingTop: '6px'}} xs={12}>
                    <Typography
                        style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020', fontSize: '12px'}}
                        variant="contained">
                        Select both or one of the desired top up types
                    </Typography>
                </Grid>
                    <Grid item container style={{ paddingTop: '50px', paddingLeft: '15px' }}>
                        <Grid xs={12}>
                            <FormControlLabel style={{  fontWeight: '700' }} control={<Checkbox  style ={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }} checked={isAudienceChecked} onChange={handleAudienceCheckboxChange} />} label="Number of required Audiences" />
                        </Grid>

                        <Grid xs={6}>
                            <TextField variant="outlined"  value={audienceCount} onChange={handleAudienceCountChange} disabled={!isAudienceChecked} fullWidth placeholder='Audience Count' inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined , height: '10px'  } }} sx={{ width: "220px", height: "30px" ,paddingTop: '10px', paddingLeft: '15px', border: '1px', borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#1565c0' : '#202020', // Default MUI border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#1e88e5' : '#303030', // Hover border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#42a5f5' : '#505050', // Focused border color
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        color: currentTheme === 'dark' ? '#ffffff' : '#110202', // Text color
                                    },
                                    '&.Mui-disabled fieldset': {
                                        color: currentTheme === 'dark' ? '#888888' : '#999999', // Disabled text color based on theme
                                        borderColor: currentTheme === 'dark' ? '#888888' : '#999999', // Disabled border color based on theme
                                    },
                                },
                            }}/>
                        </Grid>
                        <Grid xs={6} style={{paddingTop : '24px', paddingLeft : '65px' }}>
                            <InputLabel style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }}> { audienceCost } USD </InputLabel>
                        </Grid>

                        <Grid xs={12} style={{ paddingTop: '25px', fontWeight: '700'}} >
                            <FormControlLabel style={{  fontWeight: '700' }} control={<Checkbox style ={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }} checked={isCardChecked} onChange={handleCardCheckboxChange} />} label="Number of required Cards" />
                        </Grid>

                        <Grid xs={6}>
                            <TextField variant="outlined" value={cardCount} onChange={handleCardCountChange} disabled={!isCardChecked} fullWidth placeholder='Card Count' inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined, height: '10px'   } }} sx={{ width: "220px", height: "30px" ,paddingTop: '10px', paddingLeft: '15px', border: '1px', borderRadius: '8px',
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#1565c0' : '#202020', // Default MUI border color
                                    },
                                    '&:hover fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#1e88e5' : '#303030', // Hover border color
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: currentTheme === 'dark' ? '#42a5f5' : '#505050', // Focused border color
                                    },
                                    '&.Mui-disabled fieldset': {
                                        color: currentTheme === 'dark' ? '#888888' : '#999999', // Disabled text color based on theme
                                        borderColor: currentTheme === 'dark' ? '#888888' : '#999999', // Disabled border color based on theme
                                    },
                                },
                            }}/>
                        </Grid>
                        <Grid xs={6} style={{paddingTop : '24px', paddingLeft : '65px' , color: currentTheme === 'dark' ? '#ffffff' : '#202020' }}>
                            <InputLabel style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }}> {cardCost} USD </InputLabel>
                        </Grid>
                    </Grid>
                <Grid xs={12} style={{ paddingTop: '6px'}}>
                  <label variant="standard" placeholder='Custom Amount' inputProps={{ style: { color: currentTheme === 'dark' ? '#ffffff' : undefined   } }} style={{ width: "300px", height: "45px" ,paddingTop: '10px', paddingLeft: '15px'}}/>
                </Grid>
                <Divider className={currentTheme === 'dark' ? styles['custom-divider'] : styles['custom-white']} sx={{my: 2, borderColor: currentTheme === 'dark' ? '#5a1d38' : 'transparent'}}/>
                <Grid xs={6} style={{paddingTop : '8px', paddingLeft : '45px'}}>
                    <InputLabel style={{ color: currentTheme === 'dark' ? '#ffffff' : '#202020' }}> Total </InputLabel>
                </Grid>
                <Grid xs={6} style={{ fontFamily: 'Manrope, Arial, sans-serif' , paddingTop : '8px', paddingLeft : '70px'}}>
                    <InputLabel style={{ color: '#3955BF', fontWeight: '700' }}> { amount } USD </InputLabel>
                </Grid>
            </Grid>

            <Box mt={3} style={{ paddingBottom: '10px'}}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    fullWidth
                >
                    Pay now
                </Button>
            </Box>
        </Box>
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.auth.user,
    currentTheme: state.theme.currentTheme
});

export default connect(mapStateToProps)(TopUpDetails);
