// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TopupDetails_custom-divider__BlVf7 {\n  border-color: #3c4de7 !important;\n  width: 100%;\n}\n\n.TopupDetails_custom-white__yMWMw {\n  border-color: rgba(99, 97, 97, 0.22) !important;\n  width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/components/common/sidebar/top-up-details-sidebar/TopupDetails.module.scss"],"names":[],"mappings":"AAEA;EACE,gCAAA;EACA,WAAA;AADF;;AAIA;EACE,+CAAA;EACA,WAAA;AADF","sourcesContent":["@import \"../../../../scss/variables\";\n\n.custom-divider {\n  border-color: #3c4de7 !important;\n  width: 100%;\n}\n\n.custom-white {\n  border-color: rgba(99, 97, 97, 0.22) !important;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"custom-divider": "TopupDetails_custom-divider__BlVf7",
	"custom-white": "TopupDetails_custom-white__yMWMw"
};
export default ___CSS_LOADER_EXPORT___;
