import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, IconButton, Toolbar, Typography } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import Tooltip from '@mui/material/Tooltip';
import { useDemoData } from '@mui/x-data-grid-generator';
import { gridClasses } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { Additem, Flash } from 'iconsax-react';

import { ReactComponent as MetaImage } from "../../../../assets/images/icons/svg/meta.svg";
import { ReactComponent as SnapImage } from "../../../../assets/images/icons/svg/snapchat.svg";

import {
  setCompareStatusModalState, setExlPlatformDetailsState,
  setMessageSnackbarStateState, setMetaPlatformDetailsState,
  setMobileAudienceDetailsState, setSnapPlatformDetailsState
} from '../../../../redux/slices/common';
import { setCompareCardsModalState } from '../../../../redux/slices/common';
import AudienceService from '../../../../services/api/audience.service';
import ActivateCampaignModal from '../activate-campaign-modal/ActivateCampaignModal';
import ActivateCompareAudienceConfirmModal from '../activate-compared-audience-modal/ActivateCompareAudienceConfirmModal';
import { setCompareStatusTableData } from "../../../../redux/slices/audience";
import MetaPlatformDetails from "../../sidebar/meta-platform-details-confirm-sidebar/MetaPlatformDetails";
import SnapPlatformDetails from "../../sidebar/snap-platform-details-confirm-sidebar/SnapPlatformDetails";
import EXLPlatformDetails from "../../sidebar/exl-platform-details-confirm-sidebar/EXLPlatformDetails";
import connectorService from "../../../../services/api/connector.service";
import StripeService from "../../../../services/api/stripe.service";
import ActivateTopUpCreditModal from "../activate-top-credit-modal/ActivateTopUpCreditModal";
import {useNavigate} from "react-router-dom";


function QuickSearchToolbar() {

  const dispatch = useDispatch();
  const { currentTheme } = useSelector((state) => state.theme);

  const handleOpen = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setCompareCardsModalState(true));
      dispatch(setCompareStatusModalState(false));
    }
  };

  return (
    <Toolbar disableGutters>
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '& .MuiInputBase-input': {
            color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
          },
          '& .MuiOutlinedInput-root': {
            borderRadius: 'none', // Example: Remove border radius
          },
        }}
      >

        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Typography variant="body1" color={currentTheme === 'dark' ? '#fff' : '#010412'}>Generate Crossmatch</Typography>
          <IconButton onClick={handleOpen}>
            <Additem size="32" color="#3C4DE7" />
          </IconButton>
        </Box>
        <GridToolbarQuickFilter sx={{ border: 'none', borderBottom: 0, padding: 0 }} autoComplete="off" />
      </Box>
    </Toolbar>
  );
}

function CompareCardStatusModal() {

  //const { compareCardStatusModalState } = useSelector((state) => state.common);
  const { compareStatusTableData } = useSelector((state) => state.audience);
  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const [activateCampaignModalState, setActivateCampaignModalState] = useState(false);
  const [activateCompareAudienceCondirmModalstate, setActivateCompareAudienceConfirmModalState] = useState(false);
  const [selectedCompareId, setSelectedCompareId] = useState(false);
  const [selectedPartnerId, setSelectedPartnerId] = useState(false);
  const [activateType, setActivateType] = useState('');
  const [metaAddAccountModalModalState, setMetaAddAccountModalModalState] = useState(false);
  const [exlModalModalState, setExlModalModalState] = useState(false);
  const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
  const [ amountDetails, setAmountDetails] = useState([]);
  const [snapModalState, setSnapModalState] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isExlCollapsed, setIsExlCollapsed] = useState(false);
  const [activateCost, setActivateCost] = useState(false);
  const [isSnapCollapsed, setIsSnapCollapsed] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [activateTopUpCreditModalState, setActivateTopUpCreditModalState] = useState(false);
  const navigate = useNavigate();

  const [connectorData, setConnectorData] = useState([]);
  const [filteredConnectorData, setFilteredConnectorData] = useState([]);

  const currentThemeColor = currentTheme === 'light' ? '#010412' : '#FFF';

  const planMode = user?.planMode || userFromLocalStorage?.planMode;

  const dispatch = useDispatch();
  const columns = [
    { field: 'mainCardName', headerName: (
          <Tooltip title="Baseline card or point of comparison" placement="top">
            <span>Main Card</span>
          </Tooltip>
      ),  headerAlign: 'center', align: 'center', width: 260
    },
    {
      field: 'compareCardName',
      headerName: (
          <Tooltip title="The audience studied in comparison to the Main card" placement="top">
            <span>Target Card</span>
          </Tooltip>
      ),
      headerAlign: 'center',
      align: 'center',
      width: 260
    },
    {
      field: 'commonCost',
      headerName: (
          <Tooltip title="Cost to activate Match audience" placement="top">
            <span>Cost to Activate</span>
          </Tooltip>
      ),
      headerAlign: 'center',
      align: 'center',
      width: 200,
      valueGetter: (params) => `$ ${params.value || 0}`, // Prefix with $ and handle null/undefined values
    },
    {
      field: 'commonPercentage',
      headerName: (
          <Tooltip title="The percentage of the intersected audience as a ratio of the target card" placement="top">
            <span>Matched %</span>
          </Tooltip>
      ),
      headerAlign: 'center',
      align: 'center',
      width: 250,
      renderCell: (params) => crossMatched(params.value)
    },
    { field: 'isAvailable', headerName: 'Activate Matched Audience', headerAlign: 'center', align: 'center', width: 300, renderCell: (params) => activateMatchedAudience(params.value, params.row.id, 'common', params.row.commonCost) },
    // { field: 'unCommonPercentage', headerName: 'Unmatched %', headerAlign: 'center', align: 'center', width: 110, renderCell: (params) => crossMatched(params.value) },
    // { field: 'isReverseAvailable', headerName: 'Activate Unmatched Audience', headerAlign: 'center', align: 'center', width: 250, renderCell: (params) => activateMatchedAudience(params.value, params.row.id, 'uncommon') },
  ]


  function checkBalance(commonCost) {
    let isActivate = true;

    if (planMode === 'Trial' || (planMode === 'credit' && commonCost > amountDetails.totalCreditAvailable)) {

      setActivateTopUpCreditModalState(true);
      isActivate = false;
    }

    return isActivate; // Ensure a boolean value is returned
  }

  function activateMatchedAudience(isAvailable, id, type, commonCost) {

    const handleActivateClick = () => {

      setSelectedCompareId(id);
      setSelectedPartnerId('4');
      setActivateType(type);
      setActivateCost(commonCost);

      if (checkBalance(commonCost)) {

        handleExePlatFormDetailsClick(); // Execute only if balance check passes
      }
    };

    const metaClick = () => {

      setSelectedCompareId(id);
      setSelectedPartnerId('2');
      setActivateType(type);
      setActivateCost(commonCost);

      if (checkBalance(commonCost)) {

        handleMetaPlatFormDetailsClick(); // Execute only if balance check passes
      }
    };

    const snapClick = () => {

      setSelectedCompareId(id);
      setSelectedPartnerId('3');
      setActivateType(type);
      setActivateCost(commonCost);

      if (checkBalance(commonCost)) {

        handleSnapPlatFormDetailsClick(); // Execute only if balance check passes
      }
    };

    const handleExePlatFormDetailsClick = () => {

      setIsExlCollapsed(!isExlCollapsed);
      setExlModalModalState(true);
      dispatch(setExlPlatformDetailsState(true));
    };


    const handleMetaPlatFormDetailsClick = () => {

      setIsCollapsed(!isCollapsed);
      setMetaAddAccountModalModalState(true);
      dispatch(setMetaPlatformDetailsState(true));
    }

    const handleSnapPlatFormDetailsClick = () => {

      setIsSnapCollapsed(!isSnapCollapsed);
      setSnapModalState(true);
      dispatch(setSnapPlatformDetailsState(true));
    }

    // Get authorized connectors
    const authorizedConnectors = connectorData.filter((connector) => connector.isAuthorized);

    return (
        <>
          {isAvailable === true ? (
              <>
                {authorizedConnectors.length > 0 ? (
                    authorizedConnectors.map((connector) => (
                        <Button
                            key={connector.id}
                            onClick={connector.id === 4 ? handleActivateClick : connector.id === 3 ? snapClick : metaClick}
                        >
                          {connector.id === 4 ? (
                              <Flash style={{ color: '#3C4DE7' }} />
                          ) : connector.id === 3 ? (
                              <SnapImage style={{ color: '#FFFC00', width: '45px', height: '45px' }} /> // Snapchat Icon
                          ) : (
                              <MetaImage style={{ color: '#3C4DE7', width: '45px', height: '45px' }} />
                          )}
                        </Button>
                    ))
                ) : (
                    <Button
                        onClick={() => navigate('/user-profile')}
                        sx={{ textTransform: 'none' }} // Prevents text from being displayed in all caps
                    >
                      No Linked Accounts -{' '}
                      <Typography
                          component="span"
                          sx={{
                            color: '#3C4DE7',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            ml: 1,
                            fontSize: '15px',
                            textTransform: 'none' // Ensures "Link here" is also in original case
                          }}
                      >
                        Link here
                      </Typography>
                    </Button>
                )}
              </>
          ) : isAvailable === false ? (
              <Box>Audiences are low for Activation</Box>
          ) : (
              <CircularProgress size={20} />
          )}
        </>
    );
  }

  function crossMatched(percentage) {

    if (percentage !== null && percentage !== undefined) {
      return (
        percentage
      );
    } else {
      return (
        <CircularProgress size={20} />
      );
    }

  }

  const updatedColumns = columns.map(column => ({
    ...column,
    sortable: false,
  }));

  //const [tableData, setTableData] = useState(compareStatusTableData || []);

  const compareDetails = async () => {
    try {
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];
        const data = await AudienceService.compareDetails(queryParams.join('&'));

        if (data?.results?.length) {
          //setTableData(data.results);
          dispatch(setCompareStatusTableData(data.results));
        } else {
          //setTableData([]);
          dispatch(setCompareStatusTableData([]));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (compareCardStatusModalState) {
    compareDetails();

    const queryParams = [

      `userId=${user != null ? user.id : userFromLocalStorage.id}`,
    ];

    connectorService.getAllConnectorsByUser(queryParams).then((data) => {
      if (data) {
        setConnectorData(data.results);
      }
    });



    const queryCalcParams = [

      `userId=${user != null ? user.id : userFromLocalStorage.id}`,
      `countryId=${selectedCountry.id}`,
    ];


    StripeService.getAmount(queryCalcParams.join('&')).then((data) => {

          if (data?.results) {

            setAmountDetails(data.results);
          }
          return Promise.resolve();
        }, (error) => {
          return Promise.reject();
        }
    )

    // }
  }, [selectedCountry]);


  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setCompareStatusModalState(false));
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const { data } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 200,
    maxColumns: 6,
  });

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, color: grey[600] }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  const handleCollapsed = () => {


    setIsCollapsed(!isCollapsed);
    dispatch(setMobileAudienceDetailsState(false));
  }

  const handleExlCollapsed = () => {

    setIsExlCollapsed(!isExlCollapsed);
    dispatch(setMobileAudienceDetailsState(false));
  }

  const handleMetaChange = () => {

    setMetaAddAccountModalModalState(false);
    setActivateCompareAudienceConfirmModalState(true);
  }

  const handleExlChange = () => {

    setExlModalModalState(false);
    setActivateCompareAudienceConfirmModalState(true);
  }

  const handleSnapCollapsed = () => {

    setIsSnapCollapsed(!isSnapCollapsed);
    dispatch(setMobileAudienceDetailsState(false));
  }

  const handleSnapChange = () => {

    setSnapModalState(false);
    setActivateCompareAudienceConfirmModalState(true);
  }


  return (<>
    {/*<Dialog*/}
    {/*  open={compareCardStatusModalState}*/}
    {/*  fullScreen*/}
    {/*  onClose={handleClose}*/}
    {/*  onBackdropClick={handleBackdropClick}*/}
    {/*  PaperProps={{ sx: { width: '90%', height: '90%', borderRadius: "0px", boxShadow: currentTheme === "light" ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(255, 255, 255, 0.5)' } }}*/}
    {/*  sx={{*/}
    {/*    backdropFilter: "blur(25px)",*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 22, lineHeight: '20px' }}>Cross Match Report</DialogTitle>*/}
      {/* <Divider /> */}
      <Box sx={{ height: '100%', width: '100%', padding: '5px' }}>
        {/*<CloseIcon onClick={handleClose} sx={{*/}
        {/*  cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,*/}
        {/*}} />*/}
        {/* <DataGrid
          sx={{
            border: "none",
            borderTop: '1px solid',
            color: currentThemeColor,
            '& .MuiDataGrid-footerContainer ': {
              border: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              color: currentThemeColor
            },
            '& .MuiButtonBase-root ': {
              color: currentThemeColor
            }
          }}
          rows={tableData}
          columns={updatedColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 9,
              },
            },
          }}
          pageSizeOptions={[9]}
          disableColumnMenu
          disableSorting
          disableSelectionOnClick
          // hideFooter
          slots={{ toolbar: QuickSearchToolbar }}
        /> */}
        <DataGrid
          sx={{
            border: 'none',
            [`& .${gridClasses.row}`]: {
              borderLeft: '2.5px solid #3C4DE7',
              borderRadius: '0 10px 10px 0',
              bgcolor: currentTheme === 'light' ? grey[200] : grey[900],
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
              color: grey[600],
              // '&:hover': {
              //   color: '#3C4DE7',
              // },
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
              border: 'none',
            },
            '& .MuiDataGrid-footerContainer ': {
              border: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              color: currentThemeColor,
            },
            '& .MuiButtonBase-root ': {
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-selectedRowCount': {
              color: currentThemeColor
            },
          }}
          rows={ compareStatusTableData.length ? compareStatusTableData : [] }
          columns={updatedColumns}
          getRowSpacing={getRowSpacing}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableColumnMenu
          disableSorting
          disableSelectionOnClick
          slots={{
            toolbar: QuickSearchToolbar,
            noRowsOverlay: CustomNoRowsOverlay
          }}
        />
      </Box>
    {/*</Dialog >*/}

    <ActivateCampaignModal
      activateCampaignModalState={activateCampaignModalState}
      setActivateCampaignModalState={setActivateCampaignModalState}
      selectedCompareId={selectedCompareId}
    />

    <ActivateCompareAudienceConfirmModal
      activateCompareAudienceCondirmModalstate={activateCompareAudienceCondirmModalstate}
      setActivateCompareAudienceConfirmModalState={setActivateCompareAudienceConfirmModalState}
      selectedCompareId={selectedCompareId}
      selectedPartnerId={selectedPartnerId}
      activateType={activateType}
      cost={activateCost}
    />

    { metaAddAccountModalModalState &&

        <MetaPlatformDetails
            isCollapsed={isCollapsed}
            handleCollapsed={handleCollapsed}
            handleMetaChange={handleMetaChange}
        />
    }


    { snapModalState &&

        <SnapPlatformDetails
            isSnapCollapsed={isSnapCollapsed}
            handleSnapCollapsed={handleSnapCollapsed}
            handleSnapChange={handleSnapChange}
        />
    }

    { exlModalModalState &&

        <EXLPlatformDetails
            isExlCollapsed={isExlCollapsed}
            handleExlCollapsed={handleExlCollapsed}
            handleExlChange={handleExlChange}
        />
    }

    {activateTopUpCreditModalState &&
        <ActivateTopUpCreditModal
            activateTopUpCreditModalState={activateTopUpCreditModalState}
            setActivateTopUpCreditModalState={setActivateTopUpCreditModalState}
        />
    }

  </>);
}
export default CompareCardStatusModal;