import { createSlice } from '@reduxjs/toolkit';
import { refreshToken } from '../../config/config';
import { getCookie } from '../../utils/cookie';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    auth: {
      loaded: false,
      loggedIn: false,
      accessToken: null,
      refreshToken: getCookie(refreshToken),
      user: null
    },
    selectedCountry: localStorage.getItem('selectedCountry') ? JSON.parse(localStorage.getItem('selectedCountry')) : null
  },
  reducers: {
    setUser: (state, action) => {
      state.auth.user = action.payload;
      localStorage.setItem('user', JSON.stringify(action.payload));
    },
    setSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
      localStorage.setItem('selectedCountry', JSON.stringify(action.payload));
    },
    login: (state, action) => {
      state.auth.loaded = action.payload.loaded;
      state.auth.loggedIn = action.payload.loggedIn;
      state.auth.accessToken = action.payload.accessToken;
      state.auth.refreshToken = action.payload.refreshToken;
    },
    loadedLoggedIn: (state, action) => {
      state.auth.loaded = action.payload.loaded;
      state.auth.loggedIn = action.payload.loggedIn;
    },
    logout: (state, action) => {
      state.auth = {
        loaded: false,
        loggedIn: false,
        accessToken: null,
        refreshToken: null,
        user: null
      }

      localStorage.removeItem('crumbID');
      localStorage.removeItem('crumbList');
      localStorage.removeItem('mapbox.eventData:cmFmaXVsLW1lbW9i');
      localStorage.removeItem('selectedAudience');
      localStorage.removeItem('selectedCountry');
      localStorage.removeItem('audience');
      localStorage.removeItem('back-button-url');
      localStorage.removeItem('approveClientList');
      //localStorage.removeItem('redirectUrl');
      localStorage.removeItem('AudienceList');
      //localStorage.removeItem('email-country-set');
      localStorage.removeItem('mapbox.eventData.uuid:cmFmaXVsLW1lbW9i');
      localStorage.removeItem('user');
      localStorage.removeItem('p2e3r21m2i31s166s223i345o226112n');
      localStorage.removeItem('cards');
      localStorage.removeItem('applyFilterParams');
      window.location.reload();
    }
  },
});

export const { setUser, login, loadedLoggedIn, logout, setSelectedCountry } = authSlice.actions;

export default authSlice.reducer;
