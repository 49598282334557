import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import checkCircle from '../../../../assets/images/check-circle.png';
import { Box, DialogContentText } from '@mui/material';
import Image from 'mui-image';

function UploadPoiSuccessModal({ uploadPoiSuccessModalState, handleUploadPoiSuccessModalClose }) {

  return (<>
    <Dialog
      open={uploadPoiSuccessModalState}
      onClose={handleUploadPoiSuccessModalClose}
      PaperProps={{ sx: { width: "35%", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 100, height: 100 }}>
            <Image src={checkCircle} alt='logo' />
          </Box>
        </Box>
        <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
          {"Upload 100% Successful"}
        </DialogTitle>
        <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
          Upload places are available now
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "0px" }}>
        <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
          <Button variant='contained' onClick={handleUploadPoiSuccessModalClose} sx={{ textTransform: "none", borderRadius: "0px" }}>Ok</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  </>);
}

export default UploadPoiSuccessModal;