import React, { useState } from 'react';
import { useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { DataGrid, GridToolbarQuickFilter } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { useDemoData } from '@mui/x-data-grid-generator';
import { gridClasses } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import { setAudienceActivityModalState } from '../../../../redux/slices/common';
import AudienceService from '../../../../services/api/audience.service';

function QuickSearchToolbar() {

  const { currentTheme } = useSelector((state) => state.theme);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end', // Align to the right corner
        p: 0.5,
        pb: 0,
        '& .MuiInputBase-input': {
          color: currentTheme === 'dark' ? '#ffffff' : undefined // Font color white if dark theme
        },
        '& .MuiOutlinedInput-root': {
          borderRadius: 'none', // Example: Remove border radius
        },
      }}
    >
      <GridToolbarQuickFilter sx={{ border: "none", borderBottom: 0, padding: 0 }} autoComplete='off' />
    </Box>
  );
}

function AudienceActivityModal() {

  const { audienceActivityModalState } = useSelector((state) => state.common);
  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);

  const currentThemeColor = currentTheme === 'light' ? '#010412' : '#FFF';

  const dispatch = useDispatch();
  const columns = [
    { field: 'cardName', headerName: 'Audience Name', headerAlign: 'center', align: 'center', width: 250 },
    { field: 'category', headerName: 'Audience Category', headerAlign: 'center', align: 'center', width: 200 },
    { field: 'createdOn', headerName: 'Created On', headerAlign: 'center', align: 'center', width: 200 },
    { field: 'activationPlatformName', headerName: 'Activated On', headerAlign: 'center', align: 'center', width: 150 },
    { field: 'activatedByName', headerName: 'Activated By', headerAlign: 'center', align: 'center', width: 250 },
    { field: 'dateOfActivation', headerName: 'Date of Activation', headerAlign: 'center', align: 'center', width: 160 },
  ]

  const updatedColumns = columns.map(column => ({
    ...column,
    sortable: false,
  }));

  const [tableData, setTableData] = useState([])

  const audienceActivityCall = async () => {
    try {
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];
        const data = await AudienceService.audienceActivity(queryParams.join('&'));

        if (data?.results?.length) {
          setTableData(data.results);
        } else {
          setTableData([]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // if (audienceActivityModalState) {
      audienceActivityCall();
    // }
  }, [selectedCountry]);



  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setAudienceActivityModalState(false));
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const { data } = useDemoData({
    dataSet: 'Commodity',
    rowLength: 200,
    maxColumns: 6,
  });

  const getRowSpacing = React.useCallback((params) => {
    return {
      top: params.isFirstVisible ? 0 : 5,
      bottom: params.isLastVisible ? 0 : 5,
    };
  }, []);

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1, color: grey[600] }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }

  return (<>
    {/*<Dialog*/}
    {/*  open={audienceActivityModalState}*/}
    {/*  fullScreen*/}
    {/*  onClose={handleClose}*/}
    {/*  onBackdropClick={handleBackdropClick}*/}
    {/*  PaperProps={{ sx: { width: '90%', height: '90%', borderRadius: "0px", boxShadow: currentTheme === "light" ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(255, 255, 255, 0.5)' } }}*/}
    {/*  sx={{*/}
    {/*    backdropFilter: "blur(25px)",*/}
    {/*  }}*/}
    {/*>*/}
    {/*  <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Audience Activation History</DialogTitle>*/}
      {/* <Divider /> */}
      <Box sx={{ height: '100%', width: '100%', padding: '5px' }}>
        {/*<CloseIcon onClick={handleClose} sx={{*/}
        {/*  cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,*/}
        {/*}} />*/}
        {/* <DataGrid
          sx={{
            border: "none",
            borderTop: '1px solid',
            color: currentThemeColor,
            '& .MuiDataGrid-footerContainer ': {
              border: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              color: currentThemeColor
            },
            '& .MuiButtonBase-root ': {
              color: currentThemeColor
            }
          }}
          rows={tableData}
          columns={updatedColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 9,
              },
            },
          }}
          pageSizeOptions={[9]}
          disableColumnMenu
          disableSorting
          disableSelectionOnClick
          // hideFooter
          slots={{ toolbar: QuickSearchToolbar }}
        /> */}
        <DataGrid
          sx={{
            border: 'none',
            [`& .${gridClasses.row}`]: {
              borderLeft: '2.5px solid #3C4DE7',
              borderRadius: '0 10px 10px 0',
              bgcolor: currentTheme === 'light' ? grey[200] : grey[900],
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-columnHeaders': {
              border: 'none',
              color: grey[600],
              '&:hover': {
                color: '#3C4DE7',
              },
            },
            '& .MuiDataGrid-iconSeparator': {
              display: 'none',
            },
            '& .MuiDataGrid-cell': {
              border: 'none',
            },
            '& .MuiDataGrid-footerContainer ': {
              border: 'none',
            },
            '& .MuiTablePagination-displayedRows': {
              color: currentThemeColor,
            },
            '& .MuiButtonBase-root ': {
              color: currentTheme === 'light' ? grey[900] : grey[200],
            },
            '& .MuiDataGrid-selectedRowCount': {
              color: currentThemeColor
            },
          }}
          rows={tableData}
          columns={updatedColumns}
          getRowSpacing={getRowSpacing}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          disableColumnMenu
          disableSorting
          disableSelectionOnClick
          slots={{
            toolbar: QuickSearchToolbar,
            noRowsOverlay: CustomNoRowsOverlay
          }}
        />
      </Box>
    {/*</Dialog >*/}
  </>);
}

export default AudienceActivityModal;