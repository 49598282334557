// hooks/useTagInput.js
import  React, { useState } from 'react';

const useTagInput = (tags, setTags)  => {

    const [input, setInput] = useState('');
    const [isKeyReleased, setIsKeyReleased] = useState(true);
    const [isValidTag, setIsValidTag] = React.useState(false);

    // onKeyDown handler for adding/removing tags
    const onKeyDown = (e) => {
        const { key } = e;
        const trimmedInput = input.trim();

        if (isValidTag) {
            if (key === ',' || key === ' ' || key === 'Enter') {
                e.preventDefault();
                if (trimmedInput.length && !tags.includes(trimmedInput)) {
                    setTags((prevState) => [...prevState, trimmedInput]);
                }
                setInput('');
            } else if (key === 'Backspace' && !input.length && tags.length && isKeyReleased) {
                const tagsCopy = [...tags];
                const poppedTag = tagsCopy.pop();
                e.preventDefault();
                setTags(tagsCopy);
                setInput(poppedTag);
            }

            setIsKeyReleased(false);
        }
    };

    const onKeyUp = () => {
        setIsKeyReleased(true);
    };

    // Handle click to remove a tag
    const handleTagClick = (tag) => {
        const updatedTags = tags.filter((t) => t !== tag);
        setTags(updatedTags);
    };


    // Handle input blur event
    const handleBlur = () => {
        if (input.trim().length > 0 && !tags.includes(input.trim()) && /^[a-z]+$/.test(input.trim())) {
            setTags((prevState) => [...prevState, input.trim()]);
        }
        setInput('');
    };

    const onTagChange = (e) => {
        const { value } = e.target;
        setInput(value);
        setIsValidTag(validateCase(value)); // Validate the input
    };

    const validateCase = (tagName) => {
        const pattern = /^[a-zA-Z0-9]*$/;
        return pattern.test(tagName);
    };


    return {
        tags,
        input,
        setInput,
        onKeyDown,
        onKeyUp,
        handleTagClick,
        handleBlur,
        onTagChange,
        isValidTag,
    };
};

export default useTagInput;
