import { api } from "../interceptor/auth.interceptor";

class SiteService {

  static async getSiteSettings() {
    return await api
      .get("siteSetting")
      .then((response) => {
        return response.data.data;
      });
  }

}

export default SiteService;