import { authApi } from "../interceptor/auth.interceptor";

class PoiService {

  static async getPoiPincolor() {
    return await authApi
      .get("poi/pin-colors")
      .then((response) => {
        return response?.data;
      });
  }

  static async addPoi(queryParams, formData) {
    return await authApi
      .post(`poi/save?${queryParams}`, formData)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async library(queryParams) {
    return await authApi
      .get(`poi/library?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async libraryAll(queryParams) {
    return await authApi
      .get(`poi/common?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  // static async loadPoi(queryParams) {
  //   return await authApi
  //     .get(`poi/v2/load?${queryParams}`)
  //     .then((response) => {
  //       // console.log(response);
  //       return response?.data;
  //     });
  // }

  // test for increasing TO
  static async loadPoi(queryParams) {
    const timeout = 2 * 60 * 1000; // 2 minutes in milliseconds

    try {
      const response = await authApi.get(`poi/v2/load?${queryParams}`, {
        timeout: timeout,
      });

      // Process the response
      return response?.data;
    } catch (error) {
      // Handle errors
      console.error('Error:', error.message);
      // You may want to throw the error or handle it in another way based on your requirements
    }
  }

  static async applyFilter(queryParams) {
    return await authApi
      .get(`poi/v2/apply-filter?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async mergeApplyFilter(queryParams) {
        return await authApi
            .get(`poi/merge-apply-filter?${queryParams}`)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
  }

  static async poiDownload(queryParams) {
    return await authApi
      .get(`poi/download?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async brandPOIDownload(queryParams) {
    return await authApi
      .get(`poi/brand/download?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async poiEstimatedCount(queryParams) {
    return await authApi
      .get(`poi/estimated-count?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async poiDetails(queryParams) {
    return await authApi
      .get(`poi-details/?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async poiDetailsCites(queryParams) {
    return await authApi
      .get(`poi-details/cities?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async poiDetailsCategory(queryParams) {
    return await authApi
      .get(`poi-details/categories?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

  static async poiDetailsALLCategory(queryParams) {
    return await authApi
        .get(`poi-details/load-categories?${queryParams}`)
        .then((response) => {
          // console.log(response);
          return response?.data;
        });
  }

  static async poiDetailsDownload(queryParams) {
    return await authApi
      .get(`poi-details/download?${queryParams}`)
      .then((response) => {
        // console.log(response);
        return response?.data;
      });
  }

}

export default PoiService;
