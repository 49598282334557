import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import CrumbSlider from '../../components/common/slider/crumb-slider/CrumbSlider';
import HomepageDetails from '../../components/common/details/home-page-details/HomepageDetails';
import CrumbService from '../../services/api/crumb.service';
import { setMapPoints } from '../../redux/slices/map';
import ClusterdMap from '../../components/common/map/ClusterdMap';
import { setMessage } from '../../redux/slices/message';
import { errorMessage } from '../../config/config';
import { setMessageSnackbarStateState } from '../../redux/slices/common';
import { setCheckedSystem, setCheckedUser, setPoiIdsList } from '../../redux/slices/audience';

//"https://python-allpings.herokuapp.com/companies"

const DeletedCrumbsPage = () => {

  const dispatch = useDispatch();

  const [crumbs, setCrumbs] = useState(null);

  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // console.log("selectedCountry", selectedCountry);
      dispatch(setMapPoints([]));
      dispatch(setPoiIdsList(''));
      dispatch(setCheckedUser([]));
      dispatch(setCheckedSystem([]));
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
          `status=Deactivated`
        ]
        CrumbService.getDeletedCrumb(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              const crumbsList = data.results.reverse();
              // console.log("getDeletedCrumb", crumbsList);
              setCrumbs(crumbsList);
            } else {
              dispatch(setMessage({
                type: "error",
                // title: data?.message ? data.message : "Audience restored successfully",
                title: "No Deleted Crumbs",
              }));
              dispatch(setMessageSnackbarStateState(true));
            }
            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        );
      }
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry, dispatch]);

  const activateCrumb = (id) => {
    const queryParams = [
      `status=Activated`
    ];

    CrumbService.activateCrumb(id, queryParams.join('&')).then(
      (data) => {
        // console.log("data", data);
        if (data) {
          if (data.results) {
            let crumbsDummy = [];
            crumbsDummy = crumbs.filter(c => c.id !== id);
            dispatch(setMessage({
              type: "success",
              title: "Crumb Activated successfully",
            }));
            dispatch(setMessageSnackbarStateState(true));
            setCrumbs(crumbsDummy);
          }

        } else {
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    );
  };


  return (<>
    <Box className='HomepageArea' sx={{ display: 'flex' }}>
      {/*<MenuSideBar />*/}
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        <ClusterdMap pointerState={true} />

        <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '65%', zIndex: 3 }}>
          <SearchAutoComplete />
        </Box>

        <Box className="CrumbSliderArea" sx={{ position: 'absolute', bottom: 40, left: '50%', transform: 'translateX(-50%)', width: '95%', zIndex: 2 }}>
          <CrumbSlider crumbList={crumbs} activateCrumb={activateCrumb} />
        </Box>
      </Box>
      <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
        <HomepageDetails />
      </Box>
    </Box>
  </>);
}

export default DeletedCrumbsPage;