import { api, authApi } from "../interceptor/auth.interceptor";
import { apiUrl } from "../../config/config";

class AuthService {

  static async login(formData) {
    return await api
      .post("all/auth/signin", formData)
      .then(response => {
        return response?.data;
      })
  }

  static async register(formData) {
    return await api
      .post("all/auth/signup", formData)
      .then(response => {
        return response?.data;
      })
  }

  static async logout() {
    return await authApi
      .post("all/auth/signout")
      .then((response) => {
        return response?.data;
      })
  }

  // static async resetPassword_api(formData) {
  //   await fetch(apiUrl + "all/auth/reset-password", {
  //     method: 'PUT',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(formData),
  //   }).then((response) => {
  //     response.json()
  //     // window.location.href = '/';
  //   });
  // }

  static async forgetPassword(formData) {
    return await api
      .post(apiUrl + "all/auth/forget-password?email=" + formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async getRefreshToken(formData) {
    return await api
      .post("all/auth/refreshtoken", formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async demoUserCall(queryParams) {
    return await authApi
      .get(`user/demo-users?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async approveUser(queryParams, formData) {
    return await authApi
      .put(`user/approve?${queryParams}`,formData)
      .then((response) => {
        return response?.data;
      });
  }

  static async updateDemoUser(queryParams){
    return await authApi
    .put(`user/update?${queryParams}`)
    .then((response) => {
      return response?.data;
    })
  }

}

export default AuthService;