// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AudienceStatisticsDoughnut_CrumbPieStat__Wiyr- .AudienceStatisticsDoughnut_CrumbPieStatInfoBox__AgwgO .AudienceStatisticsDoughnut_CrumbPieStatIconNumber__h5vXr .AudienceStatisticsDoughnut_CrumbPieStatInfoText__a6uXT {\n  color: #040404;\n}\n.AudienceStatisticsDoughnut_CrumbPieStat__Wiyr- .AudienceStatisticsDoughnut_CrumbPieStatInfoBox__AgwgO .AudienceStatisticsDoughnut_CrumbPieStatIconNumber__h5vXr .AudienceStatisticsDoughnut_CrumbPieStatInfoTextDark__39QDB {\n  color: #f9f7f7;\n}", "",{"version":3,"sources":["webpack://./src/components/common/statistics/audience-statistics-doughnut/AudienceStatisticsDoughnut.module.scss"],"names":[],"mappings":"AAKG;EACC,cAAA;AAJJ;AAMG;EACC,cAAA;AAJJ","sourcesContent":["@import \"../../../../scss/variables\";\n\n.CrumbPieStat {\n\t.CrumbPieStatInfoBox {\n\t\t.CrumbPieStatIconNumber {\n\t\t\t.CrumbPieStatInfoText {\n\t\t\t\tcolor: #040404;\n\t\t\t}\n\t\t\t.CrumbPieStatInfoTextDark{\n\t\t\t\tcolor: #f9f7f7;\n\t\t\t}\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CrumbPieStat": "AudienceStatisticsDoughnut_CrumbPieStat__Wiyr-",
	"CrumbPieStatInfoBox": "AudienceStatisticsDoughnut_CrumbPieStatInfoBox__AgwgO",
	"CrumbPieStatIconNumber": "AudienceStatisticsDoughnut_CrumbPieStatIconNumber__h5vXr",
	"CrumbPieStatInfoText": "AudienceStatisticsDoughnut_CrumbPieStatInfoText__a6uXT",
	"CrumbPieStatInfoTextDark": "AudienceStatisticsDoughnut_CrumbPieStatInfoTextDark__39QDB"
};
export default ___CSS_LOADER_EXPORT___;
