// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Audiences_SearchBoxBackButton__VkSf5:hover {\n  background: #fff !important;\n}", "",{"version":3,"sources":["webpack://./src/pages/audiences/Audiences.module.scss"],"names":[],"mappings":"AAGE;EACE,2BAAA;AAFJ","sourcesContent":["@import \"../../scss/variables\";\n\n.SearchBoxBackButton {\n  &:hover {\n    background: $white !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBoxBackButton": "Audiences_SearchBoxBackButton__VkSf5"
};
export default ___CSS_LOADER_EXPORT___;
