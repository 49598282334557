import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import { TextField } from '@mui/material';
import Link from '@mui/material/Link';
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";

import { connect, useDispatch, useSelector } from 'react-redux';
import { setEditProfileSideBarState, setChangePasswordState, setProfileSideBarState, setMessageSnackbarStateState } from '../../../../redux/slices/common';
import UserService from '../../../../services/api/user.service';
import { loadedLoggedIn, setUser } from '../../../../redux/slices/auth';

import UploadIconImage from '../../../../assets/images/icons/svg/gallery.svg';
import userIcon from '../../../../assets/images/icons/svg/user.svg';
import SMS from '../../../../assets/images/icons/svg/sms.svg';
import Officeicon from '../../../../assets/images/icons/svg/office.svg';
import { setMessage } from '../../../../redux/slices/message';
import { errorMessage } from '../../../../config/config';
import Image from 'mui-image';

function EditProfileSideBar({ user, setUser, loadedLoggedIn, currentTheme }) {

  const { editProfileSideBarState } = useSelector((state) => state.common);

  const dispatch = useDispatch();

  const { register, handleSubmit, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handleOpen = () => {
    dispatch(setEditProfileSideBarState(true));
  };

  const handleClose = () => {
    dispatch(setEditProfileSideBarState(false));
  };

  const handleChangePassword = (e) => {
    e.preventDefault();
    dispatch(setChangePasswordState(true));
  };

  const [preview, setPreview] = useState(null);
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // console.log("user", user);
      if (user?.path) {
        setPreview(user.path);
      }
    }

    return () => {
      mounted = false;
    }
  }, [user]);

  const handleFileChange = event => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return false;
    } else {
      // console.log(fileObj);
      const objectUrl = URL.createObjectURL(fileObj);
      setPreview(objectUrl);
      setImageFile(fileObj);
    }
  }

  const onSubmit = (values) => {
    // console.log("values", values);
    // console.log("imageFile", imageFile);
    // return false;
    const queryParams = [
      `email=${values.email}`,
      `name=${values.name}`,
      `companyName=${values.companyName}`,
      `path=${user?.path ? user.path : ''}`,
    ];
    const formData = new FormData();
    formData.append('file', imageFile);

    UserService.updateProfile(user.id, queryParams.join('&'), formData).then(
      (data) => {
        // console.log("data", data);
        if (data?.results) {
          dispatch(setMessage({
            type: "success",
            title: data?.message ? data.message : "User details updated successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));
          setUser(data.results);
          loadedLoggedIn({
            loaded: true,
            loggedIn: true
          });
          handleClose();
          dispatch(setProfileSideBarState(true));
        } else {
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    );
  };

  return (<>
    <SwipeableDrawer
      anchor={"right"}
      open={editProfileSideBarState}
      onClose={handleClose}
      onOpen={handleOpen}
      PaperProps={{
        sx: {
          width: "33%"
        }
      }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      {user &&
        <Box
          component='form'
          noValidate
          autoComplete='off'
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            my: 8,
            mx: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box className="UploadProfilePhoto" sx={{ position: 'relative', borderRadius: '50%', overflow: 'hidden', border: '3px solid #fff' }}>
            <Avatar className="UploadIconImageBg"
              sx={{ bgcolor: 'secondary.main', width: 125, height: 125, position: 'relative' }}
              src={preview}
            >
            </Avatar>
            <Box className="UploadIconImage" sx={{ position: 'absolute', width: '100%', height: '100%', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 2, borderRadius: '50%', overflow: 'hidden' }}>
              <Image src={UploadIconImage} alt="gallery logo" style={{ width: 40, height: 40, display: 'block', objectFit: 'contain' }} />
            </Box>
            <input
              style={{ zIndex: 3, display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
              type="file"
              onChange={handleFileChange}
            />
          </Box>

          <TextField
            fullWidth
            label="Name"
            focused
            sx={{ m: 3 }}
            type="text"
            variant="standard"
            defaultValue={user?.name ? user.name : ""}
            error={!!errors['name']}
            helperText={errors['name'] ? errors['name'].message : ''}
            {...register("name", {
              required: "Name is required"
            })}
            InputLabelProps={{ style: { color: 'grey', margin: 'dense' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Image src={userIcon} alt="user-name" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Email"
            name="email"
            focused
            sx={{ m: 3 }}
            type="email"
            variant="standard"
            defaultValue={user?.email ? user.email : ""}
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            {...register("email", {
              required: "Email is required"
            })}
            InputLabelProps={{ style: { color: 'grey' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Image src={SMS} alt="mail" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                </InputAdornment>
              ),
            }}
          />

          <TextField
            fullWidth
            label="Company"
            sx={{ m: 3 }}
            type="text"
            variant="standard"
            defaultValue={user?.companyName ? user.companyName : ""}
            error={!!errors['companyName']}
            helperText={errors['companyName'] ? errors['companyName'].message : ''}
            {...register("companyName")}
            InputLabelProps={{ style: { color: 'grey' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Image src={Officeicon} alt="office-icon" style={{ width: 20, height: 20, objectFit: 'contain' }} />
                </InputAdornment>
              ),
            }}
          />

          <Link component="button" underline="none" onClick={(e) => handleChangePassword(e)}>Change Password</Link>

          <Button variant='contained' type="submit" color='primary' fullWidth sx={{ textTransform: "none", marginTop: '50px' }}>Update Profile</Button>

        </Box>
      }
    </SwipeableDrawer>
  </>);
}

const mapStateToProps = (state) => ({
  user: state.auth.auth.user,
  currentTheme: state.theme.currentTheme
});

export default connect(mapStateToProps, { setUser, loadedLoggedIn })(EditProfileSideBar);