import React, { useRef, useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { mapboxApiToken } from "../../../config/config";
import { Box } from "@mui/material";
import { debounce } from "../../../utils/utils";
import { connect } from "react-redux";

mapboxgl.accessToken = `${mapboxApiToken}`;

const SimpleMap = ({ selectedCountry, currentTheme }) => {
  const mapContainerRef = useRef("map-container");
  
  useEffect(() => {
    let map, resizer;
    if (selectedCountry) {
      map = new mapboxgl.Map({
        container: mapContainerRef.current,
        // See style options here: https://docs.mapbox.com/api/maps/#styles
        style: `mapbox://styles/mapbox/${currentTheme === 'light' ? 'light-v10' : 'dark-v10'}`,
        center: selectedCountry.center,
        zoom: selectedCountry.zoom
      });

      resizer = new ResizeObserver(debounce(() => map.resize(), 1));
      resizer.observe(mapContainerRef.current);
    }

    // clean up on unmount
    return () => {
      resizer?.disconnect();
      map?.remove();
    }
  }, [currentTheme, selectedCountry]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Box className="map-container" ref={mapContainerRef}></Box>
  );
};

const mapStateToProps = (state) => ({
  currentTheme: state.theme.currentTheme
});

export default connect(mapStateToProps, null)(SimpleMap);
