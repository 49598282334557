import { authApi } from "../interceptor/auth.interceptor";

class connectorService {
  static async getAllConnectors() {
    return await authApi
      .get(`connector/all`)
      .then((response) => {
        return response?.data;
      });
  }


    static async getAllConnectorsByUser(queryParams) {
        return await authApi
            .get(`connector/v2/all?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async updateStatusConnector(queryParams, formData) {
        return await authApi
            .put(`connector/status?${queryParams}`, formData)
            .then((response) => {
                // console.log(response);
                return response?.data;
            });
    }
}

export default connectorService;
