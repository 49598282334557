import React, {useEffect, useState} from 'react';
import {Box} from '@mui/system';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {columnBarWithImageOptions} from '../../../config/highcharts.config';

const ColumnBarWithImage = ({ categories, data }) => {

  const [options, setOptions] = useState({
    ...columnBarWithImageOptions,
    title: null,
    xAxis: {
      ...columnBarWithImageOptions.xAxis,
      categories: categories,
    },
    series: data,
    tooltip: {
      useHTML: true,
      backgroundColor: 'rgba(255, 255, 255, 0.85)', // Set the tooltip background color
      formatter: function () {
        // Use the "formatter" function to customize the tooltip content
        // You can access the data point using "this.point"

        // Example: You can use an image URL for the point's image
        const imageUrl = this.point.marker.logo; // Assuming the point object has an "image" property
        const imageName = this.point.marker.logoName;
        // Build the tooltip content with the image and other data
        return `
        <div>
          <img src="${imageUrl}" alt="test" width="35" height="35">
          <div><strong>${this.x}</strong></div>
          <div>${this.y}</div>
        </div>
      `;
      },
    },
  });

  useEffect(() => {
    // console.log("data", data);
    let mounted = true;
    if (mounted) {
      setOptions({
        ...columnBarWithImageOptions,
        title: null,
        xAxis: {
          ...columnBarWithImageOptions.xAxis,
          categories: categories,
        },
        series: data,
        tooltip: {
          shadow: false,
          useHTML: true,
          borderWidth: 0,
          backgroundColor: 'transparent',
          // positioner: function (labelWidth, labelHeight, point) {
          //   const tooltipX = point.plotX - labelWidth / 2.3;
          //   const tooltipY = point.plotY - labelHeight + 1; // Adjust the vertical position as needed
          //   return { x: tooltipX, y: tooltipY };
          // },
          formatter: function () {
            // Access the data point using "this.point"
            const apps = this.point.options.apps;

            // Use map to generate tooltip content with images and names
            const imagesHtml = apps.map((app, index) => (
                `<div style="display: flex; align-items: center;">
                 <div style="width: 30px; height: 30px; overflow: hidden; border-radius: 50%; margin-right:1px">
                  <img src="${app.logo}" alt="test" width="50" height="50" style="object-fit: cover; width: 100%; height: 100%;" />
                </div>
                </div>`
            ));

            return `
      <div style="display: flex; flex-direction: row;margin-right:55px">
        ${imagesHtml.join('</br>')} <!-- Join the images and names HTML into a single string -->
       
      </div>
    `;
          },
        },


      });
    }

    return () => {
      mounted = false;
    }
  }, [data, categories]);

  // console.log("options", options);

  // const addImageMarker = (chart) => {
  //   // console.log("chart", chart.renderer);
  //   var pointIdx = 0;
  //   var point = chart.series[0].points[pointIdx];
  //   var rect = chart.renderer.rect(point.plotX + chart.plotLeft - 15, 110, point.plotY + chart.plotTop - 15, 100, 5)
  //     .attr({
  //       'zIndex': 10,
  //       'stroke-width': 2,
  //       stroke: 'red',
  //       fill: 'transparent',
  //     }).addClass('rect')
  //     .add();

  //   var img = rect.renderer.image('https://play-lh.googleusercontent.com/TLUeelx8wcpEzf3hoqeLxPs3ai1tdGtAZTIFkNqy3gbDp1NPpNFTOzSFJDvZ9narFS0', point.plotX + chart.plotLeft - 15, point.plotY + chart.plotTop - 15, 30, 30);
  //   img.attr({
  //     'zIndex': 10,
  //     'width': 40,
  //     'height': 40,
  //   }).add();


  //   // console.log("img", img);
  //   // console.log("rect", rect);
  // }

  return (<>
    {data?.length > 0 && options &&
      <Box className="ColumnBarWithImage" sx={{ position: 'relative', display: 'flex', width: '100%', height: '100%' }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
        />
      </Box>
    }
  </>)
}

export default ColumnBarWithImage;
