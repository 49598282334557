import React, { useEffect, useState, useMemo } from "react";
import PropTypes from 'prop-types';
import { useAutocomplete } from '@mui/base/AutocompleteUnstyled';
import { AutocompleteChangeReason } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import { autocompleteClasses } from '@mui/material/Autocomplete';
import { Box, Button } from "@mui/material";
import styles from './SearchAutoComplete.module.scss';
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { LocationAdd, SearchStatus } from "iconsax-react";
import { useSelector } from 'react-redux';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MopedIcon from '@mui/icons-material/Moped';

import { setUploadAudienceModalState } from "../../../redux/slices/common";
import AudienceService from "../../../services/api/audience.service";
import { setDefaultSearchKeywords, setAudienceIdsList, setEditFilter } from "../../../redux/slices/audience";
// import { debounce } from "../../../utils/utils";
import { debounce } from '@mui/material/utils';
import { getPermission } from "../../../utils/cryptograpgy";
import { useWindowWidth } from "../../../utils/browser";
import { setEnablePolygon } from '../../../redux/slices/common';
import { setMessage } from '../../../redux/slices/message';

const SearchBox = styled('div')(
  ({ theme }) => `
    color: ${theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.65)' : 'rgba(0,0,0,.85)'};
    font-size: 14px;
  `,
);

const InputWrapper = styled('div')(
  ({ theme }) => `
  border: 1px solid #d9d9d9 !important;
  background-color: #fff;
  box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.15) !important;
  border-radius: 100px;
  padding: 1px;
  display: flex;
  align-items: center;
  overflow: hidden;
  min-height: 50px;

  &:hover {
    border-color: #40a9ff;
  }

  &.focused {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
  }

  & input {
    background-color: #fff;
    color: rgba(0,0,0,.85);
    height: 35px;
    box-sizing: border-box;
    padding: 4px 6px;
    min-width: 60px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
`,
);

function Tag(props) {
  const { label, onDelete, ...other } = props;
  return (
    <div {...other}>
      <span>{label}</span>
      <CloseIcon onClick={onDelete} />
    </div>
  );
}

Tag.propTypes = {
  label: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

const StyledTag = styled(Tag)(
  ({ option }) => `
    display: flex;
    align-items: center;
    height: 32px;
    margin: 2px;
    font-size: 15px;
    line-height: 22px;
    color: #fff;
    background-color: ${option?.type === 'poi' ? 'orange' : '#3C4DE7'};
    border: 1px solid ${option?.type === 'poi' ? 'orange' : '#3C4DE7'};
    border-radius: 100px;
    box-sizing: content-box;
    padding: 0 4px 0 10px;
    outline: 0;
    overflow: hidden;

    &:focus {
      border-color: ${option?.type === 'poi' ? 'orange' : '#3C4DE7'};
      background-color: ${option?.type === 'poi' ? 'orange' : '#3C4DE7'};
    }

    & span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    & svg {
      font-size: 22px;
      cursor: pointer;
      padding: 4px;
    }
  `,
);

const Listbox = styled('ul')(
  ({ theme }) => `
  width: 100%;
  margin: 2px 0 0;
  padding: 0;
  position: absolute;
  list-style: none;
  background-color: ${theme.palette.mode === 'dark' ? '#141414' : '#fff'};
  overflow: hidden;
  overflow-y: auto;
  max-height: 250px;
  border-radius: 15px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & li {
    padding: 5px 12px;
    display: flex;

    & span {
      flex-grow: 1;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === 'dark' ? '#2b2b2b' : '#fafafa'};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === 'dark' ? '#003b57' : '#e6f7ff'};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`,
);

const BlueTooltip = styled(({ className, currentTheme, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme, currentTheme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: currentTheme === 'light' ? '#010412' : theme.palette.common.white,
    color: currentTheme === 'light' ? '#fff' : '#3C4DE7',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.tooltip} .MuiTooltip-arrow`]: {
    color: currentTheme === 'light' ? '#010412' : theme.palette.common.white,
  },
}));

const SearchAutoComplete = ({ hasUploadPoiButton = true, hasMobileSearchCloseButton = false, handleMobileSearchAreaClose }) => {

  const browserWidth = useWindowWidth();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const [searchParams] = useSearchParams();
  const location = useLocation();
  // console.log("location", location);

  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { defaultSearchKeywords, audienceIdsList } = useSelector((state) => state.audience);
  const { currentTheme } = useSelector((state) => state.theme);

  const uploadPoiPermission = getPermission('upload_poi');
  const searchPermission = getPermission('i_search');
  // const audienceIds = searchParams.get('audienceIds');

  const selectedSearchKeywords = localStorage.getItem("selectedSearchKeywords") ? JSON.parse(localStorage.getItem("selectedSearchKeywords")) : [];

  const [audiences, setAudiences] = useState([]);
  const [inputValue, setInputValue] = useState('');
  //const [enablePolygons, setEnablePolygons] = useState('');
  const [lastSearchResults, setLastSearchResults] = useState([]);

  // useEffect(() => {
  //   let mounted = true;
  //   if (mounted) {
  //     // console.log("selectedCountry", selectedCountry);
  //     if (user && selectedCountry) {
  //       const queryParams = [
  //         `userId=${user.id}`,
  //         `countryId=${selectedCountry.id}`,
  //       ];
  //       AudienceService.getAudience(queryParams.join('&')).then(
  //         (data) => {
  //           if (data?.results?.length) {
  //             const audienceList = data.results.reverse();
  //             // console.log("audienceFilteredList", audienceList);
  //             const audienceFilteredList = audienceList.filter(x => (x.isActive === "Activated"))
  //             // console.log("audienceFilteredList", audienceFilteredList);
  //             setAudiences(audienceFilteredList);
  //           }
  //           return Promise.resolve();
  //         },
  //         (error) => {
  //           return Promise.reject();
  //         }
  //       )
  //     }
  //   }

  //   return () => {
  //     mounted = false;
  //   }
  // }, [user, selectedCountry]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (location?.pathname.includes("/search") && audienceIdsList) {
        dispatch(setDefaultSearchKeywords(selectedSearchKeywords));
      } else {
        dispatch(setDefaultSearchKeywords([]));
        localStorage.removeItem("selectedSearchKeywords");
      }
    }

    return () => {
      mounted = false;
    }
  }, [location]);

  const searchResultFetch = useMemo(
    () =>
      debounce((searchText, callback) => {
        // console.log("searchText", searchText);
        if (user && selectedCountry && searchText && searchText.length >= 3) {
          const queryParams = [
            `userId=${user.id}`,
            `countryId=${selectedCountry.id}`,
            `searchText=${searchText}`,
          ];
          AudienceService.globalSearch(queryParams.join('&')).then(
            (data) => {
              // if (data?.results > 0) {
              // console.log("data", data);
              callback(data?.results);
            }
            //  else {
            //   dispatch(setMessage({
            //     type: "warning",
            //     title: "please upload Poi " + searchText,
            //   }));
            //   dispatch(setMessageSnackbarStateState(true));
            // }
            // }
          );
        } else if (searchText.length < 3) {
          setLastSearchResults([]);

        }
      }, 400),
    [user, selectedCountry],
  );

  useEffect(() => {
    let active = true;

    if (inputValue === '') {
      if (lastSearchResults && lastSearchResults.length) {
        setAudiences(lastSearchResults);
      } else if (value && value.length) {
        setAudiences(value);
      } else {
        setAudiences([]);
      }
      return undefined;
    }

    searchResultFetch(inputValue, (results) => {
      if (active) {
        if (results && results.length) {
          // console.log("value", value);
          const newResult = [];
          results.forEach(item => {
            const carId = value && value.length && value.find(np => np.id === item.id && item.typeName === "card");
            const poiId = value && value.length && value.find(np => np.id === item.id && item.typeName === "poi");
            if (!poiId || !carId) {
              newResult.push(item);
            }
          });
          const newOptions = [...value, ...newResult];
          // console.log("newOptions", newOptions);
          setLastSearchResults(newOptions);
          setAudiences(newOptions);
        } else {
          setLastSearchResults([]);
          setAudiences(value);
        }
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, searchResultFetch]);

  const tempList = [];

  const {
    getRootProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl
  } = useAutocomplete({
    id: 'customized-hook-demo',
    value: defaultSearchKeywords,
    multiple: true,
    freeSolo: true,
    disableCloseOnSelect: true,
    options: audiences,
    getOptionLabel: (option) => option.name,
    filterOptions: (options, state) => options,
    onChange: (event, newValue, reason,) => {

      newValue.forEach((value) => {
        tempList.push(value);
      });
      // console.log(tempList)
      if (reason !== "createOption")
        dispatch(setDefaultSearchKeywords(newValue));
    },
    onInputChange: (event, newInputValue) => {
      console.log("event", event.target.value);
      // console.log("newInputValue", newInputValue);
      //event.preventDefault();
      setInputValue(newInputValue);

    },
    onClose: () => {
      if (!(value && value.length)) {
        setLastSearchResults([]);
        setAudiences([]);
      }
    }
  });

  const handleKeyDown = (e) => {
    // console.log("values", event);
    if (e.key === 'Enter') {
      e.preventDefault();
      if (lastSearchResults.length > 0) {
        //console.log(getOptionProps);
        handleSearchSubmit();
      }
    }
  };

  const handleSearchSubmit = () => {
    console.log(value)
    if (value?.length) {
      localStorage.setItem("selectedSearchKeywords", JSON.stringify(value));
      dispatch(setDefaultSearchKeywords(value));
      let audienceIds = [];
      let brandIds = [];
      let poiIds = [];
      for (let index = 0; index < value.length; index++) {
        const item = value[index];
        if (item && item.type === "poi") {
          brandIds.push(item.id);
        } else if (item && item.type === "card") {
          audienceIds.push(item.id);
        } else if (item && item.type === "user_poi") {
          poiIds.push(item.id);
        }
      }
      const audienceList = audienceIds.join(',');
      const brandList = brandIds.join(',');
      const poiList = poiIds.join(',');

      // console.log("audienceList", audienceList);
      // console.log("poiList", poiList);

      const mergeList = {
        brandList: brandList,
        audienceList: audienceList,
        poiList: poiList
      };
      // console.log("poiList", poiList, "audienceList", audienceList)
      if (brandList || audienceList || poiList) {
        dispatch(setAudienceIdsList(mergeList));
      }

      // console.log("joinIds", joinIds);
      // navigate(`/search?audienceIds=${joinIds}`);
      navigate(`/search`);
    } else {
      console.log("error")
    }
  };

  const handleLocationButton = () => {
    if (uploadPoiPermission) {
      dispatch(setUploadAudienceModalState(true));
    }
  }


  // const handlePolygonButton = () => {
  //
  //   const newValue = !enablePolygons;
  //   setEnablePolygons(newValue); // Update the local state
  //   dispatch(setEnablePolygon(newValue)); // Dispatch the updated value to Redux
  // };


  return (<>
    <SearchBox>
      <Box {...getRootProps()} sx={{ display: 'flex', alignItems: 'center' }}>

        {/*{(location?.pathname.includes("/search") || location?.pathname.includes("/audience/")) && uploadPoiPermission && hasUploadPoiButton &&*/}
        {/*    <BlueTooltip title={"Upload"} arrow placement="right" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>*/}
        {/*      <Button className={`${styles.SearchBoxPoiUploadButton}`} disableRipple sx={{ mr: 2, p: 2, ml: 2, width: 50, height: 50, minWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} onClick={() => handlePolygonButton()}>*/}
        {/*        <MopedIcon sx={{ width: 25, height: 25 }} />*/}
        {/*      </Button>*/}
        {/*    </BlueTooltip>*/}
        {/*}*/}

        {searchPermission &&
          <Box sx={{ position: 'relative', width: browserWidth > 500 ? 'calc(100% - 40px)' : 'calc(100% - 70px)' }}>
            <InputWrapper ref={setAnchorEl} className={focused ? 'focused' : ''}>
              <Box sx={{ px: 2, display: 'flex', alignItems: 'center' }}>
                <SearchStatus className={`ImageIcon`} />
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', flex: '1' }}>
                {value.map((option, index) => {
                  if (index < 3 && option.name) {
                    return (
                      <StyledTag label={option.name} option={option} {...getTagProps({ index })} />
                    )
                  }
                  else {
                    return null;
                  }
                })}
                <input
                  {...getInputProps()}
                  onKeyDown={(e) => handleKeyDown(e)}
                  placeholder="Search"
                  style={{ maxWidth: value?.length > 1 ? '100px' : browserWidth > 500 ? 'initial' : '80px' }}
                />
                {value?.length > 3 &&
                  <BlueTooltip
                    title={
                      <>
                        {value.map((option, index) => {
                          if (index >= 3) {
                            return (
                              <StyledTag label={option.name} {...getTagProps({ index })} />
                            )
                          }
                          else {
                            return null;
                          }
                        })}
                      </>
                    }
                    arrow
                    placement="bottom"
                    className={`${styles.ListItemTooltip ?? ''}`}
                    sx={{ m: 0 }}
                    currentTheme={currentTheme}
                  >
                    <Button className={`${styles.SearchMoreListButton}`} disableRipple sx={{ p: 0, ml: 2, width: 30, height: 30, minWidth: 30, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                      {/* <More size="32" /> */}
                      <Box className="dot-typing" />
                    </Button>
                  </BlueTooltip>
                }
              </Box>
              <Button className={`${styles.SearchBoxSubmitButton}`} disableRipple sx={{ p: 1, ml: 2, mr: 0.5, width: 40, height: 40, minWidth: 40, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%', fontSize: 13 }} onClick={() => handleSearchSubmit()}>Go</Button>
            </InputWrapper>

            {groupedOptions.length > 0 && (
              <Listbox {...getListboxProps()}>
                {groupedOptions.map((option, index) => (
                  <li {...getOptionProps({ option, index })} key={index}>
                    <span className="text">{option.name}</span>
                    {(option.type === 'poi' || option.type === 'user_poi' ) &&
                      <LocationOnIcon fontSize="small" style={{ color: '#999' }} />
                    }
                    {option.type === 'card' &&
                      <CreditCardIcon fontSize="small" style={{ color: '#999' }} />
                    }
                  </li>
                ))}
              </Listbox>
            )}
          </Box>
        }

        {!(location?.pathname.includes("/search") || location?.pathname.includes("/audience/")) && uploadPoiPermission && hasUploadPoiButton &&
          <BlueTooltip title={"Upload"} arrow placement="right" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }} currentTheme={currentTheme}>
            <Button className={`${styles.SearchBoxPoiUploadButton}`} disableRipple sx={{ p: 1, ml: 2, width: 50, height: 50, minWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} onClick={() => handleLocationButton()}>
              <LocationAdd sx={{ width: 25, height: 25 }} />
            </Button>
          </BlueTooltip>
        }

        {hasMobileSearchCloseButton &&
          <Button className={`${styles.SearchBoxPoiUploadButton}`} disableRipple sx={{ p: 1, ml: 2, width: 50, height: 50, minWidth: 50, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} onClick={() => handleMobileSearchAreaClose()}>
            <CloseIcon sx={{ width: 25, height: 25 }} />
          </Button>
        }

      </Box>
    </SearchBox>
  </>);
}

export default SearchAutoComplete;
