import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import AudiencePageDetails from '../../components/common/details/audience-page-details/AudiencePageDetails';
import WestIcon from '@mui/icons-material/West';
import styles from './AudienceDetails.module.scss';
import Image from 'mui-image';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setMapPoints, setPolygonData } from '../../redux/slices/map';
import ClusterdMap from '../../components/common/map/ClusterdMap';
import { useLocation } from 'react-router-dom';
import { setMobileAudienceDetailsState } from '../../redux/slices/common';

//"https://python-allpings.herokuapp.com/companies"

const AudienceDetailsPage = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const backButtonUrl = localStorage.getItem('back-button-url');
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedAudience, setSelectedAudience] = useState(null);

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      dispatch(setMapPoints([]));
      dispatch(setPolygonData(''));
      const selectedAudienceFromLocalStorage = localStorage.getItem("selectedAudience");
      if (selectedAudienceFromLocalStorage) {
        setSelectedAudience(JSON.parse(selectedAudienceFromLocalStorage));
      }
    }

    return () => {
      mounted = false;
    }
  }, []);


  const handleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
    dispatch(setMobileAudienceDetailsState(false));
  }

  const handleBackButton = (selectedAudience) => {
    if (backButtonUrl === '/favourite-cards') {
      navigate('/favourite-cards');
    } else if (backButtonUrl === '/approve') {
      navigate('/approve');
    } else {
      navigate('/crumb/' + selectedAudience.crumbDetails.id + '/audiences');
    }
  }

  return (<>
    <Box className={`CrumbDetailsPageArea ${isCollapsed ? '' : 'active'}`} sx={{ display: 'flex' }}>
      {/*<MenuSideBar />*/}
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        <ClusterdMap pointerState={false} />

        <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '50%', zIndex: 3 }}>
          <Box className={`SearchBoxWrapper`}>
            {selectedAudience &&
              <Button className={`${styles.SearchBoxBackButton}`} disableRipple sx={{ position: 'absolute', top: 0, right: 'calc(100% + 10px)', p: 0.7, pl: 2, height: 55, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px', background: '#fff', textTransform: 'none' }} onClick={() => handleBackButton(selectedAudience)}>
                <Box className={`SearchBoxBackButtonIcon`} sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                  <WestIcon sx={{ width: 18, height: 18 }} />
                </Box>
                <Box className={`SearchBoxBackButtonText`} sx={{ display: 'inline-block', alignItems: 'center', mr: 1, maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                  {backButtonUrl === '/favourite-cards' ? 'Favorite Cards' : (selectedAudience.audienceDetails.name.length > 5 ? selectedAudience.audienceDetails.name.substring(0, 4) + '..' : selectedAudience.audienceDetails.name)}
                </Box>
                {!(backButtonUrl === '/favourite-cards') &&
                  <Box className={`SearchBoxBackButtonImage`} sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', borderRadius: '100px', width: 70, height: 45 }}>
                    <Image src={selectedAudience.audienceDetails.imagePath} alt="" />
                  </Box>
                }
              </Button>
            }
            <SearchAutoComplete />
          </Box>
        </Box>

      </Box>
      <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
        <AudiencePageDetails
          isCollapsed={isCollapsed}
          handleCollapsed={handleCollapsed}
        />
      </Box>
    </Box>
  </>);
}

export default AudienceDetailsPage;