// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SelectedCountryModal_CountrySliderWrapper__\\+8ODL {\n  width: 1150px !important;\n  margin: 0 auto !important;\n}\n.SelectedCountryModal_CountrySliderWrapper__\\+8ODL .SelectedCountryModal_CountrySlider__cDW8o {\n  padding: 60px 45px 60px 45px;\n}\n\n/* Laptop with MDPI: 1280px */\n@media only screen and (min-width: 1201px) and (max-width: 1300px) {\n  .SelectedCountryModal_CountrySliderWrapper__\\+8ODL {\n    width: 1100px !important;\n  }\n  .SelectedCountryModal_CountrySliderWrapper__\\+8ODL .SelectedCountryModal_CountrySlider__cDW8o {\n    padding: 50px 45px 50px 45px;\n  }\n}\n/* Medium Layout(Vertical): 1080px. */\n@media only screen and (max-width: 1200px) {\n  .SelectedCountryModal_CountrySliderWrapper__\\+8ODL {\n    width: 100% !important;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/common/modal/selected-country-modal/SelectedCountryModal.module.scss"],"names":[],"mappings":"AAEA;EACC,wBAAA;EACA,yBAAA;AADD;AAEC;EACG,4BAAA;AAAJ;;AAIA,6BAAA;AACA;EACC;IACC,wBAAA;EADA;EAEA;IACC,4BAAA;EAAD;AACF;AAGA,qCAAA;AACA;EACC;IACC,sBAAA;EADA;AACF","sourcesContent":["@import \"../../../../scss/variables\";\n\n.CountrySliderWrapper {\n\twidth: 1150px !important;\n\tmargin: 0 auto !important;\n\t.CountrySlider {\n    padding: 60px 45px 60px 45px;\n\t}\n}\n\n/* Laptop with MDPI: 1280px */\n@media only screen and (min-width: 1201px) and (max-width: 1300px) {\n\t.CountrySliderWrapper {\n\t\twidth: 1100px !important;\n\t\t.CountrySlider {\n\t\t\tpadding: 50px 45px 50px 45px;\n\t\t}\n\t}\n}\n/* Medium Layout(Vertical): 1080px. */\n@media only screen and (max-width: 1200px) {\n\t.CountrySliderWrapper {\n\t\twidth: 100% !important;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CountrySliderWrapper": "SelectedCountryModal_CountrySliderWrapper__+8ODL",
	"CountrySlider": "SelectedCountryModal_CountrySlider__cDW8o"
};
export default ___CSS_LOADER_EXPORT___;
