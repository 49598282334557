import axios from 'axios';
import { apiUrl, accessToken, refreshToken, accessTokenTimeout, refreshTokenTimeout } from '../../config/config';
import { deleteCookie, getCookie, setCookie } from '../../utils/cookie';

const api = axios.create({
  baseURL: apiUrl,
});

const authApi = axios.create({
  baseURL: apiUrl,
});

authApi.interceptors.request.use(
  async (config) => {
    let accessTokenNew = getCookie(accessToken);

    // if (!accessTokenNew) {
    //   const refreshTokenNew = getCookie(refreshToken);
    //   if (refreshTokenNew) {
    //     const formData = {
    //       refreshToken: refreshTokenNew
    //     };
    //     await axios.post(apiUrl + "all/auth/refreshtoken", formData)
    //       .then((response) => {
    //         // console.log('signIn interceptors', response.data);
    //         if (response?.data?.accessToken) {
    //           setCookie(accessToken, response.data.accessToken, accessTokenTimeout);
    //           setCookie(refreshToken, response.data.refreshToken, refreshTokenTimeout);
    //           accessTokenNew = response.data.accessToken;
    //         } else {
    //           deleteCookie(accessToken);
    //           deleteCookie(refreshToken);
    //           window.location = "/";
    //         }
    //       })
    //       .catch((error) => {
    //         deleteCookie(accessToken);
    //         deleteCookie(refreshToken);
    //         window.location = "/";
    //       });
    //   } else {
    //     deleteCookie(accessToken);
    //     deleteCookie(refreshToken);
    //     window.location = "/";
    //   }
    // }

    config.headers.Authorization = `Bearer ${accessTokenNew}`;
    return config;
  },
  (err) => {
    console.error(err);
    return Promise.reject(err);
  },
);

export { api, authApi };
