import { authApi } from "../interceptor/auth.interceptor";

class MetaService {


    static async meta(queryParams) {
        return await authApi
            .post(`meta/code?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async metaAddAccount(queryParams) {
        return await authApi
            .get(`meta/add-account-details?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async metaBusinessAccounts(queryParams) {
        return await authApi
            .get(`meta/business-details?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }


    static async changeAddAccount(queryParams) {
        return await authApi
            .put(`meta/update-add-account?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async changeBusinessAccount(queryParams) {
        return await authApi
            .put(`meta/update-business-account?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }
}

export default MetaService;