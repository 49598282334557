import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { LoadingButton } from '@mui/lab';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { Flash } from 'iconsax-react';
import AudienceService from '../../../../services/api/audience.service';
import { setMessage } from '../../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { ReactComponent as MetaImage } from "../../../../assets/images/icons/svg/meta.svg";
import { useWindowWidth } from '../../../../utils/browser';

function ActivateCompareAudienceConfirmModal({ selectedPartnerId, selectedCompareId, activateCompareAudienceCondirmModalstate, setActivateCompareAudienceConfirmModalState, activateType }) {

  const { user } = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const browserWidth = useWindowWidth();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setActivateCompareAudienceConfirmModalState(false);
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const onSubmit = () => {
    const queryParams = [
      `compareId=${selectedCompareId}`,
      `partnerId=${selectedPartnerId}`,
      `userId=${user.id}`,
      `type=${activateType}`
    ]
    setIsSubmitting(true);
    AudienceService.activateCompare(queryParams.join('&')).
      then(
        (data) => {
          console.log(data)
          setIsSubmitting(false);
          dispatch(setMessage({
            type: "success",
            title: data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
          setActivateCompareAudienceConfirmModalState(false);
        },
        (error) => {
          console.log(error);
          setIsSubmitting(false);
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
          setActivateCompareAudienceConfirmModalState(false);
        }
      )
  };

  return (<>
    <Dialog
      open={activateCompareAudienceCondirmModalstate}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            {selectedPartnerId === '2' ?
              <MetaImage style={{ color: '#3C4DE7', width: '100px', height: '100px' }} size='100' />
              :
              <Flash style={{ color: '#3C4DE7' }} size='100' />
            }
          </Box>
          <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
            {"Confirm"}
          </DialogTitle>
          <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
            Are you sure you want to activate this card on{' '}
            {selectedPartnerId === '2' ? (
              <span style={{ color: '#3C4DE7' }}>Meta</span>
            ) : (
              <span style={{ color: '#3C4DE7' }}>Excelate</span>
            )}
            {' '}?
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Activate
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default ActivateCompareAudienceConfirmModal;