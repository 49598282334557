import React from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
// import Logo from '../../../../assets/images/Vector.png';
import { Box, DialogContentText } from '@mui/material';
// import Image from 'mui-image';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TickCircle } from 'iconsax-react';
import { useNavigate, useParams } from "react-router-dom";


function DeleteAudienceSuccessModal({ audienceId, deleteAudienceSuccessModalState, setDeleteAudienceSuccessModalState, redirectToAudiences, updateAudiences }) {

  const navigate = useNavigate();
  const selectedAudience = JSON.parse(localStorage.getItem('selectedAudience'));


  const handleClose = () => {
    setDeleteAudienceSuccessModalState(false);
    if (redirectToAudiences) {
      redirectToAudiences(audienceId);
    }
    if (updateAudiences) {
      updateAudiences(audienceId);
    }
  };

  return (<>
    <Dialog
      open={deleteAudienceSuccessModalState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "35%", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent>
        <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
          {"Deleted!"}
        </DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ height: '100%' }}>
            {/* < CheckCircleOutlineIcon style={{ color: 'green', fontSize: 48 }} /> */}
            <TickCircle size="32" color="green" />
          </Box>
        </Box>
        <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
          Card has been deleted
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "0px" }}>
        <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
          <Button variant='contained' onClick={handleClose} sx={{ textTransform: "none", borderRadius: "0px" }}>Ok</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog >
  </>);
}

export default DeleteAudienceSuccessModal;