import React, { useState } from "react";
import { baseUrl } from "../../../config/config";
import { debounce } from "../../../utils/utils";
import PoiService from "../../../services/api/poi.service";
import { useSelector } from "react-redux";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WhereToVoteIcon from '@mui/icons-material/WhereToVote';
import LocationOffIcon from '@mui/icons-material/LocationOff';
import { setMapPoints } from "../../../redux/slices/map";
import { useDispatch } from "react-redux";
import { setEstimateAudiences, setTotalPlaces } from "../../../redux/slices/audience";
// import LocationOnIcon from '@mui/icons-material/LocationOn';

const ImageMarker = ({ cluster, latitude, longitude, setPopupInfo, mapPoints, pointerState }) => {
  console.log("pointerState", pointerState)
  const { selectedCountry } = useSelector((state) => state.auth);
  const { totalPlaces } = useSelector((state) => state.audience);
  const [isGifVisible, setIsGifVisible] = useState(true);
  const dispatch = useDispatch();

  const handleMouseEnter = () => {
    const info = {
      latitude,
      longitude,
      info: cluster.properties
    }
    // console.log("info", info.info.colorValue);
    setPopupInfo(info);
    // commented on estimated poi api

    // if (selectedCountry) {
    //   const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));
    //   const queryParams = [
    //     `userId=0`,
    //     `countryId=${selectedCountry.id}`,
    //     `latitude=${latitude}`,
    //     `longitude=${longitude}`,
    //     `address=`,
    //     `visitPeriod=${applyFilterParams ? applyFilterParams.visitPeriod : ''}`,
    //     `geoRadius=${applyFilterParams ? applyFilterParams.geoRadius : ''}`,
    //     `weekParting=${applyFilterParams ? applyFilterParams.weekParting : ''}`,
    //     `dayParting=${applyFilterParams ? applyFilterParams.dayParting : ''}`,
    //     `totalCount=${applyFilterParams ? applyFilterParams.audiences : ''}`,
    //     `audienceId=${applyFilterParams ? applyFilterParams.audienceId : ''}`,
    //   ];
    //   // console.log("queryParams", queryParams);
    //   PoiService.poiEstimatedCount(queryParams.join('&')).then(
    //     (data) => {
    //       if (data?.results) {
    //         let infoResult = { ...info };
    //         infoResult.info = {
    //           ...infoResult.info,
    //           estimatedCount: data.results.estimatedPercentage.toLocaleString()
    //         };
    //         setPopupInfo(infoResult);
    //       }
    //       return Promise.resolve();
    //     },
    //     (error) => {
    //       // console.log(error?.response?.data?.message);
    //       return Promise.reject();
    //     }
    //   );
    // }
  }

  const handleMouseLeave = () => {
    setPopupInfo(null);
  }

  // const handleButtonClick = (viewState) => {
  //   console.log(viewState)
  //   setIsGifVisible(!isGifVisible);
  // };

  const handleButtonClick = (id, pointerState) => {
    if (!mapPoints) {
      return; // Handle the case where mapPoints is not defined yet
    }
    // Find the index of the point with matching id
    const pointIndex = mapPoints.findIndex(point => point.properties.id === id);
    if (pointerState) {
      if (pointIndex !== -1) {

        // Create a copy of mapPoints array to avoid mutating state directly
        const updatedMapPoints = mapPoints.map(point => {
          if (point.properties.id === id) {
            if (point.properties.isEnabled) {
              dispatch(setTotalPlaces(totalPlaces + 1));
              return {
                ...point,
                properties: {
                  ...point.properties,
                  isEnabled: false
                }
              };
            }
            else {
              dispatch(setTotalPlaces(totalPlaces - 1));
              return {
                ...point,
                properties: {
                  ...point.properties,
                  isEnabled: true
                }
              };
            }

          }
          return point;
        });

        // Dispatch an action to update the mapPoints in Redux store
        dispatch(setMapPoints(updatedMapPoints));
      }

    }



    setIsGifVisible(!isGifVisible);
  };

  return (
    <button
      type="button"
      className='geomarker-gif'
      // onMouseEnter={debounce(() => handleMouseEnter(), 300)}
      // onMouseLeave={debounce(() => handleMouseLeave(), 300)}
      onClick={() => handleButtonClick(cluster.properties.id, pointerState)}
    >
      {cluster.properties.isEnabled === true ? (
        <LocationOffIcon style={{ color: "grey", fontSize: 38 }} />
      ) : (
        <span
          onMouseEnter={debounce(() => handleMouseEnter(), 300)}
          onMouseLeave={debounce(() => handleMouseLeave(), 300)}
        >
          <LocationOnIcon style={{ color: cluster.properties.colorValue, fontSize: 38 }} />
        </span>
      )}
    </button>
  );
};

export default ImageMarker;
