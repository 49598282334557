
export function getRoundedImageBase64FromUrl(url, callback) {
  const image = new Image();
  const canvas = document.querySelector('.canvas');
  image.onload = () => {
    // use min size so we get a square
    const size = Math.min(image.naturalWidth, image.naturalHeight);

    // let's update the canvas size
    canvas.width = size;
    canvas.height = size;

    // draw image to canvas
    const ctx = canvas.getContext('2d');
    ctx.drawImage(image, 0, 0);

    // Add behind elements.
    ctx.globalCompositeOperation = 'destination-over';
    ctx.fillStyle = '#fff';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // only draw image where mask is
    ctx.globalCompositeOperation = 'destination-in';

    // draw our circle mask
    ctx.beginPath();
    ctx.arc(
      size * 0.5, // x
      size * 0.5, // y
      size * 0.5, // radius
      0, // start angle
      2 * Math.PI // end angle
    );
    ctx.fill();

    // restore to default composite operation (is draw over current image)
    ctx.globalCompositeOperation = 'source-over';

    const pngUrl = canvas.toDataURL("image/png");
    // console.log("url", url);
    // console.log("pngUrl", pngUrl);
    callback(pngUrl);
  };
  image.crossOrigin = "anonymous";
  image.src = url;
}

