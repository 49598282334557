import { Box, Button, Grid } from "@mui/material";
import LargeDoughnutWithText from "../../chart/LargeDoughnutWithText";
import styles from './AudienceStatisticsDoughnut.module.scss';
import { useState } from "react";
import { useWindowWidth } from "../../../../utils/browser";
import { useSelector } from "react-redux";

const AudienceStatisticsDoughnut = ({ stat, bottomLine = true, isPdfGenerate }) => {
  const shouldDisplayTitle = stat.title !== 'Languages';
  const { currentTheme } = useSelector((state) => state.theme);

  const crumbPieStatInfoTextClass = isPdfGenerate ? styles.CrumbPieStatInfoTextDark : currentTheme === 'dark' ? styles.CrumbPieStatInfoTextDark : styles.CrumbPieStatInfoText;
  const browserWidth = useWindowWidth();
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleMoreLessButton = () => {
    setIsCollapsed(!isCollapsed);
  }

  const generateStatInfo = (count, title) => {
    if (count && (title === 'Brand Affinity' || title === 'Brand Categories' || title === 'Card Categories')) {
      const textColor = currentTheme === 'light' ? '#313131' : '#E4E4E4';

      return (
        <Grid item xs={4} spacing={8} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box className={`${styles.CrumbPieStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '600', fontSize: 15, fontFamily: 'Arial, sans-serif', lineHeight: '24px', color: '#ffffff', padding: '8px', marginTop: '16px' }}>
            {/* <span style={{ fontSize: '18px', marginRight: '4px', color: textColor }}>+</span> */}
            <Box className={`${styles.CrumbPieStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '500', fontSize: 20, lineHeight: '24px', color: '#6f7288', marginLeft: '32px', marginRight: '4px' }}>+</Box>
            {/* <span style={{ fontSize: '20px', color: textColor }}>{count}</span> */}
            <Box className={`${styles.CrumbPieStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '500', fontSize: 20, lineHeight: '24px', color: '#6f7288' }}>{count}</Box>
            {/* <span style={{ fontSize: '15px', marginLeft: '4px', color: textColor }}>OTHERS</span> */}
            <Box className={`${styles.CrumbPieStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '500', fontSize: 20, lineHeight: '24px', color: '#6f7288', marginLeft: '4px' }}>OTHERS</Box>
          </Box>
        </Grid>
      );
    }
    return null;
  };

  return (<>
    {stat &&
      <Box className={`${styles.CrumbDetailsStatArea ?? ''}`} sx={{ mb: bottomLine ? 3 : 0, pb: bottomLine ? 3 : 0, borderBottom: bottomLine ? '1px solid #6F7288' : 'none', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box className="StatInfoArea" sx={{ mb: 5, mt: 2 }}>
          <Grid
            container
            spacing={3.5}
            alignItems={stat?.data?.length > 4 || browserWidth < 610 ? "start" : "center"}
            direction={browserWidth > 610 ? "row" : "column"}
          >
            <Grid item xs={browserWidth > 610 ? 4 : 6}>
              <Box className="StatChartWrapper" sx={{ py: stat?.data?.length > 4 ? 3 : 0, width: '100%', display: 'flex' }}>
                <Box className="StatChart StanChartWithHeight" sx={{ display: 'flex', width: '100%' }}>
                  <LargeDoughnutWithText
                    title={stat.title}
                    data={stat.data}
                    isPdfGenerate={isPdfGenerate}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={browserWidth > 610 ? 8 : 6}>
              <Box className={`${styles.CrumbPieStat ?? ''}`} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Grid container spacing={3}>
                  {stat?.data?.length > 0 && stat.data.map((data, dindex) => {
                    if (dindex < 4) {
                      return (
                        <Grid item xs={6} key={dindex}>
                          <Box className={`${styles.CrumbPieStatInfoBox ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', my: 1 }}>
                            <Box className={`${styles.CrumbPieStatIconNumber ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                              <Box className={`${styles.CrumbPieStatInfoIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 12, height: 12, mr: 1, borderRadius: '50%', backgroundColor: data.color }}></Box>
                              <Box className={`${crumbPieStatInfoTextClass ?? ''}`} sx={{ display: 'flex', fontSize: 14, lineHeight: '15px', fontWeight: '500', width: 'calc(100% - 30px)', color: '#6f7288' }}>{data.title}</Box>
                            </Box>
                            {shouldDisplayTitle && (
                              <Box className={`${styles.CrumbPieStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '500', fontSize: 20, lineHeight: '24px', color: '#6f7288' }}>{data.value}</Box>
                            )}
                          </Box>
                        </Grid>
                      )
                    } else {
                      return null;
                    }
                  })}
                </Grid>

                {(!isCollapsed || isPdfGenerate) && (
                  <Grid container spacing={3} sx={{ mt: 1, flexDirection: 'row' }}>
                    {stat?.data?.length > 4 && stat.data.map((data, dindex) => {
                      if (dindex >= 4) {
                        return (
                          <Grid item xs={6} key={dindex}>
                            <Box className={`${styles.CrumbPieStatInfoBox ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', my: 1 }}>
                              <Box className={`${styles.CrumbPieStatIconNumber ?? ''}`} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                                <Box className={`${styles.CrumbPieStatInfoIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 12, height: 12, mr: 1, borderRadius: '50%', backgroundColor: data.color }}></Box>
                                <Box className={`${crumbPieStatInfoTextClass ?? ''}`} sx={{ display: 'flex', fontSize: 14, lineHeight: '15px', fontWeight: '500', width: 'calc(100% - 30px)' }}>{data.title}</Box>
                              </Box>
                              {shouldDisplayTitle && (
                                <Box className={`${styles.CrumbPieStatInfoNumber ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontWeight: '500', fontSize: 20, lineHeight: '24px', color: '#6f7288' }}>{data.value}</Box>
                              )}
                            </Box>
                          </Grid>
                        );
                      } else {
                        return null;
                      }
                    })}

                    {stat.title === 'Brand Affinity' && generateStatInfo(stat.data.brandAffinityOtherCount, stat.title)}
                    {stat.title === 'Brand Categories' && generateStatInfo(stat.data.brandCategoriesOtherCount, stat.title)}
                    {stat.title === 'Card Categories' && generateStatInfo(stat.data.cardCategoryOtherCount, stat.title)}

                  </Grid>
                )}
                {stat?.data?.length > 4 && !isPdfGenerate &&
                  <Button sx={{ display: 'flex', mt: 2, color: '#2553FF', fontSize: 12, lineHeight: '15px', textTransform: 'capitalize', textAlign: 'left', justifyContent: 'flex-start', p: 0 }} variant="raised" disableRipple disableElevation onClick={() => handleMoreLessButton()}>
                    {isCollapsed ?
                      <Box>More</Box>
                      :
                      <Box>Less</Box>
                    }
                    <Box sx={{ ml: .6 }}>{stat.title}</Box>
                  </Button>
                }
              </Box>
            </Grid>
          </Grid >
        </Box >
      </Box >
    }
  </>);
};

export default AudienceStatisticsDoughnut;