import { createSlice } from '@reduxjs/toolkit';

export const crumbSlice = createSlice({
  name: 'crumb',
  initialState: {
    crumbs: []
  },
  reducers: {
    setCrumbs: (state, action) => {
      state.crumbs = action.payload;
    }
  },
});

export const { setCrumbs } = crumbSlice.actions;

export default crumbSlice.reducer;
