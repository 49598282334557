import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './slices/common';
import messageReducer from './slices/message';
import authReducer from './slices/auth';
import crumbReducer from './slices/crumb';
import audienceReducer from './slices/audience';
import mapReducer from './slices/map';
import themeReducer from './slices/theme';
import scheduleReducer from './slices/schedule';

export default configureStore({
  reducer: {
    common: commonReducer,
    message: messageReducer,
    auth: authReducer,
    crumb: crumbReducer,
    audience: audienceReducer,
    map: mapReducer,
    theme: themeReducer,
    schedule: scheduleReducer,
  },
});