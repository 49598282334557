import { authApi } from "../interceptor/auth.interceptor";

class ExlService {


    static async getAllAdv(queryParams) {
        return await authApi
            .get(`exl/adv-details?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async changeAdvAccount(queryParams) {
        return await authApi
            .put(`exl/update-adv-account?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

}

export default ExlService;
