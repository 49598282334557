import { Box } from '@mui/system';
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { horiziontalStackBarOptions } from '../../../config/highcharts.config';

const HorizontalStackedBar = ({ data }) => {

  const options = {
    ...horiziontalStackBarOptions,
    series: data
  };  

  return (<>
    {data?.length > 0 &&
      <Box className="HorizontalStackedBar" sx={{ position: 'relative', display: 'flex', width: '100%' }}>
        <HighchartsReact highcharts={Highcharts} options={options} style={{ width: '100%' }} />
      </Box>
    }
  </>)
}

export default HorizontalStackedBar;
