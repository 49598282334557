import './scss/style.scss';

import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import store from './redux/store';
import MainLayout from './components/layouts/main-layout/MainLayout';
import ScrollToTop from './components/common/scroll/ScrollToTop';
import PlanExpiredModal from './components/common/modal/plan-expired-modal/PlanExpiredModal';
import { setModalHandler } from './services/interceptor/auth.interceptor';

const loading = (
  <div className="text-center">Loading...</div>
)

const App = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    setModalHandler(setModalOpen);
  }, []);

  return (
    <Provider store={store}>
      <CookiesProvider>
        <BrowserRouter>
          <ScrollToTop>
            <React.Suspense fallback={loading}>
              <MainLayout />
            </React.Suspense>
          </ScrollToTop>
        </BrowserRouter>
      </CookiesProvider>
      <PlanExpiredModal open={isModalOpen} onClose={() => setModalOpen(false)} />
      <canvas hidden className='canvas'></canvas>
    </Provider>
  )
}

export default App;
