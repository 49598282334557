import { getRandomInt } from "./math";

export function formatToGeoJson(point, colorName = "BLUE", colorValue = "#3955BF") {
  if (point) {
    return {
      "geometry": {
        "type": "Point",
        "coordinates": [
          point.longitude,
          point.latitude
        ]
      },
      "type": "Feature",
      "properties": {
        // "id": point.longitude.toString() + point.latitude.toString() + getRandomInt(1000),
        "id": point.id,
        "description": "",
        "title": point.name,
        "address": point.address,
        "colorName": colorName,
        "colorValue": colorValue,
        "typeName": point.typeName
      }
    }
  } else {
    return null;
  }
}

export function getPinColorName(color = "#3C4DE7") {
  if (color) {
    switch (color) {
      case "#3C4DE7":
        return "BLUE";

      case "#FC6B21":
        return "ORANGE";

      case "#39BF8F":
        return "GREEN";

      case "#FF0099":
        return "PINK";

      case "#1FB9FC":
        return "SKYBLUE";

      default:
        return "BLUE";
    }
  }
}