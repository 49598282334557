import React, { useState } from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import CrumbCard from "../../card/crumb-card/CrumbCard";

import styles from './CrumbSlider.module.scss';
import { useEffect } from "react";

function CrumbSlider({ crumbList, activateCrumb }) {

  const [crumbs, setCrumbs] = useState(crumbList);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setCrumbs(crumbList);
    }

    return () => {
      mounted = false;
    }
  }, [crumbList]);


  const handleMouseEnter = (index) => {
    let crumbDummy = [...crumbs];
    crumbDummy = crumbDummy.map((crumb, i) => {
      return {
        ...crumb,
        hover: index === i ? false : true
      }
    });
    setCrumbs(crumbDummy);
  }
  const handleMouseLeave = () => {
    let crumbDummy = [...crumbs];
    crumbDummy = crumbDummy.map((crumb) => {
      return {
        ...crumb,
        hover: false
      }
    });
    setCrumbs(crumbDummy);
  }

  return (<>
    {crumbs?.length > 0 &&
      <Box className={`${styles.CrumbSliderWrapper ?? ''} CrumbSliderWrapper`} sx={{ position: 'relative' }}>
        <Swiper
          className={`${styles.CrumbSlider ?? ''} EqualHeight`}
          spaceBetween={0}
          slidesPerView={1}
          keyboard={true}
          mousewheel={true}
          modules={[Navigation]}
          loop={false}
          navigation={{
            nextEl: '.CrumbSliderWrapper .swiper-button-next',
            prevEl: '.CrumbSliderWrapper .swiper-button-prev',
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 35
            },
            // when window width is >= 480px
            600: {
              slidesPerView: 2,
              spaceBetween: 40
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 40
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 40
            },
            1370: {
              slidesPerView: 4,
              spaceBetween: 55
            },
            1600: {
              slidesPerView: 4,
              spaceBetween: 45
            },
            2000: {
              slidesPerView: 5,
              spaceBetween: 50
            }
          }}
        >
          {crumbs.map((crumb, index) => {
            return (
              <SwiperSlide key={index}>
                <CrumbCard
                  crumb={crumb}
                  index={index}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  activateCrumb={activateCrumb}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className="swiper-buttons">
          <div className="swiper-button-prev">
            <WestIcon sx={{ width: 18, height: 18 }} />
          </div>
          <div className="swiper-button-next" >
            <EastIcon sx={{ width: 18, height: 18 }} />
          </div>
        </div>
      </Box>
    }
  </>);
}

export default CrumbSlider;