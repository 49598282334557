import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { Autocomplete, Box, MenuItem, Select, Typography } from '@mui/material';
import { useForm } from "react-hook-form";
import Compress from 'compress.js';
import Image from 'mui-image';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { TickCircle } from 'iconsax-react';
import { CloseCircle } from 'iconsax-react';

import { setCreateAudienceModalState, setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import { errorMessage } from '../../../../config/config';
import CrumbService from '../../../../services/api/crumb.service';

import AudienceService from '../../../../services/api/audience.service';
import { setAudienceIdsList, setDefaultSearchKeywords, setPoiIdsList, setBrandIdList, setPartnerPOIList } from '../../../../redux/slices/audience';

import galleryAdd from '../../../../assets/images/icons/svg/gallery-add.svg';
import galleryAddBlack from '../../../../assets/images/icons/svg/gallery-add-black.svg';
import { useWindowWidth } from '../../../../utils/browser';
import PoiService from '../../../../services/api/poi.service';
import useTagInput from "../../../../hooks/useTagInput";
import { setPolygonData } from '../../../../redux/slices/map';

const Placeholder = ({ children }) => {
  return <div style={{ color: "#6F7288" }}>{children}</div>;
};

function CreateAudienceModal() {

  const browserWidth = useWindowWidth();
  const { createAudienceModalState } = useSelector((state) => state.common);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const { estimateAudiences } = useSelector((state) => state.audience);
  const { currentTheme } = useSelector((state) => state.theme);
  const mapPoints = useSelector(state => state.map.mapPoints);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const [crumbs, setCrumbs] = useState(null);
  const [brand, setBrand] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [selectedCrumb, setSelectedCrumb] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [category, setCategory] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [isNameAvailable, setIsNameAvailable] = useState(null);
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({ selectedCategory: "", selectedCrumb: "", selectedBrand: "" });

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // console.log("user", user);
      // console.log("selectedCountry", selectedCountry);
      reset();
      setSelectedCrumb("");
      setSelectedCategory("");
      setTags([]);
      setIsNameAvailable(null);
      setImageFileName(null);
      if (user && selectedCountry && createAudienceModalState) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ]
        CrumbService.getCrumbs(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              const crumbsList = data.results
                .filter(crumb => crumb.isDemoCrumb === null || crumb.isDemoCrumb === false)
                .reverse();
              // console.log("crumblist", crumbsList);
              setCrumbs(crumbsList);
            } else {
              setCrumbs([]);
            }
            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        );
        AudienceService.getAudience(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              const audienceList = data.results;
              // console.log("audienceList", audienceList.map(aud => aud.name));
              setAudiences(audienceList.map(aud => aud.name));
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        );
        PoiService.poiDetailsCategory(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              setCategory(data.results);
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        )
        CrumbService.getBrands(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              setBrand(data.results);
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        )
      }
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry, createAudienceModalState, reset]);

  const {
    input,
    setInput,
    onKeyDown,
    onKeyUp,
    handleTagClick,
    handleBlur,
    onTagChange,
    isValidTag,
  } = useTagInput(tags, setTags, true); // Passing tags and setTags to the hook


  const handleClose = (e, reason) => {
    if (reason !== "backdropClick") {
      dispatch(setCreateAudienceModalState(false));
      reset();
      setSelectedOptions({ selectedCategory: "", selectedCrumb: "", selectedBrand: "" });
      setImageFile(null);
      setImageFileName(null);
    }
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && (file.type === 'image/jpeg' || file.type === 'image/png')) {
      if (file.size > 2 * 1024 * 1024) {
        const compress = new Compress();
        compress.compress([file], {
          size: 2, // Set the desired max size in MB
          quality: 0.8, // Set the desired quality (0 to 1)
          maxWidth: 800 // Set the desired max width in pixels
        }).then((resizedImages) => {
          const resizedFileName = file.name;
          const resizedFile = Compress.convertBase64ToFile(resizedImages[0].data, resizedFileName);
          setImageFile(resizedFile);
          setImageFileName(resizedFileName);
        }).catch((error) => {
          console.error(error);
          dispatch(setMessage({
            type: "error",
            title: "An error occurred while resizing the image",
            description: error.message
          }));
          dispatch(setMessageSnackbarStateState(true));
        });
      } else {
        // Use the original image if it's already under 2 MB
        setImageFile(file);
        setImageFileName(file.name);
      }
    } else if (!file && imageFile) {
      setImageFile(imageFile);
      setImageFileName(imageFile.name);
    } else {
      // Handle non-image files
      dispatch(setMessage({
        type: "warning",
        title: "Please Upload jpeg or png Formats",
      }));
      dispatch(setMessageSnackbarStateState(true));
    }
  };

  const handleChange = (event) => {
    const name = event.target.value.toLowerCase();

    if (audiences.map(a => a.toLowerCase()).includes(name)) {
      // console.log('Audience name already taken');
      setIsNameAvailable(false);
      dispatch(setMessage({
        type: "error",
        title: "Audience Name Is Already Taken",
      }));
      dispatch(setMessageSnackbarStateState(true));
    } else {
      // console.log('Audience name available');
      setIsNameAvailable(true);
    }
  };

  const onSubmit = (values) => {
    // console.log("enter");
    // console.log("values", values, "selectedOptions", selectedOptions.selectedCrumb.id);
    // const formattedTags = tags.map((tag) => validateCase(tag));
    const audience = localStorage.getItem("audience")
   
    let aud = JSON.parse(audience);
    const nameRegex = /^[a-zA-Z0-9\s-]+$/;
    if (!nameRegex.test(values.name)) {
      dispatch(
        setMessage({
          type: "error",
          title: "Name must contain only letters, spaces, and hyphens",
        })
      );
      dispatch(setMessageSnackbarStateState(true));
      return;
    }

    if (!selectedOptions.selectedCrumb) {
      dispatch(setMessage({
        type: "error",
        title: "Select a Crumb",
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }

    if (selectedOptions.selectedCategory.length === 0) {
      dispatch(setMessage({
        type: "error",
        title: "Select a Category"
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }

    if (!selectedOptions.selectedBrand) {
      console.log(selectedOptions.selectedBrand)
      dispatch(setMessage({
        type: "error",
        title: "Select the nature of the brand",
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }



    if (!imageFile) {
      // console.log("please upload an image")
      dispatch(setMessage({
        type: "error",
        title: "please upload an image"
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }

  






    let poiIds = aud.poiId
    // if (!poiIds) {
    //   dispatch(setMessage({
    //     type: "error",
    //     title: "Missing Poi Ids",
    //   }));
    //   dispatch(setMessageSnackbarStateState(true));
    //   return false;
    // }

    const totalAudienceCount = estimateAudiences === undefined || estimateAudiences === null ? 0 : estimateAudiences;
    // let poiIdsDummy = poiIds.split(',');
    // const audience = localStorage.getItem("audience")
    // let aud = JSON.parse(audience);

    const poiRejectedIds = mapPoints
      .filter(point => point.properties.isEnabled && (point.properties.typeName === "poi" || point.properties.typeName === null || !point.properties.typeName))
      .map(point => point.properties.id);


    const locationRejectedIds = mapPoints
      .filter(point => point.properties.isEnabled && point.properties.typeName === "loc")
      .map(point => point.properties.id);

    const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));

    let isPOIDetails = 'isPOIDetailsId' in applyFilterParams ? applyFilterParams.isPOIDetailsId : false;
    const categoryList = Array.isArray(selectedOptions.selectedCategory)
    ? selectedOptions.selectedCategory.map((category) => category.id)
    : [];

    const queryParams = [
      `name=${values.name.trim()}`,
      `tags=${tags}`,
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
      `crumbId=${selectedOptions.selectedCrumb.id}`,
      `isActive=Activated`,
      `dayPart=${aud.dayParting}`,
      `weekPart=${aud.weekParting}`,
      `visitPeriod=${aud.visitPeriod}`,
      `totalAudienceCount=${totalAudienceCount}`,
      `totalLocationPin=${aud.totalLocationPin}`,
      `rejectedPOIList=${poiRejectedIds}`,
      `rejectedLocationList=${locationRejectedIds}`,
      `brandTypeId=${selectedOptions.selectedBrand.id}`,
      `categoryList=${categoryList}`,
      `rangeType=${aud.rangeType}`,
      ...(aud.rangeType === "distance" || aud.rangeType === "time"  ? [`isPolygon=true`] : [`geoRadius=${aud.geoRadius}`]),
      ...(aud.rangeType === "distance" ? [`distance=${aud.distance}`] : []),
      ...(aud.rangeType === "time" ? [`time=${aud.time}`] : []),
    ];

    if (aud.isMerge) {
      queryParams.push(`isMerge=${aud.isMerge}`);
    }

    if (applyFilterParams.brandIdList !== null) {
      queryParams.push(`brandIdList=${applyFilterParams.brandIdList}`);
    }

    if (isPOIDetails) {

      if (applyFilterParams.category !== null) {
        queryParams.push(`category=${applyFilterParams.category}`);
      }

      // if (applyFilterParams.city !== null) {
      //   queryParams.push(`city=${applyFilterParams.city}`);
      // }

      queryParams.push(`isPOIDetailsId=${isPOIDetails}`);

    }


    if (poiIds !== null && poiIds !== '') {

      queryParams.push(`poiIdList=${poiIds}`);
    }

    // console.log("queryParams", queryParams);
    // return false;
    const formData = new FormData();
    formData.append('file', imageFile);
    setIsSubmitting(true);
    AudienceService.addAudience(queryParams.join('&'), formData).then(
      (data) => {
        setTags([]);
        // setIsLowerCase(false);
        // console.log("data", data);
        setIsSubmitting(false);
        if (data?.results) {
          dispatch(setMessage({
            type: "success",
            title: data?.message ? data.message : "Audience added successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));
          dispatch(setDefaultSearchKeywords([]));
          localStorage.removeItem("selectedSearchKeywords");
          dispatch(setPoiIdsList(''));
          dispatch(setBrandIdList(''));
          dispatch(setPartnerPOIList(''));
          dispatch(setAudienceIdsList(''));
          dispatch(setPolygonData(''));
          reset();
          handleClose();
          setSelectedCrumb("");
          setSelectedCategory("");
          setIsNameAvailable(null);
          setImageFileName(null);
          navigate(`/crumb/${selectedOptions.selectedCrumb.id}/audiences`);
          // window.location.reload();
        } else {
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
          setIsSubmitting(false);
        }
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false);

        if (error?.response?.data) {
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message ? error.response.data.message : errorMessage,
          }));
        } else {
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
        }
        dispatch(setMessageSnackbarStateState(true));
        setIsSubmitting(false);
        return Promise.reject();
      }
    );
  };

  const onChangeSearchOption = (searchText) => {
    const queryParams = [
      `userId=${user.id}`,
      `countryId=${selectedCountry.id}`,
      `searchText=${searchText}`
    ]
    PoiService.poiDetailsCategory(queryParams.join('&')).then(
      (data) => {
        if (data?.results?.length) {
          setCategory(data.results);
        }
        return Promise.resolve();
      },
      (error) => {
        return Promise.reject();
      }
    )
  };

  const onChangeOption = (newValue, type,event) => {
    console.log(event)
    setSelectedOptions(prevOptions => ({
      ...prevOptions,
      [type]: newValue
    }));
  };

  return (<>
    <Dialog
      open={createAudienceModalState}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      scroll="paper"
      PaperProps={{
        sx: {
          width: "390px",
          borderRadius: "0px",
          overflowY: "auto", // Enable vertical scroll if needed
        },
      }}
      sx={{
        backdropFilter: "blur(25px)",
      }}
    >
      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Save Your Audience</DialogTitle>
      <Divider />
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <TextField
            autoFocus
            InputProps={{
              endAdornment: isNameAvailable === true ? (
                <TickCircle size="22" color="green" variant='Bold' />
              ) : isNameAvailable === false ? (
                <CloseCircle size="22" color="red" variant='Bold' />
              ) : null,
            }}
            placeholder="Audience Name"
            InputLabelProps={{
              style: { color: "#6F7288" }
            }}
            focused
            id="crumbName"
            type="text"
            fullWidth
            variant="standard"
            sx={{ my: 2 }}
            error={!!errors['name']}
            helperText={errors['name'] ? errors['name'].message : ''}
            {...register("name", {
              required: "Name is required"
            })}
            onChange={handleChange}
          />

          <Autocomplete
            fullWidth
            options={crumbs || []}
            value={selectedOptions.selectedCrumb || null}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option) => (
              <li {...props}>
                <div>
                  <span style={{ marginRight: '8px' }}>{option.name}</span>
                  <span style={{ marginLeft: 'auto', color: '#999', fontSize: '0.8em' }}>
                    Created by {option.createdBy}
                  </span>
                </div>
              </li>
            )}
            onChange={(event, newValue) => {
              onChangeOption(newValue, 'selectedCrumb',event);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"Select a Crumb"}
                variant="standard"
                sx={{ my: 2, color: '#fff' }}
                {...register("crumb")}
                disabled={crumbs && crumbs.length === 0}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {selectedOptions.selectedCrumb?.createdBy && (
                        <Typography
                          component="div"
                          variant="body2"
                          style={{ color: '#999', fontSize: '0.8em', marginLeft: 'auto' }}
                        >
                          Created by {selectedOptions.selectedCrumb.createdBy}
                        </Typography>
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
            PaperProps={{
              style: {
                maxHeight: '50vh',
                maxWidth: '50vh',
                marginTop: '2px',
                overflowX: 'auto',
              },
            }}
          />

          <Autocomplete
            fullWidth
            options={category || []}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            //value={selectedOptions.selectedCategory || []}
            defaultValue={selectedOptions.selectedCategory || []}
            getOptionLabel={(option) => option.name}
            onChange={(event, newValue) => {
              onChangeOption(newValue, 'selectedCategory',event);
            }}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={selectedOptions.selectedCategory.length > 0
                  ? ""
                  : "Card Categories"}
                variant="standard"
                sx={{
                  my: 2,
                  '& .MuiChip-root': {
                    backgroundColor: currentTheme === 'light' ? '#ededed' : '#3f3e3e',
                    color: currentTheme === 'light' ? '#000' : '#fff'
                  },
                }}
                {...register("category")}
                disabled={category && category.length === 0}
                onChange={(e) => {
                  const searchText = e.target.value;
                  // Do something with the search text, e.g., pass it to onChangeOption
                  onChangeSearchOption(searchText);
                }}
              />
            )}
            paperprops={{
              style: {
                maxHeight: '50vh',
                maxWidth: '50vh',
                marginTop: '2px',
                overflowX: 'auto',
              },
            }}
          />

          <Autocomplete
            fullWidth
            options={brand || []}
            value={selectedOptions.selectedBrand || null}
            getOptionLabel={(option) => option.name}
            // renderOption={(props, option) => (
            //   <li {...props}>
            //     <div>
            //       <span style={{ marginRight: '8px' }}>{option.name}</span>
            //       <span style={{ marginLeft: 'auto', color: '#999', fontSize: '0.8em' }}>
            //         Created by {option.createdBy}
            //       </span>
            //     </div>
            //   </li>
            // )}
            onChange={(event, newValue) => {
              onChangeOption(newValue, 'selectedBrand',event);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={"Define the nature of the brand"}
                variant="standard"
                sx={{ my: 2, color: '#fff' }}
                {...register("brand")}
                disabled={brand && brand.length === 0}
                InputProps={{
                  ...params.InputProps,
                  // endAdornment: (
                  //   <>
                  //     {selectedOptions.selectedCrumb?.createdBy && (
                  //       <Typography
                  //         component="div"
                  //         variant="body2"
                  //         style={{ color: '#999', fontSize: '0.8em', marginLeft: 'auto' }}
                  //       >
                  //         Created by {selectedOptions.selectedCrumb.createdBy}
                  //       </Typography>
                  //     )}
                  //     {params.InputProps.endAdornment}
                  //   </>
                  // ),
                }}
              />
            )}
            PaperProps={{
              style: {
                maxHeight: '50vh',
                maxWidth: '50vh',
                marginTop: '2px',
                overflowX: 'auto',
              },
            }}
          />

          <TextField
            placeholder="Description"
            InputLabelProps={{
              style: { color: "#6F7288" }
            }}
            id="standard-multiline-static"
            multiline
            rows={3}
            variant="standard"
            fullWidth
            sx={{ my: 2 }}
            error={!!errors['description']}
            helperText={errors['description'] ? errors['description'].message : ''}
            {...register("description")}
          />
          {tags.map((tag) => {
            // const formattedTags = validateCase(tag);
            return (
              <div
                key={tag}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  margin: '7px 0',
                  marginRight: '10px',
                  padding: '0 10px',
                  paddingRight: '5px',
                  border: '1px solid orange',
                  borderRadius: '5px',
                  backgroundColor: 'orange',
                  whiteSpace: 'nowrap',
                  color: 'white',
                }}
              >
                {tag}
                <button
                  style={{
                    display: 'flex',
                    padding: '6px',
                    border: 'none',
                    backgroundColor: 'unset',
                    cursor: 'pointer',
                    color: 'white',
                  }}
                  onClick={() => handleTagClick(tag)}
                >
                  x
                </button>
              </div>
            );
          })}
          <TextField
            InputProps={{
              style: { padding: "0 8px 10px 8px" },
            }}
            InputLabelProps={{
              style: { color: "#6F7288" }
            }}
            id="tags"
            type="text"
            variant="standard"
            fullWidth
            sx={{ my: 1.5 }}
            value={input}
            placeholder="Enter tags"
            onKeyDown={onKeyDown}
            onKeyUp={onKeyUp}
            onChange={onTagChange}
            onBlur={handleBlur}
          />
          {/* {isLowerCase && <p style={{ color: 'green' }}>Valid Tag entered.</p>} */}
          {!isValidTag && input && <p style={{ color: 'red' }}>Special characters are not allowed</p>}
          <Box className="UploadProfilePhoto" sx={{ position: 'relative', borderRadius: '0px', overflow: 'hidden', mb: 2 }}>
            <Box sx={{ py: 2, height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mr: 2, width: 30, height: 30 }}>
                <Image src={currentTheme === 'light' ? galleryAddBlack : galleryAdd} alt="" />
              </Box>
              <Box>{imageFileName ? imageFileName : "Upload an image "}</Box>
            </Box>
            <input
              style={{ zIndex: 2, display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
              type="file"
              // error={!!errors['image']}
              // helperText={errors['image'] ? errors['image'].message : ''}
              // {...register("image", {
              //   required: "select an image"
              // })}
              onChange={handleFileChange}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Save to my account
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default CreateAudienceModal;