import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import UserService from '../../../services/api/user.service';
import { useCookies } from "react-cookie";
import { useIdleTimer } from 'react-idle-timer';

import ChangePasswordModal from '../../common/modal/change-password-modal/ChangePasswordModal';
import CreateCrumbModal from '../../common/modal/create-crumb-modal/CreateCrumbModal';
import UploadAudienceModal from '../../common/modal/upload-audience-modal/UploadAudienceModal';
import CreateAudienceModal from '../../common/modal/create-audience-modal/CreateAudienceModal';
import FilterPoiModal from '../../common/modal/filter-poi-modal/FilterPoiModal';
import EditProfileSideBar from '../../common/sidebar/edit-profile-sidebar/EditProfileSideBar';
import ProfileSideBar from '../../common/sidebar/profile-sidebar/ProfileSideBar';
import { accessToken, accessTokenTimeout, accessTokenTimerTransition, refreshToken, refreshTokenTimeout, userIdleTimeCountDownStartTime, userIdleTimeout } from '../../../config/config';
import { loadedLoggedIn, login, logout, setUser } from '../../../redux/slices/auth';
import { Alert, Snackbar } from '@mui/material';
import { setMessageSnackbarStateState } from '../../../redux/slices/common';
import { getCookie } from '../../../utils/cookie';
import { getPermission } from '../../../utils/cryptograpgy';
import Header from '../../common/header/Header';
import { setRefreshTokenSchedule, setUserInteractionSchedule } from '../../../redux/slices/schedule';
import AuthService from '../../../services/api/auth.service';
import { getExpiryTime } from '../../../utils/date';
import CompareCardModal from '../../common/modal/compare-card-modal/CompareCardModal';
import CompareCardStatusModal from '../../common/modal/compare-card-status-modal/CompareCardStatusModal';
import ApproveAudienceConfirmModal from '../../common/modal/approve-audience-confirm-modal/ApproveAudienceConfirmModal';
import ActivateAudienceConfirmModal from '../../common/modal/activate-audience-confirm-modal/ActivateAudienceConfirmModal';
import ActivateCompareAudienceConfirmModal from '../../common/modal/activate-compared-audience-modal/ActivateCompareAudienceConfirmModal';
import ExternalUserManagementModal from "../../common/modal/ExternalAccountModal/ExternalAccountModal";
import MenuSideBar from "../../common/sidebar/menu-sidebar/MenuSideBar";

const SiteLayout = ({ refreshTokenFromRedux, user, message, messageSnackbarState, refreshTokenSchedule, userInteractionSchedule, setUser, setMessageSnackbarStateState, setRefreshTokenSchedule, setUserInteractionSchedule, logout, loadedLoggedIn }) => {

  const navigate = useNavigate();
  const location = useLocation();
  const [isLoaded, setIsLoaded] = useState(false);
  const [addCrumbPermission, setAddCrumbPermission] = useState(false);
  // const [addDeleteCrumbPermission, setDeleteCrumbPermission] = useState(false);
  const [uploadPoiPermission, setUploadPoiPermission] = useState(false);
  const [filterPoiPermission, setFilterPoiPermission] = useState(false);
  const [compareCardPermission, setCompareCardPermission] = useState(false);
  const [refreshTokenLoading, setRefreshTokenLoading] = useState(false);
  const [approveAudiencePermission, setApproveAudiencePermission] = useState(false);
  const [externalUserManagementPermission, setExternalUserManagementPermission] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const refreshTokenFromCookie = getCookie(refreshToken);
      if (!refreshTokenFromRedux || !refreshTokenFromCookie) {
        if (window.location.href.includes('from=email')) {
          const redirectUrl = window.location.origin + window.location.pathname;
          const urlParams = new URLSearchParams(window.location.search);
          const countryCodeParam = urlParams.get('countryCode').toUpperCase();
          const countryCode = countryCodeParam.split(' ')[0].toUpperCase();
          localStorage.setItem('redirectUrl', redirectUrl);
          localStorage.setItem('email-country-set', countryCode);
        }
        setTimeout(() => {
          navigate('/');
          logoutCookie();
        }, 100);
      } else {
        setIsLoaded(true);
        setAddCrumbPermission(getPermission('add_crumb'));
        setUploadPoiPermission(getPermission('upload_poi'));
        // setDeleteCrumbPermission(getPermission('delete_crumb'));
        setFilterPoiPermission(getPermission('filter_poi'));
        setCompareCardPermission(getPermission('compare_card'));
        setApproveAudiencePermission(getPermission('approve_card'));
        setExternalUserManagementPermission(getPermission('external_user_management'))
        if (!user) {
          UserService.getProfile().then(
            (data) => {
              if (data?.results) {
                setUser(data.results);
                loadedLoggedIn({
                  loaded: true,
                  loggedIn: true
                });
                setTimeout(() => {
                  localStorage.removeItem('redirectUrl');
                  localStorage.setItem('clickEvent', '');
                }, 2000);
              } else {
                logoutCookie();
              }
              return Promise.resolve();
            },
            (error) => {
              logoutCookie();
              return Promise.reject();
            }
          );
        }
      }
    }
    return () => {
      mounted = false;
    }
  }, [refreshTokenFromRedux, cookies, location]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      const refreshTokenFromCookie = getCookie(refreshToken);
      if (refreshTokenFromCookie) {
        getAccessTokenByRefreshToken();
        const refreshTokenInterval = setInterval(() => {
          getAccessTokenByRefreshToken();
        }, (accessTokenTimeout - accessTokenTimerTransition));
        setRefreshTokenSchedule(refreshTokenInterval);
      }
    }
    return () => {
      mounted = false;
    }
  }, []);

  const getAccessTokenByRefreshToken = () => {
    const refreshTokenFromCookie = getCookie(refreshToken);
    if (refreshTokenFromCookie && !refreshTokenLoading) {
      setRefreshTokenLoading(true);
      const formData = {
        refreshToken: refreshTokenFromCookie
      };
      AuthService.getRefreshToken(formData).then(
        (data) => {
          const accessTokenExpiryTime = getExpiryTime(accessTokenTimeout);
          const refreshTokenExpiryTime = getExpiryTime(refreshTokenTimeout);
          setCookie(accessToken, data.accessToken, { path: '/', expires: accessTokenExpiryTime });
          setCookie(refreshToken, data.refreshToken, { path: '/', expires: refreshTokenExpiryTime });
          login({
            loaded: true,
            loggedIn: true,
            accessToken: data.accessToken,
            refreshToken: data.refreshToken,
          });
          setRefreshTokenLoading(false);
          return Promise.resolve();
        },
        (error) => {
          logoutCookie();
        }
      );
    } else {
      logoutCookie();
    }
  };

  const onIdle = () => {
    console.log('User is idle.');
    const userIdleInterval = setInterval(() => {
      logoutCookie();
    }, userIdleTimeout);
    setUserInteractionSchedule(userIdleInterval);
  };

  const onActive = () => {
    console.log('User is active.');
    if (userInteractionSchedule) {
      clearInterval(userInteractionSchedule);
      setUserInteractionSchedule(null);
    }
  };

  const idleTimer = useIdleTimer({
    onIdle,
    onActive,
    timeout: userIdleTimeCountDownStartTime,
    throttle: 500
  });

  const logoutCookie = () => {
    removeCookie(accessToken, { path: '/' });
    removeCookie(refreshToken, { path: '/' });
    setUser(null);
    if (refreshTokenSchedule) {
      clearInterval(refreshTokenSchedule);
      if (userInteractionSchedule) {
        clearInterval(userInteractionSchedule);
      }
      setRefreshTokenSchedule(null);
      setUserInteractionSchedule(null);
    }
    logout();
  }

  return (<>
    {isLoaded &&
      <>
        <Header />
        <MenuSideBar />
        <div className={`site-layout`}>
          <Outlet />
        </div>
        <ProfileSideBar />
        <EditProfileSideBar />
        <ChangePasswordModal />
        <ActivateCompareAudienceConfirmModal />
        {addCrumbPermission &&
          <CreateCrumbModal />
        }
        {/*{*/}
        {/*  audienceActivityPermission &&*/}
        {/*  <AudienceActivityModal />*/}
        {/*}*/}
        {
          filterPoiPermission &&
          <FilterPoiModal />
        }
        {/*{*/}
        {/*  userManagementPermission &&*/}
        {/*  <UserManagementModal />*/}
        {/*}*/}
        {
          compareCardPermission &&
          <CompareCardModal />
        }
        {/*{*/}
        {/*  compareStatusPermission &&*/}
        {/*  <CompareCardStatusModal />*/}
        {/*}*/}
        {uploadPoiPermission &&
          <UploadAudienceModal />
        }
        {uploadPoiPermission &&
          <CreateAudienceModal />
        }
        {
          approveAudiencePermission &&
          <ApproveAudienceConfirmModal />
        }
        {
            externalUserManagementPermission &&
            <ExternalUserManagementModal />
        }

        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={messageSnackbarState}
          onClose={() => setMessageSnackbarStateState(false)}
          autoHideDuration={6000}
        >
          <Alert onClose={() => setMessageSnackbarStateState(false)} severity={message?.type} variant="filled" sx={{ width: '100%' }}>{message?.title}</Alert>
        </Snackbar>
      </>
    }
  </>)
}

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken,
  user: state.auth.auth.user,
  message: state.message.message,
  messageSnackbarState: state.common.messageSnackbarState,
  refreshTokenSchedule: state.schedule.refreshTokenSchedule,
  userInteractionSchedule: state.schedule.userInteractionSchedule
});

export default connect(mapStateToProps, { setUser, setMessageSnackbarStateState, setRefreshTokenSchedule, setUserInteractionSchedule, loadedLoggedIn, login, logout })(SiteLayout);