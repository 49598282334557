import React, { useEffect, useState } from "react";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import {useDispatch, useSelector} from 'react-redux';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';
import error_icon from "../../../../assets/images/error_icon.svg";
import { Grid } from "@mui/material";
import TopUpDetails from "../../sidebar/top-up-details-sidebar/TopUpDetails";
import {setMobileAudienceDetailsState, setTopUpDetailsState} from "../../../../redux/slices/common";
import {useNavigate} from "react-router-dom";
import {setTotalSelectedAudience} from "../../../../redux/slices/audience";
import AudienceService from "../../../../services/api/audience.service";
import StripeService from "../../../../services/api/stripe.service";

function ActivateTopUpCreditModal({ activateTopUpCreditModalState, setActivateTopUpCreditModalState, handleAudienceRefresh }) {

    const browserWidth = useWindowWidth();
    //const [newTopUpDetailModalState, setNewTopUpDetailModalState] = useState(false);
    //const [isCollapsed, setIsCollapsed] = useState(false);
    const [ topUpSliderState, setTopUpSliderState] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        mode: 'onChange'
    });
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);

    const [ amountDetails, setAmountDetails] = useState([]);

    useEffect(() => {
        let mounted = true;
        // console.log("audienceHistoryChange", audienceHistoryChange);
        if (mounted && user && selectedCountry) {


            const queryCalcParams = [
                `userId=${user.id}`,
                `countryId=${selectedCountry.id}`,
            ];

            StripeService.getAmount(queryCalcParams.join('&')).then((data) => {

                    if (data?.results) {
                        setAmountDetails(data.results);
                    }
                    return Promise.resolve();
                }, (error) => {
                    return Promise.reject();
                }
            )
        }

        return () => {
            mounted = false;
        }
    }, [user, selectedCountry]);



    const handleClose = () => {
        setActivateTopUpCreditModalState(false);
    };

    const handleConfirm = () => {
        setTopUpSliderState(true);
        navigate('/user-profile');
        dispatch(setTopUpDetailsState(true));
        //setActivateTopUpCreditModalState(false);
        //setNewTopUpDetailModalState(true);
    };

    const { currentTheme } = useSelector((state) => state.theme);

    const handleCollapsed = () => {
        //setIsCollapsed(!isCollapsed);
        dispatch(setMobileAudienceDetailsState(false));
    }

    return (<>
        <Dialog
            open={activateTopUpCreditModalState}
            onClose={handleClose}
            PaperProps={{ sx: { width: "500px", borderRadius: "0px" } }}
            sx={{
                backdropFilter: "blur(2px)",
            }}
        >
            <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 , overflow: "hidden"}}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: 120, height: 100 }}>
                        <Image src={ error_icon } alt='logo' />
                    </Box>
                </Box>
                {/*<DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>*/}
                {/*    {"Refresh"}*/}
                {/*</DialogTitle>*/}
                <DialogContentText style={{ paddingTop : '5px' }} id="alert-dialog-description" textAlign="center" color={"#6F7288"} >
                    Sorry, You do not have enough Credit
                </DialogContentText>
                <Grid container rowSpacing={0} columnSpacing={{xs: 0.5, sm: 2, md: 3}} style={{ marginLeft: '5px' }}>
                    <Grid style={{ paddingTop: '8px' }} item xs={6}>
                        <Box style={{ fontSize: '12px' }}> Available card Credits </Box>
                    </Grid>
                    <Grid item xs={6}
                          style={{padding: '8px', color: currentTheme === 'dark' ? '#ffffff' : '#202020'}}>
                        <Box style={{ fontSize: '12px' }}> Available Audience Credits </Box>
                    </Grid>
                    <Grid item xs={6} marginLeft='2px'>
                        <Box style={{ color: '#39BF8F' }} > {amountDetails.cardTotalLimitAvailable !== undefined ? amountDetails.cardTotalLimitAvailable.toLocaleString() : '0'} Cards</Box>
                    </Grid>
                    <Grid item xs={6} marginLeft='-5px'>
                        <Box style={{ color: '#39BF8F' }}> {amountDetails.audienceActivatedLimitAvailable !== undefined ? amountDetails.audienceActivatedLimitAvailable.toLocaleString() : '0'}  audiences </Box>
                    </Grid>
                </Grid>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ padding: "0px" }}>
                <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
                    <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>No</Button>
                    <Button className="form-button" variant='contained' onClick={() => handleConfirm()} sx={{ textTransform: "none", borderRadius: "0px" }}>Top up credits</Button>
                </ButtonGroup>
            </DialogActions>
        </Dialog>
    </>);
}

export default ActivateTopUpCreditModal;