import CryptoJS from 'crypto-js';
const permissionKey = "ectoskkddjjs-ecghdghyye";

export function encryptString(array) {
  try {
    return CryptoJS.AES.encrypt(JSON.stringify(array), permissionKey).toString();
  } catch (error) {
    return '';
  }
}

export function decryptString(str) {
  try {
    // console.log('str', str.replace(/['"]+/g, ''));
    const trimmedStr = str.replace(/['"]+/g, '');
    const dec_perm = CryptoJS.AES.decrypt(trimmedStr, permissionKey).toString(CryptoJS.enc.Utf8);
    return JSON.parse(dec_perm);
  } catch (error) {
    return '';
  }
}

export function getPermission(permission) {
  try {
    const permissions = localStorage.getItem('p2e3r21m2i31s166s223i345o226112n');
    const selectedPermissions = decryptString(permissions);
    if (selectedPermissions.includes(permission)) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export function getMultiPermission(permissionList) {
  try {
    const permissions = localStorage.getItem('p2e3r21m2i31s166s223i345o226112n');
    const selectedPermissions = decryptString(permissions);
    for (let index = 0; index < permissionList.length; index++) {
      if (selectedPermissions.includes(permissionList[index])) {
        return true;
      }
    }
    return false;
  } catch (error) {
    return false;
  }
}