import HomeLayout from '../components/layouts/site-layout/SiteLayout';
import LoginLayout from '../components/layouts/auth-layout/AuthLayout';
import Error404 from '../pages/error/Error404';
import HomePage from '../pages/home/Home';
import Login from '../pages/login/Login';
import ResetPassword from '../pages/reset-password/ResetPassword';
import SearchPage from '../pages/search/Search';
import AudiencesPage from '../pages/audiences/Audiences';
import AudienceDetailsPage from '../pages/audience-details/AudienceDetails';
import DeletedCrumbsPage from '../pages/deleted-crumbs/DeletedCrumbs';
import FavouriteCardPage from '../pages/favourite-cards/FavouriteCards';
import AudienceHistoryPage from '../pages/audience-history/AudienceHistory';
import ConnectorDetails from "../pages/campaign-details/ConnectorDetails";
import ApproveUser from "../pages/approve-user/ApproveUser";
import ActivityLog from "../pages/activity-log/ActivityLog";
import UserProfile from "../pages/user-profile/UserProfile";
import CheckoutForm from "../pages/check-out/CheckOut";
import ReturnForm from "../pages/return/return";


const websiteRoutes = [
  {
    path: '', layout: HomeLayout, children: [
      { path: '/home', name: 'Home', exact: true, element: HomePage },
      { path: '/approve', name: 'ApproveUser', exact: true, element: ApproveUser },
      { path: '/campaigns', name: 'ConnectorDetails', exact: true, element: ConnectorDetails },
      { path: '/crumb/:id/audiences', exact: true, name: 'AudiencesPage', element: AudiencesPage },
      { path: '/audience/:id', exact: true, name: 'AudienceDetailsPage', element: AudienceDetailsPage },
      { path: '/favourite-cards', exact: true, name: 'FavouriteCardPage', element: FavouriteCardPage },
      { path: '/deleted-crumbs', exact: true, name: 'DeletedCrumbsPage', element: DeletedCrumbsPage },
      { path: '/audience-history', exact: true, name: 'AudienceHistoryPage', element: AudienceHistoryPage },
      { path: '/search', exact: true, name: 'SearchPage', element: SearchPage },
      { path: '/activity', exact: true, name: 'Activity Log', element: ActivityLog },
      { path: '/user-profile', exact: true, name: 'user profile', element: UserProfile }
    ]
  },

  {
    path: '', layout: LoginLayout, children: [
      { path: '/', exact: true, name: 'Login', element: Login },
      { path: '/reset-password/:token', name: 'Forgot Password', element: ResetPassword },
    ]
  },

  { path: '/check-out', exact: true, name: 'check out', element: CheckoutForm },
  { path: '/return', exact: true, name: 'Return', element: ReturnForm },
  { path: '/404', name: 'Error404', element: Error404 },
  { path: '*', name: 'Error404', element: Error404 },
];

export default websiteRoutes;
