// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AudienceSlider_AudienceSliderWrapper__l8l9M .AudienceSlider_AudienceSlider__aejJM {\n  padding: 60px 45px 20px 45px;\n}\n\n/* Wide Mobile Layout: 480px. */\n@media only screen and (max-width: 500px) {\n  .AudienceSlider_AudienceSliderWrapper__l8l9M .AudienceSlider_AudienceSlider__aejJM {\n    padding: 60px 35px 20px 35px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/components/common/slider/audience-slider/AudienceSlider.module.scss"],"names":[],"mappings":"AAGC;EACG,4BAAA;AAFJ;;AAMA,+BAAA;AACA;EAEE;IACC,4BAAA;EAJD;AACF","sourcesContent":["@import \"../../../../scss/variables\";\n\n.AudienceSliderWrapper {\n\t.AudienceSlider {\n    padding: 60px 45px 20px 45px;\n\t}\n}\n\n/* Wide Mobile Layout: 480px. */\n@media only screen and (max-width: 500px) {\n\t.AudienceSliderWrapper {\n\t\t.AudienceSlider {\n\t\t\tpadding: 60px 35px 20px 35px;\n\t\t}\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"AudienceSliderWrapper": "AudienceSlider_AudienceSliderWrapper__l8l9M",
	"AudienceSlider": "AudienceSlider_AudienceSlider__aejJM"
};
export default ___CSS_LOADER_EXPORT___;
