import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import { useDispatch, useSelector } from 'react-redux';
import { Box } from '@mui/material';
import { useForm } from "react-hook-form";
import Compress from 'compress.js';
import Image from 'mui-image';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import { TickCircle } from 'iconsax-react';
import { CloseCircle } from 'iconsax-react';

import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import { errorMessage } from '../../../../config/config';
import AudienceService from '../../../../services/api/audience.service';
import { setDefaultSearchKeywords } from '../../../../redux/slices/audience';

import galleryAdd from '../../../../assets/images/icons/svg/gallery-add.svg';
import galleryAddBlack from '../../../../assets/images/icons/svg/gallery-add-black.svg';

function CloneAudienceModal({ audienceId, cloneAudienceModalState, setCloneAudienceModalState }) {

  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const { currentTheme } = useSelector((state) => state.theme);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const [imageFile, setImageFile] = useState(null);
  const [imageFileName, setImageFileName] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [audiences, setAudiences] = useState([]);
  const [isNameAvailable, setIsNameAvailable] = useState(null);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // console.log("user", user);
      // console.log("selectedCountry", selectedCountry);
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ]
        AudienceService.getAudience(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              const audienceList = data.results;
              // console.log("audienceList", audienceList.map(aud => aud.name));
              setAudiences(audienceList.map(aud => aud.name));
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        );
      }
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry]);

  const handleClose = () => {
    setCloneAudienceModalState(false);
  };

  const handleBackdropClick = (event) => {
    event.stopPropagation();
    return false;
  };

  // const handleFileChange = event => {
  //   const fileObj = event.target.files && event.target.files[0];
  //   if (!fileObj) {
  //     return false;
  //   } else {
  //     setImageFile(fileObj);
  //     setImageFileName(fileObj.name);
  //   }
  // }

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.type.startsWith('image/')) {
      // Get the height and width of the uploaded image
      // const reader = new FileReader();
      // reader.onload = function (e) {
      //   const img = new Image();
      //   img.onload = function () {
      //     const height = img.height;
      //     const width = img.width;
      //     // console.log(`Uploaded image dimensions: ${width} x ${height}`);
      //   };
      //   img.src = e.target.result;
      // };
      // reader.readAsDataURL(file);

      if (file.size > 2 * 1024 * 1024) {
        const compress = new Compress();
        compress.compress([file], {
          size: 2, // Set the desired max size in MB
          quality: 0.8, // Set the desired quality (0 to 1)
          maxWidth: 800 // Set the desired max width in pixels
        }).then((resizedImages) => {
          const resizedFileName = file.name;
          const resizedFile = Compress.convertBase64ToFile(resizedImages[0].data, resizedFileName);
          setImageFile(resizedFile);
          setImageFileName(resizedFileName);
        }).catch((error) => {
          console.error(error);
          dispatch(setMessage({
            type: "error",
            title: "An error occurred while resizing the image",
            description: error.message
          }));
          dispatch(setMessageSnackbarStateState(true));
        });
      } else {
        // Use the original image if it's already under 2 MB
        setImageFile(file);
        setImageFileName(file.name);
      }
    } else {
      // Handle non-image files
      dispatch(setMessage({
        type: "warning",
        title: "please upload image file",
      }));
      dispatch(setMessageSnackbarStateState(true));
    }
  };

  const handleChange = (event) => {
    const name = event.target.value.toLowerCase();

    if (audiences.map(a => a.toLowerCase()).includes(name)) {
      // console.log('Audience name already taken');
      setIsNameAvailable(false);
      dispatch(setMessage({
        type: "error",
        title: "Audience Name Is Already Taken",
      }));
      dispatch(setMessageSnackbarStateState(true));
    } else {
      // console.log('Audience name available');
      setIsNameAvailable(true);
    }
  };

  const onSubmit = (values) => {
    // const audience = localStorage.getItem("audience")
    // let aud = JSON.parse(audience);
    const nameRegex = /^[a-zA-Z\s-]+$/;
    if (!nameRegex.test(values.name)) {
      dispatch(
        setMessage({
          type: "error",
          title: "Name must contain only letters, spaces, and hyphens",
        })
      );
      dispatch(setMessageSnackbarStateState(true));
      return;
    }

    if (!imageFile) {
      // console.log("please upload an image")
      dispatch(setMessage({
        type: "error",
        title: "please upload an image"
      }));
      dispatch(setMessageSnackbarStateState(true));
      return false;
    }

    const queryParams = [
      `audienceIdList=${audienceId}`,
      `countryId=${selectedCountry.id}`,
      `userId=${user.id}`,
      `audienceName=${values.name}`,
    ];
    const formData = new FormData();
    formData.append('file', imageFile);
    setIsSubmitting(true);
    AudienceService.mergeAudience(queryParams.join('&'), formData).then(
      (data) => {
        // console.log("data", data);
        setIsSubmitting(false);
        if (data?.results) {
          dispatch(setDefaultSearchKeywords([]));
          localStorage.removeItem("selectedSearchKeywords");
          navigate('/search');
          reset();
          handleClose();
        } else {
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
          setIsSubmitting(false);
        }
        return Promise.resolve();
      },
      (error) => {
        console.log(error);
        setIsSubmitting(false);

        if (error?.response?.data) {
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
        } else {
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
        }
        dispatch(setMessageSnackbarStateState(true));
        setIsSubmitting(false);
        return Promise.reject();
      }
    );
  };

  return (<>
    <Dialog
      open={cloneAudienceModalState}
      onClose={handleClose}
      onBackdropClick={handleBackdropClick}
      PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>Clone Your Audience</DialogTitle>
      <Divider />
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: 7.5 }}>
          <TextField
            InputProps={{
              endAdornment: isNameAvailable === true ? (
                <TickCircle size="22" color="green" variant='Bold' />
              ) : isNameAvailable === false ? (
                <CloseCircle size="22" color="red" variant='Bold' />
              ) : null,
            }}
            placeholder="Audience Name"
            InputLabelProps={{
              style: { color: "#6F7288" }
            }}
            focused
            id="crumbName"
            type="text"
            fullWidth
            variant="standard"
            sx={{ my: 2 }}
            error={!!errors['name']}
            helperText={errors['name'] ? errors['name'].message : ''}
            {...register("name", {
              required: "Name is required"
            })}
            onChange={handleChange}
          />

          <Box className="UploadProfilePhoto" sx={{ position: 'relative', borderRadius: '0px', overflow: 'hidden', mb: 2 }}>
            <Box sx={{ py: 2, height: "100px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ mr: 2, width: 30, height: 30 }}>
                <Image src={currentTheme === 'light' ? galleryAddBlack : galleryAdd} alt="" />
              </Box>
              <Box>{imageFileName ? imageFileName : "Upload an image (Max 2mb)"}</Box>
            </Box>
            <input
              style={{ zIndex: 2, display: 'block', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: '100%', height: '100%', opacity: 0, cursor: 'pointer' }}
              type="file"
              onChange={handleFileChange}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Apply Filter
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default CloneAudienceModal;