import { authApi } from "../interceptor/auth.interceptor";

class SnapService {


    static async Snap(queryParams) {
        return await authApi
            .post(`snap/code?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async snapAddAccount(queryParams) {
        return await authApi
            .get(`snap/add-account-details?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async snapOrganisationAccounts(queryParams) {
        return await authApi
            .get(`snap/organisation-details?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async changeAddAccount(queryParams) {
        return await authApi
            .put(`snap/update-add-account?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }

    static async changeBusinessAccount(queryParams) {
        return await authApi
            .put(`snap/update-organisation-account?${queryParams}`)
            .then((response) => {
                return response?.data;
            });
    }
}

export default SnapService;