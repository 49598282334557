import React from "react";
import { Marker } from 'react-map-gl';
// import { baseUrl } from "../../../config/config";
import ImageMarker from "./ImageMarker";

const ClusterdPoints = ({ clusters, supercluster, points, viewState, color = "#3955BF", setViewState, setPopupInfo, mapPoints, pointerState }) => {
  return (<>
    {clusters.map(cluster => {
      const [longitude, latitude] = cluster.geometry.coordinates;
      const {
        cluster: isCluster,
        point_count: pointCount
      } = cluster.properties;

      if (isCluster) {
        return (
          <Marker
            key={`cluster-${cluster.id}`}
            latitude={latitude}
            longitude={longitude}
          >
            <div
              className="cluster-marker"
              style={{
                width: `${40 + (pointCount / points.length) * 100}px`,
                height: `${40 + (pointCount / points.length) * 100}px`,
                background: `${color}bf`
              }}
              onClick={() => {
                const expansionZoom = Math.min(
                  supercluster.getClusterExpansionZoom(cluster.id),
                  20
                );

                setViewState(vs => ({
                  ...viewState,
                  latitude,
                  longitude,
                  zoom: expansionZoom,
                }));
              }}
            >
              {pointCount}
            </div>
          </Marker>
        );
      }

      return (
        <Marker
          key={`map-${cluster.properties.id}`}
          latitude={latitude}
          longitude={longitude}
        // onClick={(e) => {
        //   e.originalEvent.stopPropagation();
        //   const info = {
        //     latitude,
        //     longitude,
        //     info: cluster.properties
        //   }
        //   setPopupInfo(info);
        // }}
        >
          <ImageMarker
            cluster={cluster}
            latitude={latitude}
            longitude={longitude}
            setPopupInfo={setPopupInfo}
            mapPoints={mapPoints}
            pointerState={pointerState}
          />
        </Marker>
      );
    })}

    {/* <Marker
      key={`map-121212121212`}
      latitude={41.371431}
      longitude={-71.831196}
    >
      <img src={`${baseUrl + 'images/marker/BLUE.png'}`} alt="" />
    </Marker> */}
  </>);
};

export default ClusterdPoints;
