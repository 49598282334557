import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Collapse, Radio, Tabs, Tab, Typography, InputLabel, Select } from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material/styles';
import styles from './SearchFilter.module.scss';
import { useNavigate } from "react-router-dom";
import Slider from '@mui/material/Slider';
import Image from "mui-image";
import { useDispatch, useSelector } from "react-redux";
import Brightness1Icon from '@mui/icons-material/Brightness1';
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import HexagonOutlinedIcon from '@mui/icons-material/HexagonOutlined';

import {
  setCreateAudienceModalState,
  setMessageSnackbarStateState,
  setUploadAudienceModalState,
  setEnablePolygon } from "../../../../redux/slices/common";
import locationCircle from '../../../../assets/images/icons/svg/location-circle.svg';
import glasses from '../../../../assets/images/icons/svg/glasses.svg';
import { Export, Location, Setting4 } from "iconsax-react";
import LinearProgress from '@mui/material/LinearProgress';
import PoiService from "../../../../services/api/poi.service";
import { formatToGeoJson } from "../../../../utils/map";
import { setMapLoading, setMapPoints, setPolygonData } from "../../../../redux/slices/map";
import {
  setAudienceIdsList,
  setAudienceLimit,
  setDefaultSearchKeywords,
  setDefaultSliderValues,
  setEstimateAudiences,
  setFilterMoreGroups,
  setPartnerPOIList,
  setPlacesList,
  setPoiIdsList,
  setBrandIdList,
  setSliderValues,
  setTotalPlaces
} from "../../../../redux/slices/audience";
import AudienceService from "../../../../services/api/audience.service";
import { setMessage } from '../../../../redux/slices/message';
import { getPermission } from "../../../../utils/cryptograpgy";
import ActivateTopUpCreditModal from "../../modal/activate-top-credit-modal/ActivateTopUpCreditModal";
import StripeService from "../../../../services/api/stripe.service";

const PrettoSlider = styled(Slider)({
  color: '#fc6b21',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-mark': {
    width: '10px',
    height: '10px',
    borderRadius: '20px',
    transform: 'translate(-5px, -50%)',
  },
  '& .MuiSlider-markLabel': {
    color: '#6F7288',
    fontSize: '10px',
  },
  '& .MuiSlider-thumb': {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    border: '6px solid currentColor',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow: 'inherit',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    display: 'none',
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#fc6b21',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

function SearchFilter() {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dayParting, setDayParting] = useState([]);
  //const [isPOIDetails, setIsPOIDetails] = useState([]);

  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const mapPoints = useSelector(state => state.map.mapPoints);
  const { audienceLimit, totalPlaces, estimateAudiences, placesList, filterMoreGroups, defaultSliderValues, sliderValues, poiIdsList, audienceIdsList, partnerPOIList, brandIdList } = useSelector((state) => state.audience);
  const { currentTheme } = useSelector((state) => state.theme);
  const [filterResultOpen, setFilterResultOpen] = useState(true);
  const [filterMoreOptionOpen, setFilterMoreOptionOpen] = useState(true);
  const [isFilterLoading, setIsFilterLoading] = useState(false);
  const [isTotalPlacesLoading, setIsTotalPlacesLoading] = useState(false);
  const [isMergeFilter, setIsMergeFilter] = useState(false);
  const [colorIndex, setColorIndex] = useState(0);
  const { enablePolygon } = useSelector((state) => state.common);

  const [poiDetails, setPoiDetails] = useState(null);
  const [poiIdsListForFilter, setPoiIdsListForFilter] = useState("");

  const addCard = getPermission('add_card');
  const crumbList = JSON.parse(localStorage.getItem('crumbList'));
  const filteredCrumbList = crumbList.filter(crumb => crumb.isDemoCrumb === null || crumb.isDemoCrumb === false);

  const [activateTopUpCreditModalState, setActivateTopUpCreditModalState] = useState(false);
  //const planMode = user?.planMode || userFromLocalStorage?.planMode;

  const [ chooseFilter, setChooseFilter] = React.useState("distance");
  const polygonData = useSelector((state) => state.map.polygonData);
  const [value, setValue] = React.useState(polygonData ? 1 : 0);



  useEffect(() => {
    if (value === 0) {
      localStorage.removeItem('applyFilterParams');
    }
  }, [selectedCountry, user, value]);  
  
  useEffect(() => {
    let mounted = true;
    if (mounted) {
      loadAudiencByIds(audienceIdsList);
    }
  
    return () => {
      mounted = false;
    };
  }, [audienceIdsList, value, chooseFilter]);  
  
  useEffect(() => {
    let mounted = true;
    if (mounted && value === 0) {  
      loadPoiByIds(poiIdsList);
    }
  
    return () => {
      mounted = false;
    };
  }, [poiIdsList, value]);  
  
  useEffect(() => {
    let mounted = true;
    if (mounted && value === 0) {  
      if (partnerPOIList !== '') {
        loadPartnerPoiIds(partnerPOIList, '');
      } else if (brandIdList !== '') {
        loadPartnerPoiIds('', brandIdList);
      }
    }
  
    return () => {
      mounted = false;
    };
  }, [partnerPOIList, brandIdList, value]);  
  
  useEffect(() => {
    if (
      chooseFilter &&
      value === 1 &&
      (brandIdList?.length ||
      partnerPOIList?.length ||
      poiIdsList?.length)
    ) {
      loadPoiPolygon(brandIdList, partnerPOIList, poiIdsList);
    }
  }, [chooseFilter, poiIdsList, partnerPOIList, brandIdList, value]);  

  const handleChange = (event, newValue) => {
    if (newValue === 1) {
        dispatch(setEnablePolygon(true));
        setValue(newValue);
    } else {
      dispatch(setEnablePolygon(false));
      setValue(newValue);
    }
  };  
    
      const handleChooseFilter = (event) => {
        const newValue = event.target.value
        setChooseFilter(newValue);  
        };
      
  const poiRejectedIds = mapPoints
      .filter(point => point.properties.isEnabled && (point.properties.typeName === "poi" || point.properties.typeName === null || !point.properties.typeName))
      .map(point => point.properties.id);


  const locationRejectedIds = mapPoints
      .filter(point => point.properties.isEnabled && point.properties.typeName === "loc")
      .map(point => point.properties.id);

  const loadPoiByIds = (poiIdsList) => {

    //setIsPOIDetails(false);
    if (selectedCountry && user && poiIdsList) {
      // dispatch(setPoiIdsList(poiIds));
      dispatch(setMapLoading(true));
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const queryParams = [
        `poiId=${poiIdsList}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
      ];

      PoiService.loadPoi(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {

            if(data?.results.total > 100){
              console.log(data.results.total)
              dispatch(
                setMessage({
                  type: "error",
                  title: "Places limit exceeded for isochrones!",
                })
              );
              dispatch(setMessageSnackbarStateState(true));
            }

            dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

            const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
              if (group.title === 'Day Parting') {
                const defaultValues = group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);
                return defaultValues;

              } else {
                const defaultValue = group.options.find(option => option.isDefault === 'true');
                return defaultValue ? defaultValue.value : group.options[0].value;
              }

            });

            setDayParting([0, 1, 2, 3])
            dispatch(setDefaultSliderValues(defaultOptionValues));
            dispatch(setSliderValues(defaultOptionValues));

            const applyFilterParams = {
              visitPeriod: '',
              geoRadius: '',
              weekParting: '',
              dayParting: [],
              audiences: data.results.audience,
              audienceId: ''
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));
            dispatch(setEstimateAudiences(data.results.audience));
            dispatch(setTotalPlaces(data.results.total));
            dispatch(setPlacesList(data.results.places));

            setPoiDetails(data.results);
            const geojsonPoints = [];
            if (data.results?.places?.length) {
              const places = data.results.places;
              for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                const place = places[indexPlaces];
                if (place?.poiDetails?.length) {
                  const poiDetailsDtos = place.poiDetails;
                  for (let index = 0; index < poiDetailsDtos.length; index++) {
                    const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                    if (poiDetailsDto) {
                      geojsonPoints.push(poiDetailsDto);
                    }
                  }
                }
              }
            }
            dispatch(setMapPoints(geojsonPoints));           
          }
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);

          if (poiIdsList !== undefined) {
            const poiIdsArrayStrings = poiIdsList.split(",");
            const poiIdsArrayNumbers = poiIdsArrayStrings.map(Number);

            let audience = JSON.parse(localStorage.getItem("audience")) || {
              userId: user.id,
              countryId: selectedCountry.id,
              poiId: poiIdsArrayNumbers,
              visitPeriod: 180,
              geoRadius: 5000,
              weekParting: 2,
              dayParting: [0, 1, 2, 3].toString(),
              totalLocationPin: data.results.total,
            };

            // Update the values of the elements in the collection
            audience.poiId = poiIdsArrayNumbers;
            audience.visitPeriod = 180;
            audience.geoRadius = 5000;
            audience.weekParting = 2;
            audience.dayParting = [0, 1, 2, 3].toString();
            audience.totalLocationPin = data.results.total;

            // Store the updated collection back into localStorage
            localStorage.setItem("audience", JSON.stringify(audience));
          }

          return Promise.resolve();
        },
        (error) => {
          // console.log("error", error?.response?.data?.message);
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          setIsMergeFilter(false)
          dispatch(setMessageSnackbarStateState(true));
          navigate('/');
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          return Promise.reject();
        }
      );
    }
  };

  const loadPartnerPoiIds = (partnerPOIList, brandIdList) => {
    //setIsPOIDetails(true);

    if (selectedCountry && user) {
      // dispatch(setPoiIdsList(poiIds));
      dispatch(setMapLoading(true));
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      dispatch(setPoiIdsList(''));
      const queryParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `isPOIDetailsId=true`
      ];

      if (partnerPOIList !== '') {

        if (partnerPOIList[0].categoryName !== '') {
          queryParams.push(`category=${partnerPOIList[0].categoryName}`);
        }

        // if (partnerPOIList[0].cityName !== '') {
        //   queryParams.push(`city=${partnerPOIList[0].cityName}`);
        // }
      } else {

        queryParams.push(`brandIdList=${brandIdList}`);
      }

      PoiService.loadPoi(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {

            if(data?.results.total > 100){
              console.log(data.results.total)
              dispatch(
                setMessage({
                  type: "error",
                  title: "Places limit exceeded for isochrones!",
                })
              );
              dispatch(setMessageSnackbarStateState(true));
            }
            dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

            if (partnerPOIList === '') {

              setColorIndex(data.results.colorName);
            }
            const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
              if (group.title === 'Day Parting') {
                return group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);

              } else {
                const defaultValue = group.options.find(option => option.isDefault === 'true');
                return defaultValue ? defaultValue.value : group.options[0].value;
              }

            });

            setDayParting([0, 1, 2, 3])
            dispatch(setDefaultSliderValues(defaultOptionValues));
            dispatch(setSliderValues(defaultOptionValues));

            const applyFilterParams = {
              visitPeriod: '',
              geoRadius: '',
              weekParting: '',
              dayParting: [],
              audiences: data.results.audience,
              audienceId: '',
              //city: data.results.city,
              category: data.results.category,
              isPOIDetailsId: data.results.isPOIDetailsId,
              brandIdList: data.results.brandIdList
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));
            dispatch(setEstimateAudiences(data.results.audience));
            dispatch(setTotalPlaces(data.results.total));
            dispatch(setPlacesList(data.results.places));

            setPoiDetails(data.results);
            const geojsonPoints = [];
            if (data.results?.places?.length) {
              const places = data.results.places;
              for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                const place = places[indexPlaces];
                if (place?.poiDetails?.length) {
                  const poiDetailsDtos = place.poiDetails;
                  for (let index = 0; index < poiDetailsDtos.length; index++) {
                    const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                    if (poiDetailsDto) {
                      geojsonPoints.push(poiDetailsDto);
                    }
                  }
                }
              }
            }
            dispatch(setMapPoints(geojsonPoints));
          }
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);

          if (poiIdsList !== undefined) {
            const poiIdsArrayStrings = poiIdsList.split(",");
            const poiIdsArrayNumbers = poiIdsArrayStrings.map(Number);

            let audience = JSON.parse(localStorage.getItem("audience")) || {
              userId: user.id,
              countryId: selectedCountry.id,
              poiId: poiIdsArrayNumbers,
              visitPeriod: 180,
              geoRadius: 5000,
              weekParting: 2,
              dayParting: [0, 1, 2, 3].toString(),
              totalLocationPin: data.results.total,
            };

            // Update the values of the elements in the collection
            audience.poiId = poiIdsArrayNumbers;
            audience.visitPeriod = 180;
            audience.geoRadius = 5000;
            audience.weekParting = 2;
            audience.dayParting = [0, 1, 2, 3].toString();
            audience.totalLocationPin = data.results.total;

            // Store the updated collection back into localStorage
            localStorage.setItem("audience", JSON.stringify(audience));
          }
          setIsMergeFilter(false)
          return Promise.resolve();
        },
        (error) => {
          // console.log("error", error?.response?.data?.message);
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          navigate('/');
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          return Promise.reject();
        }
      );
    }
  };


  const loadPoiPolygon = (brandIdList, partnerPOIList, poiIdsList) => {
    if (selectedCountry && user) {
      dispatch(setMapLoading(true));
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const queryParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `rejectedPOIList=${poiRejectedIds}`,
        `rangeType=${chooseFilter}`,
      ];
        if (brandIdList){
          queryParams.push(`brandIdList=${brandIdList}`);
          queryParams.push(`isPOIDetailsId=true`);
        } else if (poiIdsList){
          queryParams.push(`poiId=${poiIdsList}`);
        } else {
          queryParams.push(`category=${partnerPOIList[0]?.categoryName}`);
          queryParams.push(`isPOIDetailsId=true`);
        }

      PoiService.loadPolygon(queryParams.join('&')).then(
          (data) => {
            if (data?.results) {
              dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

              if (partnerPOIList === '') {

                setColorIndex(data.results.colorName);
              }
              const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
                if (group.title === 'Day Parting') {
                  return group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);

                } else {
                  const defaultValue = group.options.find(option => option.isDefault === 'true');
                  return defaultValue ? defaultValue.value : group.options[0].value;
                }

              });

              setDayParting([0, 1, 2, 3])
              dispatch(setDefaultSliderValues(defaultOptionValues));
              dispatch(setSliderValues(defaultOptionValues));

              const applyFilterParams = {
                visitPeriod: '',
                geoRadius: '',
                weekParting: '',
                dayParting: [],
                audiences: data.results.audience,
                audienceId: '',
                //city: data.results.city,
                category: data.results.category,
                isPOIDetailsId: data.results.isPOIDetailsId,
                brandIdList: data.results.brandIdList
              };

              localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));
              dispatch(setEstimateAudiences(data.results.audience));
              dispatch(setTotalPlaces(data.results.total));
              dispatch(setPlacesList(data.results.places));

              setPoiDetails(data.results);
              const geojsonPoints = [];
              if (data.results?.places?.length) {
                const places = data.results.places;
                for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                  const place = places[indexPlaces];
                  if (place?.poiDetails?.length) {
                    const poiDetailsDtos = place.poiDetails;
                    for (let index = 0; index < poiDetailsDtos.length; index++) {
                      const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                      if (poiDetailsDto) {
                        geojsonPoints.push(poiDetailsDto);
                      }
                    }
                  }
                }
              }
              dispatch(setMapPoints(geojsonPoints));
              dispatch(setPolygonData(data.results?.isoChronData));
            }
            dispatch(setMapLoading(false));
            setIsFilterLoading(false);
            setIsTotalPlacesLoading(false);

            if (poiIdsList !== undefined) {
              const poiIdsArrayStrings = poiIdsList.split(",");
              const poiIdsArrayNumbers = poiIdsArrayStrings.map(Number);

              let audience = JSON.parse(localStorage.getItem("audience")) || {
                userId: user.id,
                countryId: selectedCountry.id,
                poiId: poiIdsArrayNumbers,
                visitPeriod: 180,
                geoRadius: 5000,
                weekParting: 2,
                dayParting: [0, 1, 2, 3].toString(),
                totalLocationPin: data.results.total,
              };

              // Update the values of the elements in the collection
              audience.poiId = poiIdsArrayNumbers;
              audience.visitPeriod = 180;
              audience.geoRadius = 5000;
              audience.weekParting = 2;
              audience.dayParting = [0, 1, 2, 3].toString();
              audience.totalLocationPin = data.results?.total;

              // Store the updated collection back into localStorage
              localStorage.setItem("audience", JSON.stringify(audience));
            }
            setIsMergeFilter(false)
            return Promise.resolve();
          },
          (error) => {
            dispatch(setMessage({
              type: "error",
              title: error?.message
            }));
            dispatch(setMessageSnackbarStateState(true));
            dispatch(setMapLoading(false));
            setIsFilterLoading(false);
            setIsTotalPlacesLoading(false);
            dispatch(setPolygonData(''));
            setValue(0);
            return Promise.reject();
          }
      );
    }
  };

  const loadAudiencByIds = (audienceIdsList) => {
    //setIsPOIDetails(false);
    if (selectedCountry && user && audienceIdsList) {
      // dispatch(setAudienceIdsList(audienceIds));
      dispatch(setPoiIdsList(""));
      dispatch(setMapLoading(true));
      const queryParams = [
        `audienceIdList=${audienceIdsList.audienceList}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `action=${audienceIdsList.action ? audienceIdsList.action : ''}`,
       `rejectedPOIList=${poiRejectedIds}`,
        //`rejectedPOIList=${poiRejectedIds}`
      ];
      if(audienceIdsList.isPolygon && value === 1){
        queryParams.push(`isPolygon=${audienceIdsList.isPolygon}`)
      }
      if (value === 1) {
        queryParams.push(`isPolygon=true`); 
        queryParams.push(`rangeType=${chooseFilter}`);
        // queryParams.push(`distance=500`);
      }
      //`poiIdList=${audienceIdsList.poiList}`,
      if(audienceIdsList.brandList) {

        queryParams.push(`brandIdList=${audienceIdsList.brandList}`);
      }

      if (audienceIdsList.poiList) {

        queryParams.push(`poiIdList=${audienceIdsList.poiList}`);
      }

      AudienceService.mergeAudience(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {

            if(data?.results.total > 100){
              console.log(data.results.total)
              dispatch(
                setMessage({
                  type: "error",
                  title: "Places limit exceeded for isochrones!",
                })
              );
              dispatch(setMessageSnackbarStateState(true));
            }
            
            const dataResults = data.results;
            setColorIndex(data.results.colorName);

            if (dataResults.action === "clone") {
              try {
                // Split the string by commas and convert to an array of strings
                const stringArray = dataResults.dayParting.split(",");

                // Convert the array of strings to an array of integers
                const integerArray = stringArray.map((str) => parseInt(str, 10));

                // Set the integer array as dayParting
                setDayParting(integerArray);
              } catch (error) {
                // Handle parsing errors if necessary
                console.error("Error parsing dayParting:", error);
              }
            }
            else {
              setDayParting([0, 1, 2, 3])
            }


            setPoiDetails(data.results);
            dispatch(setFilterMoreGroups(data.results.audienceFilterConfig.config));

            const applyFilterParams = {
              visitPeriod: dataResults.action === "clone" ? dataResults.visitPeriod : '',
              geoRadius: dataResults.action === "clone" ? dataResults.geoRadius : '',
              weekParting: dataResults.action === "clone" ? dataResults.weekParting : '',
              dayParting: dataResults.action === "clone" ? dataResults.dayParting : '',
              audiences: dataResults.audience,
              audienceId: '',
              brandIdList: data.results.brandIdList,
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

            const defaultOptionValues = data.results.audienceFilterConfig.config.map(group => {
              if (group.title === 'Day Parting') {
                const defaultValues = group.options.filter((option) => option.isDefault === 'true').map((option) => option.value);
                return defaultValues;

              } else {
                const defaultValue = group.options.find(option => option.isDefault === 'true');
                return defaultValue ? defaultValue.value : group.options[0].value;
              }

            });

            dispatch(setDefaultSliderValues(defaultOptionValues));
            dispatch(setSliderValues(defaultOptionValues));

            dispatch(setEstimateAudiences(data.results.audience));
            dispatch(setTotalPlaces(data.results.total));

            setPoiIdsListForFilter(data.results.totalPOIIdList.join(','));

            let allPlaces = [];
            let allAudiences = [];
            if (data.results?.audiences?.length) {
              const audiences = data.results.audiences;
              for (let indexAudiences = 0; indexAudiences < audiences.length; indexAudiences++) {
                const audience = audiences[indexAudiences];
                allAudiences.push({
                  ...audience,
                  placeName: audience.name,
                  count: audience.totalLocationPin
                });
                if (audience?.places?.length) {
                  const places = audience.places;
                  for (let index = 0; index < places.length; index++) {
                    allPlaces.push(places[index]);
                  }
                }
              }

              const geojsonPoints = [];
              if (allPlaces?.length) {
                const places = allPlaces;
                for (let indexPlaces = 0; indexPlaces < places.length; indexPlaces++) {
                  const place = places[indexPlaces];
                  if (place?.poiDetails?.length) {
                    const poiDetailsDtos = place.poiDetails;
                    for (let index = 0; index < poiDetailsDtos.length; index++) {
                      const poiDetailsDto = formatToGeoJson(poiDetailsDtos[index], place.colorName, place.colorValue);
                      if (poiDetailsDto) {
                        geojsonPoints.push(poiDetailsDto);
                      }
                    }
                  }
                }
              }
              // console.log("geojsonPoints", geojsonPoints);
              dispatch(setMapPoints(geojsonPoints));
              dispatch(setPolygonData(data.results?.isoChroneResponse??''));

            }
            dispatch(setPlacesList(allAudiences));
          }
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          setIsMergeFilter(true)
          return Promise.resolve();
        },
        (error) => {
          // console.log("error", error?.response?.data?.message);
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          navigate('/');
          dispatch(setMapLoading(false));
          setIsFilterLoading(false);
          setIsTotalPlacesLoading(false);
          dispatch(setPolygonData(''));
          return Promise.reject();
        }
      );
    }
  };

  const handleUploadAudience = () => {
    dispatch(setUploadAudienceModalState(true));
  };

  const handleSaveAudience = async () => {

    if(user.isExternalUser || user.isInternalUser) {

      try {
        const queryCalcParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];

        const data = await StripeService.getUserPlanDetails(queryCalcParams.join('&'));

        if (data?.results) {

          // Ensure the state is updated before proceeding
          if (user.isExternalUser || user.isInternalUser) {

          if (data.results.limitExceeded) {

              setActivateTopUpCreditModalState(true);
              return;
            }
          }
        }
      } catch (error) {
        // Handle the error appropriately
        console.error('Error fetching user plan details:', error);
      }
    }

    if (filteredCrumbList?.length > 0) {
      dispatch(setCreateAudienceModalState(true));
    } else {
      dispatch(
          setMessage({
            type: "error",
            title: "Please create a crumb first",
          })
      );
      dispatch(setMessageSnackbarStateState(true));
    }
  };

  const handleCancelAcudience = () => {
    localStorage.removeItem("selectedSearchKeywords");
    dispatch(setDefaultSearchKeywords([]));
    dispatch(setPoiIdsList(''));
    dispatch(setBrandIdList(''));
    dispatch(setAudienceIdsList(''));
    dispatch(setPolygonData(''));
    dispatch(setEnablePolygon(false));
    //setIsPOIDetails(false);
    navigate('/');
  };

  const handleSearchFilterPlacesButton = () => {
    setFilterResultOpen(!filterResultOpen);
    setFilterMoreOptionOpen(false);
  }

  const handleSearchFilterMoreButton = () => {
    setFilterMoreOptionOpen(!filterMoreOptionOpen);
  }

  const handleFilters = (sliderValues, isSave, isMergeFilter, value) => {
    if (value === 1 && !isMergeFilter) {
      handlePolygonFilter(sliderValues, isSave, chooseFilter); 
      return;
    }

    if (isMergeFilter) {
      mergeHandleApplyFilters(sliderValues, isSave);
    } else {
      console.log(isMergeFilter,"else")
      handleApplyFilters(sliderValues, isSave);
    }
  };

  const [loading, setLoading] = useState(false);
  // const [estimateAudiences, setEstimateAudiences] = useState(null);

  const handlePolygonFilter = (sliderValues, isSave, chooseFilter) => {
    
    const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));

    let isPOIDetails = 'isPOIDetailsId' in applyFilterParams ? applyFilterParams.isPOIDetailsId : false;

    if (user && selectedCountry && (poiIdsList || isPOIDetails)) {
      setLoading(true);
      const queryParams = [
        `visitPeriod=${sliderValues[2]}`,
        `weekParting=${sliderValues[3]}`,
        `dayParting=${sliderValues[4].toString()}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `rejectedPOIList=${poiRejectedIds}`
      ]

      if (chooseFilter) {
        queryParams.push(`rangeType=${chooseFilter}`);
      
        if (chooseFilter === "distance") {
          // let distanceValue = sliderValues[0];
        
          // if (distanceValue === 1400) {
          //   distanceValue = 1000;
          // } else if (distanceValue === 900) {
          //   distanceValue = 500;
          // } else if (distanceValue === 500) {
          //   distanceValue = 200;
          // }
        
          queryParams.push(`distance=${sliderValues[0]}`);
        } else if (chooseFilter === "time") {
          queryParams.push(`time=${sliderValues[1]}`);
        }
        
      }      

      if (isPOIDetails) {

        if (applyFilterParams.category !== null) {
          queryParams.push(`category=${applyFilterParams.category}`);
        }

        if (applyFilterParams.brandIdList !== null) {
          queryParams.push(`brandIdList=${applyFilterParams.brandIdList}`);
        }

        queryParams.push(`isPOIDetailsId=${isPOIDetails}`);

      }

      if (poiIdsList !== '') {
          queryParams.push(`poiId=${poiIdsList}`);
        }


      PoiService.applyPolygonFilter(queryParams.join('&'))
        .then((response) => {
          const resultData = response?.results;
          if (resultData) {
            localStorage.setItem("audience", JSON.stringify(resultData));
            const applyFilterParams = {
              visitPeriod: resultData.visitPeriod,
              weekParting: resultData.weekParting,
              dayParting: resultData.dayParting,
              audiences: resultData.audience,
              audienceId: '',
              category: resultData.category,
              isPOIDetailsId: resultData.isPOIDetailsId,
              brandIdList: resultData.brandIdList
            };
            
            // if (chooseFilter) {
            //   applyFilterParams.push(`rangeType=${chooseFilter}`);
            
            //   if (chooseFilter === "distance") {              
            //     applyFilterParams.push(`distance=${resultData.distance}`);
            //   } else if (chooseFilter === "time") {
            //     applyFilterParams.push(`time=${resultData.time}`);
            //   }
              
            // } 

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

            dispatch(setEstimateAudiences(resultData.audience));
            dispatch(setAudienceLimit(resultData.audienceLimit ? resultData.audienceLimit : 0));
            dispatch(setPolygonData(resultData?.isoChroneResponse));

            // if (resultData.audience < resultData.audienceLimit) {
            //   isSave = false;
            // }
          }
        })
        .catch(error => {
          isSave = false;
          // Handle any errors that occur during the request
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          dispatch(setEstimateAudiences(0));
        })
        .finally(() => {
          setLoading(false); // set loading state to false after the API call completes
          if (isSave) {
            handleSaveAudience();
          }
        });
    }
  }

  const handleApplyFilters = (sliderValues, isSave) => {
    // const poiIds = searchParams.get('poiIds');
    // console.log("slider val", sliderValues)

    const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));

    let isPOIDetails = 'isPOIDetailsId' in applyFilterParams ? applyFilterParams.isPOIDetailsId : false;

    if (user && selectedCountry && (poiIdsList || isPOIDetails)) {
      setLoading(true);
      // const dayPartingOptions = [1, 2, 3, 0];
      // const selectedDayParting = dayPartingOptions[sliderValues[3]];
      const queryParams = [
        `visitPeriod=${sliderValues[0]}`,
        `geoRadius=${sliderValues[1]}`,
        `weekParting=${sliderValues[2]}`,
        `dayParting=${sliderValues[3].toString()}`,
        // `dayParting=${dayParting.toString()}`,
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `rejectedPOIList=${poiRejectedIds}`
      ]

      if (isPOIDetails) {

        if (applyFilterParams.category !== null) {
          queryParams.push(`category=${applyFilterParams.category}`);
        }

        // if (applyFilterParams.city !== null) {
        //   queryParams.push(`city=${applyFilterParams.city}`);
        // }

        if (applyFilterParams.brandIdList !== null) {
          queryParams.push(`brandIdList=${applyFilterParams.brandIdList}`);
        }

        queryParams.push(`isPOIDetailsId=${isPOIDetails}`);

      }

      if (poiIdsList !== '') {
          queryParams.push(`poiId=${poiIdsList}`);
        }


      PoiService.applyFilter(queryParams.join('&'))
        .then((response) => {
          const resultData = response?.results;
          // console.log("applyFilter", resultData);
          if (resultData) {
            localStorage.setItem("audience", JSON.stringify(resultData));
            const applyFilterParams = {
              visitPeriod: resultData.visitPeriod,
              geoRadius: resultData.geoRadius,
              weekParting: resultData.weekParting,
              dayParting: resultData.dayParting,
              audiences: resultData.audience,
              audienceId: '',
              //city: resultData.city,
              category: resultData.category,
              isPOIDetailsId: resultData.isPOIDetailsId,
              brandIdList: resultData.brandIdList
            };

            localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

            // if (response.results.audience < 3000) {
            //   {
            //     dispatch(setMessage({
            //       type: "error",
            //       title: "Low audiences",
            //     }))
            //   }
            //   { dispatch(setMessageSnackbarStateState(true)) }
            // }
            dispatch(setEstimateAudiences(resultData.audience));
            dispatch(setAudienceLimit(resultData.audienceLimit ? resultData.audienceLimit : 0));

            if (resultData.audience < resultData.audienceLimit) {
              isSave = false;
            }
          }
        })
        .catch(error => {
          isSave = false;
          // Handle any errors that occur during the request
          dispatch(
            setMessage({
              type: "error",
              title: error?.response?.data?.message,
            })
          );
          dispatch(setMessageSnackbarStateState(true));
          dispatch(setEstimateAudiences(0));
        })
        .finally(() => {
          setLoading(false); // set loading state to false after the API call completes
          if (isSave) {
            handleSaveAudience();
          }
        });
    }
  }

  const mergeHandleApplyFilters = (sliderValues, isSave) => {
    // const poiIds = searchParams.get('poiIds');
    // console.log("slider val", sliderValues)

    const applyFilterParams = JSON.parse(localStorage.getItem('applyFilterParams'));

    if (user && selectedCountry && (poiIdsListForFilter) || applyFilterParams.brandIdList !== null) {
      setLoading(true);
      // const dayPartingOptions = [1, 2, 3, 0];
      // const selectedDayParting = dayPartingOptions[sliderValues[3]];
      const queryParams = [
        `userId=${user.id}`,
        `countryId=${selectedCountry.id}`,
        `rejectedPOIList=${poiRejectedIds}`,
        `rejectedLocationList=${locationRejectedIds}`
      ]

      if (enablePolygon) {
         queryParams.push(`visitPeriod=${sliderValues[2]}`);
         queryParams.push(`weekParting=${sliderValues[3]}`);
         queryParams.push(`dayParting=${sliderValues[4].toString()}`);
        queryParams.push(`isPolygon=true`)
        queryParams.push(`rangeType=${chooseFilter}`);
      
        if (chooseFilter === "distance") {
          queryParams.push(`distance=${sliderValues[0]}`);
        } else if (chooseFilter === "time") {
          queryParams.push(`time=${sliderValues[1]}`);
        }
      }  else {
        queryParams.push(`visitPeriod=${sliderValues[0]}`);
        queryParams.push(`geoRadius=${sliderValues[1]}`);
        queryParams.push(`weekParting=${sliderValues[2]}`);
        queryParams.push(`dayParting=${sliderValues[3].toString()}`);
      }

      if (applyFilterParams.brandIdList !== null) {
        queryParams.push(`brandIdList=${applyFilterParams.brandIdList}`);
      }

      if (poiIdsListForFilter !== '') {
        queryParams.push(`poiId=${poiIdsListForFilter}`);
      }

      PoiService.mergeApplyFilter(queryParams.join('&'))
          .then((response) => {
            const resultData = response?.results;
            // console.log("applyFilter", resultData);
            if (resultData) {
              localStorage.setItem("audience", JSON.stringify(resultData));
              const applyFilterParams = {
                visitPeriod: resultData.visitPeriod,
                geoRadius: resultData.geoRadius,
                weekParting: resultData.weekParting,
                dayParting: resultData.dayParting,
                audiences: resultData.audience,
                audienceId: '',
                isPOIDetailsId: resultData.isPOIDetailsId,
                brandIdList: resultData.brandIdList
              };

              localStorage.setItem('applyFilterParams', JSON.stringify(applyFilterParams));

              // if (response.results.audience < 3000) {
              //   {
              //     dispatch(setMessage({
              //       type: "error",
              //       title: "Low audiences",
              //     }))
              //   }
              //   { dispatch(setMessageSnackbarStateState(true)) }
              // }
              dispatch(setEstimateAudiences(resultData.audience));
              dispatch(setAudienceLimit(resultData.audienceLimit ? resultData.audienceLimit : 0));
              dispatch(setPolygonData(resultData?.isoChroneResponse));
              
              if (resultData.audience < resultData.audienceLimit) {
                isSave = false;
              }
            }
          })
          .catch(error => {
            isSave = false;
            // Handle any errors that occur during the request
            dispatch(
                setMessage({
                  type: "error",
                  title: error?.response?.data?.message,
                })
            );
            dispatch(setMessageSnackbarStateState(true));
            dispatch(setEstimateAudiences(0));
          })
          .finally(() => {
            setLoading(false); // set loading state to false after the API call completes
            if (isSave) {
              handleSaveAudience();
            }
          });
     }
    }


  const handleDeletePlaceKeywords = (place) => {
    // console.log("handleDeletePlaceKeywords", place);
    // console.log("audienceIdsList", audienceIdsList.audienceList);
    // console.log("poiIdsList", poiIdsList);

    if (audienceIdsList && place?.name) {
      // console.log("audienceIdsList", audienceIdsList)
      setIsFilterLoading(true);
      setIsTotalPlacesLoading(true);
      dispatch(setPoiIdsList(''));
      const selectedSearchKeywords = localStorage.getItem("selectedSearchKeywords") ? JSON.parse(localStorage.getItem("selectedSearchKeywords")) : [];
      // console.log("selectedSearchKeywords", selectedSearchKeywords);
      const selectedSearchFilteredKeywords = selectedSearchKeywords.filter(s => s.name !== place.placeName);
      // const selectedAudienceIds = audienceIdsList.audienceList.split(',');
      // const selectedFilteredAudienceIds = selectedAudienceIds.filter(s => parseInt(s) !== place.placeName);
      // console.log("selectedSearchFilteredKeywords", selectedSearchFilteredKeywords);
      if (selectedSearchFilteredKeywords?.length) {
        localStorage.setItem("selectedSearchKeywords", JSON.stringify(selectedSearchFilteredKeywords));
        // console.log(selectedSearchFilteredKeywords)
        dispatch(setDefaultSearchKeywords(selectedSearchFilteredKeywords));
        // const filteredAudienceIds = selectedFilteredAudienceIds.join(',');
        // console.log(filteredAudienceIds)

        let poiIds = [];
        let audienceIds = [];

        selectedSearchFilteredKeywords.forEach(item => {
          if (item.type === 'poi' || item.type === 'user_poi') {
            poiIds.push(item.id);
          } else {
            audienceIds.push(item.id);
          }
        });

        const mergeList = {
          poiList: poiIds,
          audienceList: audienceIds
        };

        // console.log("poiList", poiIds, "audienceList", audienceIds)
        if (poiIds || audienceIds) {
          dispatch(setAudienceIdsList(mergeList));
        }
        // dispatch(setAudienceIdsList(filteredAudienceIds));
        // loadAudiencByIds(filteredAudienceIds);
      } else {
        localStorage.removeItem("selectedSearchKeywords");
        dispatch(setDefaultSearchKeywords([]));
        dispatch(setAudienceIdsList(''));
        navigate('/');
      }
    }

    if (poiIdsList) {
      // console.log("poiIdsList", poiIdsList)
      setIsFilterLoading(true);
      setIsTotalPlacesLoading(true);
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const selectedPoiIds = poiIdsList.split(',');
      const selectedFilteredPoiIds = selectedPoiIds.filter(s => parseInt(s) !== place.id);
      // console.log("selectedSearchFilteredKeywords", selectedFilteredPoiIds);
      if (selectedFilteredPoiIds?.length) {
        // navigate(`/search?poiIds=${selectedFilteredPoiIds.join(',')}`);
        const filteredPoiIds = selectedFilteredPoiIds.join(',');
        dispatch(setPoiIdsList(filteredPoiIds));
        // loadPoiByIds(filteredPoiIds);
      } else {
        dispatch(setPoiIdsList(''));
        navigate('/');
      }
    }

    if (brandIdList) {
      // console.log("poiIdsList", poiIdsList)
      setIsFilterLoading(true);
      setIsTotalPlacesLoading(true);
      localStorage.removeItem("selectedSearchKeywords");
      dispatch(setDefaultSearchKeywords([]));
      dispatch(setAudienceIdsList(''));
      const selectedBrandIds = brandIdList.split(',');
      const selectedFilteredBrandIds = selectedBrandIds.filter(s => parseInt(s) !== place.id);
      // console.log("selectedSearchFilteredKeywords", selectedFilteredPoiIds);
      if (selectedFilteredBrandIds?.length) {
        // navigate(`/search?poiIds=${selectedFilteredPoiIds.join(',')}`);
        const filteredBrandIds = selectedFilteredBrandIds.join(',');
        dispatch(setBrandIdList(filteredBrandIds));
        // loadPoiByIds(filteredPoiIds);
      } else {
        dispatch(setBrandIdList(''));
        navigate('/');
      }
    }
  }

  const handleCheckboxChange = (sectionIndex, value) => {
    const updatedDayParting = dayParting.includes(value) ? dayParting.filter((item) => item !== value) : [...dayParting, value];
    setDayParting(updatedDayParting.sort((a, b) => a - b));

    const sliderValuesDummy = [...sliderValues];
    sliderValuesDummy[sectionIndex] = [updatedDayParting];
    dispatch(setSliderValues(sliderValuesDummy));

    const updatedFilterMoreGroups = filterMoreGroups.map((group) => {
      if (group.title === "Day Parting") {
        return {
          ...group,
          options: group.options.map((option, optionIndex) => ({
            ...option,
            isDefault: updatedDayParting.includes(optionIndex).toString(),
          })),
        };
      } else {
        return group; // Keep other groups unchanged
      }
    });

    dispatch(setFilterMoreGroups(updatedFilterMoreGroups));
  };

  const handleRadioChange = (sectionIndex, value) => {
    const sliderValuesDummy = [...sliderValues];
    sliderValuesDummy[sectionIndex] = value;
    dispatch(setSliderValues(sliderValuesDummy));

    const updatedFilterMoreGroups = filterMoreGroups.map((group) => {
      if (group.title === "Week Parting") {
        return {
          ...group,
          options: group.options.map((option) => ({
            ...option,
            isDefault: option.value === value ? 'true' : 'false',
          })),
        };
      } else {
        return group; // Keep other groups unchanged
      }
    });

    dispatch(setFilterMoreGroups(updatedFilterMoreGroups));
  };

  const labelAndIconColor = currentTheme === "light" ? "#000000" : "#FFFFFF";

  return (<>
    <Box className={`${styles['theme-' + currentTheme]} `}>
      {poiDetails &&
        <Box className={`${styles.SearchFilter ?? ''}`} sx={{ position: 'relative' }}>

          <Button className={`${styles.SearchFilterPlacesButton ?? ''}`} sx={{ p: 2.5, width: '100%', minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={handleSearchFilterPlacesButton}>
            <Box className={`${styles.SearchFilterPlacesButtonContent ?? ''}`} sx={{ display: 'flex', width: 'calc(100% - 40px)' }}>
              <Box className={`${styles.SearchFilterPlacesButtonIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, mr: 1.2, borderRadius: '50%', overflow: 'hidden', backgroundColor: '#FFF0E6' }}>
                <Image src={locationCircle} style={{ width: 18, height: 18 }} />
              </Box>
              <Box className={`${styles.SearchFilterPlacesButtonTexts ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                <Box className={`${styles.SearchFilterPlacesButtonTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', mb: 0.5, textTransform: 'capitalize' }}>Placess</Box>
                {isTotalPlacesLoading ?
                  <LinearProgress />
                  :
                  <Box className={`${styles.SearchFilterPlacesButtonValue ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontSize: 14, fontWeight: '500', lineHeight: '17px' }}>{totalPlaces}</Box>
                }
              </Box>
            </Box>
            <Box className={`${styles.SearchFilterPlacesArrow ?? ''}`} sx={{ display: 'flex' }}>
              {filterResultOpen &&
                <KeyboardArrowUpOutlinedIcon sx={{ width: 25, height: 25 }} />
              }
              {!filterResultOpen &&
                <KeyboardArrowDownIcon sx={{ width: 25, height: 25 }} />
              }
            </Box>
          </Button>

          <Box className={`${styles.SearchFilterWrapperArea ?? ''}`} sx={{ position: 'relative', overflow: 'hidden', overflowY: 'auto' }}>
            {isFilterLoading &&
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', width: '100%', position: 'absolute', left: 0, top: 0, zIndex: 1 }}></Box>
            }

            <Collapse in={filterResultOpen}>
              <Box className={`${styles.SearchFilterResultListArea ?? ''}`} sx={{ position: 'relative' }}>
                {placesList?.length > 0 &&
                  <Box className={`${styles.SearchFilterResultList ?? ''}`} sx={{ position: 'relative', p: 2.5 }}>
                    <Box className={`${styles.SearchFilterResultListTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', textTransform: 'capitalize', mb: 1.5 }}>Places</Box>
                    {placesList.map((place, index) => {
                      return (
                        <Box className={`${styles.SearchFilterResult ?? ''}`} sx={{ width: '100%', minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }} key={index}>
                          <Box className={`${styles.SearchFilterResultContent ?? ''}`} sx={{ display: 'flex', width: 'calc(100% - 40px)' }}>
                            <Box className={`${styles.SearchFilterResultIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, mr: 1.2, borderRadius: '50%', overflow: 'hidden' }}>
                              {/* <Image src={locationCircle} style={{ width: 18, height: 18 }} /> */}
                              <Location style={{ color: "secondary" }} />
                            </Box>
                            <Box className={`${styles.SearchFilterResultTexts ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                              <Box className={`${styles.SearchFilterResultTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', mb: 0.5, textTransform: 'capitalize' }}>{place.placeName}</Box>
                              <Box className={`${styles.SearchFilterResultValue ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontSize: 14, fontWeight: '500', lineHeight: '17px', color: "secondary" }}>{place.count} Places</Box>
                            </Box>
                          </Box>
                          <Button className={`${styles.SearchResultDeleteButton ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 20, height: 20, minWidth: 'initial', borderRadius: '50%' }} onClick={() => handleDeletePlaceKeywords(place)}>
                            <CloseIcon sx={{ width: 12, height: 12 }} />
                          </Button>
                        </Box>
                      )
                    })}
                  </Box>
                }

                <Box className={`${styles.SearchAudienceResultList ?? ''}`} sx={{ position: 'relative', px: 2.5, py: 1 }}>

                  <Box className={`${styles.SearchAudienceResult ?? ''}`} sx={{ width: '100%', minWidth: 'initial', display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                    <Box className={`${styles.SearchAudienceResultContent ?? ''}`} sx={{ display: 'flex', width: 'calc(100% - 40px)' }}>
                      <Box className={`${styles.SearchAudienceResultIcon ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 32, height: 32, mr: 1.2, borderRadius: '50%', overflow: 'hidden', backgroundColor: '#E5F7FF' }}>
                        <Image src={glasses} style={{ width: 18, height: 18 }} />
                      </Box>
                      <Box className={`${styles.SearchAudienceResultTexts ?? ''}`} sx={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 40px)' }}>
                        <Box className={`${styles.SearchAudienceResultTitle ?? ''}`} sx={{ display: 'flex', fontSize: 10, fontWeight: '400', lineHeight: '13px', mb: 0.5, textTransform: 'capitalize' }}>Est Addressable Audience {sliderValues[0] ? -(sliderValues[0]) + 'd' : 0}</Box>
                        {loading ? (
                          <LinearProgress />
                        ) : (
                          <Box className={`${styles.SearchFilterResultValue ?? ''} ff-tomorrow`} sx={{ display: 'flex', fontSize: 14, fontWeight: '500', lineHeight: '17px' }}>
                            {
                             estimateAudiences? estimateAudiences.toLocaleString():'0'
                            }
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>

                </Box>


                <Box className={`${styles.SearchFilterActions ?? ''}`} sx={{ position: 'relative', display: 'flex' }}>
                  <Button className={`${styles.SearchFilterAudienceButton ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, minWidth: 'initial', borderRadius: 0 }} onClick={handleUploadAudience}>
                    <Export />
                  </Button>

                  <Button className={`${styles.SearchFilterMoreButton ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: 48, height: 48, minWidth: 'initial', borderRadius: 0 }} onClick={handleSearchFilterMoreButton}>
                    <Setting4 />
                  </Button>
                  {addCard &&
                    <Button className={`${styles.SearchFilterSaveButton ?? ''}`} sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', height: 48, minWidth: 'initial', textTransform: 'capitalize', borderRadius: 0, filter: estimateAudiences < 1 && 'grayscale(100%)', }} onClick={() => handleFilters(sliderValues, true, isMergeFilter, value)} disabled={estimateAudiences < 1}>Save</Button>
                  }
                  <Button sx={{ display: 'flex', flex: '1', alignItems: 'center', justifyContent: 'center', height: 48, minWidth: 'initial', textTransform: 'capitalize', borderRadius: 0, background: "#F1F1F1", color: "blue", '&:hover': { background: "white !important" } }} onClick={() => handleCancelAcudience()}>Cancel</Button>
                </Box>

              </Box>
            </Collapse>

            <Collapse in={filterMoreOptionOpen}>

              <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="icon label tabs example"
                  sx={{".MuiTab-root": {flexDirection: "row", justifyContent: "flex-start", gap: "8px", color: labelAndIconColor,
                      "& .MuiTab-iconWrapper": {
                        color: labelAndIconColor },
                      "&.Mui-selected": { color: labelAndIconColor }, 
                      "&.Mui-disabled": {
        color: currentTheme === "light" ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.6)", // Text color
        "& .MuiTab-iconWrapper": {
          color: currentTheme === "light" ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.6)", // Icon color
        },
      },
                    }, 
                    }} 
                      >
                <Tab icon={<TrackChangesIcon />} label="Radius" />
                <Tab icon={<HexagonOutlinedIcon />} label="Isochrones" disabled = { totalPlaces > 100 } />
              </Tabs>

              { value === 1 && (
                  <>
                    <Typography sx={{ color: labelAndIconColor, fontSize: "16px", fontWeight: 600, padding: "18px" }}>
                      Measure by
                    </Typography>

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <Select
                          fullWidth
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={chooseFilter}
                          onChange={handleChooseFilter}
                          sx={{
                            border: "1px",
                            borderRadius: "6px",
                            width: "275px",
                            color: labelAndIconColor, // Text color based on theme
                            backgroundColor: currentTheme === "dark" ? "#444444" : "" , // Grey background
                            '& .MuiSelect-select': {
                              backgroundColor: currentTheme === "dark" ? "#444444": "", // Grey background for the select box
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              borderColor: currentTheme === "light" ? "#000000" : "#FFFFFF", // Outline color based on theme
                            },
                          }}
                      >
                        <MenuItem value={"distance"} sx={{ color: "#000000" }}>Distance - Driving</MenuItem>
                        <MenuItem value={"time"} sx={{ color: "#000000" }}>Time - Driving</MenuItem>
                        {/*<MenuItem value={2} sx={{ color: "#000000" }}>Distance - Walking</MenuItem>*/}
                        {/*<MenuItem value={3} sx={{ color: "#000000" }}>Time - Walking</MenuItem>*/}
                      </Select>
                    </FormControl>

                  </>
              )}


              <Box className={`${styles.SearchFilterMoreArea ?? ''}`} sx={{ position: 'relative' }}>
                <Box className={`${styles.SearchFilterGroupArea ?? ''}`} sx={{ position: 'relative', py: 1 }}>
                  {filterMoreGroups?.length > 0 &&
                      filterMoreGroups.map((filter, index) => {
                        // Determine if the filter should be displayed based on the selected filter type
                        const isHidden =
                            (value !== 1 && (filter.title === 'Distance' || filter.title === 'Time Period (in Minutes)')) ||
                            (filter.title === 'Distance' && (chooseFilter === 'time' )) ||
                            (filter.title === 'Time Period (in Minutes)' && (chooseFilter === 'distance'));
                        
                        if (isHidden) return null;

                        return (
                            <Box
                                className={`${styles.SearchFilterGroup ?? ''}`}
                                sx={{ position: 'relative', px: 2.5, py: 2 }}
                                key={index}
                            >
                              <Box
                                  className={`${styles.SearchFilterGroupTitle ?? ''}`}
                                  sx={{
                                    position: 'relative',
                                    fontSize: 16,
                                    fontWeight: '600',
                                    lineHeight: '20px',
                                    mb: 2,
                                  }}
                              >
                                {filter.title}
                              </Box>
                              <Box className={`${styles.SearchFilterGroupSlider ?? ''}`} sx={{ position: 'relative' }}>
                                {filter.title !== 'Day Parting' && filter.title !== 'Week Parting' ? (
                                    <PrettoSlider
                                        aria-label="pretto slider"
                                        defaultValue={defaultSliderValues[index]}
                                        marks={filter.options}
                                        step={null}
                                        valueLabelDisplay="auto"
                                        min={filter.options[0].value}
                                        max={filter.options[filter.options.length - 1].value}
                                        value={defaultSliderValues[index]}
                                        onChange={(event, newValue) => {
                                          const sliderValuesDummy = [...sliderValues];
                                          sliderValuesDummy[index] = newValue;
                                          dispatch(setSliderValues(sliderValuesDummy));
                                          dispatch(setDefaultSliderValues(sliderValuesDummy));
                                        }}
                                    />
                                ) : filter.title === 'Day Parting' ? (
                                  <Box display="flex" justifyContent="space-between">
                                  {filter.options.map((option, optionIndex) => (
                                    <Box key={option.value} display="flex" flexDirection="column" alignItems="center">
                                      <Checkbox
                                        icon={<Brightness1OutlinedIcon sx={{ color: currentTheme === 'light' ? '#010412' : '#FFF' }} />}
                                        checkedIcon={<Brightness1Icon color="warning" />}
                                        checked={option.isDefault === 'true'}
                                        onChange={() => handleCheckboxChange(index, optionIndex)}
                                      />
                                      <Box
                                        sx={{
                                          width: '10px', 
                                          height: '10px', 
                                          borderRadius: '5px',
                                          color: '#6F7288',
                                          fontSize: '10px',
                                          textAlign: 'center',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        {option.label}
                                      </Box>
                                    </Box>
                                  ))}
                                </Box>
                                
                                ) : (
                                    <Box display="flex"  justifyContent="space-between">
                                      {filter.options.map((option, optionIndex) => (
                                        <Box key={option.value} display="flex" flexDirection="column" alignItems="center">
                                            <Radio
                                                icon={<Brightness1OutlinedIcon sx={{ color: currentTheme === 'light' ? '#010412' : '#FFF' }} />}
                                                checkedIcon={<Brightness1Icon color="warning" />}
                                                checked={option.isDefault === 'true'}
                                                onChange={() => handleRadioChange(index, optionIndex)}
                                                name="radio-buttons"
                                            /> 
                                      <Box
                                        sx={{
                                          width: '10px', 
                                          height: '10px', 
                                          borderRadius: '5px',
                                          color: '#6F7288',
                                          fontSize: '10px',
                                          textAlign: 'center',
                                          display: 'flex',
                                          alignItems: 'center',
                                          justifyContent: 'center',
                                        }}
                                      >
                                              {option.label}
                                            </Box>
                                            </Box>
                                      ))}
                                    </Box>
                                )}
                              </Box>
                            </Box>
                        );
                      })}
                </Box>
                <Button
                    className={`${styles.SearchFilterApplyButton ?? ''}`}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: 48,
                      minWidth: 'initial',
                      textTransform: 'capitalize',
                      borderRadius: 0,
                    }}
                    onClick={() => handleFilters(sliderValues, false, isMergeFilter, value)}
                >
                  Apply Filters
                </Button>
              </Box>

            </Collapse>

          </Box>

        </Box>
      }
    </Box>

        { activateTopUpCreditModalState &&
            <ActivateTopUpCreditModal
            activateTopUpCreditModalState={activateTopUpCreditModalState}
            setActivateTopUpCreditModalState={setActivateTopUpCreditModalState}
        />
        }
      </>
  );
}

export default SearchFilter;