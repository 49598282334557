import { createSlice } from '@reduxjs/toolkit';
import { getRandomInt } from '../../utils/math';

export const audienceSlice = createSlice({
  name: 'audience',
  initialState: {
    audiences: [],
    approvalAudiences: [],
    campaignAudiences: [],
    totalPlaces: 0,
    estimateAudiences: 0,
    placesList: [],
    filterMoreGroups: [],
    defaultSliderValues: [],
    sliderValues: [],
    audienceIdsList: '',
    poiIdsList: '',
    checkedUser: [],
    checkedSystem: [],
    partnerPOIList: '',
    brandIdList:'',
    defaultSearchKeywords: [],
    audienceHistoryChange: getRandomInt(100),
    audienceLimit: 0,
    activeSlide: 0,
    editFilter: 0,
    totalSelectedAudience: 0,
    compareStatusTableData: [],
  },
  reducers: {
    setAudiences: (state, action) => {
      state.audiences = action.payload;
    },
    setTotalPlaces: (state, action) => {
      state.totalPlaces = action.payload;
    },
    setEstimateAudiences: (state, action) => {
      state.estimateAudiences = action.payload;
    },
    setPlacesList: (state, action) => {
      state.placesList = action.payload;
    },
    setFilterMoreGroups: (state, action) => {
      state.filterMoreGroups = action.payload;
    },
    setDefaultSliderValues: (state, action) => {
      state.defaultSliderValues = action.payload;
    },
    setSliderValues: (state, action) => {
      state.sliderValues = action.payload;
    },
    setAudienceIdsList: (state, action) => {
      state.audienceIdsList = action.payload;
    },
    setPoiIdsList: (state, action) => {
      state.poiIdsList = action.payload;
    },
    setCheckedUser: (state, action) => {
      state.checkedUser = action.payload;
    },
    setCheckedSystem: (state, action) => {
      state.checkedSystem = action.payload;
    },
    setDefaultSearchKeywords: (state, action) => {
      state.defaultSearchKeywords = action.payload;
    },
    setAudienceHistoryChange: (state, action) => {
      state.audienceHistoryChange = action.payload;
    },
    setAudienceLimit: (state, action) => {
      state.audienceLimit = action.payload;
    },
    setActiveSlide: (state, action) => {
      state.activeSlide = action.payload;
    },
    setPartnerPOIList: (state, action) => {
      state.partnerPOIList = action.payload;
    },
    setBrandIdList: (state, action) => {
      state.brandIdList = action.payload;
    },
    setApprovalAudiences: (state, action) => {
      state.approvalAudiences = action.payload;
    },
    setCampaignAudiences: (state, action) => {
      state.campaignAudiences = action.payload;
    },
    setTotalSelectedAudience: (state, action) => {
      state.totalSelectedAudience = action.payload;
    },
    setCompareStatusTableData: (state, action) => {
      state.compareStatusTableData = action.payload;
    },
  },
});

export const { setAudiences, setTotalPlaces, setEstimateAudiences, setPlacesList, setFilterMoreGroups, setDefaultSliderValues, setSliderValues, setAudienceIdsList, setPoiIdsList, setDefaultSearchKeywords, setAudienceHistoryChange, setAudienceLimit, setActiveSlide, setCheckedUser, setCheckedSystem, setPartnerPOIList, setBrandIdList, setEditFilter, setApprovalAudiences, setCampaignAudiences, setTotalSelectedAudience, setCompareStatusTableData } = audienceSlice.actions;

export default audienceSlice.reducer;
