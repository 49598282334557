import { createSlice } from '@reduxjs/toolkit';
import { getDefaultTheme } from '../../utils/theme';

export const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    currentTheme: getDefaultTheme()
  },
  reducers: {
    setCurrentTheme: (state, action) => {
      state.currentTheme = action.payload;
    }
  },
});

export const { setCurrentTheme } = themeSlice.actions;

export default themeSlice.reducer;
