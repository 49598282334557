import React from "react";
import { Source, Layer } from 'react-map-gl';

const PolygonOverlay = ({ geojsonData }) => {

    // Check if geojsonData is a FeatureCollection with Polygon features
    if (geojsonData?.type !== 'FeatureCollection') {
        console.error("Invalid GeoJSON data type. Expected 'FeatureCollection'.");
        return null;
    }

    return (
        <Source id="polygon" type="geojson" data={geojsonData}>
            {geojsonData?.features.map((feature, index) => (
                <React.Fragment key={index}>
                    {/* Fill layer for each polygon feature */}
                    <Layer
                        id={`polygon-fill-${index}`}
                        type="fill"
                        source="polygon"  // Add source to Layer
                        paint={{
                            'fill-color': '#088',
                            'fill-opacity': 0.4,
                        }}
                        filter={['==', 'group_index', feature.properties.group_index]}
                    />
                    {/* Outline layer for each polygon feature */}
                    <Layer
                        id={`polygon-outline-${index}`}
                        type="line"
                        source="polygon"  // Add source to Layer
                        paint={{
                            'line-color': '#088',
                            'line-width': 2,
                        }}
                        filter={['==', 'group_index', feature.properties.group_index]}
                    />
                </React.Fragment>
            ))}
        </Source>
    );
};

export default PolygonOverlay;