import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Logo from '../../../../assets/images/Vector.png';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import { useWindowWidth } from '../../../../utils/browser';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

function DeleteAudienceConfirmModal({ audienceId, deleteAudienceConfirmModalState, setDeleteAudienceConfirmModalState, setDeleteAudienceSuccessModalState, handleAudienceDelete, audienceList, setAudienceList }) {

  const browserWidth = useWindowWidth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, handleSubmit, reset, formState: { errors } } = useForm({
    mode: 'onChange'
  });

  const handleClose = () => {
    setDeleteAudienceConfirmModalState(false);
  };

  const onSubmit = () => {
    // console.log(audienceId);
    if (audienceId) {
      handleClose();
      setDeleteAudienceSuccessModalState(true);
      handleAudienceDelete(audienceId);
    }
  };

  return (<>
    <Dialog
      open={deleteAudienceConfirmModalState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "390px", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <Box
        component='form'
        noValidate
        autoComplete='off'
        onSubmit={handleSubmit(onSubmit)}
      >
        <DialogContent sx={{ px: browserWidth > 500 ? 7.5 : 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ width: 100, height: 100 }}>
              <Image src={Logo} alt='logo' />
            </Box>
          </Box>
          <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
            {"Confirm"}
          </DialogTitle>
          <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
            Are you sure you want  to delete the card?
          </DialogContentText>
          <br />
          <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
            Upon deletion the card will be moved to ‘Deleted’ folder and
            will remain there until the end of billingcycle.
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ padding: "0px" }}>
          <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
            <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
            <LoadingButton
              variant='contained'
              fullWidth
              type='submit'
              loading={isSubmitting}
              className="form-button"
              sx={{ textTransform: "none", borderRadius: "0px" }}
            >
              Delete
            </LoadingButton>
          </ButtonGroup>
        </DialogActions>
      </Box>
    </Dialog>
  </>);
}

export default DeleteAudienceConfirmModal;