import React from 'react';
import { Box } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
import { baseUrl } from '../../config/config';
import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import SearchFilter from '../../components/common/filter/search-filter/SearchFilter';
import ClusterdMap from '../../components/common/map/ClusterdMap';

const SearchPage = () => {

  return (<>
    <Box className='SearchPageArea' sx={{ display: 'flex' }}>
      {/*<MenuSideBar />*/}
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        <ClusterdMap dataLink={baseUrl + "data/map.geojson"} pointerState={true}/>

        <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '55%' }}>
          <SearchAutoComplete />
        </Box>
      </Box>
      <Box className="SearchFilterArea" sx={{ width: 300, maxHeight: '100vh', position: 'fixed', top: 0, right: 0 }}>
        <SearchFilter />
      </Box>
    </Box>
  </>);
}

export default SearchPage;