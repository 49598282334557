import { Box } from "@mui/material";
import Image from "mui-image";
import HorizontalStackedBar from "../../chart/HorizontalStackedBar";

const GeneralStatisticsLine = ({ stat, bottomLine = true }) => {

  return (<>
    {stat &&
      <Box className="GeneralStatisticsLine" sx={{ mb: bottomLine ? 3 : 0, pb: bottomLine ? 3 : 0, borderBottom: bottomLine ? '1px solid #6f728866' : 'none', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box className="StatInfo" sx={{ mb: 2.5, display: 'flex', alignItems: 'center' }}>
          <Box className="StatIcon" sx={{ p: 0.8, mr: 2, width: 32, height: 32, backgroundColor: stat.iconBackgroundColor, borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Image src={stat.icon} alt="" width={22} height={22} fit="contain" />
          </Box>
          <Box className="StatText" sx={{ width: 'calc(100% - 60px)' }}>
            <Box className="StatTitle" sx={{ fontSize: 12, lineHeight: '14px', color: '#6F7288' }}>{stat.title}</Box>
          </Box>
        </Box>

        <Box className="StatList" sx={{ mb: 1, display: 'flex', flexWrap: 'wrap' }}>
          {stat.types?.length > 0 && stat.types.map((type, indexTypes) => {
            return (
              <Box className="StatListItem" sx={{ mb: 1.5, width: '50%', display: 'flex', alignItems: 'start' }} key={indexTypes}>
                <Box sx={{ mr: 1.3, width: 12, height: 12, backgroundColor: `${type.color}`, borderRadius: '2px' }} />
                <Box className="StatText" sx={{ width: 'calc(100% - 30px)' }}>
                  <Box className="StatTitle" sx={{ mb: 1, fontSize: 14, lineHeight: '17px', fontWeight: 700, textTransform: 'capitalize' }}>{type.title}</Box>
                  <Box className="StatValue" sx={{ fontSize: 12, lineHeight: '14px', color: '#6F7288' }}>{type.value}</Box>
                </Box>
              </Box>
            )
          })}
        </Box>

        <Box className="StatChart" sx={{ display: 'flex', width: '100%' }}>
          <HorizontalStackedBar
            data={stat.chart.data}
          />
        </Box>
      </Box>
    }
  </>);
};

export default GeneralStatisticsLine;