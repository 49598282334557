import { React , useState} from "react";
import {Box, Button, Menu, MenuItem} from "@mui/material";
import Image from "mui-image";
import { styled } from '@mui/material/styles';
import styles from './CrumbCard.module.scss';
import { useNavigate } from "react-router-dom";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../../../redux/slices/message';
import { errorMessage } from '../../../../config/config';
import { setCrumbs } from "../../../../redux/slices/crumb";
import {HeartSlash, More, TickCircle, Trash} from "iconsax-react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chip from '@mui/material/Chip';
import { getPermission } from "../../../../utils/cryptograpgy";
import EditCrumbModal from "../../modal/edit-crumb-modal/EditCrumbModal";
import CrumbService from "../../../../services/api/crumb.service";

const BlueTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#3C4DE7',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: 12,
  },
  [`& .${tooltipClasses.tooltip} .MuiTooltip-arrow`]: {
    color: '#3C4DE7'
  },
}));

function CrumbCard({ crumb, index, handleMouseEnter, handleMouseLeave, activateCrumb }) {

  const dispatch = useDispatch();
  const { crumbs } = useSelector((state) => state.crumb);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const deleteCrumbPermission = getPermission('delete_crumb');
  const editCrumbPermission = getPermission('edit_crumb');
  const menuOpen = Boolean(menuAnchorEl);
  const navigate = useNavigate();
  const [editCrumbModalState, setEditCrumbModalState] = useState(false);
  const [crumbDetails, setCrumbDetails] = useState({ id: '', name: '' });
  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);

  const handleCrumbClick = (id) => {
    // console.log(crumb);
    if (crumb.count > 0) {
      navigate(`/crumb/${id}/audiences`);
    } else {
      dispatch(setMessage({
        type: "error",
        title: "No cards",
      }));
      dispatch(setMessageSnackbarStateState(true));
    }
  }

  const handleMenuOpen = (event) => {
    event.preventDefault();
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleCrumbEditClick = (id, name) => {
    if (editCrumbPermission) {
      setCrumbDetails({ id, name });
      setEditCrumbModalState(true);
    }
  }

  const handleCrumbEdit = (crumbId, rename, setEditCrumbModalState, editCrumbModalState) => {
    const queryParams = [
      `crumbId=${crumbId}`,
      `name=${rename}`,
      `userId=${user.id}`,
      `CountryId=${selectedCountry.id}`,
    ]

    // setIsSubmitting(true);
    CrumbService.updateCrumb(queryParams.join('&')).then(
        (data) => {
          if (data?.results) {
            dispatch(setMessage({
              type: "success",
              title: data?.message || "crumb data saved successfully",
            }));
            dispatch(setMessageSnackbarStateState(true));
            const updatedCrumbList = crumbs.map(crumb => {
              if (crumb.id === crumbId) {
                return { ...crumb, name: rename };
              } else {
                return crumb;
              }
            });
            dispatch(setCrumbs(updatedCrumbList));
          }
          dispatch(setEditCrumbModalState(false));
          return Promise.resolve();
        },
        (error) => {
          dispatch(setMessage({
            type: "error",
            title: error?.response?.data?.message || "crumb Name is already taken!",
          }));
          dispatch(setMessageSnackbarStateState(true));
          dispatch(setEditCrumbModalState(true));
          // setIsSubmitting(false);
          return Promise.reject();
        }
    )
  };

  const deleteCrumb = (id) => {
    if (deleteCrumbPermission) {
      const queryParams = [
        `status=Deactivated`
      ];

      CrumbService.deleteCrumb(id, queryParams.join('&')).then(
        (data) => {
          // console.log("data", data);
          if (data) {
            let crumbsDummy = [];
            if (data.result) {
              dispatch(setMessage({
                type: "success",
                title: data?.message ? data.message : "Crumb restored successfully",
              }));
              crumbsDummy = [crumb].concat([...crumbs]);
            } else {
              dispatch(setMessage({
                type: "error",
                title: "Crumb deleted successfully",
              }));
              crumbsDummy = crumbs.filter(c => c.id !== crumb.id);
            }
            dispatch(setMessageSnackbarStateState(true));
            dispatch(setCrumbs(crumbsDummy));
          } else {
            dispatch(setMessage({
              type: "error",
              title: errorMessage,
            }));
            dispatch(setMessageSnackbarStateState(true));
          }
          return Promise.resolve();
        },
        (error) => {
          console.log(error);
          dispatch(setMessage({
            type: "error",
            title: errorMessage,
          }));
          dispatch(setMessageSnackbarStateState(true));
          return Promise.reject();
        }
      );
    }
  };


  function getRandomColor() {
    const red = Math.floor(Math.random() * 256);
    const green = Math.floor(Math.random() * 256);
    const blue = Math.floor(Math.random() * 256);
    return `rgb(${red}, ${green}, ${blue})`;
  }

  return (<>
    {crumb &&
      <Box className={`${styles.CrumbCard ?? ''} ${crumb.hover ? styles.Hover ?? '' : ''}`} sx={{ position: 'relative', transition: '0.5s' }} onMouseEnter={() => handleMouseEnter(index)} onMouseLeave={() => handleMouseLeave()}>
        {crumb.count > 1 &&
          <Box className={`${styles.CrumbShade ?? ''}`} sx={{ position: 'absolute', zIndex: 1, top: 0, left: 0 }}>
          </Box>
        }
        <Box className={`${styles.CrumbContent ?? ''}`} sx={{ position: 'relative', zIndex: 2 }}>
          <Box className={`${styles.CrumbImage ?? ''}`} sx={{ position: 'relative', overflow: 'hidden', borderRadius: '12px', cursor: 'pointer' }} onClick={() => handleCrumbClick(crumb.id)}>
            {/* <Image src={crumb.imagePath} alt={crumb.name} width="auto" height="auto" /> */}
            <Image src={crumb.imagePath} alt={crumb.name} />
          </Box>
          <Box className={`${styles.CrumbDetails ?? ''}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', position: 'absolute', zIndex: 4, width: '100%', top: 16, left: 0 }}>
            <Box className={`${styles.CrumbName ?? ''}`} sx={{ display: 'flex', alignItems: 'center', height: 32, borderRadius: '100px', background: crumb.nameBackground, fontSize: 14, fontWeight: '500', lineHeight: '17px', pl: 2, pt: 2 }}>
              <Chip title={crumb.name} label={crumb.name.length > 10 ? `${crumb.name.substring(0, 8)}...` : crumb.name} component="h1" variant="contained" sx={{ color: "#FFFFFF", backgroundColor: getRandomColor() }} />
            </Box>
            {/* <Chip label="Personal Inforamtion" component="h1" variant="contained"
            sx={{ color: "#FFFFFF", backgroundColor: "#090C19" }}
          /> */}
            <Box className={`${styles.CrumbAction ?? ''}`} sx={{ display: 'flex', alignItems: 'center', ml: 1, mr: 2 }}>
              {crumb && crumb.status === "Activated" &&
                <Box className={`${styles.CrumbCount ?? ''} ff-tomorrow`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 14, lineHeight: '17px', fontWeight: '500', width: 32, height: 32, borderRadius: '50%' }}>{crumb.count}</Box>
              }
              { editCrumbPermission &&
                // <BlueTooltip title={"Delete"} arrow placement="top" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }}>
                      <Button className={`${styles.CrumbDeleteButton ?? ''}`} disableRipple sx={{ p: 1, ml: 1, width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }} onClick={handleMenuOpen}>
                        <MoreVertIcon />
                      </Button>
                // </BlueTooltip>
              }
              {crumb.status === "Deactivated" &&
                <BlueTooltip title={"Activate"} arrow placement="top" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }}>
                  <Button onClick={() => activateCrumb(crumb.id)} className={`${styles.CrumbDeleteButton ?? ''}`} disableRipple sx={{ p: 1, ml: 1, width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                    <TickCircle variant="Bold" />
                  </Button>
                </BlueTooltip>
              }
              {crumb.isFavourite &&
                <BlueTooltip title={"Remove"} arrow placement="top" className={`${styles.ListItemTooltip ?? ''}`} sx={{ m: 0 }}>
                  <Button className={`${styles.CrumbDeleteButton ?? ''}`} disableRipple sx={{ p: 1, ml: 1, width: 32, height: 32, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '50%' }}>
                    <HeartSlash variant="Bold" />
                  </Button>
                </BlueTooltip>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    }

    <Menu
        anchorEl={menuAnchorEl}
        className={`${styles.audienceMenu ?? ''}`}
        open={menuOpen}
        onClose={handleMenuClose}
        onClick={handleMenuClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',

            '&.MuiMenu-paper': {
              borderRadius: '8px',
              backgroundColor: '#3C4DE7',

              '*': {
                color: '#fff',
                fontSize: '14px',
                fontWeight: '500',
                '&:active, &:focus': {
                  color: '#fff',
                  backgroundColor: 'transparent',
                }
              },
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              bottom: '-4px',
              left: '50%',
              width: 10,
              height: 10,
              bgcolor: '#3C4DE7',
              transform: 'translateX(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
    >

          <MenuItem sx={{ background: 'tranparent' }}>
            <Button onClick={() => handleCrumbEditClick(crumb.id, crumb.name)} disableRipple sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
              Edit
            </Button>
          </MenuItem>
      {crumb.status !== "Deactivated" &&
          <MenuItem sx={{ background: 'tranparent' }}>
            <Button onClick={() => deleteCrumb(crumb.id)} sx={{ width: '100%', px: 0, pt: 0, textTransform: 'capitalize', textAlign: 'left', background: 'tranparent', justifyContent: 'flex-start' }}>
              Delete
            </Button>
          </MenuItem>
          }
    </Menu>
    {
        editCrumbPermission &&
        <EditCrumbModal
            crumbId={crumbDetails.id}
            crumbName={crumbDetails.name}
            editCrumbModalState={editCrumbModalState}
            setEditCrumbModalState={setEditCrumbModalState}
            handleCrumbEdit={handleCrumbEdit}
        />
    }
  </>);
}

export default CrumbCard;
