// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchAutoComplete_SearchBoxSubmitButton__Hx6fB {\n  color: #fff !important;\n  background: #3c4de7 !important;\n}\n.SearchAutoComplete_SearchBoxSubmitButton__Hx6fB:hover, .SearchAutoComplete_SearchBoxSubmitButton__Hx6fB:active {\n  background: #3c4de7 !important;\n  color: #fff !important;\n}\n\n.SearchAutoComplete_SearchBoxPoiUploadButton__alPRf {\n  color: #fff !important;\n  background: #3c4de7 !important;\n}\n.SearchAutoComplete_SearchBoxPoiUploadButton__alPRf:hover, .SearchAutoComplete_SearchBoxPoiUploadButton__alPRf:active {\n  background: #1523a8 !important;\n  color: #fff !important;\n}", "",{"version":3,"sources":["webpack://./src/components/common/search/SearchAutoComplete.module.scss"],"names":[],"mappings":"AAEA;EACE,sBAAA;EACA,8BAAA;AADF;AAEE;EACE,8BAAA;EACA,sBAAA;AAAJ;;AAGA;EACE,sBAAA;EACA,8BAAA;AAAF;AACE;EACE,8BAAA;EACA,sBAAA;AACJ","sourcesContent":["@import \"../../../scss/variables\";\n\n.SearchBoxSubmitButton {\n  color: $white !important;\n  background: $blue !important;\n  &:hover, &:active {\n    background: $blue !important;\n    color: $white !important;\n  }\n}\n.SearchBoxPoiUploadButton {\n  color: $white !important;\n  background: $blue !important;\n  &:hover, &:active {\n    background: darken($blue, 20%) !important;\n    color: $white !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBoxSubmitButton": "SearchAutoComplete_SearchBoxSubmitButton__Hx6fB",
	"SearchBoxPoiUploadButton": "SearchAutoComplete_SearchBoxPoiUploadButton__alPRf"
};
export default ___CSS_LOADER_EXPORT___;
