import { authApi } from "../interceptor/auth.interceptor";

class GeneralStatics {

  static async getStats(queryParams) {
    return await authApi
      .get(`general-stats?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

  static async getCrumbStats(queryParams) {
    return await authApi
      .get(`crumbs/stats?${queryParams}`)
      .then((response) => {
        return response?.data;
      });
  }

}

export default GeneralStatics;