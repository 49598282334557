import React, { useState, useEffect } from 'react';
import { Box, Button } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import AudienceSlider from '../../components/common/slider/audience-slider/AudienceSlider';
import WestIcon from '@mui/icons-material/West';
import Image from 'mui-image';
import { useNavigate, useParams } from 'react-router-dom';
import styles from './Audiences.module.scss';
import HomepageDetails from '../../components/common/details/home-page-details/HomepageDetails';
import { useDispatch, useSelector } from 'react-redux';
import CrumbService from '../../services/api/crumb.service';
import { setMapPoints, setPolygonData } from '../../redux/slices/map';
import ClusterdMap from '../../components/common/map/ClusterdMap';
import AudienceService from '../../services/api/audience.service';
import { setMessage } from '../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../redux/slices/common';
import { errorMessage } from '../../config/config';
import { setAudiences } from '../../redux/slices/audience';
import StripeService from "../../services/api/stripe.service";
import ActivateTopUpCreditModal from "../../components/common/modal/activate-top-credit-modal/ActivateTopUpCreditModal";
// import { setAssignAudienceModalState } from '../../redux/slices/common';

const AudiencesPage = () => {

  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const { audiences } = useSelector((state) => state.audience);
  const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
  const [crumbDetails, setCrumbDetails] = useState(null);
  const [audienceList, setAudienceList] = useState(null);
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  localStorage.setItem('crumbID', JSON.stringify(params.id));
  const [activateTopUpCreditModalState, setActivateTopUpCreditModalState] = useState(false);

  const planMode = user?.planMode || userFromLocalStorage?.planMode;

  useEffect(() => {
    let isUploaded = false;
    const fetchData = () => {
      dispatch(setMapPoints([]));
      dispatch(setPolygonData(''));
      if (user && selectedCountry && params) {
        const queryParams = [
          `crumbId=${params.id}`,
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];

        CrumbService.getCrumbDetails(queryParams.join('&')).then(
          (data) => {
            const resultData = data?.results;
            localStorage.setItem("cards", JSON.stringify(resultData[0].audienceDtoList))
            // console.log(resultData[0].audienceDtoList[0].isUploaded)
            // isUploaded = (resultData[0]?.audienceDtoList.every(dto => dto.isUploaded || dto.isUploaded === null))
            isUploaded = (resultData[0]?.audienceDtoList.some(obj => obj.isUploaded === null))
            // console.log(isUploaded);
            if (resultData?.length) {
              const crumbName = resultData[0].name;
              localStorage.setItem("crumbName", crumbName);
              setCrumbDetails(resultData[0]);
              setAudienceList(resultData[0]?.audienceDtoList);
              dispatch(setAudiences(resultData[0]?.audienceDtoList));
            }
            return Promise.resolve();
          },
          (error) => {
            // console.log(error?.response?.data?.message);
            return Promise.reject();
          }
        );
      }
    };

    // Call the fetchData function initially
    fetchData();
    // console.log(isUploaded);
    // Call the fetchData function every 5 seconds

    const intervalId = setInterval(() => {
      if (isUploaded) {
        fetchData();
      }
    }, 300000);

    // Clean up the interval when the component unmounts or the dependency array changes
    return () => clearInterval(intervalId);
  }, [user, selectedCountry, dispatch, params]);

  // useEffect(() => {
  //   if (window.Echo) {
  //     window.Echo.channel('audience-upload-change')
  //       .listen('AudienceEvents', (e) => {
  //         // console.log(e);
  //         if (audienceList && audienceList.length > 0) {
  //           let audienceListDummy = [...audienceList];
  //           audienceListDummy.map((audience) => {
  //             if (audience?.id === e?.id) {
  //               return {
  //                 ...audience,
  //                 isUploaded: true,
  //               };
  //             } else {
  //               return audience
  //             }
  //           });
  //           setAudienceList(audienceListDummy);
  //         }
  //       });

  //     return () => {
  //       window.Echo.leaveChannel("audience-upload-change");
  //     };
  //   }
  // }, [audienceList]);

  const handleBackButton = () => {
    navigate('/');
  }

  const handleDeactivateAudience = (audience) => {
    const queryParams = [
      `status=Deactivated`
    ]
    AudienceService.deactivateAudience(audience.id, queryParams.join('&')).then(
      (data) => {
        if (data) {
          const audienceDummy = audienceList.map(item => {
            if (item.id === audience.id) {
              return {
                ...item,
                isActive: 'Deactivated',
              };
            } else {
              return item
            }
          });
          // console.log("audienceDummy", audienceDummy);
          setAudienceList(audienceDummy);
          dispatch(setAudiences(audienceDummy));
          dispatch(setMessage({
            type: "warning",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "Your Card has been deactivated",
          }));
          dispatch(setMessageSnackbarStateState(true));
          // window.location.reload();
        }
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    )
  }

  const handleActivateAudience = (audience) => {
    const queryParams = [
      `status=Activated`
    ]
    AudienceService.activateAudience(audience.id, queryParams.join('&')).then(
      (data) => {
        if (data) {
          const audienceDummy = audienceList.map(item => {
            if (item.id === audience.id) {
              return {
                ...item,
                isActive: 'Activated',
              };
            } else {
              return item
            }
          });
          // console.log("audienceDummy", audienceDummy);
          setAudienceList(audienceDummy);
          dispatch(setAudiences(audienceDummy));
          dispatch(setMessage({
            type: "success",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "Your Card has been Activated",
          }));
          dispatch(setMessageSnackbarStateState(true));
          // window.location.reload();
        }
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: errorMessage,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    )
  }

  const handleAudienceRefresh = async (audience) => {

    if(user.isExternalUser || user.isInternalUser) {

      try {
        const queryCalcParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ];


        const data = await StripeService.getUserPlanDetails(queryCalcParams.join('&'));

        if (data?.results) {

        if (data.results.limitExceeded) {
            setActivateTopUpCreditModalState(true);
            return;
          }
        }
      } catch (error) {
        // Handle the error appropriately
        console.error('Error fetching user plan details:', error);
        // Optionally, you can set an error message state or show a notification
      }
    }


    const queryParams = [
      `audienceId=${audience.id}`,
      `userId=${user.id}`
    ];
    // setIsSubmitting(true);
    AudienceService.refreshAudience(queryParams.join('&')).then(
        (data) => {
          if (data) {
            const audienceDummy = audienceList.map(item => {
              if (item.id === audience.id) {
                return {
                  ...item,
                  action: data.action,
                  isUploaded: null
                };
              } else {
                return item
              }
            });
            // console.log("audienceDummy", audienceDummy);
            setAudienceList(audienceDummy);
            dispatch(setAudiences(audienceDummy));
            dispatch(setMessage({
              type: "success",
              // title: data?.message ? data.message : "Audience restored successfully",
              title: "Started Refreshing",
            }));
            dispatch(setMessageSnackbarStateState(true));
            // window.location.reload();
          }
        },
        (error) => {
          console.log(error);
          dispatch(setMessage({
            type: "error",
            title: error.response.data.message,
          }));
          dispatch(setMessageSnackbarStateState(true));
          return Promise.reject();
        }
    );
  };

  const handleAudienceDelete = (audienceId) => {
    const queryParams = [
      `status=Deleted`
    ];
    AudienceService.deleteAudience(audienceId, queryParams.join('&')).then(
      (data) => {
        if (data) {
          dispatch(setMessage({
            type: "success",
            // title: data?.message ? data.message : "Audience restored successfully",
            title: "Audience Deleted",
          }));
          dispatch(setMessageSnackbarStateState(true));
        }
      });
  };

  const updateAudiences = (audienceId) => {
    const audienceDummy = audienceList.filter(a => a.id !== audienceId);
    // console.log("audienceDummy", audienceDummy);
    setAudienceList(audienceDummy);
    dispatch(setAudiences(audienceDummy));
  };

  const handleAudienceAssign = (audience, tags, setAssignAudienceModalState, setTags, input, setInput) => {
    const dummyTags = [...tags];
    dummyTags.push(input);
    const queryParams = [
      `emailList=${dummyTags.join(',')}`,
      `audienceId=${audience.id}`
    ];
    // setIsSubmitting(true);
    // setAssignAudienceModalState(true);
    AudienceService.assignAudience(queryParams.join('&')).then(
      (data) => {
        if (data) {
          console.log(data);
          if (data.message === "Successfully Assigned!") {
            dispatch(setMessage({
              type: "success",
              title: data.message,
            }));
            dispatch(setMessageSnackbarStateState(true));
            setAssignAudienceModalState(false);
            setTags([]);
            setInput('');
          }
          if (data.message === "emails not available") {
            // const invalidMail = data?.results.join(", ");
            dispatch(setMessage({
              type: "error",
              // title: "Invalid Email: " + invalidMail,
              title: "Invalid Email",
            }));
            dispatch(setMessageSnackbarStateState(true));
            setAssignAudienceModalState(true);
            setTags(data.results);
            // window.location.reload();
          }
        }
      },
      (error) => {
        console.log(error);
        dispatch(setMessage({
          type: "error",
          title: error.response.data.message,
        }));
        dispatch(setMessageSnackbarStateState(true));
        return Promise.reject();
      }
    );
  };

  const handleAudienceRename = (audience, audienceId, rename, category, setRenameAudienceModalState, renameAudienceModalState, selectedBrandType, selectedCrumbType, imageFile, imageSelectionData, tags) => {
    const queryParams = [
      `id=${audience.id}`
    ]

    let setTags = tags.length > 0 ? tags.join(',') : null;

    const formData = {
      name: rename.trim(),
      categoryList: category.map(item => item.id),
      userId: user.id,
      countryId: selectedCountry.id,
      tagList: setTags,
    };

    if (selectedBrandType === undefined || selectedBrandType === null) {
      formData.brandTypeId = null; // You can set it to a default value or null based on your requirements
    } else {
      formData.brandTypeId = selectedBrandType.id;
    }

    if (selectedCrumbType === undefined || selectedCrumbType === null) {

      formData.crumbId = null; // You can set it to a default value or null based on your requirements
    } else {
      formData.crumbId = selectedCrumbType.id;
    }

    const payloadData = new FormData();


    if(imageSelectionData && imageSelectionData !== "") {

      payloadData.append('imageFile', null);
      formData.imagePath = imageSelectionData;
    } else {

      if (imageFile === undefined || imageFile === null) {
        payloadData.append('imageFile', null);
      } else {
        payloadData.append('imageFile', imageFile, imageFile.name);
      }
    }
    payloadData.append('audienceDto', JSON.stringify(formData));

    // setIsSubmitting(true);
    AudienceService.renameAudience(queryParams.join('&'), payloadData).then(
      (data) => {
        if (data?.results) {
          dispatch(setMessage({
            type: "success",
            title: data?.message || "Audience data saved successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));
          const crumbID = localStorage.getItem('crumbID');
          const id = crumbID.replace(/"/g, '');
          navigate('/crumb/' + id + '/audiences');
          // setIsSubmitting(false);
          // setInput([]);
        }
        dispatch(setRenameAudienceModalState(false));
        return Promise.resolve();
      },
      (error) => {
        dispatch(setMessage({
          type: "error",
          title: error?.response?.data?.message || "Audience Name is already taken!",
        }));
        dispatch(setMessageSnackbarStateState(true));
        dispatch(setRenameAudienceModalState(true));
        // setIsSubmitting(false);
        return Promise.reject();
      }
    )
  };

  return (<>
    <Box className='AudiencesPageArea' sx={{ display: 'flex' }}>
      {/*<MenuSideBar />*/}
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        <ClusterdMap pointerState={true} />

        <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '65%', zIndex: 3 }}>
          <Box className={`SearchBoxWrapper`}>
            {crumbDetails &&
              <Button className={`${styles.SearchBoxBackButton}`} disableRipple sx={{ position: 'absolute', top: 0, right: 'calc(100% + 10px)', p: 0.7, pl: 2, height: 55, display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px', background: '#fff', textTransform: 'none' }} onClick={() => handleBackButton()}>
                <Box className={`SearchBoxBackButtonIcon`} sx={{ display: 'flex', alignItems: 'center', mr: 1 }}>
                  <WestIcon sx={{ width: 18, height: 18 }} />
                </Box>
                <Box className={`SearchBoxBackButtonText`} sx={{ display: 'inline-block', alignItems: 'center', mr: 1, maxWidth: 80, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>{crumbDetails.name.length > 6 ? `${crumbDetails.name.substring(0, 5)}..` : crumbDetails.name}</Box>
                <Box className={`SearchBoxBackButtonImage`} sx={{ display: 'flex', alignItems: 'center', overflow: 'hidden', borderRadius: '100px', width: 70, height: 45 }}>
                  <Image src={crumbDetails.imagePath} alt="" />
                </Box>
              </Button>
            }
            <SearchAutoComplete />
          </Box>
        </Box>

        {crumbDetails && audienceList?.length > 0 &&
          <Box className="AudiencesSliderArea" sx={{ position: 'absolute', bottom: 40, left: '50%', transform: 'translateX(-50%)', width: '95%', zIndex: 2 }}>
            <AudienceSlider
              crumbDetails={crumbDetails}
              audienceList={audiences}
              setAudienceList={setAudienceList}
              handleDeactivateAudience={handleDeactivateAudience}
              handleActivateAudience={handleActivateAudience}
              handleAudienceRefresh={handleAudienceRefresh}
              handleAudienceAssign={handleAudienceAssign}
              handleAudienceRename={handleAudienceRename}
              handleAudienceDelete={handleAudienceDelete}
              updateAudiences={updateAudiences}
              comefrom={'audience page'}
            />
          </Box>
        }
      </Box>
      <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
        <HomepageDetails />
      </Box>
    </Box>

    {activateTopUpCreditModalState &&
        <ActivateTopUpCreditModal
            activateTopUpCreditModalState={activateTopUpCreditModalState}
            setActivateTopUpCreditModalState={setActivateTopUpCreditModalState}
        />
    }
  </>);
}

export default AudiencesPage;