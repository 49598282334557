import React from "react";
import { Box, Grid, CircularProgress } from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from 'react-redux';

import users from '../../../../assets/images/icons/svg/users.svg';
import appleLogo from '../../../../assets/images/icons/svg/apple-logo.svg';
// import glasses from '../../../../assets/images/icons/svg/glasses.svg';
import pc from '../../../../assets/images/icons/svg/pc.svg';
import stat from '../../../../assets/images/icons/svg/stat.svg';
import tablet from '../../../../assets/images/icons/svg/tablet.svg';
import connectivity from '../../../../assets/images/icons/svg/connectivity.svg'

import styles from './HomepageDetails.module.scss';
import GeneralStatics from "../../../../services/api/generalStats.service";
import { getChartColorByIndex } from "../../../../utils/chart";
import GeneralStatisticsLine from "../../statistics/general-statistics-line/GeneralStatisticsLine";
import GeneralStatisticsDoughnut from "../../statistics/general-statistics-doughnut/GeneralStatisticsDoughnut";
import { getNumberUnit } from "../../../../utils/math";
import { useParams } from "react-router-dom";

const HomepageDetails = () => {

  const [deviceOs, setDeviceOs] = useState(null);
  const [deviceCarrier, setDeviceCarrier] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [deviceBrandList, setDeviceBrandList] = useState(null);
  const [connectionType, setConnectionType] = useState(null);
  const [audience, setAudience] = useState(null);
  const [activeUsers, setActiveUsers] = useState(null);
  // const [residents, setResidents] = useState(null);
  const [generalStats, setGeneralStats] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const { audiences } = useSelector((state) => state.audience);
  const params = useParams();

  useEffect(() => {
    let mounted = true;

    if (mounted) {
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ]
        if (params.id) {
          queryParams.push(`crumbId=${params.id}`);
        }
        // setIsLoading(true);
        GeneralStatics.getStats(queryParams.join('&')).then(
          (data) => {
            const generalStats = data?.results;
            // console.log("getStats", resultData);
            setGeneralStats(generalStats);
            if (generalStats) {
              if (generalStats.totalPopulation > 0) {
                if (generalStats.totalPopulation) {
                  generateDoughnutGeneralStatistics({
                    title: 'People',
                    icon: users,
                    iconBackgroundColor: '#ECEAFE',
                    value: ` ${getNumberUnit(generalStats.totalPopulation)}`,
                    // chartTitle: `${getPercentage(generalStats.audienceCount, generalStats.totalPopulation)}%`,
                    // data1: generalStats.audienceCount,
                    // data2: generalStats.totalPopulation - generalStats.audienceCount,
                    setState: setAudience
                  });
                } else {
                  setAudience(null);
                }

                if (generalStats.audienceCount) {
                  generateDoughnutGeneralStatistics({
                    title: 'Market Size',
                    icon: tablet,
                    iconBackgroundColor: '#EEE6FF',
                    value: ` ${getNumberUnit(generalStats.audienceCount)}`,
                    // chartTitle: `${getPercentage(generalStats.digitalReach, generalStats.totalPopulation)}%`,
                    // data1: generalStats.digitalReach,
                    // data2: generalStats.totalPopulation - generalStats.digitalReach,
                    setState: setActiveUsers
                  });
                } else {
                  setActiveUsers(null);
                }

                // if (generalStats.residentsCount ?? 0) {
                //   generateDoughnutGeneralStatistics({
                //     title: 'Residents',
                //     icon: glasses,
                //     iconBackgroundColor: '#E5F7FF',
                //     value: `Of ${getNumberUnit(generalStats.residentsCount)}`,
                //     chartTitle: `${getPercentage(generalStats.residentsCount, generalStats.totalPopulation)}%`,
                //     data1: generalStats.residentsCount,
                //     data2: generalStats.totalPopulation - generalStats.residentsCount,
                //     setState: setResidents
                //   });
                // } else {
                //   setResidents(null);
                // }
              }

              generateLineGeneralStatistics({
                title: 'Device OS',
                icon: appleLogo,
                iconBackgroundColor: '#E7E7FF',
                data: generalStats?.deviceOs,
                nameProperty: "deviceOs",
                setState: setDeviceOs,
              });

              generateLineGeneralStatistics({
                title: 'Device Carrier',
                icon: stat,
                iconBackgroundColor: '#FFF7E7',
                data: generalStats?.deviceCarrier,
                nameProperty: "deviceCarrier",
                setState: setDeviceCarrier,
              });

              generateLineGeneralStatistics({
                title: 'Device Type',
                icon: pc,
                iconBackgroundColor: '#E7FFED',
                data: generalStats?.deviceType,
                nameProperty: "deviceName",
                setState: setDeviceType,
              });

              generateLineGeneralStatistics({
                title: 'Top Devices',
                icon: tablet,
                iconBackgroundColor: '#FFF7E7',
                data: generalStats?.deviceBrandList,
                nameProperty: "deviceBrand",
                setState: setDeviceBrandList,
              });

              generateLineGeneralStatistics({
                title: 'Connection Type',
                icon: connectivity,
                iconBackgroundColor: '#FFF7E7',
                data: generalStats?.connectionType,
                nameProperty: "connectionName",
                setState: setConnectionType,
              });
            }
            setIsLoading(false);
            return Promise.resolve();
          },
          (error) => {
            // console.log(error?.response?.data?.message);
            setGeneralStats(null);
            setAudience(null);
            setActiveUsers(null);
            setDeviceOs(null);
            setDeviceCarrier(null);
            setDeviceType(null);
            setDeviceBrandList(null);
            setConnectionType(null);
            setIsLoading(false);
            return Promise.reject();
          }
        );
      }
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry, params.id]);

  const generateDoughnutGeneralStatistics = ({ title, icon, iconBackgroundColor, value, chartTitle, data1, data2, setState }) => {
    let option = {
      title: title,
      icon: icon,
      iconBackgroundColor: iconBackgroundColor,
      value: value,
      chart: {
        title: chartTitle,
        data: [
          {
            y: data1,
            color: '#3C4DE7',
          },
          {
            y: data2,
            color: '#212330',
          }
        ]
      },
    };
    setState(option);
  }

  const generateLineGeneralStatistics = ({ title, icon, iconBackgroundColor, data, nameProperty, setState }) => {
    if (data?.length) {
      let option = {
        title: title,
        icon: icon,
        iconBackgroundColor: iconBackgroundColor,
        types: [],
        chart: {
          data: []
        },
      };
      let typesDummy = [];
      let chartData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const color = getChartColorByIndex(index);
        const roundedPercentage = parseFloat(element.percentage).toFixed(2);
        typesDummy.push({
          title: element[nameProperty],
          value: `${roundedPercentage}%`,
          color: color,
        });
        chartData.push({
          name: element[nameProperty],
          data: [parseFloat(roundedPercentage)],
          color: color,
        });
      }

      option.types = typesDummy;
      option.chart.data = chartData;
      setState(option);
    } else {
      setState(null);
    }
  }

  return (
    <Box className={`${styles['theme-' + currentTheme]}`} sx={{ height: '100%' }}>
      <Box className={`${styles.HomepageDetails ?? ''}`} sx={{ p: 4.5, overflowX: 'hidden', overflowY: 'auto', height: '100%', position: 'relative', zIndex: 2 }}>
        {isLoading ?
          <Box sx={{ color: "#3C4DE7", height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <CircularProgress color="inherit" />
          </Box>
          :
          <Box>
            {generalStats &&
              <Box className="DetailsTitle" sx={{ mb: 2, fontSize: 20, lineHeight: '24px', fontWeight: 700 }}>
                {generalStats?.crumbName && audience ?
                  "General Insights of " + generalStats.crumbName
                  : (audience ? "General Insights" : "")
                }
              </Box>
            }

            {/* {generalStats && audiences.isUploaded && */}
              <Box sx={{ mb: 3, pb: 3, borderBottom: '1px solid #6F7288' }}>
                <Grid container spacing={2}>
                  {/*{audience &&*/}
                  {/*  <Grid item xs={6}>*/}
                  {/*    <GeneralStatisticsDoughnut stat={audience} />*/}
                  {/*  </Grid>*/}
                  {/*}*/}
                  {activeUsers &&
                    <Grid item xs={12}>
                      <GeneralStatisticsDoughnut stat={activeUsers} />
                    </Grid>
                  }
                </Grid>
              </Box>
            {/* } */}

            {/* {residents &&
              <GeneralStatisticsDoughnut stat={residents} />
            } */}

            {deviceOs &&
              <GeneralStatisticsLine
                stat={deviceOs}
              />
            }

            {/* {deviceCarrier && audiences.isUploaded && */}
              <GeneralStatisticsLine
                stat={deviceCarrier}
              />
            {/* } */}

            {deviceType &&
              <GeneralStatisticsLine
                stat={deviceType}
              />
            }

            {deviceBrandList &&
              <GeneralStatisticsLine
                stat={deviceBrandList}
              />
            }

            {connectionType &&
              <GeneralStatisticsLine
                stat={connectionType}
                bottomLine={false}
              />
            }
          </Box>
        }
      </Box>
    </Box>
  );
};

export default HomepageDetails;