import React, { useState, useEffect } from "react";
import { Alert, Box, Button, Divider } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import mail from "../../assets/images/icons/svg/sms.svg";
import lock from "../..//assets/images/icons/svg/lock.svg";
import logo from "../../assets/images/logo.svg";
import logoBlack from "../../assets/images/logo-black.svg";
import mediatikslogo from "../../assets/images/Mediatiks-logo-white.svg";
import mediatikslogoBlack from "../../assets/images/Mediatiks-logo-black.svg";
import Container from "@mui/material/Container";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, TextField } from "@mui/material";
import AuthService from "../../services/api/auth.service";
import { login, setSelectedCountry, setUser } from "../../redux/slices/auth";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import ForgotPasswordModal from "../../components/common/modal/forgot-password-modal/ForgotPasswordModal";
import SelectedCountryModal from "../../components/common/modal/selected-country-modal/SelectedCountryModal";
import {
  accessToken,
  accessTokenTimeout,
  errorMessage,
  refreshToken,
  refreshTokenTimeout,
} from "../../config/config";
import { useCookies } from "react-cookie";
import Image from "mui-image";
import { defaultCountries } from "../../config/countries.config";
import { useDispatch } from "react-redux";
import { setCountryBackgroundState } from "../../redux/slices/common";
import { encryptString } from "../../utils/cryptograpgy";
import { getExpiryTime } from "../../utils/date";
import DemoAccountModal from "../../components/common/modal/demo-account-modal/DemoAccountModal";

//const hostName = window.location.hostname;

//console.log("hostname =>", hostName);

const Login = ({
  login,
  setUser,
  setSelectedCountry,
  refreshTokenFromRedux,
  currentTheme,
}) => {
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [cookies, setCookie, removeCookie] = useCookies();

  const [passwordMode, setPasswordMode] = useState({
    password: "password",
  });
  const [showLoginArea, setShowLoginArea] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [isLoaded, setIsLoaded] = useState(false);
  const [responseMessage, setResponseMessage] = useState(null);
  const [userData, setUserData] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const [demoAccountModalOpen, setDemoAccountModalOpen] = useState(false);
  const [selectedCountryModalOpen, setSelectedCountryModalOpen] =
    useState(false);
  const dispatch = useDispatch();
  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (refreshTokenFromRedux) {
        // console.log("refreshTokenFromRedux", refreshTokenFromRedux);
        setTimeout(() => {
          if(userData?.isApproveUser) {
            const selectedAudience = {
              audienceDetails: {
                "id": 2,
                "name": 'Back',
              }
            }
            localStorage.setItem("selectedAudience", JSON.stringify(selectedAudience));
            localStorage.setItem("back-button-url", "/approve");
            navigate("/approve");
        } else {

            navigate("/home");
          }
        }, 100);
      } else {
        setIsLoaded(true);
      }
    }
    return () => {
      mounted = false;
    };
  }, [refreshTokenFromRedux, navigate]);

  const handleForgotPasswordModalOpen = (e) => {
    e.preventDefault();
    setForgotPasswordModalOpen(true);
  };

  const handleForgotPasswordModalClose = () => {
    setForgotPasswordModalOpen(false);
  };

  const handleSelectedCountryModalOpen = () => {
    setSelectedCountryModalOpen(true);
  };
  const handleSelectedCountryModalClose = (e, reason) => {
    if (reason !== "backdropClick") {
      setSelectedCountryModalOpen(false);
    }
  };

  const handleDemoAccountModalOpen = (e) => {
    e.preventDefault();
    setDemoAccountModalOpen(true);
  };

  const handleDemoAccountModalClose = () => {
    setDemoAccountModalOpen(false);
  };

  const handlePasswordMode = (state) => {
    let passwordModeDummy = { ...passwordMode };
    if (passwordModeDummy[state] === "password") {
      passwordModeDummy[state] = "text";
    } else {
      passwordModeDummy[state] = "password";
    }
    setPasswordMode(passwordModeDummy);
  };

  const onSubmit = (values) => {
    // console.log("values", values);
    const formData = {
      email: values.email,
      password: values.pass,
    };

    setIsSubmitting(true);
    AuthService.login(formData).then(
      (data) => {
        setIsConditionMet(true);
        const userDataResponse = data?.results;
        // console.log('login', data?.results);
        if (userDataResponse) {
          if (userDataResponse?.countries?.length) {
            // const extraMessage =
            //   userDataResponse.countries.length > 1
            //     ? " Please select a country to proceed."
            //     : "";
            setResponseMessage({
              type: "success",
              message: data.message
                ? data.message
                : "Login Successfully.",
            });
            setUserData(data?.results);
            let countryCode = localStorage.getItem("email-country-set");
            // console.log("userDataResponse.countries.length", userDataResponse.countries.length);
            if (userDataResponse.countries.length > 1 && !userDataResponse?.isApproveUser && countryCode == null) {
              setTimeout(() => {
                setShowLoginArea(false);
                handleSelectedCountryModalOpen();
                dispatch(setCountryBackgroundState(true));
              }, 1000);
            } else {
              setTimeout(() => {
                let countryFromDefaultCountries;
                let selectedCountryDummy;


                if (countryCode) {

                  countryFromDefaultCountries = defaultCountries.find(
                      (c) => c.code === countryCode
                  );

                  let userDataFromDefaultCountries = userDataResponse.countries.find(
                      (c) => c.code === countryCode
                  );
                  localStorage.removeItem('email-country-set');
                  selectedCountryDummy= {
                    ...userDataFromDefaultCountries,
                    ...countryFromDefaultCountries,
                  };
                } else {

                  countryFromDefaultCountries = defaultCountries.find(
                      (c) => c.name === userDataResponse.countries[0].name
                  );
                  selectedCountryDummy= {
                    ...userDataResponse.countries[0],
                    ...countryFromDefaultCountries,
                  };
                }

                setLoginData(selectedCountryDummy, userDataResponse);
              }, 1000);
            }
          } else {
            setResponseMessage({
              type: "error",
              message: "Please contact with admin to set a country",
            });
            setUserData(null);
            setIsSubmitting(false);
          }
        } else {
          setResponseMessage({
            type: "error",
            message: errorMessage,
          });
          setUserData(null);
          setIsSubmitting(false);
        }
        return Promise.resolve();
      },
      (error) => {
        console.log("Error Message", error.response.data.message);
        setResponseMessage({
          type: "error",
          message: error?.response?.data?.message
            ? error.response.data.message
            : errorMessage,
        });
        setIsSubmitting(false);
        setUserData(null);
        return Promise.reject();
      }
    );
  };

  const setLoginData = (selectedCountry, userData) => {
    // console.log("userData", userData);
    if (userData) {
      const accessTokenExpiryTime = getExpiryTime(accessTokenTimeout);
      const refreshTokenExpiryTime = getExpiryTime(refreshTokenTimeout);
      setCookie(accessToken, userData.accessToken, {
        path: "/",
        expires: accessTokenExpiryTime,
      });
      setCookie(refreshToken, userData.refreshToken, {
        path: "/",
        expires: refreshTokenExpiryTime,
      });
      login({
        loaded: true,
        loggedIn: true,
        accessToken: userData.accessToken,
        refreshToken: userData.refreshToken,
      });
      setUser(userData);
      setSelectedCountry(selectedCountry);

      // console.log('permissionResource', userData.permissionResource);
      const encryptedPermissionResource = encryptString(
        userData.permissionResource
      );
      // console.log('encryptedPermissionResource', encryptedPermissionResource);
      localStorage.setItem(
        "p2e3r21m2i31s166s223i345o226112n",
        encryptedPermissionResource
      );

      if(userData?.isApproveUser) {

        const selectedAudience = {
          audienceDetails: {
            "id": 2,
            "name": 'Back',
            "imagePath": 'none.jpg',
          }
        }
        localStorage.setItem("selectedAudience", JSON.stringify(selectedAudience));
        localStorage.setItem("back-button-url", "/approve");
      }

      const redirectUrl = localStorage.getItem("redirectUrl");
      if (redirectUrl) {
        localStorage.removeItem('redirectUrl');
        window.location = redirectUrl;
      } else {
        navigate("/home");
      }
    } else {
      logout();
    }
  };

  const logout = () => {
    removeCookie(accessToken, { path: "/" });
    removeCookie(refreshToken, { path: "/" });
    setUser(null);
    setUserData(null);
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    width: '500px',
    height: isConditionMet ? '748px' : '740px',
    padding: '65px 60px 70px',
  };

  return (
    <>
      {isLoaded && (
        <Container className="container">
          <Box className="flex-container">
            {showLoginArea && (
              <Box className="form-container" style={containerStyles} sx={{ opacity: [0.9, 0.8, 0.7] }}>
                <Box sx={{ textAlign: "center", mb: [0, 5] }}>
                  <Box className="login-logo">
                    {/*{hostName === 'map.mediatiks.com' ?*/}
                    {/*  <img*/}
                    {/*    src={currentTheme === "light" ? mediatikslogoBlack : mediatikslogo}*/}
                    {/*    alt="logo"*/}
                    {/*    style={{ height: 100 }}*/}
                    {/*  /> :*/}
                    <img
                        src={currentTheme === "light" ? logoBlack : logo}
                        alt="logo"
                        style={{ height: 100 }} />
                    {/*}*/}
                  </Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      fontSize: [20, 24],
                      lineHeight: "30px",
                      mt: 1,
                    }}
                    className="logo-title"
                  >
                    Curate, Visualize, Activate
                  </Box>
                  <Button
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                    className="form-button"
                    style={{
                      backgroundColor: "transparent", // Make the button transparent
                      color: currentTheme === "light" ? "black" : "white", // Set the text color to white
                      border: "2px solid white", // Add a border to the button
                      borderRadius: "25px", // Add a radius to the button
                    }}
                    sx={{
                      py: "0.8rem",
                      mt: 3.5,
                      textTransform: "capitalize",
                      fontSize: "1rem", // Adjust the font size as needed
                      fontWeight: 700,
                      "&:hover": {
                        backgroundColor: "transparent", // Transparent on hover
                        borderColor: "white", // Border color on hover
                      },
                    }}
                    onClick={(e) => handleDemoAccountModalOpen(e)}
                  >
                    Request a Demo
                  </Button>
                </Box>
                {/* <Divider color={"grey"} /> */}
                {responseMessage && (
                  <Alert
                    severity={responseMessage.type}
                    variant="filled"
                    sx={{ mt: 1 }}
                  >
                    <strong>{responseMessage.message}</strong>
                  </Alert>
                )}
                <Box
                  component="form"
                  noValidate
                  autoComplete="off"
                  onSubmit={handleSubmit(onSubmit)}
                  sx={{ mt: 3 }}
                >
                  <TextField
                    required
                    fullWidth
                    error={!!errors["email"]}
                    helperText={errors["email"] ? errors["email"].message : ""}
                    {...register("email", {
                      required: "Email is required",
                    })}
                    sx={{ mb: 4 }}
                    type="email"
                    placeholder="Your email"
                    variant="standard"
                    InputProps={{
                      style: { paddingBottom: "12px", fontSize: "18px" },
                      className: "form-control",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                          <Image
                            src={mail}
                            alt="mail"
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: "contain",
                            }}
                          />
                        </InputAdornment>
                      ),
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                  />

                  <TextField
                    required
                    fullWidth
                    sx={{ mb: 4 }}
                    type={passwordMode.password}
                    variant="standard"
                    placeholder="Password"
                    error={!!errors["pass"]}
                    helperText={errors["pass"] ? errors["pass"].message : ""}
                    {...register("pass", {
                      required: "Password required",
                    })}
                    InputProps={{
                      style: { paddingBottom: "12px", fontSize: "18px" },
                      className: "form-control",
                      startAdornment: (
                        <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                          <Image
                            src={lock}
                            alt="lock"
                            style={{
                              width: 20,
                              height: 20,
                              objectFit: "contain",
                            }}
                          />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={() => handlePasswordMode("password")}
                            aria-label="toggle password"
                            edge="end"
                            sx={{ ml: [0, 2], mr: [0, 0.5], color: "grey" }}
                            className="icon"
                          >
                            {passwordMode.password === "password" ? (
                              <VisibilityOffIcon />
                            ) : (
                              <VisibilityIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                      onKeyDown: (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          handleSubmit(onSubmit)();
                        }
                      },
                    }}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "space-between",
                      alignItems: "center",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <FormControlLabel
                      className="form-control"
                      control={<Checkbox defaultChecked />}
                      label="Remember me"
                    />
                    <Link
                      component="button"
                      variant="body2"
                      underline="hover"
                      className="form-link"
                      onClick={(e) => handleForgotPasswordModalOpen(e)}
                      sx={{ textAlign: "right" }}
                    >
                      Forgot Password?
                    </Link>
                  </Box>
                  <LoadingButton
                    variant="contained"
                    fullWidth
                    type="submit"
                    loading={isSubmitting}
                    className="form-button"
                    sx={{
                      py: "0.8rem",
                      mt: 3.5,
                      textTransform: "capitalize",
                      fontSize: [16, 20],
                      fontWeight: 700,
                      borderRadius: 1.5,
                    }}
                  >
                    Sign in
                  </LoadingButton>
                  {/*{hostName === 'map.mediatiks.com' &&*/}
                  {/*  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '15px', color: currentTheme === "dark" ? "#fff" : "000" }}>Powered By AllPings.com</Box>*/}
                  {/*}*/}
                </Box>
              </Box>
            )}
          </Box>

        </Container >
      )}

      <ForgotPasswordModal
        forgotPasswordModalOpen={forgotPasswordModalOpen}
        handleForgotPasswordModalClose={handleForgotPasswordModalClose}
      />

      <DemoAccountModal
        demoAccountModalOpen={demoAccountModalOpen}
        handleDemoAccountModalClose={handleDemoAccountModalClose}
      />
      {
        !showLoginArea && (
          <SelectedCountryModal
            selectedCountryModalOpen={selectedCountryModalOpen}
            handleSelectedCountryModalClose={handleSelectedCountryModalClose}
            userData={userData}
            setLoginData={setLoginData}
          />
        )
      }
    </>
  );
};

const mapStateToProps = (state) => ({
  refreshTokenFromRedux: state.auth.auth.refreshToken,
  currentTheme: state.theme.currentTheme,
});

export default connect(mapStateToProps, { login, setUser, setSelectedCountry })(
  Login
);
