import React, { useState, useEffect } from "react";
import {Box, Button, List, ListItem, CircularProgress, Checkbox, InputLabel, Divider} from "@mui/material";
import ListDivider from '@mui/joy/ListDivider';
import Image from "mui-image";
import CloseIcon from '@mui/icons-material/Close';
import {useSelector, useDispatch, connect} from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import { useWindowWidth } from "../../../../utils/browser";
import {
    setMessageSnackbarStateState,
    setSnapPlatformDetailsState,
    setTopUpDetailsState
} from "../../../../redux/slices/common";
import styles from './SnapPlatformDetails.module.scss';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import {setMessage} from "../../../../redux/slices/message";
import connectorService from "../../../../services/api/connector.service";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import SnapService from "../../../../services/api/snap.service";


const SnapPlatformDetails = ({ isSnapCollapsed , handleSnapCollapsed , handleSnapChange}) => {

    const browserWidth = useWindowWidth();
    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selectedAccount, setSelectedAccount] = useState('');
    const [selectedBusiness, setSelectedBusiness] = useState('');
    const { selectedCountry } = useSelector((state) => state.auth);
    const { user } = useSelector((state) => state.auth.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const userFromLocalStorage = JSON.parse(localStorage.getItem('user'));
    const { snapPlatformDetailsState } = useSelector((state) => state.common);
    const [activeStep, setActiveStep] = useState(0);
    const maxSteps = 2;
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(true); // New loading state
    const [isShow, setIsShow] = useState(false);
    const [snapAddAccount, setSnapAddAccount] = useState(   []);
    const [snapBusinessAccounts, setSnapBusinessAccounts] = useState([]);

    useEffect(() => {
        let mounted = true;
        if (mounted) {

            const queryParams = [
                `userId=${user.id}`
            ];


            SnapService.snapOrganisationAccounts(queryParams.join('&')).then(data => {
                //console.log("API List response: ", data);

                if (data.results && data.results.length > 0) {

                    setSnapBusinessAccounts(data.results);
                    //const selectedBusiness = data.results.find(business => business.isSelected);
                    setSelectedBusiness(data.results[0].id);
                    //setMetaAddAccountLink(selectedAccount ? selectedAccount.tasLink : data.results[0].tasLink);
                    setLoading(false);
                }
            }).catch(error => {
                console.error("Error calling API: ", error);
                setLoading(false);
                handleClose();
            });
        }

        return () => {
            mounted = false;

        };
    }, []);

    const fetchSnapAddAccount = () => {

        setLoading(true);
        const queryParams = [
            `userId=${user.id}`
        ];

        SnapService.snapAddAccount(queryParams.join('&')).then(data => {
            if (Array.isArray(data.results) && data.results.length > 0) {
                setSnapAddAccount(data.results);
                const selectedAccount = data.results.find(account => account.isSelected);
                setSelectedAccount(selectedAccount ? selectedAccount.id : data.results[0].id);
            } else {
                setSnapAddAccount([]); // Ensure it's always an array
            }
            setLoading(false);
        }).catch(error => {
            console.error("Error calling API: ", error);
            setSnapAddAccount([]); // Ensure it's always an array
            setLoading(false);
        });
    };

    const onSubmit = data => {
        console.log(JSON.stringify(data, null, 2));
    };

    const handleOpen = () => {

        dispatch(setSnapPlatformDetailsState(true));
    };

    const handleClose = () => {

        setActiveStep(0);
        dispatch(setSnapPlatformDetailsState(false));
        handleSnapCollapsed();
    };

    const handleCheckboxChange = (event) => {
        setChecked(event.target.checked);
    };

    const radioColor = currentTheme === 'light' ? '#3955BF' : '#3955BF';


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        let step = activeStep + 1;
        if (step === 1) {

            const queryParams = [
                `userId=${user.id}`,
                `orgAccountId=${selectedBusiness}`
            ];

            SnapService.changeBusinessAccount(queryParams.join('&')).then(data => {
                //console.log("API List response: ", data);
                //setMetaAddAccount(data.results);
                // dispatch(setMessage({
                //     type: "success",
                //     title: "FaceBook Add account chosen successfully",
                // }));
                // dispatch(setMessageSnackbarStateState(true));
                //setMetaAddAccountModalModalState(false);
                fetchSnapAddAccount();
            }).catch(error => {
                dispatch(setMessage({
                    type: "error",
                    title: error?.response?.data?.message,
                }));
                dispatch(setMessageSnackbarStateState(true));
            });
        }
    };

    const handleBack = () => {
        setChecked(false);
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    const handleSubmit = () => {


        const queryParams = [
            `userId=${user.id}`,
            `addAccountId=${selectedAccount}`,
            `isChecked=${checked}`
        ];

        SnapService.changeAddAccount(queryParams.join('&')).then(data => {

            setSnapAddAccount(data.results);
            dispatch(setMessage({
                type: "success",
                title: "SnapChat add account selected successfully",
            }));
            dispatch(setMessageSnackbarStateState(true));

        }).catch(error => {
            setIsShow(true);
            dispatch(setMessage({
                type: "error",
                title: error?.response?.data?.message,
            }));
            dispatch(setMessageSnackbarStateState(true));
        });

        handleSnapChange();
    }

    const handleBusinessRadioChange = (event) => {
        setSelectedBusiness(event.target.value);
    };

    const handleRadioChange = (event) => {
        setSelectedAccount(event.target.value);
    };



    return (<>
            <SwipeableDrawer
                anchor={"right"}
                open={ snapPlatformDetailsState }
                onClose={handleClose}
                onOpen={handleOpen}
                PaperProps={{
                    sx: {
                        width: "30%",
                        overflow: "visible",
                        display: "flex", // Make the drawer content a flexbox
                        flexDirection: "column", // Arrange children in a column
                        borderLeft: "1px solid #212330",
                    },
                }}
                sx={{
                    backdropFilter: "blur(2px)",
                }}
            >
                { isSnapCollapsed && (
                    <Button
                        className={`${styles.LogoutButton ?? ""}`}
                        onClick={handleClose}
                        sx={{
                            backgroundColor: currentTheme === "dark" ? "#ffffff" : "#202020",
                            color: "red",
                            width: 55,
                            height: 55,
                            minWidth: "initial",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            position: "absolute",
                            left: "-28px",
                            top: 40,
                            zIndex: 2000,
                            "&:hover": { background: "white !important" },
                        }}
                    >
                        <CloseIcon sx={{ width: 25, height: 25 }} />
                    </Button>
                )}
                {activeStep === 1 && (
                    <Button
                        onClick={handleBack}
                        sx={{
                            backgroundColor: currentTheme === "dark" ? "#3955BF" : "#202020",
                            color: "red",
                            width: 55,
                            height: 55,
                            minWidth: "initial",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "50%",
                            position: "absolute",
                            left: "380px",
                            top: 40,
                            zIndex: 2000,
                            "&:hover": {
                                backgroundColor: currentTheme === "dark" ? "#202020" : "#3955BF",
                                color: "white",
                            },
                        }}
                    >
                        <ArrowBackOutlinedIcon sx={{ width: 25, height: 25, color: "#ffffff" }} />
                    </Button>
                )}
                <Box style={{ paddingTop: "50px", paddingLeft: "50px", paddingRight: "30px", flex: 1, overflowY: "auto" }}>
                    <Box style={{ paddingBottom: "20px" }}>
                        <Typography
                            style={{ color: currentTheme === "dark" ? "#ffffff" : "#202020", fontSize: "32px" }}
                            variant="contained"
                        >
                            {activeStep === 0 ? (
                                <>
                                    Select your SnapChat
                                    <br />
                                    Business Account
                                </>
                            ) : (
                                <>
                                    Select your SnapChat
                                    <br />
                                    Ad Account
                                </>
                            )}
                        </Typography>
                    </Box>

                    {activeStep === 0 && (
                        <Box
                            style={{
                                paddingTop: "20px",
                                border: "1px",
                                borderStyle: "solid",
                                borderRadius: "20px",
                                color: "#ffffff",
                                backgroundColor: currentTheme === "dark" ? "#0B0E24" : "#fff",
                                borderColor: "#3955BF"
                            }}
                        >
                            <Box
                                style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }}
                                sx={{
                                    "&::-webkit-scrollbar": {
                                        width: "4px",
                                        height: "5px",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#fff",
                                        borderRadius: "10px",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: currentTheme === "dark" ? "#0B0E24" : "rgba(255,255,255,0.43)",
                                    },
                                }}
                            >
                                <Typography
                                    style={{
                                        color: currentTheme === "dark" ? "#fff" : "#0B0E24",
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "16.8px",
                                        fontFamily: "Tomorrow",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        margin: "4px 10px",
                                    }}
                                    variant="contained"
                                >
                                    {activeStep + 1}/2
                                </Typography>
                                <Divider
                                    sx={{
                                        paddingTop: "2px",
                                        background: currentTheme === "dark" ? "#1b2440" : "rgba(255,255,255,0.68)",
                                    }}
                                />
                                <FormControl>
                                    <RadioGroup
                                        aria-labelledby="meta-business-accounts-label"
                                        overlay
                                        name="meta-business-accounts"
                                        defaultValue={snapBusinessAccounts[0]?.id}
                                        sx={{
                                            borderRadius: 'sm',
                                            boxShadow: 'sm'
                                        }}
                                        onChange={handleBusinessRadioChange}
                                    >
                                        <List
                                            component="div"
                                            variant="outlined"
                                            orientation="vertical"
                                            sx={{
                                                borderRadius: 'sm',
                                                boxShadow: 'sm'
                                            }}
                                        >
                                            {snapBusinessAccounts.map((business, index) => (
                                                <React.Fragment key={business.id}>
                                                    {index !== 0 && <ListDivider
                                                        sx={{
                                                            margin: '0',
                                                            width: '50vh', // Ensures the divider matches the width of the Box
                                                        }}
                                                    />}
                                                    <ListItem sx={{  backgroundColor: selectedBusiness === business.id
                                                            ? (currentTheme === "dark" ? "#242633" : "#D9D9D9") : ''  , width: '100vh' }}>
                                                        <FormControlLabel
                                                            value={business.id}
                                                            control={
                                                                <Radio
                                                                    sx={{
                                                                        color: radioColor,
                                                                        '&.Mui-checked': { color: radioColor },
                                                                        '& .MuiSvgIcon-root': {
                                                                            fontSize: '1.5rem',
                                                                        },
                                                                        '&:not(.Mui-checked)': {
                                                                            '& .MuiSvgIcon-root': {
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                width: 24,
                                                                                height: 24,
                                                                                borderRadius: '50%',
                                                                                border: `2px solid`,
                                                                                borderColor: currentTheme === 'dark' ? '#212330' : '#E5EBFF',
                                                                                backgroundColor: currentTheme === 'dark' ? '#212330' : '#E5EBFF',
                                                                            },
                                                                        },
                                                                    }}
                                                                    checked={selectedBusiness === business.id}
                                                                    checkedIcon={
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                width: 28,
                                                                                height: 28,
                                                                                borderRadius: '50%',
                                                                                border: `1px solid ${radioColor}`,
                                                                                backgroundColor: '#3955BF',
                                                                            }}
                                                                        >
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    borderRadius: '50%',
                                                                                    position: 'relative',
                                                                                    backgroundColor: '#000',
                                                                                }}
                                                                            >
                                                                                <DoneRoundedIcon
                                                                                    sx={{
                                                                                        width: 20,
                                                                                        height: 20,
                                                                                        color: '#fff',
                                                                                        fontSize: '1rem',
                                                                                        backgroundColor: '#3955BF',
                                                                                        borderRadius: '50%', // Ensures the background is circular
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center'
                                                                                    }}
                                                                                />
                                                                            </Box>
                                                                        </Box>
                                                                    }
                                                                    icon={
                                                                        <Box
                                                                            sx={{
                                                                                display: 'flex',
                                                                                alignItems: 'center',
                                                                                justifyContent: 'center',
                                                                                width: 24,
                                                                                height: 24,
                                                                                borderRadius: '50%',
                                                                                border: `2px solid ${currentTheme === 'dark' ? '#212330' : '#E5EBFF'} `,
                                                                                backgroundColor: currentTheme === 'dark' ? '#212330': '#E5EBFF',
                                                                            }}
                                                                        />
                                                                    }
                                                                />
                                                            }
                                                            label={
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    <Typography sx={{ padding: '5px',  fontFamily: 'Tomorrow', color: currentTheme === "dark" ? "#fff" :"#0B0E24"  }}>{business.name}</Typography>
                                                                </div>
                                                            }
                                                        />
                                                    </ListItem>
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    )}

                    {activeStep === 1 && (
                        <Box
                            style={{
                                paddingTop: "20px",
                                border: "1px",
                                borderStyle: "solid",
                                borderRadius: "20px",
                                color: "#ffffff",
                                backgroundColor: currentTheme === "dark" ? "#0B0E24" : "#fff",
                                borderColor: "#3955BF"
                            }}
                        >
                            <Box
                                style={{ height: "50vh", overflowY: "auto", overflowX: "hidden" }}
                                sx={{
                                    "&::-webkit-scrollbar": {
                                        width: "4px",
                                        height: "5px",
                                    },
                                    "&::-webkit-scrollbar-thumb": {
                                        backgroundColor: "#fff",
                                        borderRadius: "10px",
                                    },
                                    "&::-webkit-scrollbar-track": {
                                        backgroundColor: currentTheme === "dark" ? "#0B0E24" : "rgba(255,255,255,0.43)",
                                    },
                                }}
                            >
                                <Typography
                                    style={{
                                        color: currentTheme === "dark" ?  "#fff": "#0B0E24" ,
                                        fontSize: "14px",
                                        fontWeight: 500,
                                        lineHeight: "16.8px",
                                        fontFamily: "Tomorrow",
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        margin: "4px 10px",
                                    }}
                                    variant="contained"
                                >
                                    {activeStep + 1}/2
                                </Typography>
                                <Divider
                                    sx={{
                                        paddingTop: "2px",
                                        background: currentTheme === "dark" ? "#1b2440" : "rgba(255,255,255,0.68)",
                                    }}
                                />
                                <FormControl component="fieldset">
                                    <RadioGroup
                                        aria-label="Snap-accounts"
                                        name="Snap-accounts"
                                        value={selectedAccount}
                                        onChange={handleRadioChange}
                                    >
                                        <List
                                            component="div"
                                            variant="outlined"
                                            orientation="vertical"
                                            sx={{
                                                borderRadius: 'sm',
                                                boxShadow: 'sm'
                                            }}
                                        >
                                            {Array.isArray(snapAddAccount) && snapAddAccount.length > 0 ? (
                                                snapAddAccount.map((account, index) => (
                                                    <React.Fragment key={account.id}>
                                                        {index !== 0 && <ListDivider
                                                            sx={{
                                                                margin: '0',
                                                                width: '50vh', // Ensures the divider matches the width of the Box
                                                            }}
                                                        />}
                                                        <ListItem sx={{  backgroundColor: selectedAccount === account.id
                                                                ? (currentTheme === "dark" ? "#242633" : "#D9D9D9") : ''  , width: '100vh' }}>
                                                            <FormControlLabel
                                                                value={account.id}
                                                                control={
                                                                    <Radio
                                                                        sx={{
                                                                            color: radioColor,
                                                                            '&.Mui-checked': { color: radioColor },
                                                                            '& .MuiSvgIcon-root': {
                                                                                fontSize: '1.5rem',
                                                                            },
                                                                            '&:not(.Mui-checked)': {
                                                                                '& .MuiSvgIcon-root': {
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    borderRadius: '50%',
                                                                                    borderColor: currentTheme === 'dark' ? '#212330' : '#E5EBFF',
                                                                                    backgroundColor: currentTheme === 'dark' ? '#212330' : '#E5EBFF',
                                                                                },
                                                                            },
                                                                        }}
                                                                        checkedIcon={
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    width: 28,
                                                                                    height: 28,
                                                                                    borderRadius: '50%',
                                                                                    border: `1px solid ${radioColor}`,
                                                                                    backgroundColor: '#3955BF',
                                                                                }}
                                                                            >
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'center',
                                                                                        width: 24,
                                                                                        height: 24,
                                                                                        borderRadius: '50%',
                                                                                        position: 'relative',
                                                                                        backgroundColor: '#000',
                                                                                    }}
                                                                                >
                                                                                    <DoneRoundedIcon
                                                                                        sx={{
                                                                                            width: 20,
                                                                                            height: 20,
                                                                                            color: '#fff',
                                                                                            fontSize: '1rem',
                                                                                            backgroundColor: '#3955BF',
                                                                                            borderRadius: '50%', // Ensures the background is circular
                                                                                            display: 'flex',
                                                                                            alignItems: 'center',
                                                                                            justifyContent: 'center'
                                                                                        }}
                                                                                    />
                                                                                </Box>
                                                                            </Box>
                                                                        }
                                                                        icon={
                                                                            <Box
                                                                                sx={{
                                                                                    display: 'flex',
                                                                                    alignItems: 'center',
                                                                                    justifyContent: 'center',
                                                                                    width: 24,
                                                                                    height: 24,
                                                                                    borderRadius: '50%',
                                                                                    border: `2px solid ${currentTheme === 'dark' ? '#212330' : '#E5EBFF'} `,
                                                                                    backgroundColor: currentTheme === 'dark' ? '#212330': '#E5EBFF',
                                                                                }}
                                                                            />
                                                                        }
                                                                    />
                                                                }
                                                                label={
                                                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                        <span style={{ color: currentTheme === "dark" ? "#fff" :"#0B0E24" , fontFamily: 'Tomorrow'  }}>{account.accountName}</span>
                                                                        <span style={{ color: '#6F7288' , fontFamily: 'Tomorrow', fontSize: '13px'}}>{account.id}</span>
                                                                        <Button
                                                                            variant="contained"
                                                                            size="small"
                                                                            sx={{
                                                                                width: '124px',
                                                                                height: '25px',
                                                                                marginTop: '8px', // Add some spacing between the ID and the button
                                                                                backgroundColor: '#6F7288',
                                                                                color: '#fff',
                                                                                fontSize: '0.75rem',
                                                                                padding: '4px 8px',
                                                                                borderRadius: '6px',
                                                                                opacity: 1,
                                                                                gap: '0px',
                                                                                '&:hover': {
                                                                                    backgroundColor: '#145dbf',
                                                                                },
                                                                            }}
                                                                        >
                                                                            { account.status }
                                                                        </Button>
                                                                    </Box>
                                                                }
                                                            />
                                                        </ListItem>
                                                    </React.Fragment>
                                                ))
                                            ) : (
                                                <Typography style={{ color: '#6F7288', display: 'flex', justifyContent: 'center' }}>Loading</Typography>
                                            )}
                                        </List>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Box>
                    )}
                </Box>
                {/*<Box sx={{ padding: "16px", borderTop: `1px solid ${currentTheme === 'dark' ? '#1b2440' : 'rgba(255,255,255,0.68)'}`, backgroundColor: currentTheme === 'dark' ? '#0B0E24' : '#191d3c' }}>*/}
                <Box sx={{ padding: "16px", paddingLeft: "45px"  }}>
                    {!loading && activeStep === 0 && (
                        <Button variant="contained" color="primary" fullWidth onClick={handleNext} style={{ height: "40px", backgroundColor: "#3955BF" }}>
                            Next
                        </Button>
                    )}
                    {!loading && activeStep === 1 && (
                        <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth style={{ backgroundColor: "#3955BF" }}>
                            Select
                        </Button>
                    )}
                </Box>
            </SwipeableDrawer>
        </>
    );
};

const mapStateToProps = (state) => ({
    user: state.auth.auth.user,
    currentTheme: state.theme.currentTheme
});

export default connect(mapStateToProps)(SnapPlatformDetails);
