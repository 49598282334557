import React, { useEffect, useState, useRef } from "react";
import { Box, Modal } from "@mui/material";
import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
import Image from "mui-image";

import styles from './SelectedCountryModal.module.scss';
import { baseUrl } from "../../../../config/config";
import { defaultCountries } from "../../../../config/countries.config";
import { useSelector } from "react-redux";
import SimpleMap from "../../map/SimpleMap";

SwiperCore.use([Keyboard, Mousewheel]);

const SelectedCountryModal = ({ selectedCountryModalOpen, handleSelectedCountryModalClose, setLoginData, userData }) => {

  const [selectedCountry, setSelectedCountry] = useState(false);
  const [selectedCountryWithCoords, setSelectedCountryWithCoords] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const selectedCountryRef = useRef(selectedCountry);

  const { currentTheme } = useSelector((state) => state.theme);

  const setSelectedCountryRef = (data) => {
    // console.log("data", data);
    if (data) {
      selectedCountryRef.current = data;
      setSelectedCountry(data);
      handleSelectedCountryCoords();
    } else {
      if (userData?.countries?.length) {
        selectedCountryRef.current = userData?.countries[0];
        setSelectedCountry(userData?.countries[0]);
        handleSelectedCountryCoords();
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (userData?.countries?.length) {
        // console.log("userData?.countries[0]", userData?.countries[0]);
        setSelectedCountryRef(userData?.countries[0]);
        window.addEventListener("keydown", handleKeyDown);
      }
    }

    return () => {
      mounted = false;
      window.removeEventListener("keydown", handleKeyDown);
    }
  }, [userData]);

  const handleKeyDown = (event) => {
    // console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
    if (event.key === 'Enter') {
      setTimeout(() => {
        handleSelectedCountry();
      }, 50);
    }
  }

  const handleCountryChange = () => {
    const selectedIndex = swiper?.realIndex;
    // console.log(selectedIndex);
    if (selectedIndex !== undefined || selectedIndex !== null) {
      setSelectedCountryRef(userData?.countries[selectedIndex]);
    }
  };

  const handleSelectedCountryCoords = () => {
    if (selectedCountryRef?.current) {
      const countryFromDefaulCountries = defaultCountries.find(c => c.name === selectedCountryRef.current.name);
      const selectedCountryDummy = {
        ...selectedCountryRef.current,
        ...countryFromDefaulCountries
      };
      setSelectedCountryWithCoords(selectedCountryDummy);
    }
  };

  const handleSelectedCountry = () => {
    // console.log("selectedCountry", selectedCountry);
    // console.log("selectedCountryRef", selectedCountryRef.current.isAvailable);
    if (selectedCountryRef?.current.isAvailable) {
      const countryFromDefaulCountries = defaultCountries.find(c => c.name === selectedCountryRef.current.name);
      const selectedCountryDummy = {
        ...selectedCountryRef.current,
        ...countryFromDefaulCountries
      };
      setLoginData(selectedCountryDummy, userData);
      handleSelectedCountryModalClose();
    }
  };

  const length = userData.countries.length;
  const slidesPerView = length < 3 ? 1 : length <= 4 ? 3 : 5;

  return (<>
    <Box sx={{ overflowX: 'hidden', height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0 }}>
      <SimpleMap selectedCountry={selectedCountryWithCoords} />
    </Box>
    <Modal
      open={selectedCountryModalOpen}
      onClose={handleSelectedCountryModalClose}
      disableEscapeKeyDown={true}
      aria-labelledby="selected-country-modal-title"
      aria-describedby="selected-country-modal-description"
      sx={{
        backdropFilter: "blur(5px)",
      }}
    >
      <Box className="SelectedCountryModal" sx={{ display: 'block', position: 'fixed', left: 0, top: '50%', transform: 'translateY(-50%)', width: '100vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto', border: 'none' }}>
        {userData?.countries?.length > 0 &&
          <Box className="SelectedCountryModalContent" sx={{ display: 'block', textAlign: 'center', p: 10, height: '100%' }}>
            <Box className="SelectedCountryModalTitle" sx={{ textAlign: 'center', mb: 3, fontSize: 32, lineHeight: '40px', fontWeight: 700, color: '#fff' }}>Select a Country</Box>
            <Box className={`${styles.CountrySliderWrapper ?? ''} CountrySliderWrapper`} sx={{ position: 'relative', width: '100%' }}>
              <Swiper
                className={`${styles.CountrySlider ?? ''} EqualHeight`}
                spaceBetween={30}
                slidesPerView={slidesPerView}
                modules={[Navigation]}
                loop={false}
                centeredSlides={true}
                grabCursor={true}
                keyboard={true}
                mousewheel={true}
                navigation={{
                  nextEl: '.CountrySliderWrapper .swiper-button-next',
                  prevEl: '.CountrySliderWrapper .swiper-button-prev',
                }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  // when window width is >= 480px
                  700: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  900: {
                    slidesPerView: slidesPerView,
                    spaceBetween: 20
                  },
                  1200: {
                    slidesPerView: slidesPerView,
                    spaceBetween: 30
                  },
                  1600: {
                    slidesPerView: slidesPerView,
                    spaceBetween: 30
                  },
                  2000: {
                    slidesPerView: slidesPerView,
                    spaceBetween: 30
                  }
                }}
                onSlideChange={() => handleCountryChange()}
                onSwiper={(swiper) => setSwiper(swiper)}
              >
                {userData.countries.map((country, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Box className="CountryBox" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', transition: '0.5s' }}>
                        <Box className="CountryBoxFlag" sx={{ width: 120, height: 120, borderRadius: '50%', overflow: 'hidden', position: 'relative', zIndex: 1, transition: '0.5s', backgroundColor: currentTheme === 'light' ? '#a7a7a7' : '#010412', filter: country.isAvailable ? 'none' : 'grayscale(100%)' }}>
                          <Image src={baseUrl + country.path} />
                          {/* {!country.isAvailable &&
                            <Box sx={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <BlockIcon sx={{ fontSize: 170, color: '#fff', opacity: 0.5, transition: '0.5s' }} />
                            </Box>
                          } */}
                        </Box>
                        <Box className="CountryBoxName" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px', overflow: 'hidden', background: '#fff', color: '#3C4DE7', mt: -3, position: 'relative', zIndex: 2, pl: 1.5, pr: 2, py: 1.5, transition: '0.5s', filter: country.isAvailable ? 'none' : 'grayscale(100%)' }}>
                          <Box className="CountryBoxNameCheck" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#39BF8F", mr: 1, transition: '0.5s' }}>
                            <CheckCircleIcon sx={{ width: 20, height: 20, transition: '0.5s' }} />
                          </Box>
                          <Box className="CountryBoxNameText" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, lineHeight: '20px', transition: '0.5s', whiteSpace: 'nowrap' }}>{country.code ? country.code : country.name}</Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <div className="swiper-buttons">
                <div className="swiper-button-prev">
                  <WestIcon sx={{ width: 18, height: 18 }} />
                </div>
                <div className="swiper-button-next">
                  <EastIcon sx={{ width: 18, height: 18 }} />
                </div>
              </div>
            </Box>

            <Button variant="contained" className="SelectedCountryModalSubmitButton" sx={{ mt: 6, px: 6, py: 1.5, fontSize: 20, textTransform: 'capitalize', filter: selectedCountryRef.current.isAvailable ? "" : "brightness(0.6)", pointerEvents: selectedCountryRef.current.isAvailable ? "" : "none" }} onClick={() => handleSelectedCountry()}>
              Select
            </Button>
          </Box>
        }
      </Box>
    </Modal>
  </>
  );
};

export default SelectedCountryModal;
