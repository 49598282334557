import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Box, Modal } from "@mui/material";
// import Button from "@mui/material/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Keyboard, Mousewheel } from "swiper/core";
import Image from "mui-image";
import { useDispatch } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';

import styles from './ActivateCampaignModal.scss';
import { baseUrl } from "../../../../config/config";
import connectorService from "../../../../services/api/connector.service";
import AudienceService from "../../../../services/api/audience.service";
import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import { LoadingButton } from "@mui/lab";
// import { defaultCountries } from "../../../../config/countries.config";

SwiperCore.use([Keyboard, Mousewheel]);

const ActivateCampaignModal = ({ activateCampaignModalState, setActivateCampaignModalState, audienceId, selectedCompareId }) => {

  const { user } = useSelector((state) => state.auth.auth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();

  // const { selectedCountry } = useSelector((state) => state.auth);
  const [selectedConnector, setSelectedConnector] = useState(null);
  // const [selectedCountry, setSelectedCountry] = useState(null);
  const [swiper, setSwiper] = useState(null);
  const [data, setData] = useState(null);

  const selectedConnectorRef = useRef(selectedConnector);

  const setSelectedConnectorRef = (data) => {
    // console.log("data", data);
    if (data) {
      selectedConnectorRef.current = data;
      setSelectedConnector(data);
    } else {
      if (data?.results?.length) {
        selectedConnectorRef.current = data.results[0];
        setSelectedConnector(data.results[0]);
      }
    }
  };

  useEffect(() => {
    let mounted = true;
    if (mounted && activateCampaignModalState) {
      connectorService.getAllConnectors().then((data) => {
        if (data) {
          // console.log(data.results[0]);
          setData(data);
          setSelectedConnectorRef(data.results[0]);
          window.addEventListener("keydown", handleKeyDown);
        }
      });
    }

    return () => {
      mounted = false;
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [activateCampaignModalState]);


  const handleKeyDown = (event) => {
    // console.log(`Key: ${event.key} with keycode ${event.keyCode} has been pressed`);
    if (event.key === 'Enter') {
      // console.log(event.key);
      setTimeout(() => {
        activate();
      }, 50);
    }
  }

  const handleConnectorChanage = () => {
    const selectedIndex = swiper?.realIndex;
    // console.log(selectedIndex);
    if (selectedIndex !== undefined || selectedIndex !== null) {
      setSelectedConnectorRef(data?.results[selectedIndex]);
    }
  };

  const handleClose = () => {
    setActivateCampaignModalState(false);
  };

  const activate = () => {
    const queryParams = [
      selectedCompareId ? `compareId=${selectedCompareId}` : `audienceId=${audienceId}`,
      `partnerId=${selectedConnector.id}`,
      `userId=${user.id}`,
    ];

    const serviceFunction = selectedCompareId
      ? AudienceService.activateCompare
      : AudienceService.activateCampaign;

    setIsSubmitting(true);

    serviceFunction(queryParams.join('&'))
      .then(
        (data) => {
          setIsSubmitting(false);
          setActivateCampaignModalState(false);
          dispatch(setMessage({
            type: "success",
            title: "Card Activated Successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));
        },
        (error) => {
          console.log(error);
          setIsSubmitting(false);
        }
      );
  }

  return (
    <Modal
      open={activateCampaignModalState}
      onClose={handleClose}
      aria-labelledby="selected-country-modal-title"
      aria-describedby="selected-country-modal-description"
      sx={{
        backdropFilter: "blur(10px)",
      }}
    >
      <Box className="SelectedCountryModal" sx={{ display: 'block', position: 'fixed', left: 0, top: '50%', transform: 'translateY(-50%)', width: '100vw', maxHeight: '100vh', overflow: 'hidden', overflowY: 'auto', border: 'none' }}>
        <CloseIcon onClick={handleClose} sx={{
          cursor: 'pointer', fontSize: 32, color: "#FF0000", background: '#FFF', borderRadius: '50px', padding: 0.5, position: 'absolute', top: 0, right: 25,
          '&:hover': {
            color: '#FFF',
            background: '#FF0000',
          }
        }} />
        {data?.results?.length > 0 &&
          <Box className="SelectedCountryModalContent" sx={{ display: 'block', textAlign: 'center', p: [6.5, 10], height: '100%' }}>
            <Box sx={{ textAlign: 'center', mb: 3, fontSize: [25, 32], lineHeight: '40px', fontWeight: 700, color: '#fff' }}>Select a connector to activate campaign</Box>
            <Box className={`${styles.CountrySliderWrapper ?? ''} CountrySliderWrapper`} sx={{ position: 'relative', width: '100%' }}>
              <Swiper
                className={`${styles.CountrySlider ?? ''} EqualHeight`}
                spaceBetween={30}
                slidesPerView={3}
                modules={[Navigation]}
                loop={false}
                centeredSlides={true}
                grabCursor={true}
                keyboard={true}
                mousewheel={true}
                navigation={{
                  nextEl: '.CountrySliderWrapper .swiper-button-next',
                  prevEl: '.CountrySliderWrapper .swiper-button-prev',
                }}
                breakpoints={{
                  // when window width is >= 320px
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 0
                  },
                  // when window width is >= 480px
                  700: {
                    slidesPerView: 3,
                  },
                  900: {
                    slidesPerView: 3,
                    spaceBetween: 20
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  },
                  1600: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  },
                  2000: {
                    slidesPerView: 3,
                    spaceBetween: 30
                  }
                }}
                onSlideChange={() => handleConnectorChanage()}
                onSwiper={(swiper) => setSwiper(swiper)}
              >
                {data.results.map((connector, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <Box className="CountryBox" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', transition: '0.5s', transform: 'scale(0.9)' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', transition: '0.5s', transform: 'scale(0.7)', filter: connector.id === 3 ? 'grayscale(100%)' : 'none' }}>
                          <Box className="CountryBoxFlag" sx={{ width: 120, height: 120, borderRadius: '50%', overflow: 'hidden', position: 'relative', zIndex: 1, transition: '0.5s', backgroundColor: '#010412' }}>
                            <Image src={baseUrl + connector.imageUrl} />
                          </Box>
                          <Box className="CountryBoxName" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px', overflow: 'hidden', background: '#fff', color: '#3C4DE7', mt: -3, position: 'relative', zIndex: 2, pl: 1.5, pr: 2, py: 1.5, transition: '0.5s' }}>
                            <Box className="CountryBoxNameCheck" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: "#39BF8F", mr: 1, transition: '0.5s' }}>
                              <CheckCircleIcon sx={{ width: 20, height: 20, transition: '0.5s' }} />
                            </Box>
                            <Box className="CountryBoxNameText" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: 20, lineHeight: '20px', transition: '0.5s', whiteSpace: 'nowrap' }}>{connector.name}</Box>
                          </Box>
                        </Box>
                      </Box>
                    </SwiperSlide>
                  )
                })}
              </Swiper>

              <div className="swiper-buttons">
                <div className="swiper-button-prev">
                  <WestIcon sx={{ width: 18, height: 18 }} />
                </div>
                <div className="swiper-button-next">
                  <EastIcon sx={{ width: 18, height: 18 }} />
                </div>
              </div>
            </Box>

            <LoadingButton
              variant="contained" className="form-button" sx={{ mt: 5, px: 5, py: 1, fontSize: [16, 20], textTransform: 'capitalize', filter: selectedConnectorRef.current.id === 3 ? "brightness(0.6)" : "", pointerEvents: selectedConnectorRef.current.id === 3 ? "none" : "" }} loading={isSubmitting} onClick={() => activate()}
            >
              Activate
            </LoadingButton>
          </Box>
        }
      </Box>
    </Modal >
  );
};

export default ActivateCampaignModal;