
export function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

export function getPercentage(value, total) {
  if (total) {
    if (total === 0) {
      return 0;
    } else {
      return ((value / total) * 100).toFixed(2);
    }
  } else {
    return 0;
  }
}

export function getNumberUnit(num, round = 1) {
  const unit = Math.floor(Math.round(num / 1.0e+1).toLocaleString().replaceAll(',', '').length),
    // wunit = ["Thousand", "Million", "Billion", "Trillion", "Quadrillion", "Quintillion", "Sextillion", "Septillion", "Octillion", "Nonillion", "Decillion", "Undecillion", "Duodecillion", "Tredecillion", "Quattuordecillion", "Quindecillion", "Sexdecillion", "Septemdecillion", "Octodecillion", "Novemdecillion", "Vigintillion", "Unvigintillion", "Duovigintillion", "Trevigintillion", "Quattuorvigintillion", "Quinvigintillion", "Sexvigintillion", "Septvigintillion", "Octovigintillion", "Nonvigintillion", "Trigintillion", "Untrigintillion", "Duotrigintillion"][Math.floor(unit / 3) - 1],
    wunit = ["K", "M", "B", "T", "AA", "AB", "AC", "AD", "AE", "AF", "AG", "AH", "AI", "AJ", "AK", "AL", "AM", "AN", "AO", "AP", "AQ", "AR", "AS", "AT", "AU", "AV", "AW", "AX", "AY", "AZ", "BA", "BB", "BC"][Math.floor(unit / 3) - 1],
    funit = Math.abs(Number(num)) / Number('1.0e+' + (unit - unit % 3));
  return wunit ? funit.toFixed(round).toLocaleString() + ' ' + wunit : num.toFixed(round).toString();
}
