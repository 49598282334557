import { createSlice } from '@reduxjs/toolkit';

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    mapPoints: [],
    mapLoading: false
  },
  reducers: {
    setMapPoints: (state, action) => {
      state.mapPoints = action.payload;
    },
    setMapLoading: (state, action) => {
      state.mapLoading = action.payload;
    }
  },
});

export const { setMapPoints, setMapLoading } = mapSlice.actions;

export default mapSlice.reducer;
