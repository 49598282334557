import React, { useState , useCallback, useEffect} from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import {Box, CircularProgress, MenuItem, Switch, Select, IconButton} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GridColumnMenu, GridToolbarQuickFilter, gridClasses, GridRenderEditCellParams, GridColDef, useGridApiRef } from '@mui/x-data-grid';
import { grey } from '@mui/material/colors';
import { styled } from '@mui/material/styles';
import { DataGrid, GridEditSingleSelectCellProps, useGridApiContext, GridEditSingleSelectCell } from '@mui/x-data-grid';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';

import { setMessageSnackbarStateState, setExternalUserManagementModalState } from '../../../../redux/slices/common';
import { setMessage } from '../../../../redux/slices/message';
import AuthService from '../../../../services/api/auth.service'
import UserService from '../../../../services/api/user.service';
import {GridRowsProp} from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";


// const roles = ['Market', 'Finance', 'Development'];
// const randomRole = () => {
//   return randomArrayItem(roles);
// };

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
            }}
        >
            <GridToolbarQuickFilter sx={{ border: "none", borderBottom: 0, padding: 0 }} autoComplete='off' />
        </Box>
    );
}

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                // Hide `columnMenuFilterItem`
                columnMenuFilterItem: null,
            }}
        />
    );
}

const useFakeMutation = () => {
    return React.useCallback(
        (user) =>
            new Promise((resolve, reject) => {
                setTimeout(() => {
                    if (user.name?.trim() === '') {
                        reject(new Error("Error while saving user: name can't be empty."));
                    } else {
                        resolve({ ...user, name: user.name?.toUpperCase() });
                    }
                }, 200);
            }),
        [],
    );
};

function ExternalUserManagementModal() {

    const { externalUserManagementModalState } = useSelector((state) => state.common);
    const { user } = useSelector((state) => state.auth.auth);
    const { selectedCountry } = useSelector((state) => state.auth);
    const { currentTheme } = useSelector((state) => state.theme);
    const currentThemeColor = currentTheme === 'light' ? '#010412' : '#FFF';
    const dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState({});

    const [tableData, setTableData] = useState([]);
    // const [tierName, setTierName] = useState('');
    // const [monthValue, setMonthValue] = useState('');

    const externalUsersCall = async () => {
        try {
            if (user) {
                const data = await UserService.externalUserCall();

                if (data?.results?.length) {
                    // const convertedData = data.results.map((item) => ({
                    //   ...item,
                    //   dateOfExpiring: new Date(item.dateOfExpiring),
                    //   dateOfApproval: new Date(item.dateOfApproval)
                    // }));
                    setTableData(data.results);
                } else {
                    setTableData([]);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (externalUserManagementModalState) {
            externalUsersCall();
        }
        //apiRef.current.setRows(tableData);
    }, [externalUserManagementModalState]);

    // const processRowUpdate = React.useCallback(
    //     async (newRow) => {
    //         try {
    //             // Update data in the backend
    //             const queryParams = [`userId=${newRow.id}`, `cardLimit=${newRow.cardLimit}`];
    //             const backendResponse = await AuthService.updateDemoUser(queryParams.join('&'));
    //
    //             // Update the user in the local state
    //             const updatedUser = await mutateRow(newRow);
    //
    //             // Display a success message
    //             dispatch(
    //                 setMessage({
    //                     type: 'success',
    //                     title: backendResponse.message,
    //                 })
    //             );
    //             dispatch(setMessageSnackbarStateState(true));
    //
    //             return updatedUser;
    //         } catch (error) {
    //             // Handle errors consistently
    //             console.log('Error:', error);
    //             // Display an error message if needed
    //             dispatch(
    //                 setMessage({
    //                     type: 'error',
    //                     title: error.message,
    //                 })
    //             );
    //             dispatch(setMessageSnackbarStateState(true));
    //             throw error; // Rethrow the error for any higher-level error handling
    //         }
    //     },
    //     [dispatch, setMessage, setMessageSnackbarStateState, mutateRow]
    // );


    const handleChange = (params) => {
        // setTierName(params.row.planType);
        // setMonthValue(params.row.packageValidityInMonths);
        // setLoading(true);
        const { id } = params.row;
        setLoadingState((prevState) => ({
            ...prevState,
            [id]: true,
        }));
        const newIsApprove = !params.row.isApprove; // Toggle the isApprove status


        if((params.row.validity == null) || (params.row.planType == null)) {

            dispatch(setMessage({
                type: "error",
                title: params.row.planType === null ? 'planType is required' : 'Validity is required',
            }));
            dispatch(setMessageSnackbarStateState(true));
            setLoadingState((prevState) => ({
                ...prevState,
                [id]: false,
            }));
            return;
        }


        if (params.row.planType === 'Enterprise' &&
            (!(params.row?.audienceCount > 10000) || !(params.row?.cardCount > 0))) {

            dispatch(setMessage({
                type: "error",
                title: 'Audience count and Card count must be greater than 10000',
            }));
            dispatch(setMessageSnackbarStateState(true));
            setLoadingState((prevState) => ({
                ...prevState,
                [id]: false,
            }));
            return;
        }

        if (params.row.planType === 'Enterprise' &&
            (!(params.row?.audienceCost > 0) || !(params.row?.cardCost > 0))) {

            dispatch(setMessage({
                type: "error",
                title: 'Audience Cost and Card Cost must be greater than 0',
            }));
            dispatch(setMessageSnackbarStateState(true));
            setLoadingState((prevState) => ({
                ...prevState,
                [id]: false,
            }));
            return;
        }


        const queryParams = [
            `userId=${Number(params.row.id)}`,
            `planType=${params.row.planType}`,
            `validity=${params.row.validity}`,
            `isApprove=${newIsApprove}`,
        ]

        if (params.row.planType === 'Enterprise') {

            queryParams.push(`audienceCount=${params.row.audienceCount}`);
            queryParams.push(`cardCount=${params.row.cardCount}`);
            queryParams.push(`audienceCost=${params.row.audienceCost}`);
            queryParams.push(`cardCost=${params.row.cardCost}`);
        }

        UserService.updateExternalUserDetails(queryParams.join('&')).then(
            (data) => {
                // setLoading(false);
                setLoadingState((prevState) => ({
                    ...prevState,
                    [id]: false,
                }));
                dispatch(setMessage({
                    type: "success",
                    title: data.message,
                }));
                dispatch(setMessageSnackbarStateState(true));

                const updatedTableData = tableData.map((row) => {
                    if (row.email === params.row.email) {
                        return { ...row, isApprove: newIsApprove };
                    }
                    return row;
                });
                setTableData(updatedTableData);
                externalUsersCall();
            })

            .catch((error) => {
                // setLoading(false);
                setLoadingState((prevState) => ({
                    ...prevState,
                    [id]: false,
                }));
                dispatch(setMessage({
                    type: "error",
                    title: error.response.data.message,
                }));
                dispatch(setMessageSnackbarStateState(true));
            });
    };

    const optionStyle = {
        minWidth: '100px',
        maxWidth: '100px',
        minHeight: '52px',
        maxHeight: '52px'
    };

    const formatDateForQueryParam = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 because months are 0-based
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const  CustomTierEditCell = (params: GridEditSingleSelectCellProps) => {
        const ref = useGridApiContext();
        return (
            <GridEditSingleSelectCell
                {...params}
                onValueChange={async (event, newValue) => {
                    await ref.current.setEditCellValue(
                        { id: params.id, field: params.field, value: newValue },
                        event
                    );

                    ref.current.stopCellEditMode({ id: params.id, field: params.field });
                }}
            />
        );
    };


    const  CustomValidityEditCell = (params: GridEditSingleSelectCellProps) => {
        const ref = useGridApiContext();
        return (
            <GridEditSingleSelectCell
                {...params}
                onValueChange={async (event, newValue) => {

                    //setMonthValue(newValue);

                    await ref.current.setEditCellValue(
                        { id: params.id, field: params.field, value: newValue },
                        event
                    );
                    ref.current.stopCellEditMode({ id: params.id, field: params.field });
                }}
            />
        );
    };

    const CustomComponent = (params) => {
        const ref = useGridApiContext();

        return (
            <Box
                sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center"
                }}
            >
                <div>{params.value}</div>
                <IconButton size="small" sx={{ padding: 0 }} tabIndex={-1}
                    onClick={(event) => {
                        event.stopPropagation(); // to not select row
                        ref.current.startCellEditMode({
                            id: params.id,
                            field: params.field
                        });
                    }}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </Box>
        );
    };

    const processRowUpdate = (newRow) => {
        const updatedRows = tableData.map((row) => (row.id === newRow.id ? newRow : row));
        setTableData(updatedRows);
        return newRow;
    };

    // Handle row update error
    const handleProcessRowUpdateError = (error) => {
        console.error(error);
    };

    const genericPreProcessEditCellProps = (params, options) => {
        const value = params.props.value;
        let hasError = false;
        let errorMessage = '';

        if (isNaN(value)) {

            hasError = true;
            errorMessage = "Input is not a number";
        } else if (value === '') {

            hasError = true;
            errorMessage = "Input cannot be empty";
        } else if (Number(value) < 0) {

            hasError = true;
            errorMessage = "Negative values are not allowed";
        } else if (options?.max !== undefined && Number(value) >= options.max) {

            hasError = true;
            errorMessage = `Value must be less than ${options.max}`;
        }

        if (hasError) {
            dispatch(setMessage({
                type: "error",
                title: errorMessage,
            }));
            dispatch(setMessageSnackbarStateState(true));
        } else if (options?.incrementBy) {
            // If no error, increment the number by the specified increment value
            params.props.value = Number(value) + options.incrementBy;
        }

        return { ...params.props, error: hasError };
    };


    const genericFloatPreProcessEditCellProps = (params) => {
        const { props } = params;
        let value = props.value;
        let hasError = false;
        let errorMessage = '';
        // Check if the value is a valid number and allow decimal values

        if (isNaN(value)) {

            hasError = true;
            errorMessage = "Input is not a number";
        } else if (value === '') {

            hasError = true;
            errorMessage = "Input cannot be empty";
        }  else if (value < 0) { // Check if the value is negative

            hasError = true;
            errorMessage = "Input cannot be negative";
        }



        if (hasError) {
            dispatch(setMessage({
                type: "error",
                title: errorMessage,
            }));
            dispatch(setMessageSnackbarStateState(true));
        }


        return { ...props, value, hasError };
    };

    const formatDecimal = (value) => {
        if (value !== null && value !== undefined) {
            return value.toFixed(4);
        }
        return '';
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: 'companyName',
            headerName: 'Company Name',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            // editable: true,
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 190,
            align: 'center',
            headerAlign: 'center',
            // editable: true,
            type: 'mail',
        },
        {
            field: 'createdCards',
            headerName: 'Cards',
            width: 100,
            align: 'center',
            headerAlign: 'center',
        },
        {
            field: "planType",
            headerName: "planType",
            editable: true,
            type: "singleSelect",
            valueOptions: ({ row }) => {
                if (row) return row.tierOption;
            },
            renderEditCell: CustomTierEditCell,
            renderCell: CustomComponent,
            minWidth: 100
        },
        {
            field: "validity",
            headerName: "Validity",
            editable: true,
            type: "singleSelect",
            valueOptions: ({ row }) => {
                if (row) return row.tierValidityOption;
            },
            renderEditCell: CustomValidityEditCell,
            renderCell: CustomComponent,
            minWidth: 100
        },
        {
            field: "audienceCount",
            headerName: "AudienceCount",
            editable: true,
            minWidth: 100,
            type: "number",
            preProcessEditCellProps: (params) => genericPreProcessEditCellProps(params, { incrementBy: 1000 }),
        },
        {
            field: "cardCount",
            headerName: "CardCount",
            editable: true,
            minWidth: 100,
            type: "number",
            preProcessEditCellProps: (params) => genericPreProcessEditCellProps(params, { incrementBy: 1000, max: 200 }),
        },
        {
            field: "audienceCost",
            headerName: "AudienceCost",
            editable: true,
            minWidth: 100,
            type: "number",
            renderCell: (params) => formatDecimal(params.value),
            preProcessEditCellProps: (params) => genericFloatPreProcessEditCellProps(params),
        },
        {
            field: "cardCost",
            headerName: "CardCost",
            editable: true,
            minWidth: 100,
            type: "number",
            preProcessEditCellProps: (params) => genericPreProcessEditCellProps(params, { incrementBy: 1000, max: 1000 }),
        },
        {
            field: "Status(Approve/Reject)",
            width: 150,
            align: 'center',
            headerAlign: 'center',
            editable: true,
            renderCell: (params) => {
                const isApprove = params.row.isApprove;
                const id = params.row.id;
                const isLoading = loadingState[id] || false;
                return (
                    isLoading ?
                        <CircularProgress size={20} sx={{ marginLeft: '20px' }} />
                        :
                        (
                            <Switch
                                color='success'
                                checked={isApprove}
                                onChange={() => handleChange(params)}
                            />)
                );
            }
        },
    ];

    const handleClose = (e, reason) => {
        if (reason !== "backdropClick") {
            dispatch(setExternalUserManagementModalState(false));
        }
    };

    const isCellEditable = (params) => {
        // Allow editing for all cells except the 'age' and 'name' fields in rows where age is odd
        if (params.row.planType !== 'Enterprise' && (params.field === 'audienceCount' || params.field === 'cardCount' || params.field === 'audienceCost' || params.field === 'cardCost')) {
            return false;
        }

        if (params.row.isActivePlan && (params.field === 'audienceCount' || params.field === 'cardCount' || params.field === 'audienceCost' || params.field === 'cardCost' || params.field === 'planType' || params.field === 'validity')) {
            return false;
        }
        return true;
    };

    const handleBackdropClick = (event) => {
        event.stopPropagation();
        return false;
    };

    const getRowSpacing = React.useCallback((params) => {
        return {
            top: params.isFirstVisible ? 0 : 5,
            bottom: params.isLastVisible ? 0 : 5,
        };
    }, []);

    const StyledGridOverlay = styled('div')(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        '& .ant-empty-img-1': {
            fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
        },
        '& .ant-empty-img-2': {
            fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
        },
        '& .ant-empty-img-3': {
            fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
        },
        '& .ant-empty-img-4': {
            fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
        },
        '& .ant-empty-img-5': {
            fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
            fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
        },
    }));

    function CustomNoRowsOverlay() {
        return (
            <StyledGridOverlay>
                <svg
                    style={{ flexShrink: 0 }}
                    width="240"
                    height="200"
                    viewBox="0 0 184 152"
                    aria-hidden
                    focusable="false"
                >
                    <g fill="none" fillRule="evenodd">
                        <g transform="translate(24 31.67)">
                            <ellipse
                                className="ant-empty-img-5"
                                cx="67.797"
                                cy="106.89"
                                rx="67.797"
                                ry="12.668"
                            />
                            <path
                                className="ant-empty-img-1"
                                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
                            />
                            <path
                                className="ant-empty-img-2"
                                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
                            />
                            <path
                                className="ant-empty-img-3"
                                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
                            />
                        </g>
                        <path
                            className="ant-empty-img-3"
                            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
                        />
                        <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
                            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
                            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
                        </g>
                    </g>
                </svg>
                <Box sx={{ mt: 1, color: currentTheme === 'light' ? '#000' : '#FFF' }}>No Rows</Box>
            </StyledGridOverlay>
        );
    }

    const initialState = {
        pagination: {
            paginationModel: {
                pageSize: 6,
            },
        },
        columns: {
            columnVisibilityModel: {
                phoneNumber: false,
                createdCards: false,
                cardLimit: false
            }
        },
    };

    return (<>
        <Dialog
            open={externalUserManagementModalState}
            fullScreen
            onClose={handleClose}
            onBackdropClick={handleBackdropClick}
            PaperProps={{ sx: { width: '90%', height: '90%', borderRadius: "0px", boxShadow: currentTheme === "light" ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : '0px 4px 10px rgba(255, 255, 255, 0.5)' } }}
            sx={{
                backdropFilter: "blur(2px)",
            }}
        >
            <DialogTitle textAlign="center" sx={{ p: 3, fontSize: 16, lineHeight: '20px' }}>External User Accounts Management</DialogTitle>
            <Box sx={{ height: '100%', width: '100%', padding: 5 }}>
                <CloseIcon onClick={handleClose} sx={{
                    cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,
                }} />
                <DataGrid
                    sx={{
                        [`& .${gridClasses.row}`]: {
                            borderLeft: '2.5px solid #3C4DE7',
                            borderRadius: '0 10px 10px 0',
                            bgcolor: currentTheme === 'light' ? grey[200] : grey[900],
                            color: currentTheme === 'light' ? grey[900] : grey[200],
                        },
                        '& .MuiDataGrid-columnHeaders': {
                            border: 'none',
                            color: grey[600],
                            '&:hover': {
                                color: '#3C4DE7',
                            },
                        },
                        '& .MuiDataGrid-iconSeparator': {
                            display: 'none',
                        },
                        '& .MuiDataGrid-cell': {
                            border: 'none',
                        },
                        '& .MuiDataGrid-footerContainer ': {
                            border: 'none',
                        },
                        '& .MuiTablePagination-displayedRows': {
                            color: currentThemeColor,
                        },
                        '& .MuiButtonBase-root ': {
                            color: currentTheme === 'light' ? grey[900] : grey[200],
                        },
                        '& .MuiDataGrid-selectedRowCount': {
                            color: currentThemeColor
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            color: currentTheme === 'light' ? grey[900] : grey[200],
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                            width: '0.4em',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
                            background: currentTheme === 'light' ? 'rgba(0,0,0,0.1)' : 'rgba(255,255,255,0.1)',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                            borderRadius: "10px",
                            boxShadow: "inset 0 0 6px rgba(0,0,0,.3)",
                            backgroundColor: currentTheme === 'light' ? '#f5f5f5' : '#494848',
                        },
                        '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                            background: currentTheme === 'light' ? '#494848' : '#f5f5f5',
                        },
                        border: 'none',
                        '& .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
                            backgroundColor: currentTheme === 'light' ? '#f4f1f1' : '#121331',
                        },
                    }}
                    initialState={initialState}
                    getRowSpacing={getRowSpacing}
                    pageSizeOptions={[6]}
                    // disableColumnMenu
                    disableSorting
                    disableSelectionOnClick
                    rows={tableData}
                    columns={columns}
                    isCellEditable={isCellEditable}
                    editMode="cell"
                    processRowUpdate={processRowUpdate}
                    onProcessRowUpdateError={handleProcessRowUpdateError}
                    slots={{
                        toolbar: QuickSearchToolbar,
                        noRowsOverlay: CustomNoRowsOverlay,
                        columnMenu: CustomColumnMenu
                    }}
                />
            </Box>
        </Dialog >
    </>);
}

export default ExternalUserManagementModal;