import React, { useEffect } from "react";
import mapboxgl from "mapbox-gl";
import { appName, mapboxApiToken } from "../../../config/config";
import { Box, Button, Grid, Link } from "@mui/material";
import { connect } from "react-redux";
import { Chart, HambergerMenu, Location, SearchNormal1 } from "iconsax-react";

import logo from '../../../assets/images/logo.svg';
import logoBlack from '../../../assets/images/logo-black.svg';
import Image from "mui-image";
import { setMobileAudienceDetailsState, setMobileGeneralStatState, setMobileMenuBarState, setMobileSearchAreaState, setUploadAudienceModalState } from "../../../redux/slices/common";
import { useWindowWidth } from "../../../utils/browser";
import { useLocation, useNavigate } from "react-router-dom";
import SearchAutoComplete from "../search/SearchAutoComplete";
import { getPermission } from "../../../utils/cryptograpgy";

mapboxgl.accessToken = `${mapboxApiToken}`;

const Header = ({ mobileMenuBarState, mobileSearchAreaState, currentTheme, setMobileMenuBarState, setMobileSearchAreaState, setUploadAudienceModalState, mobileGeneralStatState, setMobileGeneralStatState, mobileAudienceDetailsState, setMobileAudienceDetailsState }) => {

  const browserWidth = useWindowWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const uploadPoiPermission = getPermission('upload_poi');
  const searchPermission = getPermission('i_search');

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (browserWidth > 1200) {
        setMobileMenuBarState(false);
        setMobileSearchAreaState(false);
        setMobileGeneralStatState(false);
      }
    }

    return () => {
      mounted = false;
    }
  }, [browserWidth]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setMobileMenuBarState(false);
      setMobileSearchAreaState(false);
      setMobileGeneralStatState(false);
    }

    return () => {
      mounted = false;
    }
  }, [location?.pathname]);

  const handleMobileMenuBarOpen = () => {
    setMobileMenuBarState(true);
  }

  const handleMobileMenuBarClose = () => {
    setMobileMenuBarState(false);
  }

  const handleMobileStatOpen = () => {
    if (location?.pathname?.includes('/audience/')) {
      setMobileAudienceDetailsState(true);
    } else {
      setMobileGeneralStatState(true);
    }
  }

  const handleMobileGeneralStatClose = () => {
    setMobileGeneralStatState(false);
  }

  const handleMobileAudienceStatClose = () => {
    setMobileAudienceDetailsState(false);
  }

  const handleMobileSearchAreaOpen = () => {
    if (searchPermission) {
      setMobileSearchAreaState(true);
    }
  }

  const handleMobileSearchAreaClose = () => {
    setMobileSearchAreaState(false);
  }

  const handleLocationButton = () => {
    if (uploadPoiPermission) {
      setUploadAudienceModalState(true);
    }
  }

  const goToHomepage = () => {
    navigate('/home');
  }

  return (<>
    <Box className="HeaderArea" sx={{ position: 'fixed', top: 0, left: 0, width: '100%', zIndex: 1, transition: '.3s', px: browserWidth > 500 ? 4 : 2, py: browserWidth > 500 ? 3 : 1.5 }}>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={2} md={3}>
          <Button variant="text" className="menu-toggle-button" disableRipple disableFocusRipple disableElevation onClick={() => handleMobileMenuBarOpen()} sx={{ minWidth: browserWidth > 500 ? '60px' : 'initial' }}>
            <HambergerMenu
              size={browserWidth > 500 ? "32" : "24"}
              color={currentTheme === 'light' ? '#010412' : '#fff'}
            />
          </Button>
        </Grid>
        <Grid item xs={5} md={6}>
          <Box className={`HeaderLogo`}>
            <Link href='/home'>
              <Image fit="contain" src={currentTheme === 'light' ? logoBlack : logo} alt={appName} />
            </Link>
            {/* <Box onClick={() => goToHomepage()} sx={{ cursor: 'pointer' }}>
              <Image fit="contain" src={currentTheme === 'light' ? logoBlack : logo} alt={appName} />
            </Box> */}
          </Box>
        </Grid>
        <Grid item xs={5} md={3}>
          <Box className={`HeaderActions`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} >
            {searchPermission &&
              <Button variant="text" className="HeaderSearchButton" disableRipple disableFocusRipple disableElevation onClick={() => handleMobileSearchAreaOpen()} sx={{ minWidth: browserWidth > 500 ? '60px' : 'initial' }}>
                <SearchNormal1
                  size={browserWidth > 500 ? "32" : "24"}
                  color={currentTheme === 'light' ? '#010412' : '#fff'}
                />
              </Button>
            }
            {uploadPoiPermission &&
              <Button variant="text" className="HeaderLocationButton" disableRipple disableFocusRipple disableElevation onClick={() => handleLocationButton()} sx={{ minWidth: browserWidth > 500 ? '60px' : 'initial' }}>
                <Location
                  size={browserWidth > 500 ? "32" : "24"}
                  color={currentTheme === 'light' ? '#010412' : '#fff'}
                />
              </Button>
            }
            {!location?.pathname?.includes('/search') &&
              <Button variant="text" className="HeaderStatButton" disableRipple disableFocusRipple disableElevation onClick={() => handleMobileStatOpen()} sx={{ minWidth: browserWidth > 500 ? '60px' : 'initial' }}>
                <Chart
                  size={browserWidth > 500 ? "32" : "24"}
                  color={currentTheme === 'light' ? '#010412' : '#fff'}
                />
              </Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Box>

    {mobileSearchAreaState && searchPermission &&
      <Box className="MobileSearchArea" sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 100, transition: '.3s', p: browserWidth > 500 ? 10 : 3 }}>
        <SearchAutoComplete
          hasUploadPoiButton={false}
          hasMobileSearchCloseButton={true}
          handleMobileSearchAreaClose={handleMobileSearchAreaClose}
        />
      </Box>
    }

    {mobileMenuBarState &&
      <div className="overlay" onClick={() => handleMobileMenuBarClose()}></div>
    }

    {mobileGeneralStatState &&
      <div className="overlay" onClick={() => handleMobileGeneralStatClose()}></div>
    }

    {mobileAudienceDetailsState &&
      <div className="overlay" onClick={() => handleMobileAudienceStatClose()}></div>
    }
  </>);
};

const mapStateToProps = (state) => ({
  currentTheme: state.theme.currentTheme,
  mobileMenuBarState: state.common.mobileMenuBarState,
  mobileSearchAreaState: state.common.mobileSearchAreaState,
  mobileGeneralStatState: state.common.mobileGeneralStatState,
  mobileAudienceDetailsState: state.common.mobileAudienceDetailsState
});

export default connect(mapStateToProps, { setMobileMenuBarState, setMobileSearchAreaState, setUploadAudienceModalState, setMobileGeneralStatState, setMobileAudienceDetailsState })(Header);
