import { getDefaultTheme } from "../utils/theme";

export const currentTheme = getDefaultTheme();
export const horiziontalStackBarOptions = {
  chart: {
    type: 'bar',
    backgroundColor: 'transparent',
    height: 60,
    margin: 0,
    events: {
      load: function (event) {
        event.target.reflow();
      }
    }
  },
  title: false,
  xAxis: {
    visible: false,
    minPadding: 0,
    maxPadding: 0,
  },
  yAxis: {
    visible: false,
    reversedStacks: false,
    endOnTick: false,
    minPadding: 0,
    maxPadding: 0,
  },
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: true,
    headerFormat: '',
    pointFormat: '<b>{series.name}: {point.y:.2f}</b>'
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      pointWidth: 20,
      borderWidth: 0,
    }
  },
  credits: {
    enabled: false
  },
};

export const thinDonutOptions = {
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    margin: 0,
    style: {
      'width': '100%',
      'float': 'right'
    }
  },
  title: false,
  legend: {
    enabled: false
  },
  tooltip: {
    enabled: false
  },
  plotOptions: {
    series: {
      borderWidth: 0,
      enableMouseTracking: false,
      states: {
        hover: {
          enabled: false
        }
      }
    }
  },
  credits: {
    enabled: false
  },
};


export const thickDonutOptions = {
  chart: {
    type: 'pie',
    backgroundColor: 'transparent',
    margin: 0,
    style: {
      'width': '100%',
    }
  },
  title: false,
  legend: {
    enabled: false
  },
  tooltip: {
    headerFormat: '',
    pointFormat: '{point.title} <b>{point.value.toFixed(2)}</b>'
  },
  plotOptions: {
    series: {
      borderWidth: 1,
      borderColor: currentTheme === 'light' ? '#fff' : "#010412",
      showInLegend: false,
      states: {
        hover: {
          enabled: false
        },
        inactive: {
          opacity: 1
        }
      }
    }
  },
  credits: {
    enabled: false
  },
};

export const columnBarWithImageOptions = {
  chart: {
    type: 'column',
    // styledMode: true,
    backgroundColor: 'transparent',
    height: 300,
    width: 500,
    margin: 25,
    events: {
      load: function (event) {
        event.target.reflow();
      }
    }
  },
  title: false,
  xAxis: {
    type: 'category',
    labels: {
      padding: 1,
      style: {
        fontSize: '12px',
        // color: '#2553FF',
        paddingTop: 0,

      }
    },
    gridLineColor: "#010412",
    lineColor: "#010412",
  },
  yAxis: {
    visible: false,
    minPadding: 0,
    maxPadding: 0,
  },
  legend: {
    enabled: false
  },
  tooltip: {
    backgroundColor: 'transparent',
    enabled: false,
    //padding: 100
  },
  plotOptions: {
    column: {
      borderRadius: 5
    },
    series: {
      grouping: true,
      pointWidth: 55,
      borderWidth: 0,
      states: {
        hover: {
          enabled: true
        },
        inactive: {
          opacity: 1
        }
      }
    }
  },
  credits: {
    enabled: false
  },
};

export const barChartOptions = {
  chart: {
    type: 'column',
    // styledMode: true,
    backgroundColor: 'transparent',
    height: 300,
    width: 700,
    margin: 25,
    events: {
      load: function (event) {
        event.target.reflow();
      }
    }
  },
  title: false,
  xAxis: {
    type: 'category',
    labels: {
      padding: 1,
      style: {
        fontSize: '12px',
        // color: '#2553FF',
        paddingTop: 0,

      }
    },
    gridLineColor: "#010412",
    lineColor: "#010412",
  },
  yAxis: {
    visible: false,
    minPadding: 0,
    maxPadding: 0,
  },
  legend: {
    enabled: false
  },
  tooltip: {
    backgroundColor: 'transparent',
    enabled: false,
    //padding: 100
  },
  plotOptions: {
    column: {
      borderRadius: 5
    },
    series: {
      grouping: true,
      pointWidth: 55,
      borderWidth: 0,
      states: {
        hover: {
          enabled: true
        },
        inactive: {
          opacity: 1
        }
      }
    }
  },
  credits: {
    enabled: false
  },
};