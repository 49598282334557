import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Box, Modal, TextField, Typography } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useForm } from "react-hook-form";
import { LoadingButton } from "@mui/lab";
import Image from "mui-image";
import CloseIcon from '@mui/icons-material/Close';
import PhoneInput from "react-phone-input-2";
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import AuthService from "../../../../services/api/auth.service";
import mail from "../../../../assets/images/icons/svg/sms.svg";
import compicon from "../../../../assets/images/icons/svg/office.svg";
import linkedin from "../../../../assets/images/icons/svg/linkedin.svg";
import user from "../../../../assets/images/icons/svg/user.svg";
import logoBlack from "../../../../assets/images/logo-black.svg";
import logo from "../../../../assets/images/logo.svg";
import "react-phone-input-2/lib/bootstrap.css";

const DemoAccountModal = ({ demoAccountModalOpen, handleDemoAccountModalClose }) => {

  const [isSubmitting, setIsSubmitting] = useState(false);
  const { register, reset, handleSubmit, formState: { errors }, } = useForm({ mode: "onChange" });
  const [responseMessage, setResponseMessage] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [countryName, setCountryName] = useState('+1')

  const [phone, setPhone] = useState("");
  const { currentTheme } = useSelector((state) => state.theme);

  const successCallback = (position) => {
    // console.log(position.coords.latitude);
    getCountryFromCoordinates(position.coords.latitude, position.coords.longitude);
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  navigator.geolocation.getCurrentPosition(successCallback, errorCallback);

  const getCountryFromCoordinates = (latitude, longitude) => {
    const mapboxAccessToken = 'pk.eyJ1IjoicmFmaXVsLW1lbW9iIiwiYSI6ImNsY3NoeGgwZDBmaTQzeHAxZ2JmcnlibTAifQ.l8EHVjWKI-Dur3R_tMBIPQ';

    fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${longitude},${latitude}.json?access_token=${mapboxAccessToken}`)
      .then(response => response.json())
      .then(data => {
        console.log(data)
        const features = data.features;
        if (features.length > 0) {
          // The country name is often available in the 'country' or 'country_name' properties
          const countryName = features[6].properties.short_code
          console.log(`User's Country: ${countryName}`);
          setCountryName(countryName);
        } else {
          console.error('Country data not found.');
        }
      })
      .catch(error => {
        console.error('Error fetching country data:', error);
      });
  };

  // Example of how to use it with the user's coordinates
  // getCountryFromCoordinates(52.5200, 13.4050); // Replace with user's coordinates


  const handlePhoneChange = (value, country, event, formattedValue) => {
    // Extract the country code
    // Update the phone and countryCode state variables
    const inputString = formattedValue;
    const spaceIndex = inputString.indexOf(' ');
    let phoneNo;
    if (spaceIndex !== -1) {
      const firstPart = inputString.slice(0, spaceIndex);
      const phoneNumberWithSpaces = inputString.slice(spaceIndex + 1);
      const phoneNumber = phoneNumberWithSpaces.replace(/\s+/g, ''); // Remove spaces from the phone number

      phoneNo = firstPart + "-" + phoneNumber;
    } else {
      console.log('No space found in the input string.');
    }
    setPhone(phoneNo);
  };

  const handleClose = (e, reason) => {
    reset();
    if (reason == "backdropClick" && isSuccess) {
      handleDemoAccountModalClose();
      setIsSuccess(false);
    }
  }

  const handleClick = () => {
    setIsSuccess(false);
    handleDemoAccountModalClose();
  }

  const onSubmit = (values) => {
    // console.log("values", values);
    // Define validation functions for email and LinkedIn URL
    function isEmailValid(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const blockedDomains = ["gmail.com", "yahoo.com", "outlook.com", "hotmail.com"];

      if (emailRegex.test(email)) {
        const emailDomain = email.split('@')[1];
        if (!blockedDomains.includes(emailDomain)) {
          return 'valid'; // Email is valid and not from a blocked domain
        } else {
          return 'blockedDomain'; // Email is from a blocked domain
        }
      }

      return 'invalidFormat'; // Email format is invalid
    }

    function isLinkedInValid(linkedin) {
      return linkedin.includes("linkedin.com");
    }

    // Perform the validation checks
    const emailValidationResult = isEmailValid(values.email);

    if (emailValidationResult === 'invalidFormat') {
      setResponseMessage({
        type: "error",
        message: "Invalid Email Address",
      });
      return; // Stop further validation
    } else if (emailValidationResult === 'blockedDomain') {
      setResponseMessage({
        type: "error",
        message: "Please enter your business email address",
      });
      return; // Stop further validation
    } else if (!isLinkedInValid(values.linkedin)) {
      setResponseMessage({
        type: "error",
        message: "Invalid LinkedIn URL",
      });
    } else {
      // All validations passed, construct the queryParams object
      const queryParams = {
        username: values.name,
        companyName: values.companyname,
        email: values.email,
        phoneNumber: phone,
        linkedInUrl: values.linkedin,
        isDemoUser: true,
      };

      // Proceed with your API call using the valid queryParams object
      setIsSubmitting(true);
      AuthService.register(queryParams).then(
        (data) => {
          if (data) {
            setIsSubmitting(false);
            // window.location.reload();
            setIsSuccess(true);
            setResponseMessage({
              type: "success",
              message: "Contact Sales team for approval",
            });
            reset();
            setPhone('');
            setTimeout(() => {
              // setIsSuccess(false);
              setResponseMessage();
            }, 4000);
          }
          return Promise.resolve();
        },
        (error) => {
          setIsSubmitting(false);
          console.log(error);
          setResponseMessage({
            type: "error",
            message: error.response.data.message ? error.response.data.message : error.response.data,
          });
          return Promise.reject();
        }
      );
    };
  }

  return (
    <>
      <Modal
        open={demoAccountModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(15px)",
        }}
      >
        {isSuccess ?
          <>
            <Box
              className="form-container-requestdemomodal"
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                p: 4,
                maxWidth: 400,
                width: "100%",
                textAlign: "center",
                backgroundColor: "white",
                borderRadius: 4,
              }}
            >
              <Box className="login-title" sx={{ mb: 4 }}>
                <Box className="login-logo">
                  <img
                    src={currentTheme === "light" ? logoBlack : logo}
                    alt="logo"
                    style={{ height: 100 }}
                  />
                </Box>
                <Box
                  sx={{
                    textAlign: "center",
                    fontSize: [20, 24],
                    lineHeight: "30px",
                    mt: 1,
                  }}
                  className="logo-title"
                >
                  Curate, Visualize, Activate
                </Box>
                <MarkEmailReadIcon sx={{ color: "#3C4DE7", width: "100px", height: "100px", mt: 2 }} />
              </Box>

              <Typography variant="body1" sx={{ mb: 1, mt: 2, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                Congratulations!
              </Typography>
              <Typography variant="body1" sx={{ mb: 2, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                Your demo account request was submitted successfully. Check your email for confirmation and further details.
              </Typography>

              <Box sx={{ mt: 4 }}>
                <Typography variant="body2" sx={{ mb: 2, mt: 20, color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                  © Allpings
                </Typography>
                <Typography variant="body2" sx={{ color: currentTheme === 'dark' ? '#FFF' : '#000' }}>
                  Reach us at{" "}
                  <a
                    href="mailto:support@allpings.com"
                    style={{ color: "inherit", textDecoration: "underline" }}
                  >
                    support@allpings.com
                  </a>
                </Typography>
                <a
                  href="/"
                  onClick={handleClick}
                >
                  Go back
                </a>
              </Box>
            </Box>
          </>
          :


          <Box
            className="form-container-requestdemomodal"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <Box
              className="login-title"
              sx={{
                textAlign: "center",
                mb: 6,
                mt: 2,
                fontSize: 32,
                lineHeight: "40px",
                fontWeight: 700,
              }}
            >
              Request a Demo
            </Box>
            {/* <Divider color={"grey"}/> */}
            {responseMessage && (
              <Alert
                severity={responseMessage.type}
                variant="filled"
                sx={{ mb: 2 }}
              >
                <strong>{responseMessage.message}</strong>
              </Alert>
            )}

            <Box
              component="form"
              noValidate
              autoComplete="off"
              onSubmit={handleSubmit(onSubmit)}
              type="reset"
            >
              <CloseIcon onClick={handleDemoAccountModalClose} sx={{
                cursor: 'pointer', fontSize: 32, padding: 0.5, background: '#FF0000', color: '#FFF', position: 'absolute', top: 0, right: 0,
              }} />
              <Box>
                {/* <Box className="form-label" sx={{ fontSize: 18, fontWeight: 400, mb: 3 }}>Enter your email address</Box> */}
                <TextField
                  autoFocus
                  required
                  fullWidth
                  error={!!errors["name"]}
                  helperText={errors["name"] ? errors["name"].message : ""}
                  {...register("name", {
                    required: "Name is required",
                  })}
                  sx={{ mb: 4 }}
                  type="text"
                  placeholder="Name"
                  variant="standard"
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image
                          src={user}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  fullWidth
                  error={!!errors["companyname"]}
                  helperText={
                    errors["companyname"] ? errors["companyname"].message : ""
                  }
                  {...register("companyname", {
                    required: "Company name is required",
                  })}
                  sx={{ mb: 4 }}
                  type="text"
                  placeholder="Company Name"
                  variant="standard"
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image
                          src={compicon}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  required
                  fullWidth
                  error={!!errors["email"]}
                  helperText={errors["email"] ? errors["email"].message : ""}
                  {...register("email", {
                    required: "Email is required",
                  })}
                  sx={{ mb: 4 }}
                  type="email"
                  placeholder="Company Email"
                  variant="standard"
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image
                          src={mail}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  required
                  fullWidth
                  error={!!errors["linkedin"]}
                  helperText={errors["linkedin"] ? errors["linkedin"].message : ""}
                  {...register("linkedin", {
                    required: "LinkedIn URL is required",
                  })}
                  sx={{ mb: 4 }}
                  type="text"
                  placeholder="LinkedIn URL"
                  variant="standard"
                  InputProps={{
                    style: { paddingBottom: "12px", fontSize: "18px" },
                    className: "form-control",
                    startAdornment: (
                      <InputAdornment position="start" sx={{ ml: 1, mr: 2 }}>
                        <Image
                          src={linkedin}
                          alt="mail"
                          style={{ width: 20, height: 20, objectFit: "contain" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />

                <PhoneInput
                  country={countryName}
                  enableSearch={true}
                  value={phone}
                  onChange={handlePhoneChange}
                  inputStyle={{
                    // border:'none',  
                    // borderBottom: '1px solid grey', // You can adjust the color and style as needed
                    borderRadius: '0',
                    width: '100%',
                    background: currentTheme === 'dark' ? '#010412' : '#FFF',
                    color: currentTheme === 'dark' ? '#FFF' : '#000',
                  }}
                />
              </Box>
              <Box sx={{ textAlign: "center" }}>
                <LoadingButton
                  variant="contained"
                  type="submit"
                  loading={isSubmitting}
                  className="form-button"
                  sx={{
                    py: "0.8rem",
                    mt: 3.5,
                    textTransform: "capitalize",
                    fontSize: 20,
                    fontWeight: 700,
                    borderRadius: 1.5,
                    width: "90%",
                  }}
                >
                  Register
                </LoadingButton>
              </Box>
            </Box>

          </Box>
        }
      </Modal>
    </>
  );
};

export default DemoAccountModal;