import React from 'react';
import { Box } from '@mui/material';
import MenuSideBar from '../../components/common/sidebar/menu-sidebar/MenuSideBar';
import SearchAutoComplete from '../../components/common/search/SearchAutoComplete';
import CrumbSlider from '../../components/common/slider/crumb-slider/CrumbSlider';
import HomepageDetails from '../../components/common/details/home-page-details/HomepageDetails';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import CrumbService from '../../services/api/crumb.service';
import { setCrumbs } from '../../redux/slices/crumb';
import { setActiveSlide, setAudiences, setCheckedUser, setCheckedSystem, setPoiIdsList, setPartnerPOIList, setCheckedSystemFilter, setBrandIdList } from '../../redux/slices/audience';
import AudienceService from '../../services/api/audience.service';
import { setMapPoints } from '../../redux/slices/map';
import ClusterdMap from '../../components/common/map/ClusterdMap';

const HomePage = () => {

  const dispatch = useDispatch();
  const { crumbs } = useSelector((state) => state.crumb);
  const { user } = useSelector((state) => state.auth.auth);
  const { selectedCountry } = useSelector((state) => state.auth);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      localStorage.removeItem('cards');
      dispatch(setActiveSlide(0));
      dispatch(setMapPoints([]));
      dispatch(setPoiIdsList(''));
      dispatch(setPartnerPOIList(''));
      dispatch(setBrandIdList(''));
      dispatch(setCheckedUser([]));
      dispatch(setCheckedSystem([]));
      if (user && selectedCountry) {
        const queryParams = [
          `userId=${user.id}`,
          `countryId=${selectedCountry.id}`,
        ]
        CrumbService.getCrumbs(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              // const crumbsList = data.results.reverse();
              // const crumbsList = data.results.sort((a, b) => b.id - a.id);
              const crumbsList = data.results;
              // console.log("crumblist", crumbsList);
              dispatch(setCrumbs(crumbsList));
              localStorage.setItem("crumbList", JSON.stringify(crumbsList));
            } else {
              dispatch(setCrumbs([]));
              localStorage.setItem("crumbList", JSON.stringify([]));
            }
            return Promise.resolve();
          },
          (error) => {
            console.log(error);
            return Promise.reject();
          }
        );
        // console.log("queryparams", queryParams);
        AudienceService.getAudience(queryParams.join('&')).then(
          (data) => {
            if (data?.results?.length) {
              // const audienceList = data.results.reverse();
              const audienceList = data.results.sort((a, b) => b.id - a.id);
              // console.log("crumblist", audienceList);
              dispatch(setAudiences(audienceList));
            } else {
              dispatch(setAudiences([]));
            }
            return Promise.resolve();
          },
          (error) => {
            return Promise.reject();
          }
        )
      }
    }

    return () => {
      mounted = false;
    }
  }, [user, selectedCountry, dispatch]);

  return (<>
    <Box className='HomepageArea' sx={{ display: 'flex' }}>
      {/*<MenuSideBar />*/}
      <Box className="MapArea" sx={{ overflowX: 'hidden', height: '100vh', position: 'fixed', top: 0 }}>
        <ClusterdMap pointerState={true} />

        <Box className="SearchBoxArea" sx={{ position: 'absolute', top: 40, left: '50%', transform: 'translateX(-50%)', width: '65%', zIndex: 3 }}>
          <SearchAutoComplete />
        </Box>

        <Box className="CrumbSliderArea" sx={{ position: 'absolute', bottom: 40, left: '50%', transform: 'translateX(-50%)', width: '95%', zIndex: 2 }}>
          <CrumbSlider crumbList={crumbs} />
        </Box>
      </Box>
      <Box className="DetailsArea" sx={{ height: '100vh', position: 'fixed', top: 0 }}>
        <HomepageDetails />
      </Box>
    </Box>
  </>);
}

export default HomePage;