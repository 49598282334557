import { Box, Grid } from "@mui/material";
import HorizontalStackedBar from "../../chart/HorizontalStackedBar";
import styles from './AudienceAgeStatisticsBox.module.scss';

const AudienceAgeStatisticsBox = ({ stat, bottomLine = true }) => {

  return (<>
    {stat &&
      <Box className={`${styles.CrumbDetailsStatArea ?? ''}`} sx={{ mb: bottomLine ? 3 : 0, pb: bottomLine ? 3 : 0, borderBottom: bottomLine ? '1px solid #6F7288' : 'none', display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Box className="StatInfoArea" sx={{ mb: 0, p: 2, pb: 0, pt: 2, /*  border: '1px solid #262940', */ display: 'flex', flexDirection: 'column', position: 'relative' }}>
          {/* <Box className="StatInfoTitle" sx={{ py: 1, px: 1.6, fontSize: 16, lineHeight: '19px', position: 'absolute', top: -20, left: 14, background: '#010412' }}>{stat.title}</Box> */}
          {/* {console.log(stat)} */}
          <Box className="StatList" sx={{ mb: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
            {stat.charts?.length > 0 && stat.charts.map((chart, indexChart) => {
              return (
                <Box className="StatListItem" sx={{ mb: 1.5, display: 'flex', flexDirection: 'column', width: '100%' }} key={indexChart}>
                  <Box className="StatTitle" sx={{ fontSize: 12, lineHeight: '14px', color: '#6F7288' }}>{chart.name}</Box>
                  <Grid container spacing={3.5} alignItems="center">
                    <Grid item xs={9}>
                      <Box className="StatChart" sx={{ display: 'flex', width: '100%' }}>
                        <HorizontalStackedBar
                          data={chart.data}
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={3}>
                      <Box className="StatValue ff-tomorrow" sx={{ fontSize: 20, lineHeight: '24px', fontWeight: '500', color: `#fff` }}>{chart.value}</Box>
                    </Grid>
                  </Grid>
                </Box>
              )
            })}
          </Box>
        </Box>
      </Box>
    }
  </>);
};

export default AudienceAgeStatisticsBox;