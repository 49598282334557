import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import EastIcon from '@mui/icons-material/East';
import WestIcon from '@mui/icons-material/West';

import AudienceCard from "../../card/audience/AudienceCard";
import { setActiveSlide } from "../../../../redux/slices/audience";

import styles from './AudienceSlider.module.scss';
import { useDispatch, useSelector } from "react-redux";

function AudienceSlider({ audienceList, crumbDetails, handleDeactivateAudience, setAudienceList, handleActivateAudience, handleAudienceRefresh, handleAudienceAssign, handleAudienceRename, handleAudienceDelete, updateAudiences, comefrom }) {
  const { activeSlide } = useSelector((state) => state.audience);
  const [audiences, setAudiences] = useState(audienceList);
  const dispatch = useDispatch();

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      // console.log("audienceList", audienceList);
      setAudiences(audienceList);
    }

    return () => {
      mounted = false;
    }
  }, [audienceList]);

  const handleMouseEnter = (index) => {
    let audienceDummy = [...audiences];
    audienceDummy = audienceDummy.map((crumb, i) => {
      return {
        ...crumb,
        hover: index === i ? false : true
      }
    });
    setAudiences(audienceDummy);
  }
  const handleMouseLeave = () => {
    let audienceDummy = [...audiences];
    audienceDummy = audienceDummy.map((crumb) => {
      return {
        ...crumb,
        hover: false
      }
    });
    setAudiences(audienceDummy);
  }

  const handleSlideChange = (swiper) => {
    // console.log(swiper.activeIndex)
    dispatch(setActiveSlide(swiper.activeIndex));
  };

  return (<>
    {audiences?.length > 0 &&
      <Box className={`${styles.AudienceSliderWrapper ?? ''} AudienceSliderWrapper`} sx={{ position: 'relative' }}>
        <Swiper
          className={`${styles.AudienceSlider ?? ''} EqualHeight`}
          spaceBetween={0}
          slidesPerView={1}
          keyboard={true}
          mousewheel={true}
          modules={[Navigation]}
          loop={false}
          initialSlide={activeSlide}
          onSlideChange={handleSlideChange}
          navigation={{
            nextEl: '.AudienceSliderWrapper .swiper-button-next',
            prevEl: '.AudienceSliderWrapper .swiper-button-prev',
          }}
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 40
            },
            // when window width is >= 480px
            600: {
              slidesPerView: 2,
              spaceBetween: 30
            },
            900: {
              slidesPerView: 3,
              spaceBetween: 40
            },
            1200: {
              slidesPerView: 3,
              spaceBetween: 40
            },
            1400: {
              slidesPerView: 3,
              spaceBetween: 55
            },
            2000: {
              slidesPerView: 5,
              spaceBetween: 20
            }
          }}
        >
          {audiences.map((audience, index) => {
            return (
              <SwiperSlide key={index}>
                <AudienceCard
                  audience={audience}
                  crumbDetails={crumbDetails}
                  index={index}
                  handleMouseEnter={handleMouseEnter}
                  handleMouseLeave={handleMouseLeave}
                  handleDeactivateAudience={handleDeactivateAudience}
                  handleActivateAudience={handleActivateAudience}
                  handleAudienceRefresh={handleAudienceRefresh}
                  handleAudienceAssign={handleAudienceAssign}
                  handleAudienceRename={handleAudienceRename}
                  audienceList={audienceList}
                  setAudienceList={setAudienceList}
                  handleAudienceDelete={handleAudienceDelete}
                  updateAudiences={updateAudiences}
                  comefrom={comefrom}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>

        <div className="swiper-buttons">
          <div className="swiper-button-prev">
            <WestIcon sx={{ width: 18, height: 18 }} />
          </div>
          <div className="swiper-button-next">
            <EastIcon sx={{ width: 18, height: 18 }} />
          </div>
        </div>
      </Box>
    }
  </>);
}

export default AudienceSlider;