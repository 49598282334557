import React, { useState } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Logo from '../../../../assets/images/Vector.png';
import { DialogContentText } from '@mui/material';
import { Box } from '@mui/system';
import Image from 'mui-image';
import AudienceService from '../../../../services/api/audience.service';
import { setMessage } from '../../../../redux/slices/message';
import { setMessageSnackbarStateState } from '../../../../redux/slices/common';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function ApproveAudienceConfirmModal({ audienceId, approveAudienceModalState, setApproveAudienceModalState, setDeleteAudienceSuccessModalState, handleAudienceDelete }) {

  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setApproveAudienceModalState(false);
  };

  const handleConfirm = () => {
    const queryParams = [
      `id=${audienceId}`
    ]
    const formData = {
      isApprove: true,
      userId: user.id,
      countryId: selectedCountry.id,
    };
    // setIsSubmitting(true);
    AudienceService.approveAudience(queryParams.join('&'), formData).then(
      (data) => {
        if (data?.results) {
          dispatch(setMessage({
            type: "success",
            title: data?.message || "Audience Approved successfully",
          }));
          dispatch(setMessageSnackbarStateState(true));

          const backButtonUrl = localStorage.getItem('back-button-url')

          if (backButtonUrl === '/approve') {

            navigate('/approve');

          } else {

            const crumbID = localStorage.getItem('crumbID');
            const id = crumbID.replace(/"/g, '');
            // navigate('/crumb/' + id + '/audiences');
            navigate('/audience/' + audienceId)
            // setIsSubmitting(false);
          }


        }
        setApproveAudienceModalState(false);
        return Promise.resolve();
      },
      (error) => {
        dispatch(setMessage({
          type: "error",
          title: error?.response?.data?.message,
        }));
        dispatch(setMessageSnackbarStateState(true));
        setApproveAudienceModalState(true);
        // setIsSubmitting(false);
        return Promise.reject();
      }
    )

  };

  return (<>
    <Dialog
      open={approveAudienceModalState}
      onClose={handleClose}
      PaperProps={{ sx: { width: "35%", borderRadius: "0px" } }}
      sx={{
        backdropFilter: "blur(2px)",
      }}
    >
      <DialogContent sx={{ p: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: 100, height: 100 }}>
            <Image src={Logo} alt='logo' />
          </Box>
        </Box>
        <DialogTitle id="alert-dialog-title" textAlign="center" fontWeight={"bold"} fontSize={"25px"}>
          {"Confirm"}
        </DialogTitle>
        <DialogContentText id="alert-dialog-description" textAlign="center" color={"#6F7288"}>
          Are you sure you want  to approve the card?
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ padding: "0px" }}>
        <ButtonGroup fullWidth size='large' aria-label="outlined primary button group">
          <Button variant='flat' onClick={handleClose} style={{ textTransform: "none", borderRadius: "0px" }}>Cancel</Button>
          <Button variant='contained' onClick={() => handleConfirm()} sx={{ textTransform: "none", borderRadius: "0px" }}>Approve</Button>
        </ButtonGroup>
      </DialogActions>
    </Dialog>
  </>);
}

export default ApproveAudienceConfirmModal;