import React, { useState, useEffect } from "react";
import { Box, Button, List, ListItem, CircularProgress } from "@mui/material";
import Image from "mui-image";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector, useDispatch } from "react-redux";
import html2pdf from "html2pdf.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Briefcase,
  Global,
  Grammerly,
  Location,
  Mobile,
  Profile2User,
} from "iconsax-react";

import styles from "./AudiencePageDetails.module.scss";
import AudienceService from "../../../../services/api/audience.service";
import { getDougnutChartColorByIndex } from "../../../../utils/chart";
import { formatToGeoJson } from "../../../../utils/map";
import { setMapPoints, setPolygonData } from "../../../../redux/slices/map";
import DeleteAudienceConfirmModal from "../../modal/delete-audience-confirm-modal/DeleteAudienceConfirmModal";
import DeleteAudienceSuccessModal from "../../modal/delete-audience-success-modal/DeleteAudienceSuccessModal";
import ActivateCampaignModal from "../../modal/activate-campaign-modal/ActivateCampaignModal";
import CloneAudienceModal from "../../modal/clone-audience-modal/CloneAudienceModal";
import { getRoundedImageBase64FromUrl } from "../../../../utils/image";
import AudiencePageDetailsContent from "./AudiencePageDetailsContent";
import { getPermission } from "../../../../utils/cryptograpgy";
import { useWindowWidth } from "../../../../utils/browser";
import {
  setEnablePolygon,
  setMessageSnackbarStateState,
} from "../../../../redux/slices/common";
import { setMessage } from "../../../../redux/slices/message";
import { setAudienceIdsList } from "../../../../redux/slices/audience";
import { baseUrl } from "../../../../config/config";
import ApproveAudienceConfirmModal from "../../modal/approve-audience-confirm-modal/ApproveAudienceConfirmModal";

const AudiencePageDetails = ({ isCollapsed, handleCollapsed }) => {
  const browserWidth = useWindowWidth();
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteCardPermission = getPermission("delete_card");
  const cloneCardPermission = getPermission("clone_card");
  // const approveCardPermission = getPermission('approve_card');
  const activateCampaignCardPermission = getPermission(
    "activate_campaign_card"
  );
  const exportCampaignCardPermission = getPermission("export_card");

  const crumbDetailsMenu = [
    {
      icon: Global,
      iconColor: "#39BF8F",
      name: "Geographic",
    },
    {
      icon: Briefcase,
      iconColor: "#FC6B21",
      name: "Demographic",
    },
    {
      icon: Grammerly,
      iconColor: "#FCB221",
      name: "Psychographic",
    },
    {
      icon: Mobile,
      iconColor: "#060817",
      name: "Technographic",
    },
  ];

  const [selectedMenu, setselectedMenu] = useState("All");
  const [audienceDetails, setAudienceDetails] = useState(null);
  const [interest, setInterest] = useState(null);
  const [brandAffinity, setBrandAffinity] = useState(null);
  const [brandCategories, setBrandCategories] = useState(null);
  const [cardCategories, setCardCategories] = useState(null);
  // const [nationality, setNationality] = useState(null);
  const [gender, setGender] = useState(null);
  // const [houseHoldSize, setHouseHoldSize] = useState(null);
  const [topDevices, setTopDevices] = useState(null);
  const [topContentDtoList, setTopContentDtoList] = useState(null);
  const [ageRange, setAgeRange] = useState(null);
  const [carrier, setCarrier] = useState(null);
  const [os, setOS] = useState(null);
  const [apps, setTopapps] = useState(null);
  const [connectionType, setConnectionType] = useState(null);
  const [languages, setLanguages] = useState(null);
  const [deviceType, setDeviceType] = useState(null);
  const [crumbStatInfo, setCrumbStatInfo] = useState(null);
  const [deleteAudienceConfirmModalState, setDeleteAudienceConfirmModalState] =
    useState(false);
  const [deleteAudienceSuccessModalState, setDeleteAudienceSuccessModalState] =
    useState(false);
  const [activateCampaignModalState, setActivateCampaignModalState] =
    useState(false);
  const [cloneAudienceModalState, setCloneAudienceModalState] = useState(false);
  const [approveAudienceModalState, setApproveAudienceModalState] =
    useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { selectedCountry } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.auth.auth);
  const { currentTheme } = useSelector((state) => state.theme);
  const userFromLocalStorage = JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
    let mounted = true;
    if (mounted && params) {
      if (selectedCountry && params) {
        const queryParams = [
          `audienceId=${params.id}`,
          `countryId=${selectedCountry.id}`,
          `userId=${user != null ? user.id : userFromLocalStorage.id}`,
        ];
        AudienceService.getAudienceDetails(queryParams.join("&")).then(
          (data) => {
            const resultData = data?.results;
            // console.log("getAudienceDetails", resultData);
            if (resultData) {
              setAudienceDetails(resultData);
              const applyFilterParams = {
                visitPeriod: resultData.visitPeriod,
                geoRadius: resultData.geoRadius,
                weekParting: resultData.weekParting,
                dayParting: resultData.dayParting,
                audiences: resultData.audience,
                audienceId: params.id,
              };

              localStorage.setItem(
                "applyFilterParams",
                JSON.stringify(applyFilterParams)
              );

              setCrumbStatInfo([
                {
                  icon: Location,
                  iconColor: "#39BF8F",
                  text: "Locations Pin",
                  number: resultData.locationPin,
                },
                {
                  icon: Profile2User,
                  iconColor: "#3955BF",
                  text: "Total Unique Active Users",
                  number: resultData.audience
                    ? resultData.audience.toLocaleString()
                    : 0,
                },
              ]);

              generateDoughnutStatistics({
                title: "Brand Affinity",
                data: resultData?.brandAffinity,
                nameProperty: "name",
                setState: setBrandAffinity,
                brandAffinityOtherCount: resultData?.brandAffinityOtherCount,
              });

              generateDoughnutStatistics({
                title: "Brand Categories",
                data: resultData?.brandCategories,
                nameProperty: "name",
                setState: setBrandCategories,
                brandCategoriesOtherCount:
                  resultData?.brandCategoriesOtherCount,
              });

              generateDoughnutStatistics({
                title: "Related Brands",
                data: resultData?.cardCategory,
                nameProperty: "name",
                setState: setCardCategories,
                cardCategoryOtherCount: resultData?.cardCategoryOtherCount,
              });

              generateDoughnutStatistics({
                title: "Category",
                data: resultData?.topInterests,
                nameProperty: "name",
                setState: setInterest,
              });

              // generateDoughnutStatistics({
              //   title: 'Nationality',
              //   data: resultData?.nationality,
              //   nameProperty: "name",
              //   setState: setNationality,
              // });

              generateDoughnutStatistics({
                title: "Gender",
                data: resultData?.gender,
                nameProperty: "name",
                setState: setGender,
              });

              // generateDoughnutStatistics({
              //   title: 'Household Size',
              //   data: resultData?.houseHoldSize,
              //   nameProperty: "name",
              //   setState: setHouseHoldSize,
              // });

              generateDoughnutStatistics({
                title: "Top Devices",
                data: resultData?.topDevices,
                nameProperty: "name",
                setState: setTopDevices,
              });

              generateDoughnutStatistics({
                title: "Interest",
                data: resultData?.topContentDtoList,
                nameProperty: "name",
                setState: setTopContentDtoList,
              });

              generateDoughnutStatistics({
                title: "Carrier",
                data: resultData?.topCarrierList,
                nameProperty: "name",
                setState: setCarrier,
              });

              generateDoughnutStatistics({
                title: "Device OS",
                data: resultData?.topOs,
                nameProperty: "name",
                setState: setOS,
              });

              // generateDoughnutStatistics({
              //   title: 'Top Apps',
              //   data: resultData?.topApps,
              //   nameProperty: "name",
              //   setState: setTopapps,
              // });

              generateColumnBarStatistics({
                title: "Top Apps",
                data: resultData?.topApps,
                nameProperty: "name",
                setState: setTopapps,
              });

              generateDoughnutStatistics({
                title: "Device Types",
                data: resultData?.deviceTypes,
                nameProperty: "name",
                setState: setDeviceType,
              });

              generateDoughnutStatistics({
                title: "Connection Type",
                data: resultData?.connectionTypes,
                nameProperty: "name",
                setState: setConnectionType,
              });

              generateDoughnutStatistics({
                title: "Languages",
                data: resultData?.languages,
                nameProperty: "name",
                setState: setLanguages,
              });

              generateAgeStatistics({
                data: resultData?.ageRange,
                setState: setAgeRange,
              });

              const geojsonPoints = [];
              if (resultData.poiDetails?.length) {
                const places = resultData.poiDetails;
                for (
                  let indexPlaces = 0;
                  indexPlaces < places.length;
                  indexPlaces++
                ) {
                  const place = places[indexPlaces];
                  if (place?.poiDetailsDto?.length) {
                    const poiDetailsDtos = place.poiDetailsDto;
                    for (
                      let index = 0;
                      index < poiDetailsDtos.length;
                      index++
                    ) {
                      const poiDetailsDto = formatToGeoJson(
                        poiDetailsDtos[index],
                        place.colorName,
                        place.colorValue
                      );
                      if (poiDetailsDto) {
                        geojsonPoints.push(poiDetailsDto);
                      }
                    }
                  }
                }
              }
              // console.log("geojsonPoints", geojsonPoints);
              dispatch(setMapPoints(geojsonPoints));
              dispatch(setEnablePolygon(resultData.isPolygon));
              data.results?.isoChronData &&
                dispatch(setPolygonData(data.results.isoChronData));
            }
            setIsLoading(false);
            return Promise.resolve();
          },
          (error) => {
            // console.log(error?.response?.data?.message);
            setIsLoading(false);
            dispatch(setEnablePolygon(false));
            return Promise.reject();
          }
        );
      }
    }

    return () => {
      mounted = false;
      localStorage.removeItem("applyFilterParams");
    };
  }, [selectedCountry, params, dispatch]);

  const generateDoughnutStatistics = ({
    title,
    data,
    nameProperty,
    setState,
    brandAffinityOtherCount,
    brandCategoriesOtherCount,
    cardCategoryOtherCount,
  }) => {
    if (data?.length) {
      let option = {
        title: title,
        data: [],
      };
      let chartData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const color = getDougnutChartColorByIndex(index);
        let formattedPercentage;

        if (element.percentage < 1) {
          formattedPercentage = "1.00%";
        } else {
          formattedPercentage = `${element.percentage.toFixed(2)}%`;
        }
        const commonProperties = {
          y: element.percentage,
          color: color,
          title: element[nameProperty],
          value: formattedPercentage,
        };

        chartData.push(commonProperties);
      }
      option.data = chartData;

      if (brandAffinityOtherCount !== 0) {
        option.data.brandAffinityOtherCount = brandAffinityOtherCount;
      }
      if (brandCategoriesOtherCount !== 0) {
        option.data.brandCategoriesOtherCount = brandCategoriesOtherCount;
      }
      if (cardCategoryOtherCount !== 0) {
        option.data.cardCategoryOtherCount = cardCategoryOtherCount;
      }

      setState(option);
    }
  };

  const generateColumnBarStatistics = async ({ title, data, setState }) => {
    if (data?.length) {
      let option = {
        title: title,
        data: [],
        label: [],
      };
      let columnChartData = [];
      let scatterChartData = [];
      let labelData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        labelData.push(element.categoryName);
        const percentage = parseFloat(element.percentage.toFixed(2));
        columnChartData.push({
          name: element.categoryName,
          y: percentage,
          logo: element.apps[0].logo,
          apps: element.apps,
          logoName: element.apps[0].appName,
        });
      }
      option.label = labelData;
      option.data = [
        {
          type: "column",
          name: "Assigned",
          data: columnChartData,
          color: "#3C4DE7",
        },
        {
          type: "scatter",
          data: scatterChartData,
        },
      ];
      setState(option);
    }
  };

  const generateBarChartStatistics = async ({ title, data, setState }) => {
    if (data?.length) {
      let option = {
        title: title,
        data: [],
        label: [],
      };
      let columnChartData = [];
      let scatterChartData = [];
      let labelData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        labelData.push(element.name);
        const percentage = parseFloat(element.percentage.toFixed(2));
        columnChartData.push({
          name: element.name,
          y: percentage,
          color: getDougnutChartColorByIndex(index),
        });
      }
      option.label = labelData;
      option.data = [
        {
          type: "column",
          name: "Assigned",
          data: columnChartData,
          color: columnChartData.color,
        },
        {
          type: "scatter",
          data: scatterChartData,
        },
      ];
      setState(option);
    }
  };

  const generateAgeStatistics = ({ data, setState }) => {
    if (data?.length) {
      let option = {
        title: "Age Range",
        charts: [],
      };
      let chartData = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        const title =
          element.upperBoundAge === 0
            ? "55+"
            : `${element.lowerBoundAge}-${element.upperBoundAge}`;
        chartData.push({
          name: title,
          value: `${element.percentage.toFixed(2)}%`,
          data: [
            {
              name: title,
              data: [element.percentage],
              color: "#3C4DE7",
            },
            {
              name: "Others",
              data: [100 - element.percentage],
              color: "#212330",
            },
          ],
        });
      }
      option.charts = chartData;
      setState(option);
    }
  };

  const handleMenu = (menu) => {
    setselectedMenu(menu);
    if (isCollapsed) {
      handleCollapsed();
    }
  };

  const handleAudienceDeleteConfirmModal = () => {
    if (deleteCardPermission) {
      setDeleteAudienceConfirmModalState(true);
    }
  };

  const handleAudienceDelete = (audienceId) => {
    const queryParams = [`status=Deleted`];
    AudienceService.deleteAudience(audienceId, queryParams.join("&")).then(
      (data) => {
        if (data) {
          dispatch(
            setMessage({
              type: "success",
              // title: data?.message ? data.message : "Audience restored successfully",
              title: "Audience Deleted",
            })
          );
          dispatch(setMessageSnackbarStateState(true));
        }
      }
    );
  };

  const redirectToAudiences = (audienceId) => {
    const selectedAudience = JSON.parse(
      localStorage.getItem("selectedAudience")
    );
    if (
      selectedAudience &&
      selectedAudience.crumbDetails &&
      selectedAudience.audienceDetails.id == audienceId
    ) {
      navigate(`/crumb/${selectedAudience.crumbDetails.id}/audiences`);
    }
  };

  const activateCampaignHandler = () => {
    if (activateCampaignCardPermission) {
      setActivateCampaignModalState(true);
      // const queryParams = [
      //   `audienceId=${params.id}`,
      //   `partnerId=1`,
      //   `countryId=${selectedCountry.id}`,
      // ];
      // AudienceService.activateCampaign(queryParams.join('&')).then(
      //   (data) => {
      //     dispatch(setMessage({
      //       type: "success",
      //       title: "Card Activated Succefully",
      //     }));
      //     dispatch(setMessageSnackbarStateState(true));
      //   },
      //   (error) => {
      //     console.log(error);
      //   }
      // );
    }
  };

  const generatePDF = () => {
    if (exportCampaignCardPermission) {
      setTimeout(() => {
        const element = document.getElementById("screenshot");
        // console.log(element);
        // Set the options for html2pdf
        // const options = {
        //   margin: 0,
        //   filename: audienceDetails.name,
        //   image: { type: 'jpeg', quality: 1 },
        //   html2canvas: {
        //     backgroundColor: '#010412',
        //     dpi: 300,
        //     letterRendering: true,
        //     scale: 2,
        //     useCORS: true
        //   },
        //   pagebreak: {
        //     mode: ['css', 'legacy'],
        //     avoid: 'img'
        //   },
        //   jsPDF: {
        //     unit: 'pt',
        //     format: 'a4',
        //     orientation: 'portrait',
        //   },
        // };

        // Calculate the height of the content
        const height = element.offsetHeight;
        // Set a custom page size based on the height of the content
        const pageSize = {
          width: 800, // A4 width in points (default)
          height: height + 110, // Add some extra height to accommodate the content
        };
        // Set the options for html2pdf
        const crumbName = localStorage.getItem("crumbName");
        const selectedCountryCode = selectedCountry.code || "";
        const crumbname = crumbName || "";
        const audienceDetailsName = audienceDetails.name || "";

        // Get the current date and time formatted as "yyyy-mm-dd HH:MM:SS"
        const timestamp = new Date().toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false, // Use 24-hour format
        });

        // Replace spaces and colons with underscores to create a valid filename
        const sanitizedTimestamp = timestamp.replace(/[:\s]/g, "_");
        const options = {
          margin: 0,
          filename: `${selectedCountryCode}_${crumbname}_${audienceDetailsName}_${sanitizedTimestamp}`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: {
            backgroundColor: "#010412",
            dpi: 300,
            letterRendering: true,
            scale: 2,
            useCORS: true,
            width: pageSize.width, // Set the width to match the page size
            height: pageSize.height, // Set the height to match the content's height
          },
          jsPDF: {
            unit: "pt",
            format: [pageSize.width, pageSize.height], // Set the custom page size
            orientation: "portrait",
            pagebreak: {
              avoidElements: ".avoid-split", // Add the 'avoid-split' class to elements that should not be split
            },
          },
        };

        // Use html2pdf to export the component as a PDF
        html2pdf()
          .from(element)
          .set(options)
          .save()
          .then(function () {
            // while (element.hasChildNodes()) {
            //   element.removeChild(element.firstChild);
            // }
          });
      }, 1000);
    }
  };

  const cloneCard = (audienceDetails) => {
    if (cloneCardPermission) {
      // dispatch(setCloneAudienceModalState(true));
      const audiencesObject = {
        audienceList: [params.id],
        poiList: [],
        action: "clone",
        isPolygon: audienceDetails.isPolygon,
      };
      dispatch(setAudienceIdsList(audiencesObject));
      navigate("/search");
    }
  };

  const approveCard = () => {
    // if (approveCardPermission) {
    setApproveAudienceModalState(true);
    // }
  };

  const [expanded, setExpanded] = useState({
    brandAffinity: true,
    brandCategories: true,
    cardCategories: true,
    interest: false,
    ageRange: false,
    gender: false,
    topContentDtoList: false,
    topDevices: false,
    os: false,
    deviceType: false,
    carrier: false,
    apps: false,
    connectionType: false,
    languages: false,
  });

  const handleExpand = (key) => {
    setExpanded({ ...expanded, [key]: !expanded[key] });
  };

  return (
    <Box
      className={`${styles["theme-" + currentTheme]}`}
      sx={{ height: "100%" }}>
      {!isCollapsed && (
        <Button
          className={`${styles.LogoutButton ?? ""}`}
          sx={{
            width: 55,
            height: 55,
            minWidth: "initial",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            position: "absolute",
            left: -(55 / 2),
            top: 40,
            zIndex: 3,
          }}
          onClick={handleCollapsed}>
          <CloseIcon sx={{ width: 25, height: 25 }} />
        </Button>
      )}

      <Box
        className={`${styles.CrumbPageMenu ?? ""}`}
        sx={{
          position: "absolute",
          left: -50,
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: 1,
        }}>
        <List
          className={`${styles.CrumbPageMenuList ?? ""}`}
          sx={{
            p: 0,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
          }}>
          <ListItem
            className={`${styles.CrumbPageMenuListItem ?? ""}`}
            sx={{
              p: 0,
              width: "auto",
              position: "relative",
              transition: "0.5s",
              zIndex:
                crumbDetailsMenu.length +
                1 +
                (selectedMenu === "All" ? crumbDetailsMenu.length + 2 : 0),
            }}>
            <Button
              disableRipple
              className={`${styles.CrumbPageMenuListItemButton ?? ""} ${
                selectedMenu === "All" ? styles.Active : ""
              }`}
              sx={{
                px: 2,
                py: 1.7,
                display: "flex",
                alignItems: "center",
                textTransform: "capitalize",
                textAlign: "left",
                justifyContent: "flex-end",
                borderRadius: "12px 0px 0px 12px",
                fontSize: 16,
                fontWeight: "500",
                minWidth: "initial",
                transition: "all 0.5s",
                position: "relative",
              }}
              onClick={() => handleMenu("All")}>
              <Box
                className={`${styles.CrumbPageMenuListItemName ?? ""} ${
                  styles.All ?? ""
                }`}>
                All
              </Box>
            </Button>
          </ListItem>

          {crumbDetailsMenu?.length > 0 &&
            crumbDetailsMenu.map((menu, index) => {
              const MenuIcon = menu.icon;
              return (
                <ListItem
                  className={`${styles.CrumbPageMenuListItem ?? ""}`}
                  sx={{
                    p: 0,
                    mt: -1,
                    width: "auto",
                    position: "relative",
                    transition: "0.5s",
                    zIndex:
                      crumbDetailsMenu.length -
                      index +
                      (selectedMenu === menu.name
                        ? crumbDetailsMenu.length + 2
                        : 0),
                  }}
                  key={index}>
                  <Button
                    disableRipple
                    className={`${styles.CrumbPageMenuListItemButton ?? ""} ${
                      selectedMenu === menu.name ? styles.Active : ""
                    }`}
                    sx={{
                      px: 2,
                      py: 1.7,
                      display: "flex",
                      alignItems: "center",
                      textTransform: "capitalize",
                      textAlign: "left",
                      justifyContent: "flex-end",
                      borderRadius: "12px 0px 0px 12px",
                      fontSize: 16,
                      fontWeight: "500",
                      minWidth: "initial",
                      transition: "all 0.5s",
                      position: "relative",
                    }}
                    onClick={() => handleMenu(menu.name)}>
                    <Box
                      className={`${styles.CrumbPageMenuListItemIcon ?? ""}`}
                      sx={{
                        display: "flex",
                        color: menu.iconColor,
                        transition: "0.5s",
                      }}>
                      <MenuIcon sx={{ width: 25, height: 25 }} />
                    </Box>
                    <Box
                      className={`${styles.CrumbPageMenuListItemName ?? ""}`}
                      sx={{ transition: "0.5s" }}>
                      {menu.name}
                    </Box>
                  </Button>
                </ListItem>
              );
            })}
        </List>
      </Box>

      <Box
        className={`${styles.CrumbPageDetails ?? ""}`}
        sx={{
          px: browserWidth > 500 ? 6 : 3,
          py: browserWidth > 500 ? 5 : 2,
          overflowX: "hidden",
          overflowY: "auto",
          height: "100%",
          position: "relative",
          zIndex: 2,
          paddingLeft: "35px",
          paddingRight: "35px",
        }}>
        {isLoading ? (
          <Box
            sx={{
              color: "#3C4DE7",
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Box>
            <AudiencePageDetailsContent
              audienceDetails={audienceDetails}
              isPdfGenerate={false}
              crumbStatInfo={crumbStatInfo}
              handleAudienceDeleteConfirmModal={
                handleAudienceDeleteConfirmModal
              }
              activateCampaignHandler={activateCampaignHandler}
              generatePDF={generatePDF}
              cloneCard={cloneCard}
              approveCard={approveCard}
              selectedMenu={selectedMenu}
              brandAffinity={brandAffinity}
              brandCategories={brandCategories}
              cardCategories={cardCategories}
              expanded={expanded}
              handleExpand={handleExpand}
              ageRange={ageRange}
              interest={interest}
              gender={gender}
              topContentDtoList={topContentDtoList}
              topDevices={topDevices}
              os={os}
              deviceType={deviceType}
              carrier={carrier}
              connectionType={connectionType}
              languages={languages}
              apps={apps}
              deleteCardPermission={deleteCardPermission}
              cloneCardPermission={cloneCardPermission}
              // approveCardPermission={approveCardPermission}
              activateCampaignCardPermission={activateCampaignCardPermission}
              exportCampaignCardPermission={exportCampaignCardPermission}
              currentTheme={currentTheme}
            />
          </Box>
        )}
      </Box>

      {exportCampaignCardPermission && (
        <div
          id="screenshot"
          style={{
            backgroundColor: "#010412",
            color: "#fff",
          }}>
          <Image
            src={baseUrl + "images/pdf_export/Allpings-Report header.jpg"}
            alt=""
            width="auto"
            height="auto"
          />
          <div
            style={{
              padding: "80px",
              width: "800px",
            }}>
            <AudiencePageDetailsContent
              audienceDetails={audienceDetails}
              isPdfGenerate={true}
              crumbStatInfo={crumbStatInfo}
              handleAudienceDeleteConfirmModal={
                handleAudienceDeleteConfirmModal
              }
              activateCampaignHandler={activateCampaignHandler}
              generatePDF={generatePDF}
              cloneCard={cloneCard}
              approveCard={approveCard}
              selectedMenu={selectedMenu}
              brandAffinity={brandAffinity}
              brandCategories={brandCategories}
              cardCategories={cardCategories}
              expanded={expanded}
              handleExpand={handleExpand}
              ageRange={ageRange}
              interest={interest}
              gender={gender}
              topContentDtoList={topContentDtoList}
              topDevices={topDevices}
              os={os}
              deviceType={deviceType}
              carrier={carrier}
              connectionType={connectionType}
              languages={languages}
              apps={apps}
              deleteCardPermission={deleteCardPermission}
              cloneCardPermission={cloneCardPermission}
              // approveCardPermission={approveCardPermission}
              activateCampaignCardPermission={activateCampaignCardPermission}
              exportCampaignCardPermission={exportCampaignCardPermission}
              currentTheme={currentTheme}
            />
          </div>
          <Image
            src={
              baseUrl +
              "images/pdf_export/all-pings-header-and-footer-report.png"
            }
            alt=""
            width="800px"
            height="auto"
          />
        </div>
      )}

      {deleteCardPermission && (
        <DeleteAudienceConfirmModal
          audienceId={params.id}
          deleteAudienceConfirmModalState={deleteAudienceConfirmModalState}
          setDeleteAudienceConfirmModalState={
            setDeleteAudienceConfirmModalState
          }
          setDeleteAudienceSuccessModalState={
            setDeleteAudienceSuccessModalState
          }
          handleAudienceDelete={handleAudienceDelete}
        />
      )}

      {deleteCardPermission && (
        <DeleteAudienceSuccessModal
          audienceId={params.id}
          deleteAudienceSuccessModalState={deleteAudienceSuccessModalState}
          setDeleteAudienceSuccessModalState={
            setDeleteAudienceSuccessModalState
          }
          redirectToAudiences={redirectToAudiences}
        />
      )}

      {activateCampaignCardPermission && (
        <ActivateCampaignModal
          audienceId={params.id}
          activateCampaignModalState={activateCampaignModalState}
          setActivateCampaignModalState={setActivateCampaignModalState}
        />
      )}

      {cloneCardPermission && (
        <CloneAudienceModal
          audienceId={params.id}
          cloneAudienceModalState={cloneAudienceModalState}
          setCloneAudienceModalState={setCloneAudienceModalState}
        />
      )}
      {/* {approveCardPermission && */}
      <ApproveAudienceConfirmModal
        audienceId={params.id}
        approveAudienceModalState={approveAudienceModalState}
        setApproveAudienceModalState={setApproveAudienceModalState}
      />
      {/* } */}
    </Box>
  );
};

export default AudiencePageDetails;
